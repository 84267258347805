import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  getMobileOperatingSystem,
  isAndroidDevice,
  isIosDevice
} from "../../helpers/utils";
import IconSmartScan from "./../../assets/images/icon-smart-scan.png";

const SmartScanWorksPage = (props) => {
  const { QRCode } = props;

  function renderQRCodeOrNextButton() {
    let isMobileDevice = isAndroidDevice() || isIosDevice();

    if (!isMobileDevice) {
      return QRCode ? (
        <div>
          <div className="fs-2 text-secondary mt-5 mb-4 mb-md-4">
            You will need a phone to continue​
          </div>
          <p className="text-secondary mx-auto" style={{ maxWidth: "450px" }}>
            Scan this QR code to open your dental.com account on your phone to
            continue with Smart Scan.​
          </p>
          <figure className="">
            <img style={{ width: "35%" }} src={QRCode} alt="" />
          </figure>
        </div>
      ) : (
        <></>
      );
    } else {
      return (
        <Link
          className="btn btn-secondary btn-rounded btn-lg px-4 px-md-5 mt-3 mt-md-0 w-100 w-md-auto"
          to="/smart-scan-visit"
        >
          Next
        </Link>
      );
    }
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <div className="gth-main-header mb-3">
            <div className="heading-wrapper back-to-wrapper position-relative">
              <h1 className="title">Smart Scan</h1>
            </div>
          </div>
          <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
            <div className="fixed-button-bottom text-center">
              <div className="border border-transparent">
                <figure className="mt-2 mt-md-4 mb-4 mb-md-5">
                  <img
                    style={{ maxWidth: "100px" }}
                    src={IconSmartScan}
                    alt=""
                  />
                </figure>
                <h2
                  className="text-primary mb-4 mb-md-4 mx-auto"
                  style={{ maxWidth: "600px" }}
                >
                  Smart Scan uses artificial intelligence to detect suspicions
                  issues with your mouth and teeth.
                </h2>
                <h2 className="mb-4">How It Works</h2>
                <ul className="list-unstyled instructions-list text-start d-table mb-0 mx-auto">
                  <li className="mb-3 w-100 w-md-auto position-relative">
                    <i className="bi bi-chevron-right fs-5 position-absolute start-0"></i>{" "}
                    Capture images with our guided prompts​
                  </li>
                  <li className="mb-3 w-100 w-md-auto position-relative">
                    <i className="bi bi-chevron-right fs-5 position-absolute start-0"></i>{" "}
                    Receive a report with the Smart Scan's findings*​
                  </li>
                </ul>
                <p
                  className="text-secondary mx-auto mt-3 mt-md-4 mb-5 d-table security-inf text-md-center text-start"
                  style={{ maxWidth: "530px" }}
                >
                  <i class="bi bi-lock-fill fs-2 me-2"></i>
                  <b>We take your privacy seriously.</b> All images are secure,
                  confidential, and never shared with anyone. HIPAA Compliant
                </p>
                <small className="text-muted mx-auto my-3 my-md-4 d-table">
                  <em>
                    *For informational purposes only. Findings are not reviewed
                    by a licensed dentist and do not constitute a dental
                    diagnosis.
                  </em>
                </small>
                {renderQRCodeOrNextButton()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmartScanWorksPage;
