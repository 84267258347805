import React from "react";
import playIcon from "../../assets/images/rec-play-icon.png";
import pauseIcon from "../../assets/images/rec-pause-icon.png";

const ReceivedMessage = ({
  message,
  userImg,
  playBtnClicked,
  pauseBtnClicked,
  isPlaying,
  id
}) => {
  return (
    <>
      <div className="chatbox">
        <img src={userImg} className="author-img rounded-circle" />
        <div
          className="chat-msg msg-output"
          id={id}
          style={{ display: "table" }}
          dangerouslySetInnerHTML={{
            __html: message
          }}
        ></div>
        <div className="toggle" data-play="false">
          {!isPlaying ? (
            <img
              src={playIcon}
              className="author-img recPlay"
              onClick={playBtnClicked}
            />
          ) : (
            <img
              src={pauseIcon}
              className="author-img "
              onClick={pauseBtnClicked}
            />
          )}
        </div>
        <br />
      </div>
    </>
  );
};

export default ReceivedMessage;
