import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import {
  getMinuteOptions,
  getHourOptions,
  showAlertDialouge,
  handleApiErrors,
  toTimezoneName,
  toTimezoneOffset,
  GetTimezoneFullName,
  isEmptyArray,
  onImageError,
  queryStringToJSON,
  getCampaignStatesOfType
} from "../../helpers/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  getCampaign_Ajax,
  getProviderSchedules_Ajax,
  updateOnDemandConsultation_Ajax
} from "../../helpers/requests";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import moment from "moment";
import { CampaignStateSettingTypes } from "../../constants";

const SecondOpinionAppointmentReschedulePage = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { consultation } = props;

  const secondOpinionApptOid = useSelector(
    (state) => state?.appSettings?.settingsData?.secondOpinionApptOid
  );
  const campaignId = useSelector(
    (state) => state?.appSettings?.settingsData?.schedulerCampaignId
  );

  // campaign data
  const [schedulerCampaign, setSchedulerCampaign] = useState(null);

  const [inputs, setInputs] = useState({
    date: "",
    hour: "",
    minute: "",
    meridiem: "AM"
  });

  const { date, hour, minute, meridiem } = inputs;
  const [providerOffice, setProviderOffice] = useState(null);
  const [providerSchedules, setProviderSchedules] = useState([]);
  const [providerSchedulesGroupedByDay, setProviderSchedulesGroupedByDay] =
    useState([]);
  const [scheduledDateTime, setScheduledDateTime] = useState(null);
  const [providerInfo, setProviderInfo] = useState(null);

  useEffect(() => {
    getProviderSchedules();
    getCampaign(campaignId);
  }, []);

  useEffect(() => {
    groupScheduleTimesByDay();
  }, [providerSchedules, providerOffice]);

  useEffect(() => {
    const { date, hour, minute, meridiem } = inputs;

    if (date && hour && minute && meridiem) {
      var dateTimeString = date + " " + hour + ":" + minute + " " + meridiem;
      setScheduledDateTime(dateTimeString);
    }
  }, [inputs]);

  useEffect(() => {
    if (consultation && providerOffice) {
      var dateObj;

      if (providerOffice.timeZone) {
        dateObj = moment
          .utc(consultation.scheduledDateTime)
          .utcOffset(toTimezoneOffset(providerOffice.timeZone));
      } else {
        dateObj = moment();
      }

      var date = dateObj.format("YYYY-MM-DD");
      var hour = dateObj.format("h");
      var minute = dateObj.format("m");
      var meridiem = dateObj.format("A");

      var existingData = {
        date,
        hour,
        minute,
        meridiem
      };

      setInputs(existingData);
    }
  }, [consultation, providerOffice]);

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  function navigateToReviewDisabled() {
    return !date || !hour || !minute || !meridiem;
  }

  function getScheduledDayTimings(day) {
    if (day) {
      return providerSchedulesGroupedByDay.find((s) => {
        return s.field === day;
      });
    }
  }

  function isInputDateTimeValid() {
    if (scheduledDateTime) {
      var currentOfficeDateTime;

      if (providerOffice && providerOffice.timeZone) {
        currentOfficeDateTime = moment
          .utc()
          .utcOffset(toTimezoneOffset(providerOffice.timeZone))
          .format("YYYY-MM-DD hh:mm A");
        currentOfficeDateTime = moment(
          currentOfficeDateTime,
          "YYYY-MM-DD hh:mm A"
        );
      } else {
        currentOfficeDateTime = moment();
      }

      var scheduledDateTimeMomentObj = moment(
        scheduledDateTime,
        "YYYY-MM-DD hh:mm A"
      );
      var isScheduleDateTimeValid =
        scheduledDateTimeMomentObj.diff(currentOfficeDateTime, "minutes") >= 59;

      if (!isScheduleDateTimeValid) {
        showAlertDialouge(
          "Message",
          "Please select a date and time at least one hour from now."
        );
        return;
      }

      var scheduledDay = scheduledDateTimeMomentObj.format("dddd");
      var scheduledDayTimings = getScheduledDayTimings(scheduledDay);
      var scheduledTime = moment(
        scheduledDateTimeMomentObj.format("hh:mm A"),
        "hh:mm A"
      );

      if (scheduledDayTimings && !isEmptyArray(scheduledDayTimings.groupList)) {
        var isProviderAvailableOnSelectedTime = false;
        scheduledDayTimings.groupList.forEach((time) => {
          var startTime, endTime;

          if (time.startTime12hr) {
            startTime = moment(time.startTime12hr, "h:mm A");
          }

          if (time.endTime12hr) {
            endTime = moment(time.endTime12hr, "h:mm A");
          }

          if (scheduledTime.isBetween(startTime, endTime)) {
            isProviderAvailableOnSelectedTime = true;
            return;
          }
        });

        if (!isProviderAvailableOnSelectedTime) {
          showAlertDialouge(
            "Message",
            "The provider is not available at the selected time."
          );
        }

        return isProviderAvailableOnSelectedTime;
      } else {
        showAlertDialouge(
          "Message",
          "The provider is not available at the selected day."
        );
      }
    }
  }

  function groupScheduleTimesByDay() {
    if (!isEmptyArray(providerSchedules) && providerOffice) {
      var ScheduleTimings = providerSchedules.map((timing) => {
        var temp = { ...timing };

        temp.startTime12hr = moment(temp.startTime).format("hh:mm A ");
        temp.endTime12hr = moment(temp.endTime).format("hh:mm A ");

        return temp;
      });

      var schedulesGroupedByDay = ScheduleTimings.groupBy("day");
      setProviderSchedulesGroupedByDay(schedulesGroupedByDay);
    }
  }

  function getProviderSchedules() {
    if (secondOpinionApptOid > 0 && consultation.providerId > 0) {
      var params = {
        providerId: consultation.providerId
      };

      dispatch(showLoadingSpinner());
      getProviderSchedules_Ajax(
        secondOpinionApptOid,
        params,
        function (response) {
          if (response && response.success) {
            setProviderSchedules(
              response.data.providers[0].schedules[0].scheduleTimings
            );
            setProviderOffice(response.data.providers[0].schedules[0].office);

            var providerInfo = {
              fullName: response.data.providers[0].fullName,
              photoId: response.data.providers[0].photoId
            };

            setProviderInfo(providerInfo);
          } else if (response && !response.success) {
            showAlertDialouge("Error", response.message);
          }

          dispatch(hideLoadingSpinner());
        },
        function (err) {
          handleApiErrors(err);
          dispatch(hideLoadingSpinner());
        }
      );
    }
  }

  function getCampaign(campaignId) {
    let params = queryStringToJSON();
    let includeZState = false;
    if (params.includezstate) {
      if (params.includezstate == "true") {
        includeZState = true;
      }
    }
    if (campaignId) {
      let params = {
        campaignId: campaignId,
        includeIntakeForm: false,
        includeZState: includeZState
      };

      dispatch(showLoadingSpinner());
      getCampaign_Ajax(
        params,
        function (response) {
          dispatch(hideLoadingSpinner());

          if (response && response.success && response.data) {
            if (response.data.campaignStates) {
              response.data.campaignStates = getCampaignStatesOfType(
                CampaignStateSettingTypes.Assigned,
                response.data.campaignStates
              );
            }

            setSchedulerCampaign(response.data);
          } else if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function bookAppointment() {
    if (
      consultation.patientId > 0 &&
      consultation.providerId > 0 &&
      schedulerCampaign &&
      isInputDateTimeValid()
    ) {
      var dateTimeString = moment(
        scheduledDateTime,
        "YYYY-MM-DD HH:mm a"
      ).format("YYYY-MM-DD HH:mm a");

      var updateData = {
        id: consultation.id,
        scheduledDateTime: dateTimeString,
        localTimeZoneOffset: toTimezoneOffset(providerOffice.timeZone)
      };

      dispatch(showLoadingSpinner());

      updateOnDemandConsultation_Ajax(
        JSON.stringify(updateData),
        function (response) {
          dispatch(hideLoadingSpinner());

          if (response && response.success) {
            showAlertDialouge(
              "Message",
              "Your appointment has been rescheduled successfully",
              function () {
                navigate("/dashboard");
              }
            );
          } else if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className=" mb-3">
              <div className="heading-wrapper back-to-wrapper position-relative">
                <h1 className="title">Reschedule Appointment</h1>
              </div>
            </div>
            <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
              <form action="">
                <div className="form-group mb-3 col-md-12">
                  <label className="form-label">Appointment For</label>
                  {consultation.patientName ? (
                    <div className="text-primary">
                      {consultation.patientName}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                {providerOffice && providerInfo ? (
                  <>
                    <div className="form-group mb-3">
                      <label className="form-label">Provider</label>
                      <div className="d-flex flex-wrap align-items-center mt-1">
                        <div>
                          <img
                            className="img-70 rounded-circle"
                            src={providerInfo.photoId}
                            onError={onImageError}
                            alt=""
                          />
                        </div>
                        <div className="flex-1">
                          <div className="fs-5 ps-3">
                            {providerInfo.fullName
                              ? providerInfo.fullName
                              : " "}
                          </div>
                          {/* <div className="text-primary fs-5">Specialization - 1</div>
                                            <div className="text-primary fs-5">DDS, MDS</div> */}
                        </div>
                      </div>
                    </div>
                    <div className=" form-view appointment_for right-wrapper col-sm-12 col-lg-8">
                      <div className="form-group mb-3 select-date">
                        <div className="row">
                          <div className="col-sm-12 col-md-4">
                            <label className="form-label">
                              {providerOffice.timeZone ? (
                                <span>
                                  {"Availablity, Times are shown in "}
                                  {
                                    <p className="fs-3 fw-bold">
                                      {GetTimezoneFullName(
                                        toTimezoneOffset(
                                          providerOffice.timeZone
                                        ),
                                        providerOffice.isDST
                                      ) +
                                        " (" +
                                        toTimezoneName(
                                          toTimezoneOffset(
                                            providerOffice.timeZone
                                          ),
                                          providerOffice.isDST
                                        ) +
                                        ")"}
                                    </p>
                                  }
                                </span>
                              ) : (
                                <></>
                              )}
                            </label>
                            {!isEmptyArray(providerSchedulesGroupedByDay) ? (
                              providerSchedulesGroupedByDay.map(
                                (day, index) => {
                                  return (
                                    <>
                                      <label className="form-label">
                                        {day.field} :<br />
                                        {day.groupList &&
                                        day.groupList.length > 0 ? (
                                          day.groupList.map((time, index) => {
                                            return (
                                              <>
                                                {time.startTime12hr +
                                                  " to " +
                                                  time.endTime12hr +
                                                  " "}
                                                <br />
                                              </>
                                            );
                                          })
                                        ) : (
                                          <></>
                                        )}
                                      </label>
                                    </>
                                  );
                                }
                              )
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="form-group mb-3 select-date">
                        <div className="row">
                          <div className="col-sm-12 col-md-4">
                            <label className="form-label">
                              Select Appointment Date
                            </label>
                            <input
                              className="form-control"
                              type="date"
                              max={"9999-12-31"}
                              name="date"
                              value={date}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-sm-12">
                        <label className="form-label">
                          Select Appointment Time
                        </label>
                        <div className="row">
                          <div className="col-sm-4 mb-3">
                            <select
                              className="form-select form-control"
                              name="hour"
                              id="hour"
                              value={hour}
                              onChange={handleChange}
                            >
                              <option value="">Hour</option>
                              {getHourOptions()}
                            </select>
                          </div>
                          <div className="col-sm-4 mb-3">
                            <select
                              className="form-select form-control"
                              name="minute"
                              id="minute"
                              value={minute}
                              onChange={handleChange}
                            >
                              <option value="">Minute</option>
                              {getMinuteOptions()}
                            </select>
                          </div>
                          <div className="col-sm-4 mb-3">
                            <select
                              className="form-select form-control"
                              name="meridiem"
                              id="ampm"
                              value={meridiem}
                              onChange={handleChange}
                            >
                              <option value="AM">AM</option>
                              <option value="PM">PM</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <div className="form-group text-center mt-4 col-md-12">
                  <Link
                    className="btn btn-outline btn-rounded btn-lg px-3 px-md-5 mx-md-2 mt-3 mt-md-0 w-100 w-md-auto order-3 order-md-0"
                    to="/dashboard"
                  >
                    Cancel
                  </Link>
                  <button
                    className="btn btn-secondary btn-rounded btn-lg px-3 px-md-5 mx-md-2 mt-3 mt-md-0"
                    id="review-submit-tab"
                    type="button"
                    disabled={navigateToReviewDisabled()}
                    onClick={bookAppointment}
                  >
                    Reschedule Appointment
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SecondOpinionAppointmentReschedulePage;
