import React from "react";
import { useLocation } from "react-router-dom";
import SecondOpinionAppointmentReschedulePage from "../../components/SecondOpinionAppointmentReschedule/SecondOpinionAppointmentReschedulePage";
const SecondOpinionAppointmentReschedule = () => {
  const { state } = useLocation();

  return (
    <>
      <body>
        <section className="step-section py-4 py-md-5">
          <div className="border-radius-xlg ">
            {state ? (
              <SecondOpinionAppointmentReschedulePage
                consultation={state.consultation}
              />
            ) : (
              <></>
            )}
          </div>
        </section>
      </body>
    </>
  );
};
export default SecondOpinionAppointmentReschedule;
