import React from "react";
import { Link } from "react-router-dom";

const AlertModal = () => {
  return (
    <>
      {/* <!-- record sent modal --> */}
      <div
        className="modal fade"
        id="request-sent-modal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0 position-absolute top-0 end-0">
              <Link to="/dashboard">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </Link>
            </div>
            <div className="modal-body text-center px-4 py-5 position-static">
              <div className="">
                <i
                  className="bi bi-check-circle text-green lh-1 mb-3 d-table mx-auto"
                  style={{ fontSize: "60px" }}
                ></i>
                <h2 className="mb-3">Request Sent!</h2>
                {/* <!-- <p className="fs-4 text-muted">Congratulations! A request is sent successfully.</p> --> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AlertModal;
