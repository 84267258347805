export const isTextToSpeechSupported = () => {
  if ("speechSynthesis" in window) {
    return true;
  } else {
    console.log("Your browser does not support text-to-speech.");
    return false;
  }
};

export const startSpeaking = (text, startCallback, onEndCallback, elementId, targetLang) => {
  if (text && isTextToSpeechSupported()) {
    stopSpeaking();
    
    if (targetLang)
    {
      text = document.getElementById(elementId).innerText;
    }

    let speech2 = new SpeechSynthesisUtterance("");
    speech2.lang = targetLang ?? "en-US";

    window.speechSynthesis.speak(speech2);

    let speech = new SpeechSynthesisUtterance(text);
    speech.lang = targetLang ?? "en-US";

    try {
      speech.onstart = () => {
        if (startCallback) {
          startCallback();
        }
      };

      speech.onend = () => {
        if (onEndCallback) {
          onEndCallback();
        }
      };

      setTimeout(function() {
        window.speechSynthesis.speak(speech);
    }, 200);
      
    }
    catch (ex) {
      console.log(ex);
    }
  }
};

export const stopSpeaking = (onCancel) => {
  if (isTextToSpeechSupported() && isSpeaking()) {
    window.speechSynthesis.cancel();
    if (onCancel) {
      onCancel();
    }
  }
};

export const isSpeaking = () => {
  if (isTextToSpeechSupported() && window?.speechSynthesis?.speaking) {
    return window.speechSynthesis.speaking;
  }
  return false;
};
