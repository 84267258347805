import React from "react";
import { DateTimeFormats } from "../../constants";
import { formatDateTimeWithZone, toLocal } from "../../helpers/utils";
import NoteAttachedFormView from "./NoteAttachedFormView";

const NoteAttachedFormsModal = (props) => {
  const { noteAttachedForms, setNoteAttachedForms } = props;

  function getDateTime(form) {
    var time = "";

    if (form?.modifiedOn) {
      time = toLocal(form.modifiedOn);
    } else if (form?.createdOn) {
      time = toLocal(form.createdOn);
    }

    if (time) {
      return formatDateTimeWithZone(
        toLocal(time),
        DateTimeFormats.MMM_DD_YYYY + " [at] " + DateTimeFormats.HH_MM_A
      );
    }
  }

  return (
    <div className="modal fade" id="AttachedFormsModal" tabIndex="-1">
      <div className="modal-dialog modal-xl modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header bg-primary">
            <h2 className="modal-title">Attached Forms</h2>
            <button
              type="button"
              className="btn-close text-white"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setNoteAttachedForms(null)}
            ></button>
          </div>
          <div className="modal-body">
            {noteAttachedForms &&
              Array.isArray(noteAttachedForms) &&
              noteAttachedForms.length > 0 &&
              noteAttachedForms.map((formData, index) => {
                return (
                  <div key={index} className="mb-3">
                    <div className="gth-panel-header bg-body px-3 py-2 rounded-3 d-flex justify-content-between align-items-center">
                      <a
                        href={"#intakeForm-collapse1" + formData.id.toString()}
                        className="text-body text-decoration-none"
                        data-bs-toggle="collapse"
                        role="button"
                        aria-expanded="false"
                      >
                        {formData?.medicalForm?.title}
                      </a>
                      <a
                        href={"#intakeForm-collapse1" + formData.id.toString()}
                        className="text-body text-decoration-none"
                        data-bs-toggle="collapse"
                        role="button"
                        aria-expanded="false"
                      >
                        {getDateTime(formData)}
                      </a>
                    </div>
                    <NoteAttachedFormView
                      index={formData.id.toString()}
                      formData={formData}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoteAttachedFormsModal;
