import moment from "moment";

import { saveData, getData, removeData } from "./storage";
import {
  Functionalities,
  PATIENT_PORTAL_AUTO_LOGIN_API_KEY
} from "../constants";
import {
  getBaseUrl,
  getPatientOrigin,
  getPgidFromUrl
} from "../ApplicationSettings";

export const storeTokenData = (pgid, tokenDataString) => {
  if (pgid) {
    saveData(
      Functionalities.TokenDataKey + "_" + pgid + "_" + getPatientOrigin(),
      tokenDataString
    );
  }
};

export const storeSelectedPatientId = (patId) => {
  if (patId) {
    let patientIdKey = generateOriginBasedKey(
      Functionalities.SelectedPatientId
    );
    saveData(patientIdKey, patId);
  }
};

export const storeNavigationBarHiddenValue = (hideNavigationBar) => {
  if (hideNavigationBar) {
    let hideNavbarKey = generateOriginBasedKey(
      Functionalities.ShouldHideNavbar
    );
    saveData(hideNavbarKey, hideNavigationBar);
  }
};

export const hasHideNavigationBarInLocalStorage = () => {
  const shouldHideNavbar = getData(
    generateOriginBasedKey(Functionalities.ShouldHideNavbar)
  );
  return shouldHideNavbar;
};

export const setChatBoxInvisibilityInLocalStorage = (hideChatBox) => {
  if (hideChatBox) {
    let hideChatBoxKey = generateOriginBasedKey(
      Functionalities.ShouldHideChatBox
    );
    saveData(hideChatBoxKey, hideChatBox);
  }
};

export const getChatBoxInvisibilityInLocalStorage = () => {
  const shouldHideChatBox = getData(
    generateOriginBasedKey(Functionalities.ShouldHideChatBox)
  );
  return shouldHideChatBox;
};

export const updateTokenData = (tokenData) => {
  let tokenDataString = JSON.stringify(tokenData);
  let pgid = getPgidFromUrl();
  if (pgid) {
    saveData(
      Functionalities.TokenDataKey + "_" + pgid + "_" + getPatientOrigin(),
      false
    );
    storeTokenData(pgid, tokenDataString);
  }
};

export const getTokenData = (pgid) => {
  if (!pgid) {
    pgid = getPgidFromUrl();
  }

  if (pgid) {
    var tdx_token = getData(
      Functionalities.TokenDataKey + "_" + pgid + "_" + getPatientOrigin()
    );
    if (tdx_token && tdx_token != null) {
      var tokenData = JSON.parse(tdx_token);
      if (
        tokenData &&
        tokenData.token &&
        tokenData.expiration &&
        moment().utc().format() <= tokenData.expiration
      ) {
        return tokenData;
      }
    }
  }

  return false;
};

export const getSelectedPatientId = () => {
  const selectedPatId = getData(
    generateOriginBasedKey(Functionalities.SelectedPatientId)
  );
  if (selectedPatId) {
    return selectedPatId;
  }

  let curreentProfileSetting = getSelectedPatientProfile();
  if (curreentProfileSetting) {
    return curreentProfileSetting.patid;
  }

  let patientInfo = getPatientInfo();
  if (patientInfo) {
    return patientInfo.patid;
  }
  return 0;
};

export const removeTokenData = (pgId) => {
  if (!pgId) {
    pgId = getPgidFromUrl();
  }

  if (pgId) {
    removeData(
      Functionalities.TokenDataKey + "_" + pgId + "_" + getPatientOrigin()
    );
    removeSelectedPatientId();
    removeSelectedPatientProfile();
  }
};

export const onBeforeSend = (xhr) => {
  var tokenData = getTokenData();
  if (tokenData && tokenData.token) {
    xhr.setRequestHeader("Authorization", "Bearer " + tokenData.token);
  } else {
    return "";
  }
};

export const isPatient = () => {
  try {
    var tokenData = getTokenData();
    if (tokenData && tokenData.user && tokenData.user.roles) {
      var roles = tokenData.user.roles;
      for (var i = 0; i < roles.length; i++) {
        if (
          roles[i].hasOwnProperty("name") &&
          roles[i].name.toLowerCase() == "patient"
        ) {
          return true;
        }
      }
    }
  } catch (ex) {}
  return false;
};

export const getPatientInfo = () => {
  if (isPatient()) {
    var tokenData = getTokenData();
    if (tokenData && tokenData.info) {
      return tokenData.info;
    }
  }
  return false;
};

export const getPatientLoginUrl = () => {
  const tokenData = getTokenData();
  return tokenData?.info?.loginUrl;
};

export const storeSelectedPatientProfile = (selectedPatientDataString) => {
  if (selectedPatientDataString) {
    saveData(
      generateOriginBasedKey(Functionalities.SelectedPatientProfileKey),
      selectedPatientDataString
    );
    storeSelectedPatientId(JSON.parse(selectedPatientDataString).patid);
  }
};

export const getSelectedPatientProfile = () => {
  const selectedPatient = getData(
    generateOriginBasedKey(Functionalities.SelectedPatientProfileKey)
  );
  if (selectedPatient && selectedPatient != null) {
    let selectedPatientData = JSON.parse(selectedPatient);
    if (selectedPatientData) {
      return selectedPatientData;
    }
  }
  return false;
};

export const removeSelectedPatientProfile = () => {
  removeData(generateOriginBasedKey(Functionalities.SelectedPatientProfileKey));
};

export const removeSelectedPatientId = () => {
  removeData(generateOriginBasedKey(Functionalities.SelectedPatientId));
};

export const generateOriginBasedKey = (key) => {
  let pgId = getPgidFromUrl();
  return key + "_" + pgId + "_" + getPatientOrigin();
};

export const getAutoLoginUrl = (params) => {
  let tokenData = getTokenData();
  if (tokenData?.token) {
    let url =
      getBaseUrl() +
      "/login?apiKey=" +
      PATIENT_PORTAL_AUTO_LOGIN_API_KEY +
      "&token=" +
      tokenData.token;

    if (params) {
      url += "&" + $.param(params);
    }

    return url;
  }
};
