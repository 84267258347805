import checkInsurance from "../../../src/assets/images/check-insurance.gif";

const InsuranceCheckModal = () => {
  return (
    <div
      className="modal fade"
      id="check-insurance-modal"
      tabIndex={-1}
      abindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLongTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-md modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header primary-border-color">
            <h2 className="modal-title text-primary text-center w-100">
              Insurance Information
            </h2>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body text-center pb-5">
            <img
              className="mb-0"
              src={checkInsurance}
              alt=""
              style={{ maxWidth: "270px" }}
            />
            <h4>
              Checking your insurance benefits now, this may take a few moments.
              Please do not refresh the page.​
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsuranceCheckModal;
