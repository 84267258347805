import React from "react";
import { formatDateTime } from "../../helpers/utils";
import { getOverAllStatusTextColorClass } from "../../helpers/smartScanUtils";

const WellnessScoreSummaryInPrintPage = (props) => {
  const { wellnessScoreSummary } = props;

  function getChangeArrowClass(scoreChange) {
    if (scoreChange > 0) {
      return "bi-arrow-up";
    } else if (scoreChange < 0) {
      return "bi-arrow-down";
    }

    return "";
  }

  return wellnessScoreSummary ? (
    <div
      class="row align-items-center text-black gap-3 gap-md-0 mb-4 p-3 mx-0"
      style={{ background: "#f8f8f8" }}
    >
      <div class="col-md-3">
        <h5 style={{ fontSize: "1.25rem" }}>Your Wellness Score</h5>
      </div>
      <div class="col-md-9">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 10
          }}
        >
          {wellnessScoreSummary?.previousDentalCondition ? (
            <div className="text-center">
              <div style={{ fontSize: 20 }}>
                <strong>
                  {wellnessScoreSummary?.previousDentalCondition?.score}
                </strong>
              </div>
              <small>
                {formatDateTime(
                  wellnessScoreSummary?.previousSmartScanDate,
                  "MM/DD/YY"
                )}
              </small>
            </div>
          ) : (
            <></>
          )}
          <div
            style={{
              flexGrow: 1,
              textAlign: "center"
            }}
            className={getOverAllStatusTextColorClass(
              wellnessScoreSummary?.currentDentalCondition?.status
            )}
          >
            <div style={{ fontSize: 20 }}>
              <b>{wellnessScoreSummary?.currentDentalCondition?.score}</b>
            </div>
            <span>{wellnessScoreSummary?.currentDentalCondition?.status}</span>
          </div>
          {wellnessScoreSummary?.scoreChange ? (
            <>
              <div className="text-center">
                <div style={{ color: "#3fbbeb", fontSize: 20 }}>
                  <i
                    className={getChangeArrowClass(
                      wellnessScoreSummary?.scoreChange
                    )}
                  />
                  <b>{Math.abs(wellnessScoreSummary?.scoreChange)}</b>
                </div>
                <small>Change</small>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        <div>
          <small style={{ fontSize: 14 }}>
            Your oral health is{" "}
            <span>{wellnessScoreSummary?.currentDentalCondition?.status}</span>,
            expand the options below to learn what's impacting your score.
          </small>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default WellnessScoreSummaryInPrintPage;
