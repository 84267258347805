import React from "react";
import InputMask from "react-input-mask";
import { useForm } from "react-hook-form";
import { validatePhoneNumber } from "../../helpers/utils";
import { useNavigate } from "react-router-dom";

function RequestPhoneCallModal(props) {
  const { onCallMeBackBtnClicked = () => {} } = props;

  const phoneNoInputFieldName = "phoneNo";

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { isValid },
    setValue,
    reset
  } = useForm({
    mode: "onChange"
  });

  function submitCallRequest(formData) {
    if (formData[phoneNoInputFieldName]) {
      onExitBtnClicked();
      if (onCallMeBackBtnClicked) {
        onCallMeBackBtnClicked(formData[phoneNoInputFieldName]);
      }
    }
  }

  function hideModal() {
    return new Promise((resolve) => {
      window?.$("#dentist-form-modal")?.modal("hide");
      resolve(true);
    });
  }

  function onExitBtnClicked(event) {
    if (event) {
      event.preventDefault();
    }

    hideModal();

    reset();
    setValue("phoneNo", "");
  }

  function onCancelBtnClicked() {
    hideModal().then(() => {
      navigate("/dashboard");
    });
  }

  return (
    <div
      className="modal fade"
      id="dentist-form-modal"
      tabIndex={-1}
      style={{ display: "none" }}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h3>Are you sure you want to exit?</h3>
            <button
              type="button"
              className="btn-close text-white"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onExitBtnClicked}
            />
          </div>
          <div className="modal-body text-center">
            <form onSubmit={handleSubmit(submitCallRequest)}>
              <p className="mb-3">
                If you'd prefer a phone call instead, Please leave your number
                below and we'll get back to you shortly.
              </p>
              <InputMask
                mask="(999)-999-9999"
                className="form-control"
                id="dentist-phoneno"
                placeholder="(XXX)-XXX-XXXX"
                {...register(phoneNoInputFieldName, {
                  required: "This field is required.",
                  min: 14,
                  validate: (value) => validatePhoneNumber(value)
                })}
              />
              <div className="gth-confirmation-btn mx-auto d-flex justify-content-center mt-4">
                <button
                  onClick={onCancelBtnClicked}
                  className="btn btn-outline px-4 me-3"
                  style={{ minWidth: "0" }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button
                  disabled={!isValid}
                  type="submit"
                  className="btn btn-primary px-4"
                  style={{ minWidth: "0" }}
                >
                  Call Me Back
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RequestPhoneCallModal;
