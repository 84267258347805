import React, { useState } from "react";

const MessageBox = ({
  sendMessage,
  isProcessing,
  isWaitingForReply,
  isAITyping
}) => {
  const [inputMsg, setInputMsg] = useState("");

  function handleSendMessage() {
    if (inputMsg.length > 0) {
      sendMessage(inputMsg);
      resetTextAreaHeight();
      setInputMsg("");
    }
  }

  function sendBtnClicked() {
    handleSendMessage();
  }

  const resizeTextarea = (e) => {
    const textarea = document.getElementById("textbox");
    textarea.style.height = textarea.scrollHeight + "px";
  };

  const resetTextAreaHeight = () => {
    const textarea = document.getElementById("textbox");
    textarea.style.height = "46px";
  };

  function keyDownFunction(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSendMessage();
    }
  }

  return (
    <>
      <div className="textbox-control">
        <div id="controller">
          <label className="d-none">
            <input checked="" type="checkbox" id="enter" />
            Send on enter
          </label>
          <textarea
            disabled={isProcessing || isWaitingForReply || isAITyping}
            textarea=""
            id="textbox"
            onInput={(e) => resizeTextarea(e)}
            onKeyDown={(e) => keyDownFunction(e)}
            className="form-control"
            placeholder=""
            style={{ height: "46px" }}
            value={inputMsg}
            onChange={(e) => {
              setInputMsg(e.target.value);
            }}
          ></textarea>
          <button
            disabled={isProcessing}
            id="send"
            className="btn chat-btn"
            style={{ display: "inline-flex" }}
            onClick={sendBtnClicked}
          >
            <i className="bi bi-send"></i>
          </button>
        </div>
      </div>
    </>
  );
};

export default MessageBox;
