import React, { useState } from "react";
import SharedVideoEducationModal from "./SharedVideoEducationModal";
import { isEmptyArray } from "../../helpers/utils";

export default function CaseReportVideoEducations({
  caseReportVideoEducations
}) {
  const [sharedVideoEducation, setSharedVideoEducation] = useState(null);

  function showSharedVideoEducation(sharedVideoEducation) {
    if (sharedVideoEducation) {
      setSharedVideoEducation(sharedVideoEducation);
    }
  }

  return (
    <>
      {!isEmptyArray(caseReportVideoEducations) ? (
        <div className="form-group mt-4 mb-3 col-md-12">
          <label className="form-label">Shared Video Education</label>
          <table cellPadding="0" cellSpacing="0" border="0" width="100%">
            <tbody>
              {caseReportVideoEducations.map((videoEducation) => {
                return (
                  <tr
                    key={videoEducation.caseReportVideoEducationId?.toString()}
                  >
                    <td className="py-1 w-50 align-top">
                      {
                        videoEducation.patientVideoEducation.videoEducation
                          .title
                      }
                    </td>
                    <td className="py-1 w-50 align-top text-end text-md-start">
                      <a
                        href="#"
                        onClick={() => {
                          showSharedVideoEducation(
                            videoEducation.patientVideoEducation
                          );
                        }}
                        data-bs-toggle="modal"
                        data-bs-target="#videoEducationModal"
                        className="text-decoration-none"
                      >
                        View <i className="bi bi-box-arrow-up-right"></i>
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <></>
      )}
      <SharedVideoEducationModal sharedVideoEducation={sharedVideoEducation} />
    </>
  );
}
