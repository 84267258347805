import React from "react";
import ConsentFormPage from "../../components/ConsentForm/ConsentFormPage";
import { useLocation } from "react-router-dom";

const ConsentForm = () => {
  const { state } = useLocation();

  return (
    <>
      <div className="body-content">
        <section className="questionnaire-section py-4 py-md-5">
          <ConsentFormPage
            recordRequestProviderData={state?.recordRequestProviderData}
            patientId={state?.patientId}
          />
        </section>
      </div>
    </>
  );
};

export default ConsentForm;
