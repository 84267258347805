import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SmartScanGraphDataPropertyIndices } from "../../constants";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import ShadeSelector from "./ShadeSelector";
import WhitenessScore from "../SmartScanGraph/WhitenessScore";
import { getWhitenessScoreReport_Ajax } from "../../helpers/requests";
import initialWhitenessImage from "../../assets/images/shade/actual_shade9_demo1.png";
import { getPatientInfo } from "../../helpers/authManager";

const WhitenessScorePage = (props) => {
  const { patientId, onGraphPointClicked, smartScanId } = props;
  const [whitnessScoreReport, setWhitnessScoreReport] = useState();
  const [currentScore, setCurrentScore] = useState(-1);
  const [selectedSmartScanId, setSelectedSmartScanId] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDemoShadeSelectorEnabled = useSelector(
    (state) => state?.appSettings?.settingsData?.isDemoShadeSelectorEnabled
  );

  const patientInfo = getPatientInfo();

  const isDemoPatient = useSelector((state) =>
    state?.appSettings?.settingsData?.demoPatientIds?.includes(
      patientInfo?.patid
    )
  );

  let demoWhitenessReport = {
    smartScanId: 1,
    shadeScore: 9,
    photoId: initialWhitenessImage,
    patientId: 1
  };

  function getSmartScanId() {
    return selectedSmartScanId > 0 ? selectedSmartScanId : smartScanId;
  }

  function renderSmartScanChart() {
    return patientId > 0 ? (
      <WhitenessScore
        patientId={patientId}
        onGraphPointClicked={onGraphPointClicked}
        onWhitnessGraphPointClick={onWhitnessGraphPointClick}
        smartScanId={getSmartScanId()}
        currentScore={currentScore}
        itemPerPage={5}
        chartHeight={9}
      />
    ) : (
      <></>
    );
  }

  function onWhitnessGraphPointClick(whitnessScore) {
    if (
      whitnessScore &&
      whitnessScore[SmartScanGraphDataPropertyIndices.SmartScanId] > 0
    ) {
      getWhitenessScoreReport(
        whitnessScore[SmartScanGraphDataPropertyIndices.SmartScanId]
      );
    }
  }

  function getWhitenessScoreReport(smartScanId) {
    let params = {
      patId: patientId,
      shouldGetLatestSmartScanWhitnessReport: smartScanId > 0 ? false : true
    };

    dispatch(showLoadingSpinner());
    setSelectedSmartScanId(smartScanId);
    getWhitenessScoreReport_Ajax(
      smartScanId,
      params,
      function (resposne) {
        dispatch(hideLoadingSpinner());

        if (
          resposne &&
          resposne?.success &&
          resposne?.data?.whitenessReportVM
        ) {
          setWhitnessScoreReport(resposne.data.whitenessReportVM);
          setCurrentScore(resposne.data.whitenessReportVM.shadeScore);
        } else {
          setWhitnessScoreReport(undefined);
          setCurrentScore(0);
        }
      },
      function (err) {
        dispatch(hideLoadingSpinner());
        handleApiErrors(err);
      }
    );
  }

  function onSeeADentistNowButtonClicked() {
    if ((smartScanId > 0 || selectedSmartScanId > 0) && patientId > 0) {
      navigate("/see-dentist", {
        state: {
          smartScanId: getSmartScanId(),
          patientId
        }
      });
    }
  }

  useEffect(() => {
    if (!isDemoPatient) {
      getWhitenessScoreReport(smartScanId);
    } else {
      setWhitnessScoreReport(demoWhitenessReport);
      setCurrentScore(demoWhitenessReport.shadeScore);
    }
  }, [smartScanId]);

  return (
    <div className="smart-scan-card mobile-center patient-member-card mb-3">
      {renderSmartScanChart()}
      {whitnessScoreReport && (
        <div className="smart-scan-card mobile-center patient-member-card mb-3">
          <div className="bg-white border-radius-xlg">
            <div className="border-0 border-radius-xlg p-3">
              <div className="px-3 py-0" id="wellness-current-value">
                <div className="row mb-4">
                  <div className="col-sm-12 mb-4 mb-md-0">
                    <h3 className="card-title pe-lg-4 mb-3">Whiteness Score</h3>
                    <h2 className="text-primary fw-bold">
                      Tap on each shade above to visualize your smile at various
                      shades until you find your desired score.
                    </h2>
                  </div>
                  <ShadeSelector
                    whitnessScoreReport={whitnessScoreReport}
                    onSeeADentistNowButtonClicked={
                      onSeeADentistNowButtonClicked
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WhitenessScorePage;
