import React, { useEffect, useState } from "react";

import xRays from "./../../assets/images/x-ray1.jpg";
import Pagination from "../../redux/customHooks/pagination";
import {
  Pagination as PaginationConstants,
  DateTimeFormats,
  NoteType
} from "../../constants";
import {
  formatDateTime,
  toTimezoneName,
  formatDateTimeWithZone,
  toLocal,
  isEqualToCurrentDate,
  getTimeSince,
  isImageFile,
  isValidDocFileForPatientUpload,
  isPDFfile,
  onImageError
} from "../../helpers/utils";
import { useNavigate, Link } from "react-router-dom";

const NotesTable = (props) => {
  const [currentPage, setCurrentPage] = useState(
    PaginationConstants.FirstPageNo
  );
  const navigate = useNavigate();

  const {
    patientNotes,
    totalCount,
    getPatientNotes,
    reloadPatientNote,
    setReloadPatientNote,
    getProgressNoteAttachments,
    getProviderData,
    openFile,
    utcOffset,
    isDST,
    getProgressNoteAttachedForms,
    getCreatorInfo
  } = props;

  useEffect(() => {
    getPatientNotes(currentPage, PaginationConstants.ItemPerPage);
  }, [currentPage]);

  useEffect(() => {
    if (reloadPatientNote) {
      getPatientNotes(currentPage, PaginationConstants.ItemPerPage);
      setReloadPatientNote(false);
    }
  }, [reloadPatientNote]);

  function getNoteCreationTimeAndDateAndTags(note) {
    if (note && note.createdOn) {
      var creationTime = "";
      var isPrescriptionNeeded = note.prescriptionNeeded;
      var isReferralNeeded = note.referralSuggestionNeeded;
      if (utcOffset && isDST) {
        creationTime =
          formatDateTime(note.createdOn, DateTimeFormats.HH_MM_A) +
          " (" +
          toTimezoneName(utcOffset, isDST) +
          ")";
      } else {
        creationTime = formatDateTimeWithZone(
          toLocal(note.createdOn),
          DateTimeFormats.HH_MM_A
        );
      }

      var creationDateOrTimeSince = "";

      if (isEqualToCurrentDate(note.createdOn)) {
        creationDateOrTimeSince = getTimeSince(note.createdOn) + " ago";
      } else if (utcOffset) {
        creationDateOrTimeSince = formatDateTime(
          note.createdOn,
          DateTimeFormats.MMM_DD_YYYY
        );
      } else {
        creationDateOrTimeSince = formatDateTime(
          toLocal(note.createdOn),
          DateTimeFormats.MMM_DD_YYYY
        );
      }

      if (creationTime && creationDateOrTimeSince) {
        return (
          <>
            <p className="fs-4 mb-0">{creationTime}</p>
            <span className="d-block">{creationDateOrTimeSince}</span>
            {isPrescriptionNeeded && (
              <a className="d-block text-decoration-none text-violet">
                <small>
                  <strong>Prescription needed</strong>
                </small>
              </a>
            )}
            {isReferralNeeded && (
              <a className="d-block text-decoration-none text-violet">
                <small>
                  <strong>Referral needed</strong>
                </small>
              </a>
            )}
          </>
        );
      }
    }
  }

  function isShowAttachmentBtnVisible(note) {
    return (
      note &&
      note.noteType &&
      note.noteType == NoteType.PatientNote &&
      note.noteAttatchments &&
      Array.isArray(note.noteAttatchments) &&
      note.noteAttatchments.length <= 0 &&
      note.totalAttachment
    );
  }

  function isShowAttachedFormsBtnVisible(note) {
    return (
      note &&
      note.noteType &&
      note.noteType == NoteType.ProgressNote &&
      note.totalAttachment > 0
    );
  }

  function isAttachmentsVisible(note) {
    return (
      note &&
      note.noteAttatchments &&
      Array.isArray(note.noteAttatchments) &&
      note.noteAttatchments.length > 0
    );
  }

  function getAttachmentThumbnails(note) {
    if (note) {
      const { noteAttatchments } = note;

      var arr = [];
      if (
        noteAttatchments &&
        Array.isArray(noteAttatchments) &&
        noteAttatchments.length > 0
      ) {
        noteAttatchments.map((attachment, index) => {
          if (attachment?.fileId) {
            if (isImageFile(attachment.fileId)) {
              arr.push(
                <a
                  href="#"
                  className="me-2"
                  data-bs-toggle="modal"
                  data-bs-target="#fileViewerImage"
                  onClick={() => openFile(attachment.fileId)}
                >
                  <img
                    className="gth-uploads-icon"
                    src={attachment.fileId}
                    onError={onImageError}
                    alt="File img"
                  />
                </a>
              );
            } else if (isValidDocFileForPatientUpload(attachment.fileId)) {
              arr.push(
                <a
                  href="#"
                  className="text-body gth-pdf-modal"
                  data-bs-toggle="modal"
                  data-bs-target="#fileViewerDoc"
                  onClick={() => openFile(attachment.fileId)}
                >
                  {isPDFfile(attachment.fileId) ? (
                    <i className="bi bi-file-earmark-pdf-fill fs-1"></i>
                  ) : (
                    <i className="bi bi-file-earmark-text-fill fs-1"></i>
                  )}
                </a>
              );
            }
          }
        });
      }

      return <>{arr}</>;
    }
  }

  function getFormattedDateOfService(note) {
    if (note && note.dateOfService) {
      return (
        <p style={{ fontWeight: "bold" }}>
          Date of service:{" "}
          {formatDateTime(note.dateOfService, DateTimeFormats.MMM_DD_YYYY)}
        </p>
      );
    }
  }

  return (
    <section className="step-section py-4 py-md-5">
      <div className="container">
        <div className="row mb-3">
          <div className="col-sm-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Notes
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-10 offset-sm-1 col-md-12 offset-md-0 col-xl-10 offset-xl-1">
            <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
              {patientNotes &&
              Array.isArray(patientNotes) &&
              patientNotes.length > 0 ? (
                <>
                  <div className="gth-main-header d-flex justify-content-between align-items-center mb-3">
                    <h1>Notes</h1>
                  </div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ width: "130px" }}>Date</th>
                        <th>Note</th>
                        <th style={{ width: "100px" }}>Added By</th>
                        <th style={{ width: "100px" }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {patientNotes &&
                        Array.isArray(patientNotes) &&
                        patientNotes.length > 0 &&
                        patientNotes.map((note, index) => {
                          return (
                            <tr>
                              <td data-label="Date">
                                {getNoteCreationTimeAndDateAndTags(note)}
                              </td>
                              <td className="text-break" data-label="Note">
                                <div>{getFormattedDateOfService(note)}</div>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: note?.description
                                  }}
                                ></div>
                                {isShowAttachmentBtnVisible(note) ? (
                                  <div className="mt-3 gth-attached-files d-flex align-items-center flex-wrap justify-content-center">
                                    <button
                                      target="_blank"
                                      className="btn btn-primary btn-xs"
                                      onClick={() =>
                                        getProgressNoteAttachments(note)
                                      }
                                    >
                                      Show attachments
                                    </button>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {isAttachmentsVisible(note) && (
                                  <div className="mt-3 gth-attached-files d-flex align-items-center flex-wrap justify-content-center">
                                    {getAttachmentThumbnails(note)}
                                  </div>
                                )}

                                {isShowAttachedFormsBtnVisible(note) ? (
                                  <div className="mt-3 gth-attached-files d-flex align-items-center flex-wrap justify-content-center">
                                    <button
                                      target="_blank"
                                      className="btn btn-primary btn-xs"
                                      data-bs-toggle="modal"
                                      data-bs-target="#AttachedFormsModal"
                                      onClick={() =>
                                        getProgressNoteAttachedForms(note)
                                      }
                                    >
                                      Show attachments
                                    </button>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </td>
                              <td data-label="Added By">
                                <p
                                  data-bs-toggle="modal"
                                  data-bs-target="#providerProfile"
                                  onClick={() => getCreatorInfo(note)}
                                >
                                  {note?.provider?.fullName}
                                </p>
                              </td>
                              <td data-label="Action">
                                <a
                                  href={`/notes-print/${
                                    note.noteId
                                      ? note.noteId
                                      : note.patientNoteId
                                  }/${note.noteType}`}
                                  target="_blank"
                                  className="btn btn-primary btn-xs"
                                >
                                  <i className="bi bi-printer"></i> Print
                                </a>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={totalCount ? totalCount : 0}
                    pageSize={PaginationConstants.ItemPerPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </>
              ) : (
                <div className="d-flex justify-content-center mt-5 mb-5">
                  <h2>No patient note found!</h2>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NotesTable;
