import React from "react";
import { Provider } from "react-redux";
import createStore from "./redux/createStore";

import Setup from "./Setup";

const store = createStore();

function App() {
  return (
    <Provider store={store}>
      <Setup />
    </Provider>
  );
}

export default App;
