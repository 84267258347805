import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import InputMask from "react-input-mask";
import Autocomplete from "../GooglePlaceAutoComplete/AutoComplete";

import NoPreviewAvailble from "./../../assets/images/No_preview_available.jpg";
import {
  DateTimeFormats,
  GenderOptions,
  SignUpFormFields,
  ValidationTypes,
  SmsSubscriptionOptions
} from "../../constants";
import {
  GetPatientInsuranceInfo_Ajax,
  getPatientProfileSettings_Ajax,
  savePatientProfileSettings_Ajax,
  getCountries_Ajax,
  getStates_Ajax,
  getCityOrZips_Ajax,
  getTimezones_Ajax
} from "../../helpers/requests";
import { getPatientInfo } from "../../helpers/authManager";
import {
  formatDateTime,
  getNameInitials,
  handleApiErrors,
  isImageFile,
  isPhoneNumberValid,
  onImageError,
  showAlertDialouge,
  validateFileMaxSize,
  getAddressFromGooglePlaceObject,
  handleRemoveProfilePhoto
} from "../../helpers/utils";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import { useAlert } from "react-alert";
import { isPatientDateOfBirthValid } from "../../helpers/utils";
import { setIsFamilyMembersListChanged } from "../../redux/actions/userData";
import DateInputMask from "../DateInput/DateInputMask";

const ProfileSettingForm = () => {
  const [countires, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [zips, setZips] = useState([]);
  const [timeZones, setTimeZones] = useState([]);

  const [shouldUpdateLocations, setShouldUpdateLocations] = useState(false);
  const [isPatientPhoneNumberValid, setIsPatientPhoneNumberValid] =
    useState(false);
  const [profilePic, setProfilePic] = useState(null);
  const [existingPatientInfo, setExistingPatientInfo] = useState(null);
  const [photoUrl, setPhotoUrl] = useState(null);
  const [isEligibleForInsurance, SetIsEligibleForInsurance] = useState(false);

  const dispatch = useDispatch();
  const alert = useAlert();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors, isValid }
  } = useForm({
    mode: "onChange"
  });

  const firstNameWatch = watch(SignUpFormFields.FirstName);
  const lastNameWatch = watch(SignUpFormFields.LastName);
  const dateOfBirthWatch = watch(SignUpFormFields.DateOfBirth);
  const emailWatch = watch(SignUpFormFields.Email);
  const countryWatch = watch(SignUpFormFields.Country);
  const stateWatch = watch(SignUpFormFields.State);
  const cityWatch = watch(SignUpFormFields.City);
  const phoneWatch = watch(SignUpFormFields.Phone);
  const genderWatch = watch(SignUpFormFields.Gender);
  const zipWatch = watch(SignUpFormFields.Zip);
  const timezoneWatch = watch(SignUpFormFields.Timezone);
  const currentPatient = useSelector((state) => state.userData.currentPatient);
  const responsiblePatientId = getPatientInfo().patid;
  const smsSubscriptionWatch = watch(SignUpFormFields.IsSubscribedForSms);

  useEffect(() => {
    if (currentPatient) {
      getLocationOptionsAndPopulateDate();
    }
  }, [currentPatient]);

  // this has no usage currently
  function getPatientInsuranceInfo() {
    var patientId = getPatientInfo().patid;

    if (patientId) {
      var params = {
        includePatInsEligData: true
      };

      dispatch(showLoadingSpinner());
      GetPatientInsuranceInfo_Ajax(
        patientId,
        params,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (
            response &&
            response.success &&
            response.data &&
            response.data.eligibilityInfo &&
            response.data.eligibilityInfo.eligibilityStatus &&
            response.data.eligibilityInfo.eligibilityStatus.toLowerCase() ==
              "yes"
          ) {
            SetIsEligibleForInsurance(true);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  useEffect(() => {
    var phone = getValues(SignUpFormFields.Phone);

    if (phone && isPhoneNumberValid(phone)) {
      setIsPatientPhoneNumberValid(true);
    } else {
      setIsPatientPhoneNumberValid(false);
    }
  }, [phoneWatch]);

  useEffect(() => {
    var countryCode = getValues(SignUpFormFields.Country);

    if (countryCode && shouldUpdateLocations) {
      getStates(countryCode);
      getTimeZones(countryCode);
    }
  }, [countryWatch]);

  useEffect(() => {
    var countryCode = getValues(SignUpFormFields.Country);
    var stateCode = getValues(SignUpFormFields.State);

    if (countryCode && stateCode && shouldUpdateLocations) {
      getCities(countryCode, stateCode);
    }
  }, [stateWatch]);

  useEffect(() => {
    var countryCode = getValues(SignUpFormFields.Country);
    var stateCode = getValues(SignUpFormFields.State);
    var city = getValues(SignUpFormFields.City);

    if (countryCode && stateCode && shouldUpdateLocations) {
      getZips(countryCode, stateCode, city);
    }
  }, [cityWatch]);

  function getLocationOptionsAndPopulateDate() {
    let patientId = currentPatient.patid;

    dispatch(showLoadingSpinner());
    getPatientProfileSettings_Ajax(
      patientId,
      function (response) {
        if (response && response.success && response.data) {
          dispatch(hideLoadingSpinner());
          var patientInfo = response.data;
          setExistingPatientInfo(patientInfo);

          setShouldUpdateLocations(false);
          getCountries().then((success) => {
            if (success) {
              getStates(patientInfo.country).then((success) => {
                if (success) {
                  getCities(patientInfo.country, patientInfo.state).then(
                    (success) => {
                      if (success) {
                        getZips(
                          patientInfo.country,
                          patientInfo.state,
                          patientInfo.city
                        ).then((success) => {
                          if (success) {
                            getTimeZones(patientInfo.country).then(
                              (success) => {
                                if (success) {
                                  populatePatientProfile(
                                    patientInfo,
                                    response.data.photoId
                                  );
                                }
                              }
                            );
                          }
                        });
                      }
                    }
                  );
                }
              });
            }
          });
        }
      },
      function (err) {
        dispatch(hideLoadingSpinner());
        handleApiErrors(err);
      }
    );
  }

  function populatePatientProfile(patientInfo, photoId) {
    if (patientInfo) {
      setValue(SignUpFormFields.FirstName, patientInfo.firstName);
      setValue(SignUpFormFields.MiddleName, patientInfo.middleName);
      setValue(SignUpFormFields.LastName, patientInfo.lastName);
      setValue(SignUpFormFields.Gender, patientInfo.gender);
      setValue(
        SignUpFormFields.DateOfBirth,
        formatDateTime(patientInfo.dateOfBirth, DateTimeFormats.MM_DD_YYYY)
      );
      setValue(SignUpFormFields.Email, patientInfo.user?.email);
      setValue(
        SignUpFormFields.Phone,
        patientInfo.user?.phone != null ? patientInfo.user?.phone : ""
      );
      setValue(SignUpFormFields.Address1, patientInfo.address1);
      setValue(SignUpFormFields.Address2, patientInfo.address2);
      setValue(SignUpFormFields.Country, patientInfo.country);
      setValue(SignUpFormFields.State, patientInfo.state);
      setValue(SignUpFormFields.City, patientInfo.city);
      setValue(SignUpFormFields.Zip, patientInfo.zip);
      setValue(SignUpFormFields.Timezone, patientInfo.timezone);
      setValue(
        SignUpFormFields.IsSubscribedForSms,
        patientInfo.user?.isSubscribedForSms
      );
      setPhotoUrl(photoId);

      setShouldUpdateLocations(true);
    }
  }

  const handleAddress1 = (place) => {

    if (place?.name) {
      setValue(SignUpFormFields.Address1, place.name);
    }
    
    let address = getAddressFromGooglePlaceObject(place);

    if (address == null) {
      return;
    }
    
    setShouldUpdateLocations(false);
    setValue(SignUpFormFields.Country, address.country, {
        shouldValidate: true
      });

    getStates(address.country).then(() => {
      setValue(SignUpFormFields.State, address.state, {
        shouldValidate: true
      });

      getCities(address.country, address.state).then(() => {
        setValue(SignUpFormFields.City, address.city, {
          shouldValidate: true
        });

        getZips(address.country, address.state, address.city).then(() => {
          setValue(SignUpFormFields.Zip, address.zip, {
            shouldValidate: true
          });
          setShouldUpdateLocations(true);
        });
      });
    });
  }

  const handleAddress2 = (place) => {
    if (place?.name) {
      setValue(SignUpFormFields.Address2, place.name);
    }
  }

  function getCountries() {
    return new Promise(function (resolve) {
      dispatch(showLoadingSpinner());
      getCountries_Ajax(
        function (response) {
          dispatch(hideLoadingSpinner());
          if (response && response.success && response.data) {
            setValue(SignUpFormFields.Country, "");
            setValue(SignUpFormFields.State, "");
            setValue(SignUpFormFields.City, "");
            setValue(SignUpFormFields.Zip, "");
            setCountries(response.data);
            setValue(SignUpFormFields.Country, "US");
          }
          resolve(true);
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
          resolve(false);
        }
      );
    });
  }

  function getStates(countryCode) {
    return new Promise(function (resolve) {
      if (countryCode) {
        dispatch(showLoadingSpinner());
        getStates_Ajax(
          countryCode,
          function (response) {
            dispatch(hideLoadingSpinner());
            if (response && response.success && response.data) {
              setValue(SignUpFormFields.State, "");
              setValue(SignUpFormFields.City, "");
              setValue(SignUpFormFields.Zip, "");
              setStates(response.data);
            }
            resolve(true);
          },
          function (err) {
            dispatch(hideLoadingSpinner());
            handleApiErrors(err);
            resolve(false);
          }
        );
      } else {
        resolve(true);
      }
    });
  }

  function getCities(countryCode, stateCode) {
    return new Promise(function (resolve) {
      if (countryCode && stateCode) {
        dispatch(showLoadingSpinner());
        getCityOrZips_Ajax(
          countryCode,
          stateCode,
          undefined,
          undefined,
          undefined,
          function (response) {
            dispatch(hideLoadingSpinner());
            if (response && response.success && response.data) {
              setValue(SignUpFormFields.City, "");
              setValue(SignUpFormFields.Zip, "");
              setCities(response.data);
            }
            resolve(true);
          },
          function (err) {
            dispatch(hideLoadingSpinner());
            handleApiErrors(err);
            resolve(false);
          }
        );
      } else {
        resolve(true);
      }
    });
  }

  function getZips(countryCode, stateCode, city) {
    return new Promise(function (resolve) {
      if (countryCode && stateCode && city) {
        dispatch(showLoadingSpinner());
        getCityOrZips_Ajax(
          countryCode,
          stateCode,
          city,
          undefined,
          undefined,
          function (response) {
            dispatch(hideLoadingSpinner());
            if (response && response.success && response.data) {
              setValue(SignUpFormFields.Zip, "");
              setZips(response.data);
            }
            resolve(true);
          },
          function (err) {
            dispatch(hideLoadingSpinner());
            handleApiErrors(err);
            resolve(false);
          }
        );
      } else {
        resolve(true);
      }
    });
  }

  function getTimeZones(countryCode) {
    return new Promise(function (resolve) {
      if (countryCode) {
        dispatch(showLoadingSpinner());
        getTimezones_Ajax(
          countryCode,
          function (response) {
            dispatch(hideLoadingSpinner());
            if (
              response &&
              response.success &&
              response.data &&
              Array.isArray(response.data)
            ) {
              setValue(SignUpFormFields.Timezone, "");
              setTimeZones(response.data);
            }
            resolve(true);
          },
          function (err) {
            dispatch(hideLoadingSpinner());
            handleApiErrors(err);
            resolve(false);
          }
        );
      } else {
        resolve(true);
      }
    });
  }

  function isSaveBtnVisible() {
    return (
      firstNameWatch &&
      lastNameWatch &&
      genderWatch &&
      dateOfBirthWatch &&
      countryWatch &&
      cityWatch &&
      stateWatch &&
      zipWatch &&
      timezoneWatch &&
      (
        responsiblePatientId != currentPatient.patid ||
        (
          emailWatch && 
          isPatientPhoneNumberValid
        )
      )
    );
  }

  function updateSelectedPhoto(event) {
    if (event && event.target && event.target.files) {
      var file = event.target.files[0];
      var fileName = file.name.toLowerCase();

      if (isImageFile(fileName)) {
        var tempPath = URL.createObjectURL(file);

        var picObj = {
          url: tempPath,
          type: fileName.split(".").pop(),
          file: file
        };

        setProfilePic(picObj);
      } else {
        showAlertDialouge("Error", "File type not supported");
      }
    }
  }

  function validateImageMaxSize() {
    if (profilePic) {
      var arr = [profilePic.file];
      var fileSizeErrMsg = validateFileMaxSize(arr);

      if (fileSizeErrMsg) {
        showAlertDialouge("Error", fileSizeErrMsg);

        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  function onSubmit(signUpFormData) {
    if (validateImageMaxSize() && existingPatientInfo && signUpFormData) {
      var jsonData = { ...existingPatientInfo };

      jsonData.firstName = signUpFormData.firstName;
      jsonData.middleName = signUpFormData.middleName;
      jsonData.lastName = signUpFormData.lastName;
      jsonData.gender = signUpFormData.gender;
      jsonData.dateOfBirth = signUpFormData.dateOfBirth;
      jsonData.user.phone = signUpFormData.phone;
      jsonData.address1 = signUpFormData.address1;
      jsonData.address2 = signUpFormData.address2;
      jsonData.country = signUpFormData.country;
      jsonData.state = signUpFormData.state;
      jsonData.city = signUpFormData.city;
      jsonData.zip = signUpFormData.zip;
      jsonData.timezone = signUpFormData.timezone;
      jsonData.user.isSubscribedForSms =
        signUpFormData.isSubscribedForSms == 1 ? true : false;
      var dobValidityCheck = isPatientDateOfBirthValid(
        signUpFormData.dateOfBirth
      );

      if (dobValidityCheck.valid) {
        var formData = new FormData();

        if (profilePic && profilePic.file) {
          formData.append("profilePhoto", profilePic.file);
        } else {
          formData.append("profilePhoto", null);
          jsonData.photoId = null;
        }

        formData.append("patientVM", JSON.stringify(jsonData));

        dispatch(showLoadingSpinner());
        savePatientProfileSettings_Ajax(
          formData,
          function (response) {
            dispatch(hideLoadingSpinner());
            if (response && response.success && response.data) {
              dispatch(setIsFamilyMembersListChanged(true));
              getLocationOptionsAndPopulateDate();
              if (response.message) {
                showAlertDialouge("Message", response.message);
              }
            } else if (response && !response.success && response.message) {
              showAlertDialouge("Error", response.message);
            }
          },
          function (err) {
            dispatch(hideLoadingSpinner());
            handleApiErrors(err);
          }
        );
      } else {
        var errorMessage = dobValidityCheck.errorMessage
          ? dobValidityCheck.errorMessage
          : "Please enter a valid date of birth.";

        showAlertDialouge("Error", errorMessage);
      }
    }
  }

  const [showInputField, setShowInputField] = useState(false);
  useEffect(() => {
    if (responsiblePatientId === currentPatient.patid) {
      setShowInputField(true);
    } else {
      setShowInputField(false);
    }
  }, [responsiblePatientId, currentPatient.patid]);

  function shouldShowRemovePhotoBtn() {
    return (photoUrl && photoUrl != null) || profilePic != null;
  }

  const handleProfilePhotoRemoval = handleRemoveProfilePhoto(() => {
    setProfilePic(null);
    setPhotoUrl(null);
  });

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset>
          <legend className="fs-1 mb-0 mb-3 mb-md-4 text-violet">
            Profile Setting
          </legend>
          <div className="row align-items-center">
            <div className="profile-setting-upload mt-4 mb-3 col-md-6 col-xl-6">
              <div className="profile_img">
                <div className="circle me-4 d-flex justify-content-center align-items-center">
                  {profilePic && profilePic.url ? (
                    <img
                      className="profile-pic"
                      src={profilePic.url}
                      onError={onImageError}
                    />
                  ) : photoUrl ? (
                    <img
                      className="profile-pic"
                      src={photoUrl}
                      onError={onImageError}
                    />
                  ) : (
                    <div className="bg-color d-4 d-flex justify-content-center align-items-center">
                      {existingPatientInfo ? (
                        <div className="profile-initial">
                          {getNameInitials(existingPatientInfo.fullName)}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                </div>
                <div className="upload-btn">
                  {shouldShowRemovePhotoBtn() ? (
                    <div className="input-group mb-3">
                      <label
                        className="input-group-text btn btn-danger"
                        onClick={handleProfilePhotoRemoval}
                      >
                        <i className="bi bi-trash-fill me-1"></i>Remove Photo
                      </label>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="input-group mb-3">
                    <label
                      className="input-group-text btn btn-primary"
                      htmlFor="inputGroupFile01"
                    >
                      <i className="bi bi-upload me-1"></i>Upload
                    </label>
                    <input
                      type="file"
                      className="form-control d-none"
                      id="inputGroupFile01"
                      onChange={updateSelectedPhoto}
                    />
                  </div>
                  <div className="text-muted text-start">
                    (File should be Smaller than 1MB)
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group mb-3 col-md-12 col-xl-4">
              <label className="form-label">
                First Name<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="First Name"
                className="form-control"
                placeholder="First Name"
                {...register(SignUpFormFields.FirstName, {
                  required: true,
                  maxLength: {
                    value: 50,
                    message: "Maximum 50 characters are allowed."
                  }
                })}
              />
              {errors.firstName?.message && (
                <p className="help-block">{errors.firstName.message}</p>
              )}
            </div>
            <div className="form-group mb-3 col-md-12 col-xl-4">
              <label className="form-label">Middle Name</label>
              <input
                type="text"
                name="Middle Name"
                className="form-control"
                placeholder="Middle Name"
                {...register(SignUpFormFields.MiddleName, {
                  maxLength: {
                    value: 50,
                    message: "Maximum 50 characters are allowed."
                  }
                })}
              />
              {errors.middleName?.message && (
                <p className="help-block">{errors.middleName.message}</p>
              )}
            </div>
            <div className="form-group mb-3 col-md-12 col-xl-4">
              <label className="form-label">
                Last Name<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="Last Name"
                className="form-control"
                placeholder="Last Name"
                {...register(SignUpFormFields.LastName, {
                  required: true,
                  maxLength: {
                    value: 50,
                    message: "Maximum 50 characters are allowed."
                  }
                })}
              />
              {errors.lastName?.message && (
                <p className="help-block">{errors.lastName.message}</p>
              )}
            </div>
            <div className="form-group mb-3 col-md-6 col-xl-6 d-flex flex-wrap">
              <label className="form-label w-100">
                Gender<span className="text-danger">*</span>
              </label>
              <div className="form-check mb-2 me-4 ps-0">
                <input
                  className="form-check-input mx-1 position-static float-none"
                  type="radio"
                  name="painLevel"
                  id="painLevel1"
                  value={GenderOptions.Male}
                  checked={genderWatch == GenderOptions.Male}
                  {...register(SignUpFormFields.Gender)}
                />
                <label className="form-check-label fs-4 d-inline">Male</label>
              </div>
              <div className="form-check mb-2 me-4 ps-0">
                <input
                  className="form-check-input mx-1 position-static float-none"
                  type="radio"
                  name="painLevel"
                  id="painLevel1"
                  value={GenderOptions.Female}
                  checked={genderWatch == GenderOptions.Female}
                  {...register(SignUpFormFields.Gender)}
                />
                <label className="form-check-label fs-4 d-inline">Female</label>
              </div>
              <div className="form-check mb-2 me-4 ps-0">
                <input
                  className="form-check-input mx-1 position-static float-none"
                  type="radio"
                  name="painLevel"
                  id="painLevel1"
                  value={GenderOptions.Other}
                  checked={genderWatch == GenderOptions.Unknown || genderWatch == GenderOptions.Other}
                  {...register(SignUpFormFields.Gender)}
                />
                <label className="form-check-label fs-4 d-inline">
                  Other
                </label>
              </div>
            </div>
            <div className="form-group mb-3 col-md-6 col-xl-6">
              <label className="form-label">
                Date of Birth<span className="text-danger">*</span>
              </label>
              <DateInputMask
                register={register}
                fieldName={SignUpFormFields.DateOfBirth}
                errors={errors}
                validationType={ValidationTypes.DateOfBirth}
              />
            </div>
            {showInputField && (
              <>
                <div className="form-group mb-3 col-md-6 col-xl-6">
                  <label className="form-label">
                    Email<span className="text-danger">*</span>
                  </label>
                  <input
                    disabled
                    type="email"
                    name="Last Name"
                    className="form-control"
                    placeholder=""
                    {...register(SignUpFormFields.Email, { required: true })}
                  />
                </div>
                <div className="form-group mb-3 col-md-6 col-xl-6">
                  <label className="form-label">
                    Phone<span className="text-danger">*</span>
                  </label>
                  <InputMask
                    mask="(999)-999-9999"
                    className="form-control"
                    placeholder="(XXX)-XXX-XXXX"
                    {...register(SignUpFormFields.Phone, { required: true })}
                  />
                  <div className="d-flex flex-nowrap form-group justify-content-start align-items-center mt-2">
                    <label className="mb-1 me-1 w-auto me-auto me-sm-5">
                      Receive Text
                    </label>
                    <div className="d-inline-block form-check me-4">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="receive-text-yes"
                        id="receive-text-yes"
                        value={SmsSubscriptionOptions.Yes}
                        checked={
                          smsSubscriptionWatch == SmsSubscriptionOptions.Yes
                        }
                        {...register(SignUpFormFields.IsSubscribedForSms)}
                      />
                      <label
                        className="form-check-label fs-4"
                        for="receive-text-yes"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check d-inline-block">
                      <input
                        class="form-check-input receive"
                        type="radio"
                        name="receive-text-no"
                        id="receive-text-no"
                        value={SmsSubscriptionOptions.No}
                        checked={
                          smsSubscriptionWatch == SmsSubscriptionOptions.No
                        }
                        {...register(SignUpFormFields.IsSubscribedForSms)}
                      />
                      <label
                        className="form-check-label fs-4"
                        for="receive-text-no"
                      >
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="form-group mb-3 col-md-6 col-xl-6">
              <label className="form-label">Address1</label>
              <Autocomplete
                handleAddress={handleAddress1}
                existingValue={getValues(SignUpFormFields.Address1)}
                inputElementId="user-address1"
              />
            </div>
            <div className="form-group mb-3 col-md-6 col-xl-6">
              <label className="form-label">Address2</label>
              <Autocomplete
                handleAddress={handleAddress2}
                existingValue={getValues(SignUpFormFields.Address2)}
                inputElementId="user-address2"
              />
            </div>
            <div className="form-group mb-3 col-md-6 col-xl-6">
              <label className="form-label">
                Country<span className="text-danger">*</span>
              </label>
              <select
                className="form-select form-control"
                disabled={true}
                {...register(SignUpFormFields.Country, { required: true })}
              >
                <option key={0} value="">
                  Select Country
                </option>
                {countires.map((country, index) => {
                  return (
                    <option key={index + 1} value={country?.value}>
                      {country?.countryName}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group mb-3 col-md-6 col-xl-6">
              <label className="form-label">
                State<span className="text-danger">*</span>
              </label>
              <select
                className="form-select form-control"
                {...register(SignUpFormFields.State, { required: true })}
              >
                <option key={0} value="">
                  Select States
                </option>
                {states.map((state, index) => {
                  return (
                    <option key={index + 1} value={state?.value}>
                      {state.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group mb-3 col-md-6 col-xl-6">
              <label className="form-label">
                City<span className="text-danger">*</span>
              </label>
              <select
                className="form-select form-control"
                {...register(SignUpFormFields.City, { required: true })}
              >
                <option key={0} value="">
                  Select City
                </option>
                {cities.map((city, index) => {
                  return (
                    <option key={index + 1} value={city}>
                      {city}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group mb-3 col-md-6 col-xl-6">
              <label className="form-label">
                Zip Code<span className="text-danger">*</span>
              </label>
              <select
                className="form-select form-control"
                {...register(SignUpFormFields.Zip, { required: true })}
              >
                <option key={0} value="" disabled={true}>
                  Select Zip Code
                </option>
                {zips.map((zip, index) => {
                  return (
                    <option key={index + 1} value={zip}>
                      {zip}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group mb-3 col-md-6 col-xl-6">
              <label className="form-label">
                Timezone<span className="text-danger">*</span>
              </label>
              <select
                className="form-select form-control"
                {...register(SignUpFormFields.Timezone, { required: true })}
              >
                <option key={0} value="">
                  Select Timezones
                </option>
                {timeZones.map((timezone, index) => {
                  return (
                    <option key={index + 1} value={timezone?.offset}>
                      {timezone?.timezoneName}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group text-center mt-2 mt-md-4 col-sm-12">
              <button
                disabled={!isSaveBtnVisible()}
                type="submit"
                className="btn btn-secondary btn-rounded btn-lg px-5"
              >
                Save
              </button>
            </div>
          </div>
        </fieldset>
      </form>
    </>
  );
};
export default ProfileSettingForm;
