import {
  SmartScanCaptureOptions,
  SmartScanOralHealthStatus,
  SmartScanOverAllStatus,
  SmartScanStatus,
  smartScanCategory
} from "../constants";

export function getOverAllStatusTextColorClass(status) {
  switch (status) {
    case SmartScanOverAllStatus.Good:
      return "text-green";
    case SmartScanOverAllStatus.Moderate:
      return "text-gold";
    case SmartScanOverAllStatus.Poor:
      return "text-danger";
    default:
      return "";
  }
}

export function getDentalConditionStatusTextColorClass(status) {
  switch (status) {
    case SmartScanOralHealthStatus.Good:
      return "text-green";
    case SmartScanOralHealthStatus.Poor:
      return "text-danger";
    case SmartScanOralHealthStatus.NeedsImprovement:
      return "text-gold";
    case SmartScanOralHealthStatus.NonObservable:
      return "text-muted";
    default:
      return "text-black";
  }
}

export const getSmartScanSelfieImagesBasedOnCategory = (category) => {
  switch (category) {
    case smartScanCategory.Bite:
      return require("../assets/images/01-SelfieUpper.jpg");
    case smartScanCategory.UpperArch:
      return require("../assets/images/02-SelfieRightSide.jpg");
    case smartScanCategory.LowerArch:
      return require("../assets/images/03-SelfieLeftSide.jpg");
    case smartScanCategory.UpperLip:
      return require("../assets/images/04-SelfieFront.jpg");
    case smartScanCategory.LowerLip:
      return require("../assets/images/05-SelfieLower.jpg");
    default:
      return "";
  }
};

export const getSmartScanAssistedImagesBasedOnCategory = (category) => {
  switch (category) {
    case smartScanCategory.Bite:
      return require("../assets/images/01-AssistedUpper.jpg");
    case smartScanCategory.UpperArch:
      return require("../assets/images/02-AssistedRightSide.jpg");
    case smartScanCategory.LowerArch:
      return require("../assets/images/03-AssistedLeftSide.jpg");
    case smartScanCategory.UpperLip:
      return require("../assets/images/04-AssistedFront.jpg");
    case smartScanCategory.LowerLip:
      return require("../assets/images/05-AssistedLower.jpg");
    default:
      return "";
  }
};

export const getSmartScanImageBasedOnCaptureOption = (
  captureOption,
  category
) => {
  if (captureOption == SmartScanCaptureOptions.Myself) {
    return getSmartScanSelfieImagesBasedOnCategory(category);
  } else if (captureOption == SmartScanCaptureOptions.Assisted) {
    return getSmartScanAssistedImagesBasedOnCategory(category);
  }
};

export const getSmartScanAssistedInstructionsBasedOnCategory = (category) => {
  switch (category) {
    case smartScanCategory.Bite:
      return "Open your mouth as wide as you can to get a clear shot of all your upper teeth.";
    case smartScanCategory.UpperArch:
      return "Have patient turn head slightly to the left. Instruct to use right-hand index and middle finger to retract cheek. You may need to use your fingers if the patient is a child.";
    case smartScanCategory.LowerArch:
      return "Have patient turn head slightly to the right. Instruct to use left-hand index and middle finger to retract cheek. You may need to use your fingers if the patient is a child.";
    case smartScanCategory.UpperLip:
      return "A big smile with the patient's teeth slightly open to show front teeth and gums. You may need to use your thumb and your forefinger to open your lips a bit more to show gums.";
    case smartScanCategory.LowerLip:
      return "Open your mouth as wide as you can to get a clear shot of all your bottom teeth.";
    default:
      return "";
  }
};

export const getSmartScanSelfieInstructionsBasedOnCategory = (category) => {
  switch (category) {
    case smartScanCategory.Bite:
      return "Turn your phone upside down for a better angle, open your mouth as wide as you can to get a clear shot of all your upper teeth.";
    case smartScanCategory.UpperArch:
      return "Turn your head slightly to the left. Use your right-hand index and middle fingers to retract your right cheek.";
    case smartScanCategory.LowerArch:
      return "Turn your head slightly to the right. Use your left-hand index and middle fingers to retract your left cheek.";
    case smartScanCategory.UpperLip:
      return "A big smile with the your teeth slightly open to show your front teeth and some of your gums. You may need to use your thumb and your forefinger to open your lips a bit more.";
    case smartScanCategory.LowerLip:
      return "Open your mouth as wide as you can to get a clear shot of all your bottom teeth.";
    default:
      return "";
  }
};

export const getSmartScanInstructionsBasedOnCaptureOption = (
  captureOption,
  category
) => {
  if (captureOption == SmartScanCaptureOptions.Myself) {
    return getSmartScanSelfieInstructionsBasedOnCategory(category);
  } else if (captureOption == SmartScanCaptureOptions.Assisted) {
    return getSmartScanAssistedInstructionsBasedOnCategory(category);
  }
};

export const excludeSmartScanStatuses = [SmartScanStatus.CaseRejected];
