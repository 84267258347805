import React, { useState, useEffect } from "react";
import QuestionnaireTabs from "../QuestionnaireTabs/QuestionnaireTabs";
import QuestionnaireRecordRequestForm from "./QuestionnaireRecordRequestForm";
import QuestionnaireUploadRecords from "./QuestionnaireUploadRecords";
import useGetSecondOpinionRecordRequestProgress from "../CustomHooks/useGetSecondOpinionRecordRequestProgress";
import GreenTickIcon from "../../assets/images/thanks-msg.svg";
import { EligibilityStatus } from "../../constants";

const QuestionnaireRecordRequestPage = () => {
  const [eventForm, setEventForm] = useState(false);
  const [uploadRecord, setUploadRecord] = useState(false);

  const [patientId, setPatientId] = useState(0);
  const [recordRequestProviderData, setRecordRequestProviderData] = useState();
  const [uploadFiles, setUploadFiles] = useState();
  const state = useGetSecondOpinionRecordRequestProgress();
  const [secondOpinionState, setSecondOpinionState] = useState(null);

  useEffect(() => {
    if (uploadRecord) {
      setSecondOpinionState({
        ...secondOpinionState,
        uploadRecordPageStates: { uploadRecord: true },
        patientId: patientId
      });
    } else {
      setSecondOpinionState({ patientId: patientId });
    }
  }, [uploadRecord]);

  useEffect(() => {
    if (eventForm) {
      setSecondOpinionState({
        ...secondOpinionState,
        uploadRecordPageStates: { eventForm: true },
        patientId: patientId
      });
    } else {
      setSecondOpinionState({ patientId: patientId });
    }
  }, [eventForm]);

  useEffect(() => {
    if (state?.patientId > 0) {
      setSecondOpinionState(state);
    }
  }, [state]);

  useEffect(() => {
    if (secondOpinionState) {
      if (secondOpinionState?.patientId) {
        setPatientId(secondOpinionState?.patientId);
      }

      setRecordRequestProviderFormData(secondOpinionState);
      setUploadedRecordData(secondOpinionState);
    }
  }, [secondOpinionState]);

  function setUploadedRecordData(state) {
    if (state?.uploadRecordPageStates?.uploadRecord) {
      setUploadRecord(state.uploadRecordPageStates.uploadRecord);
      setUploadFiles(state.uploadedFiles);
    } else {
      setUploadRecord(false);
      setUploadFiles(null);
    }
  }

  function setRecordRequestProviderFormData(state) {
    if (state?.uploadRecordPageStates?.eventForm) {
      setEventForm(state.uploadRecordPageStates.eventForm);
      setRecordRequestProviderData(state.recordRequestProviderData);
    } else {
      setEventForm(false);
      setRecordRequestProviderData(null);
    }
  }

  const requestFormHandler = () => {
    setEventForm((wasShown) => !wasShown);
  };
  const uploadRecordsHandler = () => {
    setUploadRecord((canShow) => !canShow);
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
              <QuestionnaireTabs />
              {!eventForm && !uploadRecord && (
                <>
                  {state?.insuranceEligibilityStatus ==
                  EligibilityStatus.Yes ? (
                    <div className="row text-center">
                      <div className="col-md-12 mb-3 mt-5">
                        <img className="mb-3" src={GreenTickIcon} alt="" />
                        <h2 className="mb-3">You’re Covered!</h2>
                        <h4>
                          We’ve confirmed this is a covered benefit with your
                          insurance.
                        </h4>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  <div className="form-group text-center mt-3 col-sm-12">
                    <p className="fs-3 text-muted">
                      Don't have your records? Request your records from your
                      dentist.
                    </p>
                    <button
                      type="button"
                      className="btn btn-secondary btn-rounded btn-lg w-100 max-375"
                      onClick={requestFormHandler}
                    >
                      Request Your Records
                    </button>
                  </div>
                  <div className="form-group text-center mt-3 col-sm-12">
                    <p className="fs-3 text-muted">
                      Already have your records? Upload them now!
                    </p>
                    <button
                      type="button"
                      className="btn btn-outline-secondary btn-rounded btn-lg w-100 max-375"
                      onClick={uploadRecordsHandler}
                    >
                      <i className="bi bi-cloud-upload"></i> &nbsp; Upload Your
                      Records
                    </button>
                  </div>
                </>
              )}
              {eventForm && (
                <QuestionnaireRecordRequestForm
                  stateChanger={setEventForm}
                  patientId={patientId}
                  pageStates={secondOpinionState}
                  formData={recordRequestProviderData}
                />
              )}
              {uploadRecord && (
                <QuestionnaireUploadRecords
                  closeUploadRecordHandler={setUploadRecord}
                  patientId={patientId}
                  pageStates={secondOpinionState}
                  uploadFiles={uploadFiles}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionnaireRecordRequestPage;
