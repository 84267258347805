import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useAlert } from "react-alert";
import { changePassword_Ajax } from "../../helpers/requests";
import { hideLoadingSpinner } from "../../redux/actions/loadingSpinner";
import { Patterns } from "../../constants";
import { showAlertDialouge, validatePassword } from "../../helpers/utils";

const ChangePasswordForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const alert = useAlert();
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors, isValid },
    reset
  } = useForm({ mode: "onChange" });

  function passowrdMatched() {
    var currentPassword = getValues("currentPassword");
    var newPassword = getValues("newPassword");
    var confirmPassword = getValues("confirmPassword");

    if (currentPassword === newPassword) {
      showAlertDialouge(
        "Error",
        "New password should not match with the current password"
      );

      return false;
    }

    if (newPassword !== confirmPassword) {
      showAlertDialouge("Error", "Password does not match");
    } else {
      return true;
    }
  }

  function changePassword(passwordForm) {
    if (
      passwordForm?.currentPassword &&
      passwordForm?.newPassword &&
      passwordForm?.confirmPassword &&
      passowrdMatched()
    ) {
      var changePasswordModel = {
        Password: passwordForm.currentPassword,
        NewPassword: passwordForm.newPassword
      };

      changePassword_Ajax(
        JSON.stringify(changePasswordModel),
        function (response) {
          dispatch(hideLoadingSpinner());
          if (response && response.success) {
            reset();
            showAlertDialouge("Message", response.message, function () {
              navigate("/");
            });
          } else if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
        }
      );
    }
  }

  function toogleDisplayCurrentPassword() {
    setShowCurrentPassword(!showCurrentPassword);
  }

  function toogleDisplayNewPassword() {
    setShowNewPassword(!showNewPassword);
  }

  function toogleDisplayConfirmPassword() {
    setShowConfirmPassword(!showConfirmPassword);
  }

  return (
    <>
      <div className="container">
        <div className="row mb-3">
          <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xxl-4 offset-xxl-4">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Change Password
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xxl-4 offset-xxl-4">
            <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
              <div className="" id="step-tabContent">
                <div
                  className="fade show active"
                  id="profile-setting"
                  role="tabpanel"
                  aria-labelledby="see-dentist-tab"
                >
                  <form onSubmit={handleSubmit(changePassword)}>
                    <fieldset>
                      <h2 className="text-center text-violet mb-4">
                        Change Password
                      </h2>
                      <div className="row">
                        <div className="form-group mb-3">
                          <div className="row align-items-center">
                            <div className="col-auto">
                              <label className="form-label">
                                Current Password{" "}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                            <div className="col">
                              <div className="fs-5 mb-1 show-password">
                                <a
                                  onClick={() => {
                                    toogleDisplayCurrentPassword();
                                  }}
                                  className="ms-auto d-table text-muted"
                                >
                                  {showCurrentPassword
                                    ? "Hide Password"
                                    : "Show Password"}
                                </a>
                              </div>
                            </div>
                          </div>
                          <input
                            type={!showCurrentPassword ? "password" : "text"}
                            name=""
                            className="form-control"
                            placeholder=""
                            {...register("currentPassword", {
                              required: true
                            })}
                          />
                        </div>
                        <div className="form-group mb-3">
                          <div className="row align-items-center">
                            <div className="col-auto">
                              <label className="form-label">
                                New Password{" "}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                            <div className="col">
                              <div className="fs-5 mb-1 show-password">
                                <a
                                  onClick={() => {
                                    toogleDisplayNewPassword();
                                  }}
                                  className="ms-auto d-table text-muted"
                                >
                                  {showNewPassword
                                    ? "Hide Password"
                                    : "Show Password"}
                                </a>
                              </div>
                            </div>
                          </div>
                          <input
                            type={!showNewPassword ? "password" : "text"}
                            name=""
                            className="form-control"
                            placeholder=""
                            {...register("newPassword", {
                              required: true,
                              validate: (value) => validatePassword(value)
                            })}
                          />
                          {/* {!errors.newPassword && (
                            <span className="fs-6 text-muted mt-1 d-inline-block align-top w-100">
                              Eight or more characters, with at least one
                              lowercase and one uppercase letter.
                            </span>
                          )} */}
                          {errors.newPassword && (
                            <span className="help-block">
                              Please enter a valid password. Your password must
                              contain eight or more characters, with at least
                              one lowercase and one uppercase letter.
                            </span>
                          )}
                        </div>
                        <div className="form-group mb-3">
                          <div className="row align-items-center">
                            <div className="col-auto">
                              <label className="form-label">
                                Confirm Password{" "}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                            <div className="col">
                              <div className="fs-5 mb-1 show-password">
                                <a
                                  onClick={() => {
                                    toogleDisplayConfirmPassword();
                                  }}
                                  className="ms-auto d-table text-muted"
                                >
                                  {showConfirmPassword
                                    ? "Hide Password"
                                    : "Show Password"}
                                </a>
                              </div>
                            </div>
                          </div>
                          <input
                            type={!showConfirmPassword ? "password" : "text"}
                            name=""
                            className="form-control"
                            placeholder=""
                            {...register("confirmPassword", {
                              required: true,
                              validate: (value) =>
                                getValues("newPassword") === value
                            })}
                          />
                          {errors.confirmPassword && (
                            <span className="help-block">
                              The password and confirmation password do not
                              match
                            </span>
                          )}
                        </div>
                        <div className="form-group text-center mt-2 mt-md-4 col-sm-12">
                          <button
                            type="submit"
                            className="btn btn-secondary btn-rounded btn-lg px-5"
                            disabled={!isValid}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </fieldset>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePasswordForm;
