import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import SmartScanWorksPage from "../../components/SmartScanWorks/SmartScanWorksPage";
import { PATIENT_PORTAL_AUTO_LOGIN_API_KEY } from "../../constants";
import { getTokenData } from "../../helpers/authManager";
import { getQRCode_ajax } from "../../helpers/requests";
import { handleApiErrors, showAlertDialouge } from "../../helpers/utils";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import { getBaseUrl } from "../../ApplicationSettings";

const SmartScanWorks = () => {
  const [QRCode, setQRCode] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    getQRCode();
  }, []);

  function getQRCode() {
    let tokenData = getTokenData();
    if (tokenData && tokenData.token) {
      let url = encodeURIComponent(
        getBaseUrl() +
          "/login?apiKey=" +
          PATIENT_PORTAL_AUTO_LOGIN_API_KEY +
          "&token=" +
          tokenData.token +
          "&redirectTo=/smart-scan-works"
      );

      dispatch(showLoadingSpinner());
      getQRCode_ajax(
        url,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (response && response.success && response.data) {
            setQRCode(response.data);
          } else if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  return (
    <>
      <body className="mobile-body">
        <div className="body-content">
          <section className="review-photo-section py-4 py-md-5">
            <SmartScanWorksPage QRCode={QRCode} setQRCode={setQRCode} />
          </section>
        </div>
      </body>
    </>
  );
};

export default SmartScanWorks;
