import React from "react";
import { useForm } from "react-hook-form";
import { Patterns } from "../../constants";
import { shareWellnessScoreReort_Ajax } from "../../helpers/requests";
import { useDispatch } from "react-redux";
import { showAlertDialouge } from "../../helpers/utils";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import { handleApiErrors } from "../../helpers/utils";

const ShareWellnessReport = (props) => {
  const { smartScanId } = props;

  const dispatch = new useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid }
  } = useForm({
    mode: "onChange"
  });

  function shareWellnessReport(formData) {
    if (formData) {
      let shareViewModel = {
        referToFirstName: formData.firstName,
        referToLastName: formData.lastName,
        referToEmail: formData.email,
        smartScanId: smartScanId
      };

      if (shareViewModel) {
        dispatch(showLoadingSpinner());

        shareWellnessScoreReort_Ajax(
          JSON.stringify(shareViewModel),
          function (response) {
            if (response?.success) {
              dispatch(hideLoadingSpinner());
              showAlertDialouge("Message", response?.message);
            } else {
              showAlertDialouge("Error", "Failed to share smart scan");
            }
          },
          function (err) {
            handleApiErrors(err);
            dispatch(hideLoadingSpinner());
          }
        );
      }
    }
    reset();
  }

  function resetShareWellnessReportForm() {
    reset();
  }

  return (
    <div className="modal fade" id="smartscan-print-share" tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header primary-border-color">
            <h2 className="modal-title w-100 text-primary text-center">
              Share
            </h2>
            <button
              onClick={resetShareWellnessReportForm}
              type="button"
              className="btn-close text-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(shareWellnessReport)}>
              <div className="row">
                <div className="col-md-12">
                  <div className="input-group mb-3">
                    <label className="form-label">Dentist First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First Name"
                      {...register("firstName", {
                        required: true,
                        maxLength: {
                          value: 50,
                          message: "Maximum 50 characters are allowed."
                        }
                      })}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="input-group mb-3">
                    <label className="form-label">Dentist Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last Name"
                      {...register("lastName", {
                        required: true,
                        maxLength: {
                          value: 50,
                          message: "Maximum 50 characters are allowed."
                        }
                      })}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="input-group mb-3">
                    <label className="form-label">Dentist Email</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email"
                      {...register("email", {
                        required: "This field is required.",
                        pattern: {
                          value: Patterns.Email,
                          message:
                            "Please enter a valid email address. (e.g. example@email.com)"
                        }
                      })}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-primary d-block ms-auto mx-auto"
                    value="Submit"
                    data-bs-dismiss="modal"
                    disabled={!isValid}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ShareWellnessReport;
