import { useEffect, useState } from "react";
import { GetPatientInsuranceInfo_Ajax } from "../../helpers/requests";
import { useDispatch } from "react-redux";
import { handleApiErrors } from "../../helpers/utils";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";

const useGetPatientInsuranceInfo = (patientId) => {
  const [insuranceInfo, setInsuranceInfo] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (patientId) {
      getPatientInsuranceInfo();
    }
  }, [patientId]);

  function getPatientInsuranceInfo() {
    if (patientId) {
      dispatch(showLoadingSpinner());
      GetPatientInsuranceInfo_Ajax(
        patientId,
        {},
        function (response) {
          dispatch(hideLoadingSpinner());
          if (response && response.success && response.data) {
            setInsuranceInfo(response.data);
          } else {
            setInsuranceInfo({});
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  return insuranceInfo;
};

export default useGetPatientInsuranceInfo;
