import React from "react";
import {
  formatDateTime,
  formatDateTimeWithZone,
  getTimeSince,
  isEmptyArray,
  isEqualToCurrentDate,
  toLocal
} from "../../helpers/utils";
import AttachedSignature from "../Print/AttachedSignature";
import { DateTimeFormats, SignatureType } from "../../constants";

export default function GeneralProgressNotesTable({ patientNotes }) {
  function getNoteCreationTimeAndDate(note) {
    if (note && note.createdOn) {
      let creationTime = "";

      creationTime = formatDateTimeWithZone(
        toLocal(note.createdOn),
        DateTimeFormats.HH_MM_A
      );

      let creationDateOrTimeSince = "";

      if (isEqualToCurrentDate(note.createdOn)) {
        creationDateOrTimeSince = getTimeSince(note.createdOn) + " ago";
      } else {
        creationDateOrTimeSince = formatDateTime(
          toLocal(note.createdOn),
          DateTimeFormats.MMM_DD_YYYY
        );
      }

      if (creationTime && creationDateOrTimeSince) {
        return (
          <>
            <p className="fs-4 mb-0">{creationTime}</p>
            <span className="d-block">{creationDateOrTimeSince}</span>
          </>
        );
      }
    }
  }

  function getFormattedDateOfService(note) {
    if (note && note.dateOfService) {
      return (
        <p style={{ fontWeight: "bold" }}>
          Date of service:{" "}
          {formatDateTime(note.dateOfService, DateTimeFormats.MMM_DD_YYYY)}
        </p>
      );
    }
  }

  function isScribeTypeSignature(note) {
    return note?.signatureType == SignatureType.Scribe;
  }
  return (
    <>
      {!isEmptyArray(patientNotes) ? (
        <table className="table">
          <thead>
            <tr>
              <th style={{ width: "130px" }}>Date</th>
              <th>Note</th>
            </tr>
          </thead>
          <tbody>
            {patientNotes.map((note) => {
              return (
                <tr key={note.caseReportProgressNoteId?.toString()}>
                  <td data-label="Date">{getNoteCreationTimeAndDate(note)}</td>
                  <td className="text-break" data-label="Note">
                    <div>{getFormattedDateOfService(note)}</div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: note?.description
                      }}
                    ></div>
                    <AttachedSignature
                      isSignatureAttached={note?.isSignatureAttached}
                      attachedSignature={note?.attachedSignature}
                      isScribeTypeSignature={isScribeTypeSignature(note)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <></>
      )}
    </>
  );
}
