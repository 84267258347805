import React from "react";

const RelationOptions = () => {
  return (
    <>
      <option value="" defaultValue>
        Select Relation
      </option>
      <option value="Son">Son</option>
      <option value="Daughter">Daughter</option>
      <option value="Spouse">Spouse</option>
      <option value="Father">Father</option>
      <option value="Mother">Mother</option>
      <option value="Friend">Friend</option>
      <option value="Other">Other</option>
    </>
  );
};

export default RelationOptions;
