import React from "react";
import { Link, useNavigate } from "react-router-dom";

import ProfileSettingForm from "./ProfileSettingForm";

const ProfileSettingSection = () => {
  return (
    <>
      <section className="sec-profile-setting py-4 py-md-5">
        <div className="container">
          <div className="row mb-3">
            <div className="col-sm-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Profile Settings
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-10 offset-sm-1 col-md-12 offset-md-0 col-lg-10 offset-lg-1">
              <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
                <div className="tab-content" id="step-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="profile-setting"
                    role="tabpanel"
                    aria-labelledby="see-dentist-tab"
                  >
                    <ProfileSettingForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default ProfileSettingSection;
