import React from "react";
import QuestionnaireReviewSubmitPage from "../../components/QuestionnaireReviewSubmit/QuestionnaireReviewSubmitPage";
import useGetSecondOpinionRecordRequestProgress from "../../components/CustomHooks/useGetSecondOpinionRecordRequestProgress";

const QuestionnaireReviewSubmit = () => {
  const state = useGetSecondOpinionRecordRequestProgress();
  return (
    <>
      <div className="body-content">
        <section className="questionnaire-section py-4 py-md-5">
          <QuestionnaireReviewSubmitPage
            uploadedFiles={state?.uploadedFiles}
            patientState={state?.patientState}
            secondOpinionForm={state?.secondOpinionForm}
            consentForm={state?.consentForm}
            recordRequestProviderData={state?.recordRequestProviderData}
            patientId={state?.patientId}
            insuranceEligibilityStatus={state?.insuranceEligibilityStatus}
          />
        </section>
      </div>
    </>
  );
};

export default QuestionnaireReviewSubmit;
