import React from "react";
import {
  GenderOptions,
  SignUpFormFields,
  ValidationTypes
} from "../../constants";
import RelationOptions from "../RelationOptions/RelationOptions";
import { useForm } from "react-hook-form";
import DateInputMask from "../DateInput/DateInputMask";

const AddDependentForm = (props) => {
  function clickAddDependent(data) {
    props.onSubmit(data);
    reset();
  }

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, errors }
  } = useForm({
    mode: "onChange"
  });

  return (
    <form className="row pt-4" onSubmit={handleSubmit(clickAddDependent)}>
      <div className="form-group mb-3 col-md-6">
        <label className="form-label">
          First Name<span className="text-danger">*</span>
        </label>
        <input
          type="text"
          className="form-control"
          placeholder="First name"
          {...register(SignUpFormFields.FirstName, {
            required: true,
            maxLength: {
              value: 50,
              message: "Maximum 50 characters are allowed."
            }
          })}
        />
        {errors.firstName?.message && (
          <p className="help-block">{errors.firstName.message}</p>
        )}
      </div>
      <div className="form-group mb-3 col-md-6">
        <label className="form-label">
          Last Name<span className="text-danger">*</span>
        </label>
        <input
          type="text"
          className="form-control"
          placeholder="Last name"
          {...register(SignUpFormFields.LastName, {
            required: true,
            maxLength: {
              value: 50,
              message: "Maximum 50 characters are allowed."
            }
          })}
        />
        {errors.lastName?.message && (
          <p className="help-block">{errors.lastName.message}</p>
        )}
      </div>
      <div className="form-group mb-3 col-md-6">
        <label className="form-label">
          Select Relation<span className="text-danger">*</span>
        </label>
        <select
          className="form-select form-control"
          name="relation"
          id="relation"
          {...register(SignUpFormFields.Relation, { required: true })}
        >
          <RelationOptions />
        </select>
      </div>
      <div className="form-group mb-3 col-md-6">
        <label className="form-label">
          Date of Birth<span className="text-danger">*</span>
        </label>
        <DateInputMask
          register={register}
          fieldName={SignUpFormFields.DateOfBirth}
          errors={errors}
          validationType={ValidationTypes.DateOfBirth}
        />
      </div>
      <div className="form-group mb-3 col-md-12 d-flex flex-wrap align-items-center">
        <label className="d-inline-block mb-2">
          Gender<span className="text-danger">*</span>
        </label>
        <div className="form-check mb-2 me-2 me-md-4 d-inline-block">
          <input
            className="form-check-input"
            type="radio"
            name="anyAllergy"
            id="genderMale"
            value={GenderOptions.Male}
            {...register(SignUpFormFields.Gender, { required: true })}
          />
          <label className="form-check-label fs-4" htmlFor="genderMale">
            Male
          </label>
        </div>
        <div className="form-check mb-2 me-2 me-md-4 d-inline-block">
          <input
            className="form-check-input"
            type="radio"
            name="anyAllergy"
            id="genderFemale"
            value={GenderOptions.Female}
            {...register(SignUpFormFields.Gender, { required: true })}
          />
          <label className="form-check-label fs-4" htmlFor="genderFemale">
            Female
          </label>
        </div>
        <div className="form-check mb-2 me-2 me-md-4 d-inline-block">
          <input
            className="form-check-input"
            type="radio"
            name="anyAllergy"
            id="genderOther"
            value={GenderOptions.Other}
            {...register(SignUpFormFields.Gender, { required: true })}
          />
          <label className="form-check-label fs-4" htmlFor="genderOther">
            Other
          </label>
        </div>
      </div>
      <div className="form-group mt-2 mt-md-4 col-sm-12">
        <button
          disabled={!isValid}
          type="submit"
          className="btn btn-primary btn-rounded btn-sm px-5"
        >
          Add Dependent
        </button>
      </div>
    </form>
  );
};

export default AddDependentForm;
