import React from "react";
import SmartScanUpperArchPage from "../../components/SmartScanUpperArch/SmartScanUpperArchPage";

const SmartScanUpperArch = () => {
  return (
    <>
      <body className="mobile-body">
        <div className="body-content">
          <section className="review-photo-section py-4 py-md-5">
            <SmartScanUpperArchPage />
          </section>
        </div>
      </body>
    </>
  );
};

export default SmartScanUpperArch;
