import {
  SHOW_LOADING_SPINNER,
  HIDE_LOADING_SPINNER
} from "../actions/typeConstants";

const initialState = {
  loadingSpinnerCount: 0
};

export const loadingSpinner = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LOADING_SPINNER:
      return Object.assign({}, state, {
        loadingSpinnerCount: state.loadingSpinnerCount + 1
      });
    case HIDE_LOADING_SPINNER:
      return Object.assign({}, state, {
        loadingSpinnerCount: state.loadingSpinnerCount - 1
      });
    default:
      return state;
  }
};
