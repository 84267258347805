import React from "react";
import { Link } from "react-router-dom";

import SurveyFormSubmissionComponent from "../../components/SurveyFormSubmission/SurveyFormSubmission";
import dentalComLogo from "./../../assets/images/dental-com-logo.svg";
import { getLogoUrl } from "../../ApplicationSettings";

const SurveyFormSubmission = () => {
  const logo = require("./../../assets/images/" + getLogoUrl());
  return (
    <>
      <body>
        <header className="py-2">
          <nav className="navbar navbar-expand-lg p-0">
            <div className="container-fluid">
              <Link
                to="/"
                className="navbar-brand p-0"
                href="javascript:void(0);"
              >
                <img src={logo} alt="Logo" width="120" height="50" />
              </Link>
            </div>
          </nav>
        </header>
        <section className="step-section py-4 py-md-5">
          <div className="container">
            <div className="row">
              <div className="col-sm-10 offset-sm-1 col-md-12 offset-md-0 col-xl-10 offset-xl-1">
                <SurveyFormSubmissionComponent />
              </div>
            </div>
          </div>
        </section>
        {/* <!-- scripts --> */}
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js"
          integrity="sha512-894YE6QWD5I59HgZOGReFYm4dnWc1Qt5NtvYSaNcOP+u1T9qYdvdihz0PPSiiqn/+/3e7Jo4EaG7TubfWGUrMQ=="
          crossOrigin="anonymous"
          referrerPolicy="no-referrer"
        ></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/signature_pad/1.3.2/signature_pad.min.js"></script>
        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"
          integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"
          crossOrigin="anonymous"
        ></script>
      </body>
    </>
  );
};

export default SurveyFormSubmission;
