import React from "react";
import ChatSection from "../../components/Chat/ChatSection";

const Chat = () => {
  return (
    <>
      <header className="py-2">
        <ChatSection />
      </header>
    </>
  );
};

export default Chat;
