import React from "react";
import { useSelector } from "react-redux";
import MyDependentsEditMember from "./MyDependentsEditMember";
const MyDependentsAccordion = (props) => {
  const { familyMembersList } = props;

  return (
    <>
      <div className="accordion my-dependence-accordion" id="accordionExample">
        {familyMembersList &&
          Array.isArray(familyMembersList) &&
          familyMembersList.length > 0 &&
          familyMembersList.map((familyMember, index) => {
            return (
              <div key={index.toString()} className="accordion-item">
                <h2
                  className="accordion-header"
                  id={"headingOne" + index.toString()}
                >
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={"#collapseOne" + index.toString()}
                    aria-expanded="true"
                    aria-controls={"collapseOne" + index.toString()}
                  >
                    {/* <!-- Tony Doe -->
                                            <!-- <span className="ps-1">(Son)</span> --> */}
                    <div className="accordion-action-buttons d-flex justify-content-between w-100">
                      <div className="family-member">
                        {familyMember.fullName}
                      </div>
                      <div className="accordion-action-btn me-1">
                        <a
                          href=""
                          className="edit btn btn-primary btn-xs"
                          data-bs-target={"#collapseOne" + index.toString()}
                        >
                          Edit
                        </a>
                        {/* <a href="" className="delete btn btn-xs p-0 mx-1 fs-5" data-bs-toggle="modal"
                                                        data-bs-target="#deleteAlert-modal"> Delete</a> */}
                      </div>
                    </div>
                  </button>
                </h2>
                <div
                  id={"collapseOne" + index.toString()}
                  className="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    {/* Edit Member */}
                    <MyDependentsEditMember dependentMember={familyMember} />
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default MyDependentsAccordion;
