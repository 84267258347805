import React, { useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import { Shades } from "../../constants";
import $ from "jquery";

const carouselOptions = {
  items: 1,
  slideBy: 1,
  dots: false,
  utoplayTimeout: 5000,
  loop: false,
  startPosition: 10,
  nav: true,
  smartSpeed: 200,
  slideSpeed: 2000,
  responsiveRefreshRate: 100,
  responsive: {
    0: {
      items: 2
    },
    475: {
      items: 3
    },
    768: {
      items: 5
    },
    992: {
      items: 7
    },
    1200: {
      items: 10
    }
  }
};

const ShadeOwlCarousel = (props) => {
  const { shadeScore, currentShadeId, onHandleShadeClick } = props;

  const handleShadeClick = (id) => {
    if (onHandleShadeClick) onHandleShadeClick(id);
    let div = "shade-owl-item-" + id;
    $(div).addClass("current");
  };

  const getSelectedItemCSSClass = (id) => {
    if (id == currentShadeId) {
      return "current";
    }
    return "";
  };

  useEffect(() => {
    const items = document.querySelectorAll(".owl-item");
    items.forEach((item, index) => {
      item.setAttribute("id", `shade-owl-item-id-${index}`);
      if (index == currentShadeId) {
        item.classList.add("current");
      }
    });
  });

  return (
    <div className="grid-score-wrapper">
      <OwlCarousel
        id="score-number"
        className="owl-carousel owl-theme owl-loaded owl-drag"
        {...carouselOptions}
        key={currentShadeId}
      >
        <div className="owl-stage-outer">
          <div className="owl-stage">
            {Shades.map((shade) => (
              <div
                key={shade.id}
                className={`owl-item ${getSelectedItemCSSClass(shade.id)}`}
                style={{ width: "124.667px" }}
                onClick={() => handleShadeClick(shade.id)}
              >
                <div className="item">
                  <div className="box" style={{ backgroundColor: shade.color }}>
                    {shade.score == shadeScore && <span>Your Score</span>}
                  </div>
                  <div>{shade.score}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </OwlCarousel>
    </div>
  );
};

export default ShadeOwlCarousel;
