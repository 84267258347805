import React, { useState, useEffect } from "react";
import SuccessMessage from "./SuccessMessage";
import RecordSentModal from "../../pages/PatientRecordsRequest/RecordSentModal";
import QuestionnaireUploadRecords from "../QuestionnaireRecordRequest/QuestionnaireUploadRecords";
import moment from "moment";

import {
  queryStringToJSON,
  isEmptyArray,
  showAlertDialouge,
  handleApiErrors,
  formatDateTime
} from "../../helpers/utils";
import {
  saveSecondOpinionRecord_Ajax,
  getSecondOpinion_Ajax
} from "../../helpers/requests";
import { useDispatch } from "react-redux";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import { DateTimeFormats, MaxAllowdFileSizes } from "../../constants";
import ConsentDataPage from "./ConsentDataPage";

const PatientRecordsRequestPage = () => {
  const dispatch = useDispatch();

  const [recordSubmitted, setRecordSubmitted] = useState(false);
  const [apiKey, setApiKey] = useState(null);
  const [secondOpinionId, setSecondOpinionId] = useState(0);
  const [recordRequestHistoryId, setRecordRequestHistoryId] = useState(0);
  const [secondOpinion, setSecondOpinion] = useState(null);
  const [isLinkValid, setIsLinkValid] = useState(false);
  const [
    isSecondOpinionFetchApiInProgress,
    setIsSecondOpinionFetchApiInProgress
  ] = useState(true);

  useEffect(() => {
    getDataFromParam();
  }, []);

  useEffect(() => {
    if (apiKey && secondOpinionId) {
      getSecondOpinion(apiKey, secondOpinionId);
    }
  }, [apiKey, secondOpinionId]);

  useEffect(() => {
    if (
      secondOpinion &&
      secondOpinion.secondOpinionRecordRequestData &&
      secondOpinion.secondOpinionRecordRequestData.createdOn
    ) {
      if (
        moment
          .utc()
          .diff(
            moment(secondOpinion.secondOpinionRecordRequestData.createdOn),
            "hours"
          ) <= 24
      ) {
        setIsLinkValid(true);
      }
    }
  }, [secondOpinion]);

  function getSecondOpinion(apiKey, secondOpinionId) {
    if (apiKey && secondOpinionId) {
      var params = {
        includeAdditionalData: true,
        includeRecordRequestData: true,
        apiKey: apiKey
      };

      dispatch(showLoadingSpinner());
      getSecondOpinion_Ajax(
        secondOpinionId,
        params,
        function (response) {
          dispatch(hideLoadingSpinner());
          setIsSecondOpinionFetchApiInProgress(false);

          if (
            response &&
            response.success &&
            response.data &&
            response.data.secondOpinion
          ) {
            setSecondOpinion(response.data.secondOpinion);
          } else if (response && !response.success && response.message) {
            setSecondOpinion(null);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          setIsSecondOpinionFetchApiInProgress(false);
          handleApiErrors(err);
        }
      );
    }
  }

  function submitBtnClicked(uploadedFiles) {
    if (!isEmptyArray(uploadedFiles) && secondOpinionId && apiKey) {
      var apiKeyViewModelVM = {
        secondOpinionId: secondOpinionId,
        recordRequestHistoryId: recordRequestHistoryId,
        apiKey: apiKey
      };

      var formData = new FormData();
      formData.append("apiKeyViewModel", JSON.stringify(apiKeyViewModelVM));

      if (!isEmptyArray(uploadedFiles)) {
        uploadedFiles.map((upload) => {
          if (upload && upload.file) {
            formData.append("photos", upload.file);
          }
        });
      }

      dispatch(showLoadingSpinner());
      saveSecondOpinionRecord_Ajax(
        formData,
        function (response) {
          dispatch(hideLoadingSpinner());

          if (response && response.success) {
            setRecordSubmitted(true);
          } else if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function getDataFromParam() {
    var params = queryStringToJSON();
    if (params.apikey) {
      setApiKey(params.apikey);
    }

    if (params.secondopinionid) {
      var id = parseInt(params.secondopinionid);

      if (!isNaN(id)) {
        setSecondOpinionId(id);
      }
    }

    if (params.recordrequesthistoryid) {
      var id = parseInt(params.recordrequesthistoryid);

      if (!isNaN(id)) {
        setRecordRequestHistoryId(id);
      }
    }
  }

  function renderMessage(message) {
    if (message) {
      return (
        <div className="text-center mt-5 mb-5">
          <h2>{message}</h2>
        </div>
      );
    } else {
      return <></>;
    }
  }

  function renderUploadForm() {
    return (
      <form className="min-vh">
        <div className="form-group mb-5 col-md-12">
          <div className="form-section">
            <div className="gth-corpote-report">
              <p>
                <b>Provider Name</b>
              </p>
              <p>
                {secondOpinion?.secondOpinionRecordRequestData?.dentistName}
              </p>
            </div>
          </div>
          <label className="form-label mt-4 mb-3">
            Patient requesting their records:
          </label>
          <div className="form-section">
            <div className="gth-corpote-report">
              <p>
                <b>Patient First Name</b>
              </p>
              <p>{secondOpinion?.patient?.firstName}</p>
            </div>
            <div className="gth-corpote-report">
              <p>
                <b>Patient Last Name</b>
              </p>
              <p>{secondOpinion?.patient?.lastName}</p>
            </div>
            <div className="gth-corpote-report">
              <p>
                <b>Patient Date of Birth</b>
              </p>
              <p>
                {formatDateTime(
                  secondOpinion?.patient?.dateOfBirth,
                  DateTimeFormats.MM_DD_YY
                )}
              </p>
            </div>
            <div className="gth-corpote-report">
              <p>
                <a
                  href="javascript:;"
                  data-bs-toggle="modal"
                  data-bs-target="#medical-records-release-modal"
                >
                  Medical Records Release Authorization Form
                </a>
              </p>
            </div>
          </div>
        </div>

        <QuestionnaireUploadRecords
          isProviderUploadRecord={true}
          onSubmit={submitBtnClicked}
        />
      </form>
    );
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="gth-main-header mb-3">
              <div className="heading-wrapper back-to-wrapper position-relative">
                <h1 className="title">Patient Records Request</h1>
              </div>
            </div>
            <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
              <fieldset className="d-none111">
                {isSecondOpinionFetchApiInProgress ? (
                  renderMessage("Getting second opinion data")
                ) : !secondOpinion ? (
                  renderMessage("Second opinion data not found")
                ) : !isLinkValid ? (
                  renderMessage("Invalid URL")
                ) : !recordSubmitted ? (
                  renderUploadForm()
                ) : recordSubmitted ? (
                  <SuccessMessage
                    patientFullName={secondOpinion?.patient?.fullName}
                  />
                ) : (
                  <></>
                )}
              </fieldset>
            </div>
          </div>
        </div>
      </div>

      {secondOpinion?.consentForm ? (
        <RecordSentModal consentForm={secondOpinion?.consentForm} />
      ) : (
        <></>
      )}
      {secondOpinion?.consentData ? (
        <ConsentDataPage consentData={secondOpinion?.consentData} />
      ) : (
        <></>
      )}
    </>
  );
};

export default PatientRecordsRequestPage;
