import React from "react";
import { useNavigate } from "react-router-dom";

const About = () => {
  let navigate = useNavigate();

  const goHome = () => {
    navigate("/");
  };

  return (
    <div>
      <h1>About Page Here</h1>
      <button onClick={goHome}>Go to Home</button>
    </div>
  );
};

export default About;
