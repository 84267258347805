import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import "../../assets/css/style.css";
import SignUpForm from "../../components/SignUpForm/SignUpForm";
import { DentalDotComPageUrl } from "../../constants";
import { getLogoUrl } from "../../ApplicationSettings";

function GetSignUpForm(props) {
  if (props.isSignUpExternalPatient) {
    return (
      <SignUpForm
        isSignUpExternalPatient={props.isSignUpExternalPatient}
        patientData={props.externalPatientData}
      />
    );
  }
  return <SignUpForm />;
}

export default function SignUp() {
  const { state } = useLocation();
  const isSignUpExternalPatient = state?.isSignUpExternalPatient;
  const externalPatientData = state?.externalPatientData;
  const logo = require("./../../assets/images/" + getLogoUrl());

  return (
    <>
      <body>
        <header className="py-2">
          <nav className="navbar navbar-expand-lg p-0">
            <div className="container-fluid">
              <a
                className="hover-element navbar-brand p-0"
                href={DentalDotComPageUrl}
              >
                <img src={logo} alt="Logo" width="120" height="50" />
              </a>
            </div>
          </nav>
        </header>
        <section className="account-section sign-in-section py-4 py-md-5 v-middle">
          <GetSignUpForm
            isSignUpExternalPatient={isSignUpExternalPatient}
            externalPatientData={externalPatientData}
          />
        </section>

        {/* <!-- scripts --> */}
        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"
          integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"
          crossOrigin="anonymous"
        >
          {" "}
        </script>
      </body>
    </>
  );
}
