import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import {
  getStates_Ajax,
  getCityOrZips_Ajax,
  searchPharmacies_Ajax,
  getPatientPreferredPharmacy_Ajax
} from "../../helpers/requests";
import { handleApiErrors, handleBeforeUnload } from "../../helpers/utils";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";

import Pagination from "../../redux/customHooks/pagination";
import {
  CountryCodes,
  Pagination as PaginationConstants
} from "../../constants";

const PharmacySelection = (props) => {
  const dispatch = useDispatch();

  const {
    patientState,
    goToNextPage,
    selectedPharmacy,
    setSelectedPharmacy,
    patientData
  } = props;

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [zips, setZips] = useState([]);

  const [pharmacyName, setPharmacyName] = useState(null);
  const [pharmacyState, setPharmacyState] = useState(null);
  const [pharmacyCity, setPharmacyCity] = useState(null);
  const [pharmacyZip, setPharmacyZip] = useState(null);

  const [currentPage, setCurrentPage] = useState(
    PaginationConstants.FirstPageNo
  );
  const [totalCount, setTotalCount] = useState(0);

  const [pharmacies, setPharmacies] = useState([]);
  const [isFetchingPreferredPharmacy, setIsFetchingPreferredPharmacy] =
    useState(true);
  const [isSearchResultAvailable, setIsSearchResultAvailable] = useState(false);

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);

  useEffect(() => {
    getStates(CountryCodes.US);
  }, []);

  useEffect(() => {
    if (patientData) {
      getPatientPreferredPharmacy();
    }
  }, [patientData]);

  useEffect(() => {
    if (pharmacyState) {
      getCities(CountryCodes.US, pharmacyState);
    }
  }, [pharmacyState]);

  useEffect(() => {
    if (pharmacyCity) {
      getZips(CountryCodes.US, pharmacyState, pharmacyCity);
    }
  }, [pharmacyCity]);

  useEffect(() => {
    if (patientState && states && Array.isArray(states) && states.length > 0) {
      setPharmacyState(patientState);
    }
  }, [patientState, states]);

  useEffect(() => {
    if (currentPage) {
      searchPharmacy();
    }
  }, [currentPage]);

  function getPatientPreferredPharmacy() {
    if (patientData) {
      dispatch(showLoadingSpinner());
      setIsFetchingPreferredPharmacy(true);
      getPatientPreferredPharmacy_Ajax(
        patientData.patid,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (response && response.data && response.data.preferredPharmacy) {
            setSelectedPharmacy(response.data.preferredPharmacy);
          } else {
            setSelectedPharmacy(null);
          }
          setIsFetchingPreferredPharmacy(false);
        },
        function (err) {
          handleApiErrors(err);
          setIsFetchingPreferredPharmacy(false);
          dispatch(hideLoadingSpinner());
        }
      );
    }
  }

  function getStates(countryCode) {
    if (countryCode) {
      getStates_Ajax(
        countryCode,
        function (response) {
          if (response && response.success && response.data) {
            setStates(response.data);
            setCities([]);
            setZips([]);

            setPharmacyCity(null);
            setPharmacyZip(null);
          }
        },
        function (err) {
          handleApiErrors(err);
        }
      );
    }
  }

  function getCities(countryCode, stateCode) {
    if (countryCode && stateCode) {
      getCityOrZips_Ajax(
        countryCode,
        stateCode,
        undefined,
        undefined,
        undefined,
        function (response) {
          if (response && response.success && response.data) {
            setCities(response.data);
            setZips([]);

            setPharmacyCity(null);
            setPharmacyZip(null);
          }
        },
        function (err) {
          handleApiErrors(err);
        }
      );
    }
  }

  function getZips(countryCode, stateCode, city) {
    if (countryCode && stateCode && city) {
      getCityOrZips_Ajax(
        countryCode,
        stateCode,
        city,
        undefined,
        undefined,
        function (response) {
          if (response && response.success && response.data) {
            setZips(response.data);

            setPharmacyZip(null);
          }
        },
        function (err) {
          handleApiErrors(err);
        }
      );
    }
  }

  function IsValidPharmacySearch() {
    if (pharmacyState || pharmacyCity || pharmacyZip) {
      return true;
    } else {
      // show alert with new alert function ("warning", "Invalid Search", "Please select at least one of state, city or zip");
    }
  }

  function searchPharmacyBtnClicked() {
    setCurrentPage(PaginationConstants.FirstPageNo);
    searchPharmacy();
  }

  function searchPharmacy() {
    if (IsValidPharmacySearch()) {
      var params = {
        stateCode: pharmacyState,
        city: pharmacyCity,
        zip: pharmacyZip,
        pharmacyName: pharmacyName,
        itemsPerPage: PaginationConstants.ItemPerPage,
        currentPage: currentPage
      };

      setIsSearchResultAvailable(false);
      dispatch(showLoadingSpinner());
      searchPharmacies_Ajax(
        params,
        function (response) {
          dispatch(hideLoadingSpinner());

          if (
            response &&
            response.success &&
            response.data &&
            response.data.pharmacies &&
            Array.isArray(response.data.pharmacies) &&
            response.data.pharmacies.length > 0
          ) {
            setPharmacies(response.data.pharmacies);
            setTotalCount(response.data.totalCount);
          } else if (response && !response.success) {
            setPharmacies([]);
            setTotalCount(0);
          }
          setIsSearchResultAvailable(true);
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function renderPharmacyList() {
    if (isSearchResultAvailable) {
      if (
        !selectedPharmacy &&
        pharmacies &&
        Array.isArray(pharmacies) &&
        pharmacies.length > 0
      ) {
        return (
          <div className="col-md-6">
            <h5 className="font-weight-bold">
              {totalCount} {totalCount > 1 ? "pharmacies" : "pharmacy"} found
            </h5>
            <div className="pharmacy-list-container">
              {pharmacies.map((pharmacy, index) => {
                return (
                  <div className="border-1 pharmacy-card">
                    <div className="srch-upinner-content">
                      <h4 data-bind="text: name">{pharmacy.name}</h4>
                      <button
                        className="btn btn-primary btn-sm"
                        id="search-pharmacy-btn"
                        type="button"
                        onClick={() => setSelectedPharmacy(pharmacy)}
                      >
                        Select
                      </button>
                    </div>
                    <div className="mt-3">
                      <div className="row mb-3">
                        <div className="col-md-6">{pharmacy.address}</div>
                        <div className="col-md-6">
                          Phone:{" "}
                          <span className="search-bypharmcyclr">
                            {pharmacy.phone}
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          {pharmacy.abstractAddress}
                        </div>
                        <div className="col-md-6">
                          Fax:{" "}
                          <span className="search-bypharmcyclr">
                            {pharmacy.fax}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={totalCount ? totalCount : 0}
              pageSize={PaginationConstants.ItemPerPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        );
      } else if (
        !selectedPharmacy &&
        pharmacies &&
        Array.isArray(pharmacies) &&
        pharmacies.length == 0
      ) {
        return <>No pharmacy found</>;
      } else {
        return <></>;
      }
    } else {
      return <></>;
    }
  }

  function renderSelectedPharmacy() {
    if (selectedPharmacy) {
      return (
        <div className="col-md-6">
          <h5 className="font-weight-bold">Your preferred pharmacy</h5>
          <div className="pharmacy-list-container">
            <div className="border-1 pharmacy-card">
              <div className="srch-upinner-content">
                <h4 data-bind="text: name">{selectedPharmacy.name}</h4>
                <button
                  className="btn btn-secondary btn-sm"
                  id="search-pharmacy-btn"
                  type="button"
                  onClick={() => setSelectedPharmacy(null)}
                >
                  Change
                </button>
              </div>
              <div className="mt-3">
                <div className="row mb-3">
                  <div className="col-md-6">{selectedPharmacy.address}</div>
                  <div className="col-md-6">
                    Phone:{" "}
                    <span className="search-bypharmcyclr">
                      {selectedPharmacy.phone}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    {selectedPharmacy.abstractAddress}
                  </div>
                  <div className="col-md-6">
                    Fax:{" "}
                    <span className="search-bypharmcyclr">
                      {selectedPharmacy.fax}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  }

  return (
    <>
      {!isFetchingPreferredPharmacy ? (
        <div
          className="tab-pane fade show active"
          id="see-dentist"
          role="tabpanel"
          aria-labelledby="see-dentist-tab"
        >
          {/* <form> */}
          <fieldset>
            <legend className="fs-1 mb-3">Add a Preferred Pharmacy</legend>
            {!selectedPharmacy ? (
              <>
                <div className="form-group mt-4 mb-3 col-md-8 col-xl-6">
                  <div className="form-group-title fs-3 text-violet mb-2 mb-md-3 lh-1">
                    Search a nearby pharmacy{" "}
                    <span className="text-danger">*</span>
                  </div>
                  <div className="row">
                    <div className="form-group mb-3 col-md-12">
                      <label className="form-label">Pharmacy Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter pharmacy name"
                        name="pharmacyName"
                        id="pharmacyName"
                        value={pharmacyName}
                        onChange={(e) => setPharmacyName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group mb-3 col-md-4">
                      <label className="form-label">State</label>
                      <select
                        className="form-select form-control"
                        name="pharmacyState"
                        id="pharmacyState"
                        value={pharmacyState}
                        onChange={(e) => setPharmacyState(e.target.value)}
                      >
                        <option key={0} value="">
                          Select State
                        </option>
                        {states.map((state, index) => {
                          return (
                            <option key={index + 1} value={state?.value}>
                              {state.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="form-group mb-3 col-md-4">
                      <label className="form-label">City</label>
                      <select
                        className="form-select form-control"
                        name="pharmacyCity"
                        id="pharmacyCity"
                        value={pharmacyCity}
                        onChange={(e) => setPharmacyCity(e.target.value)}
                      >
                        <option key={0} value="">
                          Select City
                        </option>
                        {cities.map((city, index) => {
                          return (
                            <option key={index + 1} value={city}>
                              {city}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="form-group mb-3 col-md-4">
                      <label className="form-label">Zip</label>
                      <select
                        className="form-select form-control"
                        name="pharmacyZip"
                        id="pharmacyZip"
                        value={pharmacyZip}
                        onChange={(e) => setPharmacyZip(e.target.value)}
                      >
                        <option key={0} value="">
                          Select Zip
                        </option>
                        {zips.map((zip, index) => {
                          return (
                            <option key={index + 1} value={zip}>
                              {zip}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    className="btn btn-primary btn-md mb-4"
                    id="search-pharmacy-btn"
                    type="button"
                    onClick={searchPharmacyBtnClicked}
                  >
                    Search Pharmacy
                  </button>
                </div>
              </>
            ) : (
              <></>
            )}
            <div>
              {renderPharmacyList()}
              {renderSelectedPharmacy()}
            </div>
            <div>{}</div>
            <div
              className="nav form-group text-center d-flex flex-wrap justify-content-center mt-2 mt-md-4 col-sm-12 button-wrapper"
              id="step-tab"
              role="tablist"
            >
              <Link className="d-block w-100 w-md-auto" to="/dashboard">
                <button
                  type="submit"
                  className="btn btn-outline btn-rounded btn-lg px-3 px-md-5 mx-md-2 mt-3 mt-md-0"
                >
                  Cancel
                </button>
              </Link>
              <button
                className="btn btn-secondary btn-rounded btn-lg px-3 px-md-5 mx-md-2 mt-3 mt-md-0"
                id="billing-info-tab"
                type="button"
                onClick={goToNextPage}
                hidden={selectedPharmacy}
              >
                Skip
              </button>
              <button
                className="btn btn-secondary btn-rounded btn-lg px-3 px-md-5 mx-md-2 mt-3 mt-md-0"
                id="billing-info-tab"
                type="button"
                onClick={goToNextPage}
                hidden={!selectedPharmacy}
              >
                Next
              </button>
            </div>
          </fieldset>
        </div>
      ) : (
        <></>
      )}
      {/* View Details Tab :: End */}
    </>
  );
};

export default PharmacySelection;
