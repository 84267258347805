import React from "react";
import Navbar from "./../../components/Navbar/Navbar";
import SecondOpinionAppointmentPage from "../../components/SecondOpinionAppointment/SecondOpinionAppointmentPage";
import { useLocation } from "react-router-dom";
const SecondOpinionAppointment = () => {
  const { state } = useLocation();

  return (
    <>
      <body>
        <section className="step-section py-4 py-md-5">
          <div className="border-radius-xlg ">
            {state ? (
              <SecondOpinionAppointmentPage
                providerId={state.providerId}
                patientId={state.patientId}
                patientName={state.patientName}
              />
            ) : (
              <></>
            )}
          </div>
        </section>
      </body>
    </>
  );
};
export default SecondOpinionAppointment;
