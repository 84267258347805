import { getLogoUrl } from "../../ApplicationSettings";
import { DentalDotComPageUrl } from "../../constants";

const BrandLogo = () => {
  const logo = require("./../../assets/images/" + getLogoUrl());

  return (
    <div className="row">
      <div className="col-sm-12 text-center">
        <a
          className="hover-element p-0 d-inline-block mx-auto section-brand mb-3 mb-md-4"
          href={DentalDotComPageUrl}
        >
          <img src={logo} alt="Logo" />
        </a>
      </div>
    </div>
  );
};

export default BrandLogo;
