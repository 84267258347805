import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Patterns, SignUpFormFields, TokenType } from "../../constants";
import { resetPassword_Ajax, validateToken_Ajax } from "../../helpers/requests";
import { handleApiErrors, showAlertDialouge } from "../../helpers/utils";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import appLogo from "./../../assets/images/dental-com-logo.svg";

function ResetPasswordForm(props) {
  const { token } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid }
  } = useForm({ mode: "onChange" });

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    validateResetToken();
  }, []);

  function validateResetToken() {
    if (token) {
      let jsonData = {
        token: token,
        type: TokenType.PasswordResetToken
      };

      dispatch(showLoadingSpinner());
      validateToken_Ajax(
        JSON.stringify(jsonData),
        function (response) {
          if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message, function () {
              navigate("/login");
            });
          }
          dispatch(hideLoadingSpinner());
        },
        function (err) {
          handleApiErrors(err);
          dispatch(hideLoadingSpinner());
        }
      );
    }
  }

  function passwordMatched() {
    var newPassword = getValues(SignUpFormFields.Password);
    var confirmPassword = getValues(SignUpFormFields.ConfirmPassword);

    if (newPassword != confirmPassword) {
      showAlertDialouge(
        "Error",
        "New password and confirmation password do not match"
      );
    } else {
      return true;
    }
  }

  function resetPassword(formData) {
    if (
      formData &&
      formData.password &&
      formData.confirmPassword &&
      passwordMatched() &&
      token
    ) {
      var jsonData = {
        newPassword: formData.password,
        token: token
      };

      dispatch(showLoadingSpinner());
      resetPassword_Ajax(
        JSON.stringify(jsonData),
        function (response) {
          if (response && response.success && response.message) {
            showAlertDialouge("Message", response.message, function () {
              navigate("/login");
            });
          } else if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
          dispatch(hideLoadingSpinner());
        },
        function (err) {
          handleApiErrors(err);
          dispatch(hideLoadingSpinner());
        }
      );
    }
  }

  function toogleDisplayNewPassword() {
    setShowNewPassword(!showNewPassword);
  }

  function toogleDisplayConfirmPassword() {
    setShowConfirmPassword(!showConfirmPassword);
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xxl-4 offset-xxl-4">
            <form
              className="bg-white border-radius-xlg px-4 px-md-5 py-4"
              onSubmit={handleSubmit(resetPassword)}
            >
              <h2 className="text-center text-violet mb-4">
                Reset Your Password
              </h2>
              <div className="text-secondary fw-bold fs-5 mb-1">
                * All fields are required
              </div>

              <div className="form-group mb-3">
                <div className="row align-items-center">
                  <div className="col-auto">
                    <label className="form-label">New Password</label>
                  </div>
                  <div className="col">
                    <div className="fs-5 mb-1 show-password">
                      <a
                        onClick={() => {
                          toogleDisplayNewPassword();
                        }}
                        className="ms-auto d-table text-muted"
                      >
                        {showNewPassword ? "Hide Password" : "Show Password"}
                      </a>
                    </div>
                  </div>
                </div>
                <input
                  type={!showNewPassword ? "password" : "text"}
                  className="form-control"
                  placeholder="Password"
                  {...register(SignUpFormFields.Password, {
                    required: true,
                    min: 8,
                    pattern: Patterns.Password
                  })}
                />

                {errors.password && (
                  <span className="fs-6 text-muted mt-1 d-inline-block align-top w-100">
                    Please enter a valid password. Your password must contain
                    eight or more characters, with at least one lowercase and
                    one uppercase letter.
                  </span>
                )}
              </div>
              <div className="form-group mb-3">
                <div className="row align-items-center">
                  <div className="col-auto">
                    <label className="form-label">Confirm Password</label>
                  </div>
                  <div className="col">
                    <div className="fs-5 mb-1 show-password">
                      <a
                        onClick={() => {
                          toogleDisplayConfirmPassword();
                        }}
                        className="ms-auto d-table text-muted"
                      >
                        {showConfirmPassword
                          ? "Hide Password"
                          : "Show Password"}
                      </a>
                    </div>
                  </div>
                </div>
                <input
                  type={!showConfirmPassword ? "password" : "text"}
                  className="form-control"
                  placeholder="Confirm Password"
                  {...register(SignUpFormFields.ConfirmPassword, {
                    required: true
                  })}
                />
              </div>
              <div className="form-group text-center mt-2 mt-md-4 col-sm-12">
                <button
                  type="submit"
                  className="btn btn-secondary btn-rounded btn-lg px-5"
                  disabled={!isValid}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPasswordForm;
