import React from "react";
import { useState } from "react";

// Date formatter function
function dateFormatter(value) {
  if (!value) return value;
  // Only Digits are allowed
  document
    .querySelector("#valid-date-add-dependent")
    .addEventListener("keydown", function (evt) {
      !/(^\d*\.?\d*$)|(Backspace|Control|Meta)/.test(evt.key) &&
        evt.preventDefault();
    });
  // Add Slash, Validate month and Date
  var date = document.getElementById("valid-date-add-dependent");
  function checkValue(str, max) {
    if (str.charAt(0) !== "0" || str == "00") {
      var num = parseInt(str);
      if (isNaN(num) || num <= 0 || num > max) num = 1;
      str =
        num > parseInt(max.toString().charAt(0)) && num.toString().length == 1
          ? "0" + num
          : num.toString();
    }
    return str;
  }
  date.addEventListener("input", function (e) {
    this.type = "text";
    var input = this.value;
    if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
    var values = input.split("/").map(function (v) {
      return v.replace(/\D/g, "");
    });
    if (values[0]) values[0] = checkValue(values[0], 12);
    if (values[1]) values[1] = checkValue(values[1], 31);
    var output = values.map(function (v, i) {
      return v.length == 2 && i < 2 ? v + "/" : v;
    });
    this.value = output.join("").substr(0, 10);
  });
}

const AddDependentForm = ({ onAdd }) => {
  // Date Format
  const [dateFormat, setDateFormat] = useState("");
  const handleDateInput = (e) => {
    const dateformatted = dateFormatter(e.target.value);
    setDateFormat(dateformatted);
  };
  // Relation
  const relations = ["Child", "Spouse", "Other"];
  return (
    <>
      <div className="row pt-4">
        {/* First Name */}
        <div className="form-group mb-3 col-md-6">
          <label className="form-label">First Name</label>
          <input
            type="text"
            className="form-control"
            placeholder="First name"
          />
        </div>
        {/* Last Name */}
        <div className="form-group mb-3 col-md-6">
          <label className="form-label">Last Name</label>
          <input type="text" className="form-control" placeholder="Last name" />
        </div>
        {/* Select Relation */}
        <div className="form-group mb-3 col-md-6">
          <label className="form-label">Select Relation</label>
          <select
            className="form-select form-control"
            name="relation"
            id="relation"
          >
            <option>Select Relation</option>
            {relations.map((relation) => (
              <option key={relation}>{relation}</option>
            ))}
          </select>
        </div>
        {/* Date of Birth */}
        <div className="form-group mb-3 col-md-6">
          <label className="form-label">Date of Birth</label>
          <input
            type="text"
            className="form-control"
            id="valid-date-add-dependent"
            placeholder="mm/dd/yyyy"
            onChange={(e) => handleDateInput(e)}
            value={dateFormat}
          />
        </div>
        {/* Gender */}
        <div className="form-group mb-3 col-md-12 d-flex flex-wrap align-items-center">
          <label className="d-inline-block mb-2">Gender</label>
          <div className="form-check mb-2 me-2 me-md-4 d-inline-block">
            <input
              className="form-check-input"
              type="radio"
              name="anyAllergy"
              id="genderMale"
            />
            <label className="form-check-label fs-4" htmlFor="genderMale">
              Male
            </label>
          </div>
          <div className="form-check mb-2 me-2 me-md-4 d-inline-block">
            <input
              className="form-check-input"
              type="radio"
              name="anyAllergy"
              id="genderFemale"
            />
            <label className="form-check-label fs-4" htmlFor="genderFemale">
              Female
            </label>
          </div>
          <div className="form-check mb-2 me-2 me-md-4 d-inline-block">
            <input
              className="form-check-input"
              type="radio"
              name="anyAllergy"
              id="genderUnknown"
            />
            <label className="form-check-label fs-4" htmlFor="genderUnknown">
              Unknown
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddDependentForm;
