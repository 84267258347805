import React from "react";
import Navbar from "./../../components/Navbar/Navbar";
import ProfileSettingSection from "../../components/ProfileSetting/ProfileSettingSection";
const ProfileSetting = () => {
  return (
    <>
      <body>
        <ProfileSettingSection />
      </body>
    </>
  );
};

export default ProfileSetting;
