import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import InstructionPage from "../../components/Instruction/InstructionPage";

const Instruction = () => {
  return (
    <body>
      <div className="body-content">
        <section className="review-photo-section py-4 py-md-5">
          <InstructionPage />
        </section>
      </div>
    </body>
  );
};

export default Instruction;
