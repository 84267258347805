import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getSecondOpinionAdditionalInfo_Ajax } from "../../helpers/requests";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";

const AdditionalInfoNeededPage = (props) => {
  const { secondOpinionId } = props;
  const dispatch = useDispatch();
  const [additionalInfo, setAdditionalInfo] = useState(null);

  useEffect(() => {
    if (secondOpinionId) {
      getSecondOpinionAdditionalInfo();
    }
  }, [secondOpinionId]);

  function getSecondOpinionAdditionalInfo() {
    if (secondOpinionId) {
      dispatch(showLoadingSpinner());

      getSecondOpinionAdditionalInfo_Ajax(
        secondOpinionId,
        function (response) {
          dispatch(hideLoadingSpinner());

          if (response?.success && response?.data) {
            setAdditionalInfo(response?.data);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  return (
    <>
      {additionalInfo?.additionalFileNeeded && (
        <div class="mb-4">
          <h3 class="mb-2">Additional Files</h3>
          <p>{additionalInfo?.additionalFileDescription}</p>
        </div>
      )}
      {additionalInfo?.proposedTreatmentNeeded && (
        <div class="mb-4">
          <h3 class="mb-2">Proposed Treatment</h3>
          <p>{additionalInfo?.proposedTreatmentDescription}</p>
        </div>
      )}
      {additionalInfo?.xraysAndImagesNeeded && (
        <div class="mb-4">
          <h3 class="mb-2">X-rays & Images</h3>
          <p>{additionalInfo?.xraysAndImagesDescription}</p>
        </div>
      )}
    </>
  );
};

export default AdditionalInfoNeededPage;
