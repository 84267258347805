import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ConfirmModal from "./ConfirmModal";

import {
  convertKBFileSizeToMB,
  getSupportedDocExtensions,
  getSupportedImageExtensions,
  handleApiErrors,
  isEmptyArray,
  isImageFile,
  isPDFfile,
  isValidDocFileForPatientUpload,
  onImageError,
  showAlertDialouge,
  showConfirmDialouge,
  supportedDocExtensions,
  supportedImageExtentions,
  validateFileMaxSize
} from "../../helpers/utils";
import { useDispatch } from "react-redux";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import { saveSecondOpinionRecord_Ajax } from "../../helpers/requests";
import { MaxAllowdFileSizes } from "../../constants";

const UploadTypes = {
  Image: 1,
  Note: 2,
  Other: 3
};

const QuestionnaireUploadRecordPage = (props) => {
  const { secondOpinionId } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [uploadedFiles, setUploadedFiles] = useState([]);

  function updateSelectedPhoto(event, uploadType) {
    if (event && event.target && event.target.files) {
      var arr = [];
      var imageMaxSizeInBytes = MaxAllowdFileSizes.IMAGE_MAX_SIZE_IN_MB_5;
      var FileExceededAllowedSize = [];

      if (
        uploadedFiles &&
        Array.isArray(uploadedFiles) &&
        uploadedFiles.length > 0
      ) {
        arr = [...uploadedFiles];
      }

      for (var i = 0; i < event.target.files.length; i++) {
        var file = event.target.files[i];
        var fileName = file.name.toLowerCase();

        if (isImageFile(fileName) || isValidDocFileForPatientUpload(fileName)) {
          var fileSizeErrMsg;

          if (isImageFile(fileName)) {
            fileSizeErrMsg = validateFileMaxSize([file], imageMaxSizeInBytes);
          } else if (isValidDocFileForPatientUpload(fileName)) {
            fileSizeErrMsg = validateFileMaxSize([file]);
          }

          if (!fileSizeErrMsg) {
            var tempPath = URL.createObjectURL(file);
            arr.push({
              url: tempPath,
              type: fileName.split(".").pop(),
              file: file,
              uploadType: uploadType
            });
          } else {
            FileExceededAllowedSize.push(fileName);
          }
        } else {
          showAlertDialouge("Error", "File type not supported");
        }
      }

      if (arr && arr.length > 0) {
        setUploadedFiles(arr);
      }

      if (FileExceededAllowedSize && FileExceededAllowedSize.length > 0) {
        showAlertDialouge(
          "Error",
          "The following file(s) have exceeded maximum allowed size: " +
            FileExceededAllowedSize.join(", ") +
            ". Please upload images less than " +
            MaxAllowdFileSizes.IMAGE_MAX_SIZE_IN_MB_5 +
            " MB and documents less than " +
            MaxAllowdFileSizes.FILE_MAX_SIZE_IN_MB +
            " MB"
        );
      }
    }
  }

  function deleteUploadedBtnClicked(fileIndex) {
    showConfirmDialouge(
      "Are you sure?",
      "Are you sure you want to Delete this uploaded record?",
      function () {
        deleteFileWithIndex(fileIndex);
      }
    );
  }

  function deleteFileWithIndex(fileIndex) {
    if (fileIndex < 0) return;

    var arr = [];

    if (
      uploadedFiles &&
      Array.isArray(uploadedFiles) &&
      uploadedFiles.length > 0
    ) {
      arr = uploadedFiles.filter((file, index) => {
        return index != fileIndex;
      });
    }

    if (arr) {
      setUploadedFiles(arr);
    }
  }

  function nextBtnClicked() {
    if (secondOpinionId && !isEmptyArray(uploadedFiles)) {
      var apiKeyViewModelVM = {
        secondOpinionId: secondOpinionId
      };

      var formData = new FormData();
      formData.append("apiKeyViewModel", JSON.stringify(apiKeyViewModelVM));

      if (!isEmptyArray(uploadedFiles)) {
        uploadedFiles.map((upload) => {
          if (upload && upload.file) {
            formData.append("photos", upload.file);
          }
        });
      }

      dispatch(showLoadingSpinner());
      saveSecondOpinionRecord_Ajax(
        formData,
        function (response) {
          dispatch(hideLoadingSpinner());

          if (response && response.success) {
            showAlertDialouge("Success", response.message, function () {
              navigate("/dashboard");
            });
          }
          if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function renderUploadedFiles(uploadType) {
    if (uploadedFiles && !isEmptyArray(uploadedFiles)) {
      var filterdListByType = uploadedFiles.filter((upload) => {
        return upload && upload.uploadType == uploadType;
      });

      if (!isEmptyArray(filterdListByType)) {
        return (
          <div className="form-group mt-4 mb-3 col-md-12 d-none111">
            <ul className="uploaded-file-wrapper p-0 m-0">
              {uploadedFiles.map((upload, index) => {
                if (upload.uploadType == uploadType) {
                  return (
                    <li className="d-flex flex-wrap mb-3">
                      <div className="left-wrapper">
                        {isImageFile(upload?.file?.name) ? (
                          <img src={upload?.url} onError={onImageError} />
                        ) : isValidDocFileForPatientUpload(
                            upload?.file?.name
                          ) ? (
                          isPDFfile(upload?.file?.name) ? (
                            <i className="bi bi-file-earmark-pdf-fill fs-1"></i>
                          ) : (
                            <i className="bi bi-file-earmark-text-fill fs-1"></i>
                          )
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="right-wrapper right-wrapper position-relative">
                        <div className="form-group-title fs-4 pe-4 pe-md-5 mb-1 lh-1 text-break">
                          {upload?.file?.name}
                        </div>
                        <span className="file-size fs-5 text-muted">
                          {Number(
                            convertKBFileSizeToMB(upload?.file?.size)
                          ).toFixed(2) + " MB"}
                        </span>
                        <a
                          href="javascript:;"
                          className="close text-danger px-1 position-absolute top-0 end-0"
                        >
                          <i
                            className="bi bi-x-lg"
                            onClick={() => deleteUploadedBtnClicked(index)}
                          ></i>
                        </a>
                      </div>
                    </li>
                  );
                } else {
                  return <></>;
                }
              })}
            </ul>
          </div>
        );
      } else {
        return <></>;
      }
    } else {
      return <></>;
    }
  }

  return (
    <>
      <div className="body-content">
        <section className="questionnaire-section py-4 py-md-5">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="gth-main-header mb-3">
                  <div className="heading-wrapper back-to-wrapper position-relative">
                    <h1 className="title">Upload Your Records</h1>
                  </div>
                </div>
                <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
                  <div className="min-vh">
                    <div className="fs-3 mb-5">
                      <p>
                        To receive a second opinion, please upload any
                        supporting documents and images that were gathered
                        during your initial treatment recommendation.
                      </p>
                      <p>
                        Records can include but are not limited to: X-ray,
                        Intraoral Images, Clinical Notes and Treatment Plans.
                      </p>
                      <p>
                        Supported image extensions:{" "}
                        {getSupportedImageExtensions()} <br /> Maximum allowed
                        image size: {MaxAllowdFileSizes.IMAGE_MAX_SIZE_IN_MB_5}{" "}
                        MB
                      </p>
                      <p>
                        Supported document extensions:{" "}
                        {getSupportedDocExtensions()} <br /> Maximum allowed
                        document size: {MaxAllowdFileSizes.FILE_MAX_SIZE_IN_MB}{" "}
                        MB
                      </p>
                    </div>
                    <br />
                    <br />
                    <div className="form-group mb-3 col-md-12">
                      <div className="upload-file-wrapper">
                        <input
                          type="file"
                          multiple
                          name=""
                          onChange={(event) =>
                            updateSelectedPhoto(event, UploadTypes.Image)
                          }
                        />
                        <a
                          className="btn btn-outline-secondary btn-rounded btn-lg w-100"
                          href="javascript:;"
                        >
                          <i className="bi bi-cloud-upload"></i> &nbsp; Upload
                          X-rays &amp; Images
                        </a>
                      </div>
                    </div>
                    {renderUploadedFiles(UploadTypes.Image)}
                    <div className="form-group mb-3 col-md-12">
                      <div className="upload-file-wrapper">
                        <input
                          type="file"
                          multiple
                          name=""
                          onChange={(event) =>
                            updateSelectedPhoto(event, UploadTypes.Note)
                          }
                        />
                        <a
                          className="btn btn-outline-secondary btn-rounded btn-lg w-100"
                          href="javascript:;"
                        >
                          <i className="bi bi-cloud-upload"></i> &nbsp; Upload
                          Clinical Notes
                        </a>
                      </div>
                    </div>
                    {renderUploadedFiles(UploadTypes.Note)}
                    <div className="form-group mb-3 col-md-12">
                      <div className="upload-file-wrapper">
                        <input
                          type="file"
                          multiple
                          name=""
                          onChange={(event) =>
                            updateSelectedPhoto(event, UploadTypes.Other)
                          }
                        />
                        <a
                          className="btn btn-outline-secondary btn-rounded btn-lg w-100"
                          href="javascript:;"
                        >
                          <i className="bi bi-cloud-upload"></i> &nbsp; Upload
                          Other Files
                        </a>
                      </div>
                    </div>
                    {renderUploadedFiles(UploadTypes.Other)}
                    <br />
                    <br />
                    <div className="form-group text-center mt-3 mt-md-5 col-sm-12 button-wrapper">
                      <button
                        onClick={nextBtnClicked}
                        disabled={isEmptyArray(uploadedFiles)}
                        className="btn btn-secondary btn-rounded btn-lg px-3 px-md-5 mt-3 mx-md-2 w-100 w-md-auto"
                        style={{ minWidth: "220px" }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <ConfirmModal />
    </>
  );
};

export default QuestionnaireUploadRecordPage;
