import {
  SAVE_TOKEN_DATA,
  GET_TOKEN_DATA,
  REMOVE_TOKEN_DATA,
  SAVE_PGID,
  SAVE_CURRENT_PATIENT,
  REMOVE_CURRENT_PATIENT,
  SAVE_FAMILY_MEMBERS_LIST,
  SAVE_PATIENT_AND_FAMILY_MEMBERS_LIST,
  REMOVE_FAMILY_MEMBERS_LIST,
  SET_IS_FAMILY_MEMBERS_LIST_CHANGED,
  SET_IS_ONLINE_CSR_AVAILABLE,
  SET_LOGGED_IN_PATIENT_PHOTO_ID,
  SET_IS_ON_DEMAND_CAPTURE_CREDIT_OR_DEBIT_CARD_ENABLED,
  SET_IS_SCHEDULED_APPOINTMENT_CAPTURE_CREDIT_OR_DEBIT_CARD_ENABLED,
  SET_IS_PHARMACY_SELECTION_ENABLED,
  SET_IS_RELOAD_PATIENT_STATUS_SUMMARY,
  SET_HAS_ON_DEMAND_PROMO_CODE,
  SET_HAS_SCHEDULER_PROMO_CODE,
  SET_HAS_SECOND_OPINION_PROMO_CODE,
  SET_ON_DEMAND_CAMPAIGN_CHARGE,
  SET_SCHEDULER_CAMPAIGN_CHARGE,
  SET_SECOND_OPINION_CHARGE,
  SET_IS_SUBMITTED_FORM_REVIEW_ENABLED,
  SET_SMART_SCAN_CHARGE,
  SET_SELECTED_PATIENT_INSURANCE,
  SET_SELECTED_PATIENT_FOR_APPOINTMENT
} from "./typeConstants";

export const saveTokenData = (tokenData) => ({
  type: SAVE_TOKEN_DATA,
  payload: {
    tokenData
  }
});

export const removeTokenData = () => ({
  type: REMOVE_TOKEN_DATA
});

export const getTokenData = () => ({
  type: GET_TOKEN_DATA
});

export const savePgId = (pgId) => ({
  type: SAVE_PGID,
  payload: {
    pgId
  }
});

export const saveCurrentPatient = (patientData) => ({
  type: SAVE_CURRENT_PATIENT,
  payload: {
    patientData
  }
});

export const removeCurrentPatient = (patientData) => ({
  type: REMOVE_CURRENT_PATIENT
});

export const saveFamilyMembersList = (familyMemers) => ({
  type: SAVE_FAMILY_MEMBERS_LIST,
  payload: {
    familyMemers
  }
});

export const savePatientAndFamilyMembersList = (
  patientAndFamilyMembersList
) => ({
  type: SAVE_PATIENT_AND_FAMILY_MEMBERS_LIST,
  payload: {
    patientAndFamilyMembersList
  }
});

export const removeFamilyMembersList = (familyMemers) => ({
  type: REMOVE_FAMILY_MEMBERS_LIST
});

export const setIsFamilyMembersListChanged = (isChanged) => ({
  type: SET_IS_FAMILY_MEMBERS_LIST_CHANGED,
  payload: {
    isChanged
  }
});

export const setIsOnlineCsrAvailable = (isAvailable) => ({
  type: SET_IS_ONLINE_CSR_AVAILABLE,
  payload: {
    isAvailable
  }
});

export const setLoggedInPatientPhotoId = (photoId) => ({
  type: SET_LOGGED_IN_PATIENT_PHOTO_ID,
  payload: {
    photoId
  }
});

export const setIsOnDemandCaptureCreditOrDebitCardEnabled = (
  isOnDemandCaptureCreditOrDebitCardEnabled
) => ({
  type: SET_IS_ON_DEMAND_CAPTURE_CREDIT_OR_DEBIT_CARD_ENABLED,
  payload: {
    isOnDemandCaptureCreditOrDebitCardEnabled
  }
});

export const setIsScheduledAppointmentCaptureCreditOrDebitCardEnabled = (
  isScheduledAppointmentCaptureCreditOrDebitCardEnabled
) => ({
  type: SET_IS_SCHEDULED_APPOINTMENT_CAPTURE_CREDIT_OR_DEBIT_CARD_ENABLED,
  payload: {
    isScheduledAppointmentCaptureCreditOrDebitCardEnabled
  }
});

export const setIsPharmacySelectionEnabled = (isEnabled) => ({
  type: SET_IS_PHARMACY_SELECTION_ENABLED,
  payload: {
    isEnabled
  }
});

export const setIsReloadPatientStatusSummary = (
  isReloadPatientStatusSummary
) => ({
  type: SET_IS_RELOAD_PATIENT_STATUS_SUMMARY,
  payload: {
    isReloadPatientStatusSummary
  }
});

export const sethasOnDemandPromoCode = (hasOnDemandPromoCode) => ({
  type: SET_HAS_ON_DEMAND_PROMO_CODE,
  payload: {
    hasOnDemandPromoCode
  }
});

export const sethasSchedulerPromoCode = (hasSchedulerPromoCode) => ({
  type: SET_HAS_SCHEDULER_PROMO_CODE,
  payload: {
    hasSchedulerPromoCode
  }
});

export const sethasSecondOpinionPromoCode = (hasSecondOpinionPromoCode) => ({
  type: SET_HAS_SECOND_OPINION_PROMO_CODE,
  payload: {
    hasSecondOpinionPromoCode
  }
});

export const setOnDemandCampaignCharge = (onDemandCampaignCharge) => ({
  type: SET_ON_DEMAND_CAMPAIGN_CHARGE,
  payload: {
    onDemandCampaignCharge
  }
});

export const setSchedulerCampaignCharge = (schedulerCampaignCharge) => ({
  type: SET_SCHEDULER_CAMPAIGN_CHARGE,
  payload: {
    schedulerCampaignCharge
  }
});

export const setSecondOpinionCharge = (secondOpinionCharge) => ({
  type: SET_SECOND_OPINION_CHARGE,
  payload: {
    secondOpinionCharge
  }
});

export const setIsSubmittedFormReviewEnabled = (
  isSubmittedFormReviewEnabled
) => ({
  type: SET_IS_SUBMITTED_FORM_REVIEW_ENABLED,
  payload: {
    isSubmittedFormReviewEnabled
  }
});

export const setSmartScanCharge = (smartScanCharge) => ({
  type: SET_SMART_SCAN_CHARGE,
  payload: {
    smartScanCharge
  }
});

export const setSelectedPatientInsurance = (selectedPatientInsurance) => ({
  type: SET_SELECTED_PATIENT_INSURANCE,
  payload: {
    selectedPatientInsurance
  }
});

export const setSeletedPatientForAppointment = (
  selectedPatientForAppointment
) => ({
  type: SET_SELECTED_PATIENT_FOR_APPOINTMENT,
  payload: {
    selectedPatientForAppointment
  }
});
