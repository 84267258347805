import React, { useEffect } from "react";
import providerImg from "./../../assets/images/provider.png";
import "../../assets/css/appointment-style.css";
import { Link, useNavigate } from "react-router-dom";
import {
  getLocalTimezoneOffset,
  handleApiErrors,
  showAlertDialouge,
  showConfirmDialouge,
  toTimezoneName
} from "../../helpers/utils";
import { useAlert } from "react-alert";
import {
  ConsultationStatus,
  CONSULTATION_PROVIDER_PENDING
} from "../../constants";
import { useDispatch } from "react-redux";
import { updateOnDemandConsultation_Ajax } from "../../helpers/requests";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";

import moment from "moment";

const AppointmentCancelPage = (props) => {
  const navigate = useNavigate();
  const alert = useAlert();
  const dispatch = useDispatch();

  const { consultation } = props;

  function getAppointmentDate() {
    if (consultation && consultation.scheduledDateTime) {
      var dateUtc = moment.utc(consultation.scheduledDateTime).local();

      return dateUtc.format("dddd") + " " + dateUtc.format("MMMM Do YYYY");
    }
  }

  function getAppointmentTime() {
    if (consultation && consultation.scheduledDateTime) {
      var dateUtc = moment.utc(consultation.scheduledDateTime).local();
      var localTimeZoneOffset = getLocalTimezoneOffset();
      var timeZoneName = toTimezoneName(localTimeZoneOffset);

      return dateUtc.format(`h:mm:ss A (${timeZoneName})`);
    }
  }

  function rescheduleBtnClicked() {
    if (consultation && consultation.id && consultation.scheduledDateTime) {
      if (consultation.providerId == CONSULTATION_PROVIDER_PENDING) {
        navigate("/reschedule-virtual-appointment", {
          state: {
            consultationId: consultation.id,
            existingScheduledDateTime: consultation.scheduledDateTime
          }
        });
      } else if (consultation.providerId > 0) {
        navigate("/second-opinion-appointment-reschedule", {
          state: {
            consultation: consultation
          }
        });
      }
    }
  }

  function cancelBtnClicked() {
    if (consultation && consultation.id) {
      showConfirmDialouge(
        "Are you sure?",
        "You are about to cancel this appointment",
        function () {
          cancelAppointment(consultation.id);
        }
      );
    } else {
      showAlertDialouge("Error", "Appointment not found");
    }
  }

  function cancelAppointment(consultationId) {
    if (consultationId) {
      var data = {
        id: consultationId,
        status: ConsultationStatus.Cancelled
      };

      dispatch(showLoadingSpinner());
      updateOnDemandConsultation_Ajax(
        JSON.stringify(data),
        function (response) {
          dispatch(hideLoadingSpinner());

          if (response && response.success) {
            showAlertDialouge(
              "Message",
              "Your appointment has been canceled successfully",
              function () {
                navigate("/dashboard");
              }
            );
          } else if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
              <h2 className="text-center mb-4">Appointment Cancellation</h2>

              <div className="appointments d-flex flex-wrap mb-4">
                <div className="confirm-left">
                  <div className="confirm-list">
                    <label>Date</label>
                    <span>{getAppointmentDate()}</span>
                  </div>
                </div>
                <div className="confirm-right">
                  <div className="confirm-list confirm-list-new">
                    <div className="confirm-list">
                      <label>Time</label>
                      <span>{getAppointmentTime()}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-wrap justify-content-center">
                <Link
                  to="/dashboard"
                  className="btn btn-outline btn-rounded btn-lg px-3 px-md-5 mx-md-2 mt-3 mt-md-0 w-100 w-md-auto order-3 order-md-0"
                >
                  Back
                </Link>
                <button
                  onClick={cancelBtnClicked}
                  className="btn btn-secondary btn-lg px-3 px-md-4 mx-md-2 mt-3 mt-md-0 w-100 w-md-auto order-1 order-md-0"
                >
                  Cancel Appointment
                </button>
                {consultation && !(consultation.apptId > 0) ? (
                  <button
                    onClick={rescheduleBtnClicked}
                    className="btn btn-primary btn-lg px-3 px-md-4 mx-md-2 mt-3 mt-md-0 w-100 w-md-auto order-2 order-md-0"
                  >
                    Reschedule
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppointmentCancelPage;
