import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import "./../../assets/css/print-style.css";
import {
  getPrescriptions_Ajax,
  getProviderData_Ajax
} from "../../helpers/requests";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import {
  getGenderText,
  handleApiErrors,
  onImageError,
  showAlertDialouge
} from "../../helpers/utils";
import { SignatureType } from "../../constants";

const PrescriptionPrint = () => {
  const location = useLocation();
  const { patientId, prescriptionId } = useParams();
  const alert = useAlert();
  const dispatch = useDispatch();
  const [providerData, setProviderData] = useState(null);
  const [prescription, setPrescription] = useState(null);
  const [isScribeTypeSignature, setIsScribeTypeSignature] = useState(false);

  useEffect(() => {
    getPrescription();
  }, []);

  useEffect(() => {
    getProviderData();
  }, [prescription]);

  useEffect(() => {
    setIsScribeTypeSignature(
      prescription?.signatureType == SignatureType.Scribe
    );
  }, [prescription]);

  function getPrescription() {
    if (prescriptionId) {
      var params = {
        patient_id: patientId,
        prescriptionId: prescriptionId
      };
      dispatch(showLoadingSpinner());

      getPrescriptions_Ajax(
        params,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (
            response &&
            response.success &&
            response.data.prescriptions &&
            Array.isArray(response.data.prescriptions) &&
            response.data.prescriptions.length > 0
          ) {
            setPrescription(response.data.prescriptions[0]);
          }
        },
        function (err) {
          handleApiErrors(err);
          dispatch(hideLoadingSpinner());
        }
      );
    }
  }

  function getProviderData() {
    if (
      prescription &&
      prescription.provider &&
      prescription.provider.providerid
    ) {
      dispatch(showLoadingSpinner());
      getProviderData_Ajax(
        prescription.provider.providerid,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (response && response.success && response.data) {
            setProviderData(response.data);
          } else if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function isValidDate(date) {
    var dateTime = new Date(date);
    if (!date || !dateTime.getTime()) {
      return false;
    } else {
      return true;
    }
  }

  function calculateAge(birthdayString) {
    // birthday is a string
    if (!isValidDate(birthdayString)) {
      return "";
    }

    var birthday = new Date(birthdayString);
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  function printPrescription() {
    document.title = "Prescription";
    window.print();
  }

  return (
    <>
      {prescription && providerData && (
        <body
          style={{
            margin: "0",
            padding: "0px 15px",
            backgroundColor: "#f2f2f2"
          }}
        >
          <div
            style={{
              textAlign: "right",
              maxWidth: "700px",
              margin: "20px auto"
            }}
          >
            <button
              onClick={() => printPrescription()}
              style={{
                backgroundColor: "#3fbbeb",
                border: "1px solid #3fbbeb",
                borderRadius: "50rem",
                fontSize: "12px",
                padding: "3px 15px",
                color: "#fff",
                cursor: "pointer"
              }}
              className="noprint"
            >
              <i className="fa fa-print"></i> Print
            </button>
          </div>
          <div
            id="prescription-body"
            style={{
              maxWidth: "700px",
              color: "#5D6267",
              margin: "0px auto",
              padding: "20px 15px",
              fontFamily: "Arial, Helvetica, sans-serif",
              background: "#fff",
              minHeight: "600px",
              overflow: "auto"
            }}
          >
            <table width={"100%"} style={{ borderBottom: "3px solid #3fbbeb" }}>
              <tbody>
                <tr>
                  <td style={{ width: "65%" }}>
                    <table style={{ marginBottom: "15px" }}>
                      <tbody>
                        <tr>
                          <td
                            style={{
                              fontSize: "16px",
                              padding: "7px 0",
                              fontWeight: "600"
                            }}
                          >
                            {prescription.provider?.fullName}{" "}
                            <span
                              style={{ fontSize: "12px", fontWeight: "400" }}
                            >
                              {providerData?.title}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "12px", padding: "3px 0" }}>
                            {prescription.office?.officeNameWithAddress
                              ? prescription.office?.officeNameWithAddress
                              : " "}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "12px", padding: "3px 0" }}>
                            {prescription.office?.arealAddress
                              ? prescription.office.arealAddress
                              : " "}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "12px", padding: "3px 0" }}>
                            Provider DEA:{" "}
                            {providerData.dea ? providerData.dea : " "}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "12px", padding: "3px 0" }}>
                            Provider NPI:{" "}
                            {prescription.provider?.providerInsuranceInfo?.npi
                              ? prescription.provider?.providerInsuranceInfo
                                  ?.npi
                              : " "}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>

                  {prescription.pharmacy && (
                    <td style={{ width: "35%" }}>
                      <table style={{ marginBottom: "15px" }}>
                        <tbody>
                          <tr>
                            <td
                              style={{
                                fontSize: "16px",
                                padding: "7px 0",
                                fontWeight: "600"
                              }}
                            >
                              Pharmacy
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontSize: "14px",
                                padding: "3px 0",
                                fontWeight: "600"
                              }}
                            >
                              {prescription.pharmacy?.name}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ fontSize: "12px", padding: "3px 0" }}>
                              {prescription.pharmacy?.address
                                ? prescription.pharmacy?.address
                                : " "}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ fontSize: "12px", padding: "3px 0" }}>
                              {prescription.pharmacy?.abstractAddress
                                ? prescription.pharmacy?.abstractAddress
                                : " "}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ fontSize: "12px", padding: "3px 0" }}>
                              Phone:{" "}
                              {prescription.pharmacy?.phone
                                ? prescription.pharmacy?.phone
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ fontSize: "12px", padding: "3px 0" }}>
                              Fax:{" "}
                              {prescription.pharmacy?.fax
                                ? prescription.pharmacy?.fax
                                : " "}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  )}
                </tr>
              </tbody>
            </table>

            <table
              className="patient-detail"
              width={"100%"}
              style={{ marginTop: "20px", borderSpacing: "0" }}
            >
              <tr>
                <th
                  colSpan="3"
                  style={{
                    textAlign: "left",
                    fontSize: "12px",
                    padding: "10px 0"
                  }}
                >
                  Patient Name:{" "}
                  <span style={{ color: "#3fbbeb", paddingLeft: "10px" }}>
                    {prescription.patient.fullName}
                  </span>
                </th>
                <th
                  style={{
                    textAlign: "left",
                    fontSize: "12px",
                    padding: "10px 0"
                  }}
                >
                  Age:{" "}
                  <span style={{ color: "#3fbbeb", paddingLeft: "10px" }}>
                    {calculateAge(prescription.patient.dateOfBirth)}
                  </span>
                </th>
                <th
                  style={{
                    textAlign: "left",
                    fontSize: "12px",
                    padding: "10px 0"
                  }}
                >
                  Gender:{" "}
                  <span style={{ color: "#3fbbeb", paddingLeft: "10px" }}>
                    {getGenderText(prescription.patient.gender)}
                  </span>
                </th>
              </tr>
              <tr>
                <th
                  colSpan="2"
                  style={{
                    textAlign: "left",
                    fontSize: "12px",
                    padding: "10px 0"
                  }}
                >
                  Date:{" "}
                  <span style={{ color: "#3fbbeb", paddingLeft: "10px" }}>
                    {new Date(prescription.createdOn).toLocaleDateString(
                      "en-US"
                    )}
                  </span>
                </th>
                <th
                  colSpan="3"
                  style={{
                    textAlign: "left",
                    fontSize: "12px",
                    padding: "10px 0"
                  }}
                >
                  Address:{" "}
                  <span style={{ color: "#3fbbeb", paddingLeft: "10px" }}>
                    {prescription.patient?.fullAddress
                      ? prescription.patient?.fullAddress
                      : ""}
                  </span>
                </th>
              </tr>
            </table>

            <div className="table">
              <table
                cellSpacing="0"
                cellPadding="3"
                border="0"
                width="100%"
                style={{ fontSize: "12px", marginTop: "30px" }}
                className="table presc-print-table table-hover"
              >
                <thead>
                  <tr>
                    <th scope="col">Medicine Name</th>
                    <th scope="col">Type</th>
                    <th scope="col">Unit</th>
                    <th scope="col">Frequency</th>
                    <th scope="col">How Taken</th>
                    <th scope="col">Duration</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Description</th>
                    <th scope="col">Refill</th>
                  </tr>
                </thead>
                <tbody>
                  {prescription &&
                    prescription.prescriptionMedications &&
                    Array.isArray(prescription.prescriptionMedications) &&
                    prescription.prescriptionMedications.length > 0 &&
                    prescription.prescriptionMedications.map((medication) => {
                      return (
                        <tr key={medication.medicineId.toString()}>
                          <td data-label="Medicine Name">
                            {medication.medicine.name}
                          </td>
                          <td data-label="Type">{medication.type}</td>
                          <td data-label="Unit" className="Unit">
                            {" "}
                            {medication.unit}{" "}
                          </td>
                          <td data-label="Frequency">
                            {" "}
                            {medication.frequency}{" "}
                          </td>
                          <td data-label="How Taken">{medication.intake}</td>
                          <td data-label="Duration">{medication.duration}</td>
                          <td data-label="Quantity">{medication.quantity}</td>
                          <td className="text-break" data-label="Description">
                            {medication.description
                              ? medication.description
                              : ""}
                          </td>
                          <td data-label="Refill">{medication.refill}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            {prescription.notes && (
              <div
                className="mt-5 mb-2"
                style={{ fontSize: "12px", color: "#212529" }}
              >
                <p className="prescription-print-note-title pt-2 mt-5 mb-0">
                  Prescription Notes
                </p>
                <p
                  colSpan="5"
                  className="text-break text-left pt-2 w-100 prescription-print-note-details"
                >
                  <span>{prescription.notes}</span>
                </p>
              </div>
            )}
            {prescription.isSignatureAttached &&
              prescription?.attachedSignature && (
                <div className="row  ">
                  <div className="col-md-12">
                    <div className="form-group">
                      <h4 className="mb-2">Signature</h4>
                      <div style={{ pageBreakBefore: "auto" }}>
                        {isScribeTypeSignature ? (
                          <img
                            className="gth-signature"
                            src={prescription?.attachedSignature}
                            onError={onImageError}
                          ></img>
                        ) : (
                          <p type="text" className="gth-signature">
                            {" "}
                            {prescription?.attachedSignature}{" "}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </body>
      )}
    </>
  );
};

export default PrescriptionPrint;
