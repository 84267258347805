import React from "react";
import { isEmptyArray } from "../../helpers/utils";

export default function CaseReportAlternativeTreatments({
  caseReportAlternativeTreatments
}) {
  return (
    <>
      {!isEmptyArray(caseReportAlternativeTreatments) ? (
        <>
          <p class="mb-4">
            Given the diverse nature of dental conditions and the range of
            available treatment options, it's common for multiple approaches to
            be viable. Thus, we provide additional treatment alternatives that
            could be considered, recognizing that there isn't always a singular
            "best" solution or a treatment that should be outright dismissed.{" "}
          </p>
          <div class="mb-4">
            <p class="mb-3">
              <strong>Potential alternative treatment</strong>
            </p>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Tooth # Or Area</th>
                  <th scope="col">Submitted Recommendation</th>
                  <th scope="col">Change Recommended</th>
                  <th scope="col">Description Of Alternative</th>
                </tr>
              </thead>
              <tbody>
                {caseReportAlternativeTreatments.map((alternativeTreatment) => {
                  return (
                    <tr
                      key={alternativeTreatment.caseReportAlternativeTreatmentId.toString()}
                    >
                      <td>{alternativeTreatment.toothOrArea}</td>
                      <td>{alternativeTreatment.submittedRecommendation}</td>
                      <td>
                        {alternativeTreatment.changeRecommendedTypeInString}
                      </td>
                      <td>{alternativeTreatment.alternativeRecommendation}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
