import moment from "moment";
import React from "react";
import CaseReportAnnotations from "./CaseReportAnnotations";
import CaseReportVideoEducations from "./CaseReportVideoEducations";
import CaseReportEducations from "./CaseReportEducations";
import GeneralProgressNotesTable from "./GeneralProgressNotesTable";
import ScheduleAppointment from "./ScheduleAppointment";
import { isEmptyArray, onImageError } from "../../helpers/utils";
import TreatmentRecomTypeCaseReportPatientDetails from "./TreatmentRecomTypeCaseReportPatientDetails";

export default function TreatmentRecommendationTypeCaseReport({
  caseReport,
  caseReportAnnotations,
  patientNotes,
  schedulerCampaign,
  includeZState
}) {
  return (
    <>
      <div className="form-group col-md-12">
        <label className="form-label">Patient Information</label>
        <TreatmentRecomTypeCaseReportPatientDetails caseReport={caseReport} />
      </div>
      <div className="form-group mt-4 mb-3 col-md-12">
        <label className="form-label">Second Opinion Report</label>
        <div className="d-flex flex-wrap align-items-center mt-1">
          {caseReport && caseReport.provider ? (
            <>
              {caseReport.provider.photoId ? (
                <div>
                  <img
                    className="img-70 rounded-circle"
                    src={caseReport.provider.photoId}
                    onError={onImageError}
                    alt=""
                  />
                </div>
              ) : (
                <></>
              )}
              <div className="flex-1 ps-3">
                {caseReport.provider.fullName ? (
                  <div>{caseReport.provider.fullName}</div>
                ) : (
                  <></>
                )}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      {!isEmptyArray(caseReportAnnotations) ? (
        <div className="form-group mt-4 mb-3 col-md-12">
          <label className="form-label">Annotated Photos</label>
          <CaseReportAnnotations
            caseReportAnnotations={caseReportAnnotations}
          />
        </div>
      ) : (
        <></>
      )}
      <div className="form-group mt-4 mb-3 col-md-12">
        <label className="form-label">General Progress Notes</label>
        <GeneralProgressNotesTable patientNotes={patientNotes} />
      </div>

      {caseReport && caseReport.recommendation ? (
        <div className="form-group mt-4 mb-3 col-md-12">
          <label className="form-label">
            Second Opinion Treatment Recommendation
          </label>
          <p className="text-muted">{caseReport.recommendation}</p>
        </div>
      ) : (
        <></>
      )}
      {caseReport ? (
        <CaseReportVideoEducations
          caseReportVideoEducations={caseReport?.caseReportVideoEducations}
        />
      ) : (
        <></>
      )}
      {caseReport ? (
        <CaseReportEducations
          caseReportEducations={caseReport?.caseReportEducations}
        />
      ) : (
        <></>
      )}
      <ScheduleAppointment
        schedulerCampaign={schedulerCampaign}
        caseReport={caseReport}
        includeZState={includeZState}
      />
    </>
  );
}
