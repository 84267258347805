import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  getDefaultPicPath,
  isImageFile,
  isValidDocFileForPatientUpload
} from "../../helpers/utils";
import SharedEducationImageModal from "./SharedEducationImageModal";
import SharedEducationPdfModal from "./SharedEducationPdfModal";
import { setFileUrlToOpen } from "../../redux/actions/fileViewer";

const SharedEducationModal = (props) => {
  const dispatch = useDispatch();

  const { sharedEducation } = props;

  function openFile(url) {
    if (url) {
      dispatch(setFileUrlToOpen(url, "#educationModal"));
    }
  }

  function shareEducationModalBody() {
    return (
      sharedEducation && (
        <>
          <div className="modal-header bg-primary">
            <h2 className="modal-title">Shared Education</h2>
            <button
              type="button"
              className="btn-close text-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <strong>Title</strong>
            <p>
              {sharedEducation.education.title
                ? sharedEducation.education.title
                : " "}
            </p>
            <strong>Description</strong>
            <p>
              {sharedEducation.education.description
                ? sharedEducation.education.description
                : " "}
            </p>
            {sharedEducation.education.media &&
              Array.isArray(sharedEducation.education.media) &&
              sharedEducation.education.media.length > 0 && (
                <div className="form-group">
                  <div className="rpe-item">
                    <div className="rpe-reports">
                      <div className="n-education-content">
                        <label>Media</label>
                        <div id="media-gallery">
                          {sharedEducation.education.media.map((media) => {
                            if (isImageFile(media.mediaId)) {
                              return (
                                <a
                                  href="#"
                                  className="rpe-img"
                                  data-bs-toggle="modal"
                                  data-toggle="modal"
                                  data-bs-target="#fileViewerImage"
                                  onClick={() => openFile(media.mediaId)}
                                >
                                  <img
                                    className=""
                                    src={media.mediaId}
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null;
                                      currentTarget.src =
                                        getDefaultPicPath(currentTarget);
                                    }}
                                    alt="File img"
                                  />
                                </a>
                              );
                            } else if (
                              isValidDocFileForPatientUpload(media.mediaId)
                            ) {
                              return (
                                <a
                                  href="#"
                                  className="text-body gth-pdf-modal rpe-img"
                                  data-toggle="modal"
                                  data-bs-toggle="modal"
                                  data-bs-target="#fileViewerDoc"
                                  onClick={() => openFile(media.mediaId)}
                                >
                                  <img
                                    className=""
                                    src={media.mediaId}
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null;
                                      currentTarget.src =
                                        getDefaultPicPath(currentTarget);
                                    }}
                                    alt="File img"
                                  />
                                </a>
                              );
                            } else {
                              return (
                                <a
                                  href={media.mediaId}
                                  target="_blank"
                                  className="text-body gth-pdf-modal rpe-img"
                                >
                                  <img
                                    src={media.mediaId}
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null;
                                      currentTarget.src =
                                        getDefaultPicPath(currentTarget);
                                    }}
                                    alt="File img"
                                  />
                                </a>
                              );
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </>
      )
    );
  }

  return (
    <>
      {/* <!-- Education Modal --> */}
      {
        <div className="modal fade" id="educationModal" tabIndex="-2">
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">{shareEducationModalBody()}</div>
          </div>
        </div>
      }
    </>
  );
};

export default SharedEducationModal;
