import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const useSelectedPatientInsurance = () => {
  const location = useLocation();

  const selectedPatientInsurance = useSelector((state) => {
    if (state?.userData?.selectedPatientInsurance) {
      return state.userData.selectedPatientInsurance;
    } else if (location?.state?.insuranceInfo) {
      return location.state.insuranceInfo;
    }

    return null;
  });

  return selectedPatientInsurance;
};

export default useSelectedPatientInsurance;
