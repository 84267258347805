import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getCaseReportVideoEducationDetails_Ajax } from "../../helpers/requests";
import {
  getDefaultPicPath,
  handleApiErrors,
  isImageFile,
  isPDFfile,
  isValidDocFileForPatientUpload,
  onImageError,
  showAlertDialouge
} from "../../helpers/utils";
import { setFileUrlToOpen } from "../../redux/actions/fileViewer";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import IconPDF from "./../../assets/images/icon-pdf.svg";
import GenericFileIcon from "../../assets/images/word_processor_file_icon.png";

const SharedVideoEducationModal = (props) => {
  const dispatch = useDispatch();

  const { sharedVideoEducation } = props;
  const [sharedVideoEducationDetails, setSharedVideoEducationDetails] =
    useState(null);

  useEffect(() => {
    if (sharedVideoEducation) {
      getSharedVideoEducationDetails();
    }
  }, [sharedVideoEducation]);

  function getSharedVideoEducationDetails() {
    if (sharedVideoEducation && sharedVideoEducation.id > 0) {
      dispatch(showLoadingSpinner());

      getCaseReportVideoEducationDetails_Ajax(
        sharedVideoEducation.id,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (response && response.success && response.data) {
            setSharedVideoEducationDetails(response.data.patientVideoEducation);
          } else if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function openFile(url) {
    if (url) {
      dispatch(setFileUrlToOpen(url, "#videoEducationModal"));
    }
  }

  function getPatientVideoEducationMedia() {
    return (
      <>
        {sharedVideoEducationDetails?.patientVideoEducationMedia?.map(
          (media) => {
            if (isImageFile(media?.mediaId)) {
              return (
                <div className="attached-file resources-box " key={media?.id}>
                  <button
                    type="button"
                    data-bs-toggle="modal"
                    data-toggle="modal"
                    data-bs-target="#fileViewerImage"
                    onClick={() => openFile(media?.mediaUrl)}
                    style={{
                      border: "none",
                      padding: 0,
                      background: "none"
                    }}
                  >
                    <img
                      className="attached-file-img"
                      src={media?.mediaUrl || getDefaultPicPath()}
                      alt="File img"
                    />
                  </button>
                  <div className="title-entr text-center">
                    <span>{media?.title}</span>
                  </div>
                </div>
              );
            } else if (isValidDocFileForPatientUpload(media?.mediaUrl)) {
              return (
                <div className="attached-file resources-box 1" key={media?.id}>
                  <button
                    type="button"
                    data-bs-toggle="modal"
                    data-toggle="modal"
                    data-bs-target="#fileViewerDoc"
                    onClick={() => openFile(media?.mediaUrl)}
                    style={{
                      border: "none",
                      padding: 0,
                      background: "none",
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    <img
                      className="attached-file-img"
                      src={
                        isPDFfile(media?.mediaUrl) ? IconPDF : GenericFileIcon
                      }
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = getDefaultPicPath(currentTarget);
                      }}
                      alt="File img"
                    />
                  </button>
                  <div className="title-entr text-center">
                    <span>{media?.title}</span>
                  </div>
                </div>
              );
            } else {
              return (
                <a
                  href={media.mediaUrl}
                  target="_blank"
                  className="text-body gth-pdf-modal rpe-img"
                >
                  <img
                    src={media.mediaUrl}
                    onError={onImageError}
                    alt="File img"
                  />
                </a>
              );
            }
          }
        )}
      </>
    );
  }

  function getPatientVideoEducationPatientEducations() {
    return (
      <>
        {sharedVideoEducationDetails?.patientVideoEducationPatientEducations?.map(
          (item) => (
            <div
              key={item?.patientVideoEducationId}
              className="attached-records attached-file resources-box "
            >
              {item?.education && (
                <>
                  {item?.education?.media?.length > 0 &&
                    item?.education?.media.map((mediaItem, mediaIndex) =>
                      isImageFile(mediaItem?.mediaId) ? (
                        <div
                          key={mediaItem?.mediaId}
                          style={{
                            display: mediaIndex === 0 ? "block" : "none"
                          }}
                        >
                          <button
                            type="button"
                            data-bs-toggle="modal"
                            data-toggle="modal"
                            data-bs-target="#fileViewerImage"
                            onClick={() => openFile(mediaItem?.mediaId)}
                            style={{
                              border: "none",
                              padding: 0,
                              background: "none"
                            }}
                          >
                            <img
                              className="attached-file-img"
                              src={mediaItem?.mediaId}
                              alt="File img"
                            />
                          </button>
                        </div>
                      ) : (
                        <div
                          className="attached-file resources-box 2"
                          key={media?.id}
                        >
                          <button
                            data-toggle="modal"
                            data-bs-toggle="modal"
                            data-bs-target="#fileViewerDoc"
                            onClick={() => openFile(mediaItem?.mediaId)}
                            style={{
                              border: "none",
                              padding: 0,
                              background: "none",
                              width: "100%",
                              height: "100%"
                            }}
                          >
                            <img
                              className=""
                              src={
                                isPDFfile(mediaItem?.mediaId)
                                  ? IconPDF
                                  : GenericFileIcon
                              }
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src =
                                  getDefaultPicPath(currentTarget);
                              }}
                              alt="File img"
                            />
                          </button>
                        </div>
                      )
                    )}
                </>
              )}
              <div className="title-entr text-center overflow-hidden">
                <span>{item?.education?.title}</span>
              </div>
            </div>
          )
        )}
      </>
    );
  }
  function getPatientVideoEducationMedicalRecords() {
    return (
      <>
        {sharedVideoEducationDetails?.patientVideoEducationMedicalRecords?.map(
          (item, index) => (
            <div
              key={item?.id}
              className="attached-records attached-file resources-box "
            >
              {item?.medicalRecord && (
                <>
                  {item?.medicalRecord?.photos?.length > 0 &&
                    item?.medicalRecord?.photos?.map((photoItem, photoIndex) =>
                      isImageFile(photoItem?.photoId) ? (
                        <div
                          key={photoItem?.photoId}
                          style={{
                            display: photoIndex === 0 ? "block" : "none"
                          }}
                        >
                          <button
                            type="button"
                            data-toggle="modal"
                            data-bs-toggle="modal"
                            data-bs-target="#fileViewerImage"
                            onClick={() => openFile(photoItem?.photoId)}
                            style={{
                              border: "none",
                              padding: 0,
                              background: "none"
                            }}
                          >
                            <img
                              className="attached-file-img"
                              src={photoItem?.photoId}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = getDefaultPicPath(e.target);
                              }}
                              alt=""
                            />
                          </button>
                        </div>
                      ) : (
                        <div className="attached-file resources-box 3">
                          <button
                            data-toggle="modal"
                            data-bs-toggle="modal"
                            data-bs-target="#fileViewerDoc"
                            onClick={() => openFile(photoItem?.photoId)}
                            style={{
                              border: "none",
                              padding: 0,
                              background: "none",
                              width: "100%",
                              height: "100%"
                            }}
                          >
                            <img
                              className=""
                              src={
                                isPDFfile(photoItem?.photoId)
                                  ? IconPDF
                                  : GenericFileIcon
                              }
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src =
                                  getDefaultPicPath(currentTarget);
                              }}
                              alt="File img"
                            />
                          </button>
                        </div>
                      )
                    )}
                </>
              )}
              <div className="title-entr text-center">
                <span>{item?.medicalRecord?.name}</span>
              </div>
            </div>
          )
        )}
      </>
    );
  }

  return (
    <>
      {
        <div className="modal fade" id="videoEducationModal" tabIndex="-1">
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              {sharedVideoEducationDetails && (
                <>
                  <div className="modal-header bg-primary">
                    <h2 className="modal-title">Shared Education</h2>
                    <button
                      type="button"
                      className="btn-close text-white"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <strong>Title</strong>
                    <p>
                      {sharedVideoEducationDetails.videoEducation.title
                        ? sharedVideoEducationDetails.videoEducation.title
                        : " "}
                    </p>
                    <strong>Description</strong>
                    <p>
                      {sharedVideoEducationDetails.videoEducation.description
                        ? sharedVideoEducationDetails.videoEducation.description
                        : " "}
                    </p>
                    {sharedVideoEducationDetails.comments ? (
                      <>
                        <strong>Comments</strong>
                        <p>{sharedVideoEducationDetails.comments}</p>
                      </>
                    ) : (
                      <></>
                    )}
                    {sharedVideoEducationDetails.videoEducation.videoUrl ? (
                      <div className="video-responsive">
                        <video
                          width="100%"
                          height="auto"
                          preload="metadata"
                          controls
                          playsInline
                        >
                          <source
                            src={
                              sharedVideoEducationDetails.videoEducation
                                .videoUrl
                            }
                            type="video/mp4"
                          />
                        </video>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="form-group">
                      <div className="rpe-item">
                        <div className="rpe-reports">
                          <div className="n-education-content">
                            <strong>Resources</strong>
                            <div id="atc-otr-view">
                              {getPatientVideoEducationMedia()}
                              {getPatientVideoEducationPatientEducations()}
                              {getPatientVideoEducationMedicalRecords()}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default SharedVideoEducationModal;
