import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import {
  seeDentistNow_Ajax,
  postBookingProcedures_Ajax,
  getApplyPromoCode_Ajax,
  getBasicCampaignsInfo_Ajax
} from "../../helpers/requests";
import { getTokenData } from "../../helpers/authManager";
import {
  handleApiErrors,
  redirectToCheckout,
  auditConference,
  handleBeforeUnload,
  showAlertDialouge
} from "../../helpers/utils";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import {
  ConsentOptions,
  ConsultationEventName,
  DentalDotComPortalType,
  PageName,
  PaymentCancelUrl
} from "../../constants";
import SeeDentistPromo from "./SeeDentistPromo";
import useIsBillingInformationAvailable from "../CustomHooks/useIsBillingInformationAvailable";
import useIsParTypeCarrieSelected from "../CustomHooks/useIsParTypeCarrieSelected";
import { getBaseUrl } from "../../ApplicationSettings";
import RequestPhoneCallModal from "../RequestPhoneCallModal/RequestPhoneCallModal";

const SeeDentistReviewPage = (props) => {
  const {
    onDemandCampaign,
    patientState,
    campaignForms,
    selectedPharmacy,
    patientData,
    smartScanId = 0,
    primaryDentistInfo = null,
    primaryConsultantInfoCollectionDecision = 0,
    returnUrl,
    shouldUseBDPhoneNumber = null
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const onDemandCampaignId = useSelector(
    (state) => state?.appSettings?.settingsData?.onDemandCampaignId
  );
  const officeId = useSelector(
    (state) => state?.appSettings?.settingsData?.officeId
  );
  const hasPromoCode = useSelector((state) => {
    if (state.userData?.hasOnDemandPromoCode) {
      return state.userData?.hasOnDemandPromoCode;
    } else if (location.state?.userData?.hasOnDemandPromoCode) {
      return location.state?.userData?.hasOnDemandPromoCode;
    }
    return false;
  });
  const onDemandCampaignCharge = useSelector((state) => {
    if (state?.userData?.onDemandCampaignCharge > 0) {
      return state?.userData?.onDemandCampaignCharge;
    } else if (location.state?.userData?.onDemandCampaignCharge > 0) {
      return location.state?.userData?.onDemandCampaignCharge;
    }
    return 0;
  });
  const [validPromoCode, setValidPromoCode] = useState(null);
  const isBillingInformationAvailable = useIsBillingInformationAvailable(
    location.state?.userData?.isOnDemandCaptureCreditOrDebitCardEnabled
  );
  const [isParTypeCarrierSelected, isCarrierInfoAvailable] =
    useIsParTypeCarrieSelected(patientData?.patid);

  const phoneNoRef = useRef();
  const isVoipFeatureEnabled = useSelector(
    (state) => state?.appSettings?.settingsData?.isVoipFeatureEnabled
  );

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);

  function startOnDemandConsultationFlow() {
    if (patientState && patientData && onDemandCampaign) {
      var NewUser = {
        pgId: onDemandCampaign.pgId,
        firstName: patientData.firstName,
        lastName: patientData.lastName,
        email: patientData.user?.email,
        phone: patientData.user?.phone,
        dateOfBirth: "1990-10-11",
        gender: patientData.gender
      };

      var submittedCampaignForms = [];

      if (
        campaignForms &&
        Array.isArray(campaignForms) &&
        campaignForms.length > 0
      ) {
        campaignForms.map((form) => {
          submittedCampaignForms.push({
            medicalFormId: form.medicalFormId,
            formData: JSON.stringify(form.formData)
          });
        });
      }

      let paymentCancelUrl = getBaseUrl() + PaymentCancelUrl;
      var paymentSuccessUrl =
        getBaseUrl() + "/dashboard?callbackRequestSuccess=true";

      var patientDataJson = {
        Pgid: onDemandCampaign.pgId,
        NewUser: NewUser,
        SubmittedCampaignForms: [],
        PreferredPharmacyId: 0,
        State: patientState,
        Country: patientData.country,
        IsVDCAppDeepLinkNeeded: false,
        PatientId: patientData.patid,
        SubmittedCampaignForms: submittedCampaignForms,
        IsDentalDotComCall: true,
        DisablePatientEmail: true,
        OfficeId: officeId,
        PaymentCancelUrl: paymentCancelUrl,
        AddHomeOfficeFromParam: true,
        preferredPharmacyId: selectedPharmacy?.id,
        referFromUrl: getBaseUrl() + "/dashboard",
        createdFrom: DentalDotComPortalType,
        SharedWithPrimaryConsultant: primaryConsultantInfoCollectionDecision
      };

      if (phoneNoRef.current) {
        patientDataJson.recipientPhoneNumber = phoneNoRef.current;
        patientDataJson.paymentSuccessUrl = paymentSuccessUrl;
      }

      if (smartScanId > 0) {
        patientDataJson.smartScanId = smartScanId;
      }

      if (isParTypeCarrierSelected() || !isBillingInformationAvailable) {
        patientDataJson.IsPaymentDisabled = true;
      }
      if (
        primaryDentistInfo != null &&
        primaryConsultantInfoCollectionDecision == ConsentOptions.Agree
      ) {
        patientDataJson.ProviderInvitationByPatient = primaryDentistInfo;
      }

      if (returnUrl) {
        patientDataJson.returnUrl = returnUrl;
      }
      if (shouldUseBDPhoneNumber) {
        patientDataJson.ShouldUseBDPhoneNumber = shouldUseBDPhoneNumber;
      }

      var params = {
        promoCode: validPromoCode
      };

      dispatch(showLoadingSpinner());
      seeDentistNow_Ajax(
        onDemandCampaign.campaignId,
        params,
        JSON.stringify(patientDataJson),
        function (response) {
          dispatch(hideLoadingSpinner());
          if (response && response.success) {
            var videoConfURL = undefined;
            var isRedirectToStripe = false;
            var paymentSessionId = undefined;
            var stripeAccount = undefined;

            if (response.data && response.data.videoConfURL) {
              videoConfURL = response.data.videoConfURL;

              if (response.data.portalAutoLoginApiKey) {
                videoConfURL +=
                  "&apikey=" + response.data.portalAutoLoginApiKey;
                videoConfURL += "&token=" + getTokenData().token;
              }

              if (returnUrl) {
                videoConfURL += "&returnUrl=" + returnUrl;
              }
              if (shouldUseBDPhoneNumber) {
                videoConfURL +=
                  "&shouldUseBDPhoneNumber=" + shouldUseBDPhoneNumber;
              }
            }

            if (
              response.data &&
              response.data.consultationId &&
              response.data.consultationId > 0
            ) {
              let eventName = phoneNoRef.current
                ? ConsultationEventName.PATIENTREQUESTEDCALLBACK
                : ConsultationEventName.PATIENTCONSULTATIONINITIATED;

              auditConference(
                response.data.consultationId,
                eventName,
                PageName.SEEDENTISTREVIEWANDSUBMIT,
                patientData.patid
              );
            }

            if (response.data && response.data.postBookingProcedures) {
              postBookingProcedures_Ajax(
                JSON.stringify(response.data.postBookingProcedures)
              );
            }

            if (response.data && response.data.isRedirectToStripe) {
              isRedirectToStripe = response.data.isRedirectToStripe;
            }
            if (response.data && response.data.paymentSessionId) {
              paymentSessionId = response.data.paymentSessionId;
            }
            if (response.data && response.data.stripeAccount) {
              stripeAccount = response.data.stripeAccount;
            }

            window.removeEventListener("beforeunload", handleBeforeUnload);
            if (isRedirectToStripe) {
              redirectToCheckout(paymentSessionId, stripeAccount);
            } else if (phoneNoRef.current) {
              showAlertDialouge(
                "Request Received!",
                <>
                  <p>One of our Virtual Assistants will reach out soon.</p>
                  <small>
                    <em>Call back time is typically less than 5 minutes</em>
                  </small>
                </>,
                function () {
                  navigate("/dashboard");
                }
              );
            } else if (videoConfURL) {
              window.open(videoConfURL, "_self");
            }
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function shouldShowPromoCodeSection() {
    if (!isCarrierInfoAvailable()) {
      return false;
    }
    if (!isBillingInformationAvailable || isParTypeCarrierSelected()) {
      return false;
    }

    return hasPromoCode;
  }

  function onCallMeBackBtnClicked(phoneNo) {
    if (phoneNo) {
      phoneNoRef.current = phoneNo;
      startOnDemandConsultationFlow();
    }
  }

  function getCancelButton() {
    let className =
      "btn btn-outline btn-rounded btn-lg px-3 px-md-5 mx-md-2 mt-3 mt-md-0";
    let id = "billing-info-tab";

    if (isVoipFeatureEnabled) {
      return (
        <button
          className={className}
          id={id}
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#dentist-form-modal"
        >
          Cancel
        </button>
      );
    } else {
      return (
        <Link to="/dashboard">
          <button className={className} id={id} type="button">
            Cancel
          </button>
          ;
        </Link>
      );
    }
  }

  return (
    <>
      <div
        className="tab-pane fade show active"
        id="review-submit"
        role="tabpanel"
        aria-labelledby="review-submit-tab"
      >
        <fieldset>
          <legend className="fs-1 mb-4 mb-md-5">Review and Submit</legend>
          <div className="row mb-5">
            <div className="col-md-12 col-lg-6">
              <div
                className={
                  hasPromoCode
                    ? "fs-3 mx-auto mt-0 mb-4 my-lg-5 col-lg-10 col-xl-8 inner-wrapper"
                    : "col-lg-10 offset-lg-1 fs-4"
                }
              >
                <p className="mb-4 mb-md-5">
                  Visit is for <br />{" "}
                  <span className="fw-bold">{patientData?.fullName}</span>
                </p>
                <p className="fs-3 text-violet">
                  Once you click “Start Visit”, you will be taken into a virtual
                  waiting room.
                </p>
                <p className="fs-3 text-violet">
                  <i className="bi bi-play-btn-fill me-1"></i> Make sure to test
                  your video and audio capabilities prior to starting your
                  visit.
                </p>
              </div>
            </div>
            <SeeDentistPromo
              initialCharge={onDemandCampaignCharge}
              type={"See a dentist now"}
              validPromoCode={validPromoCode}
              setValidPromoCode={setValidPromoCode}
              campaignId={onDemandCampaignId}
              hasPromoCode={hasPromoCode}
              shouldShowPromoCodeSection={shouldShowPromoCodeSection}
            ></SeeDentistPromo>
          </div>
          {isBillingInformationAvailable && (
            <div className="fs-3 text-muted fst-italic text-center mt-3 mt-md-5">
              Your payment method will not be charged until your visit is
              complete.
            </div>
          )}
          <div
            className="nav form-group text-center d-flex flex-wrap justify-content-center mt-2 mt-md-4 col-sm-12 button-wrapper"
            id="step-tab"
            role="tablist"
          >
            {getCancelButton()}
            <button
              className="btn btn-secondary btn-rounded btn-lg px-3 px-md-5 mx-md-2 mt-3 mt-md-0"
              type="submit"
              onClick={startOnDemandConsultationFlow}
            >
              {" "}
              Start Visit
            </button>
          </div>
        </fieldset>
        <RequestPhoneCallModal
          onCallMeBackBtnClicked={onCallMeBackBtnClicked}
        />
      </div>
    </>
  );
};

export default SeeDentistReviewPage;
