import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// import react alert dependencies
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

// import styles
import "./App.css";
import "react-confirm-alert/src/react-confirm-alert.css";

// import components
import LoadingSpinner from "./components/LoadingSpinner/LoadingSpinner";
import Navigation from "./Navigation";

// import utilities
import { saveAppSettings } from "./redux/actions/appSettings";
import { getSubDomain, groupBy, handleApiErrors } from "./helpers/utils";
import { getAppSettingsData } from "./helpers/requests";
import FileViewer from "./components/FileViewer/FileViewer";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "./redux/actions/loadingSpinner";
import localforage from "localforage";
import { IndexedDBName } from "./constants";

// configure react alert
const options = {
  position: positions.TOP_CENTER,
  timeout: 3000,
  offset: "100px",
  transition: transitions.SCALE,
  containerStyle: {
    zIndex: 10000,
    overflowWrap: "anywhere"
  }
};

localforage.config({
  name: IndexedDBName,
  driver: localforage.INDEXEDDB
});

// prototypes
Array.prototype.groupBy = groupBy;

function Setup() {
  const dispatch = useDispatch();
  const [isGetAppSettingsCompleted, setIsGetAppSettingsCompleted] =
    useState(false);

  useEffect(() => {
    storeAppSettingsData();
  }, []);

  function storeAppSettingsData() {
    dispatch(showLoadingSpinner());
    getAppSettingsData(
      function (response) {
        dispatch(hideLoadingSpinner());

        if (response) {
          var defaultSetName = response.defaultSetName;
          var subDomain = getSubDomain();

          var defaultAppSettings = {};
          var subDomainAppSettings = {};

          if (defaultSetName && response[defaultSetName]) {
            defaultAppSettings = response[defaultSetName];
          }

          if (subDomain && response[subDomain]) {
            subDomainAppSettings = response[subDomain];
          }

          var appSettings = { ...defaultAppSettings, ...subDomainAppSettings };

          if (appSettings) {
            dispatch(saveAppSettings(appSettings));
            window.appSettings = appSettings;
            setIsGetAppSettingsCompleted(true);
          }
        }
      },
      function (err) {
        dispatch(hideLoadingSpinner());
        handleApiErrors(err);
      }
    );
  }

  return (
    <React.StrictMode>
      <AlertProvider template={AlertTemplate} {...options}>
        <LoadingSpinner />
        <FileViewer />
        {isGetAppSettingsCompleted ? <Navigation /> : <></>}
      </AlertProvider>
    </React.StrictMode>
  );
}

export default Setup;
