import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";

import baguetteBox from "baguettebox.js";
import ReviewPhotoOne from "./../../assets/images/review-photo1.png";
import ReviewPhotoTwo from "./../../assets/images/review-photo2.png";
import PDFIcon from "./../../assets/images/icon-pdf.svg";
import DocIcon from "./../../assets/images/icon-doc.svg";
import JpgIcon from "./../../assets/images/icon-jpg.svg";
import DeleteModal from "./DeleteModal";

const UploadMoreRecordPage = () => {
  useEffect(() => {
    baguetteBox.run(".gallery");
  }, []);
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="gth-main-header mb-3">
              <div className="heading-wrapper back-to-wrapper position-relative">
                <h1 className="title">Upload Your Records</h1>
              </div>
            </div>
            <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
              <form className="min-vh">
                <div className="form-group mb-5 col-md-12">
                  <label className="form-label">Uploaded Images</label>
                  <div className="gallery thumb-img-wrapper d-flex flex-nowrap overflow-auto">
                    <Link to={ReviewPhotoOne} className="lightbox">
                      <img src={ReviewPhotoOne} alt="" />
                    </Link>
                    <Link to={ReviewPhotoTwo} className="lightbox">
                      <img src={ReviewPhotoTwo} alt="" />
                    </Link>
                    <Link to="javascript:;" className="img-file">
                      <img src={PDFIcon} alt="" />
                    </Link>
                    <Link to="javascript:;" className="img-file">
                      <img src={DocIcon} alt="" />
                    </Link>
                  </div>
                </div>

                <div className="form-group mb-3 col-md-12">
                  <div className="upload-file-wrapper">
                    <input type="file" name="" />
                    <a
                      className="btn btn-outline-secondary btn-rounded btn-lg w-100"
                      href="javascript:;"
                    >
                      <i className="bi bi-cloud-upload"></i> &nbsp; Upload
                      X-rays & Images
                    </a>
                  </div>
                </div>
                <div className="form-group mt-4 mb-3 col-md-12 d-none111">
                  <div className="file-uploading-wrapper d-flex flex-wrap">
                    <div className="left-wrapper">
                      <img src={JpgIcon} alt="" />
                      {/* <!-- <img src="images/icon-png.svg" alt="" /> -->
                                    <!-- <img src="images/icon-pdf.svg" alt="" /> -->
                                    <!-- <img src="images/icon-doc.svg" alt="" /> --> */}
                    </div>
                    <div className="right-wrapper">
                      <div className="form-group-title fs-4 mb-2 lh-1 text-break">
                        Closed_mouth.jpg
                      </div>
                      <div className="progress mb-2">
                        <div
                          className="progress-bar w-75"
                          role="progressbar"
                          aria-valuenow="75"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <div className="size-wrapper d-flex flex-wrap justify-content-between">
                        <span className="file-size fs-5 text-muted me-2 me-md-3">
                          5.7 MB of 7.8 MB
                        </span>
                        <span className="uploading-num fs-5 text-primary">
                          Uploading 75%
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group mt-4 mb-3 col-md-12 d-none111">
                  <ul className="uploaded-file-wrapper p-0 m-0">
                    <li className="d-flex flex-wrap mb-3">
                      <div className="left-wrapper">
                        <img src={ReviewPhotoOne} alt="" />
                      </div>
                      <div className="right-wrapper right-wrapper position-relative">
                        <div className="form-group-title fs-4 pe-4 pe-md-5 mb-1 lh-1 text-break">
                          Closed_mouth.jpg
                        </div>
                        <span className="file-size fs-5 text-muted">
                          7.8 MB
                        </span>
                        <a
                          href="javascript:;"
                          className="close text-danger px-1 position-absolute top-0 end-0"
                          data-bs-toggle="modal"
                          data-bs-target="#modal-delete"
                        >
                          <i className="bi bi-x-lg"></i>
                        </a>
                      </div>
                    </li>
                    <li className="d-flex flex-wrap mb-3">
                      <div className="left-wrapper">
                        <img src={ReviewPhotoTwo} alt="" />
                      </div>
                      <div className="right-wrapper right-wrapper position-relative">
                        <div className="form-group-title fs-4 pe-4 pe-md-5 mb-1 lh-1 text-break">
                          Open_mouth.jpg
                        </div>
                        <span className="file-size fs-5 text-muted">
                          7.8 MB
                        </span>
                        <a
                          href="javascript:;"
                          className="close text-danger px-1 position-absolute top-0 end-0"
                          data-bs-toggle="modal"
                          data-bs-target="#modal-delete"
                        >
                          <i className="bi bi-x-lg"></i>
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="form-group mb-3 col-md-12">
                  <div className="upload-file-wrapper">
                    <input type="file" name="" />
                    <a
                      className="btn btn-outline-secondary btn-rounded btn-lg w-100"
                      href="javascript:;"
                    >
                      <i className="bi bi-cloud-upload"></i> &nbsp; Upload
                      Clinical Notes
                    </a>
                  </div>
                </div>
                <div className="form-group mb-3 col-md-12">
                  <div className="upload-file-wrapper">
                    <input type="file" name="" />
                    <a
                      className="btn btn-outline-secondary btn-rounded btn-lg w-100"
                      href="javascript:;"
                    >
                      <i className="bi bi-cloud-upload"></i> &nbsp; Upload Other
                      Files
                    </a>
                  </div>
                </div>
                <br />
                <br />
                <div className="form-group text-center mt-3 mt-md-5 col-sm-12 button-wrapper">
                  <Link
                    to="/dashboard"
                    className="btn btn-secondary btn-rounded btn-lg px-3 px-md-5 mt-3 mx-md-2 w-100 w-md-auto"
                    style={{ minWidth: "220px" }}
                  >
                    Next
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <DeleteModal />
    </>
  );
};

export default UploadMoreRecordPage;
