import React from "react";
import { DateTimeFormats, SignatureType } from "../../constants";
import { Form } from "@formio/react";
import "formiojs/dist/formio.builder.min.css";
import AttachedSignature from "../Print/AttachedSignature";

import {
  formatDateTimeWithZone,
  toLocal,
  initializeAdditionalChangesInFormIoFormDuringCreate
} from "../../helpers/utils";
import { useNavigate } from "react-router-dom";

const FormView = (props) => {
  const navigate = useNavigate();

  const { index, formData } = props;

  function isScribeTypeSignature(historyData) {
    return historyData?.signatureType == SignatureType.Scribe;
  }

  function getDateTime(historyData) {
    var time = "";

    if (historyData?.modifiedOn) {
      time = toLocal(historyData.modifiedOn);
    } else if (historyData?.createdOn) {
      time = toLocal(historyData.createdOn);
    }

    if (time) {
      return formatDateTimeWithZone(
        toLocal(time),
        DateTimeFormats.MMM_DD_YYYY + " [at] " + DateTimeFormats.HH_MM_A
      );
    }
  }

  function renderForm(formData, historyData) {
    if (formData && historyData) {
      var formComponents = JSON.parse(formData?.medicalForm?.components);
      var submissionData = JSON.parse(historyData?.formData);

      // form components
      var formObj = {
        display: "form",
        components: formComponents
      };

      // submitted data
      var submissionData = {
        data: JSON.parse(historyData?.formData)
      };

      // form display options
      var formOptions = {
        readOnly: true,
        viewAsHtml: true
      };

      return (
        <>
          <Form
            form={formObj}
            submission={submissionData}
            options={formOptions}
          />
        </>
      );
    }
  }

  function printBtnClicked(formData, historyData) {
    navigate("/form-print", {
      state: {
        formData,
        historyData
      }
    });
  }

  return (
    <>
      <div key={index} className="collapse" id={"intakeForm-collapse1" + index}>
        <div className="card card-body">
          {formData &&
          formData.medicalHistories &&
          Array.isArray(formData.medicalHistories) &&
          formData.medicalHistories.length > 0 ? (
            <>
              {formData.medicalHistories.map((historyData, historyIndex) => {
                return (
                  <div className="mb-1">
                    <div className="gth-panel-header bg-body py-2 rounded-3 d-flex justify-content-md-between align-items-start align-items-md-center flex-column flex-md-row">
                      <a
                        href={"#intakeForm-detail" + index + historyIndex}
                        className="text-decoration-none  px-3 mt-0 w-100 w-md-auto"
                        data-bs-toggle="collapse"
                        role="button"
                        aria-expanded="false"
                        onClick={() =>
                          initializeAdditionalChangesInFormIoFormDuringCreate(
                            "intakeForm-detail" + index + historyIndex,
                            JSON.parse(historyData?.formData)
                          )
                        }
                      >
                        {getDateTime(historyData)}
                      </a>
                      <a
                        href={"#intakeForm-detail" + index + historyIndex}
                        className="text-body text-decoration-none  px-3 mt-1 mt-md-0 w-100 w-md-auto"
                        data-bs-toggle="collapse"
                        role="button"
                        aria-expanded="false"
                        onClick={() =>
                          initializeAdditionalChangesInFormIoFormDuringCreate(
                            "intakeForm-detail" + index + historyIndex,
                            JSON.parse(historyData?.formData)
                          )
                        }
                      >
                        {historyData?.createdUser?.fullName}
                      </a>
                      <a
                        href={`/form-print/${formData?.id}/${historyData?.historyId}`}
                        target="_blank"
                        className="btn btn-primary btn-xs text-nowrap mx-3 mt-2 mt-md-0"
                      >
                        <i className="bi bi-printer-fill"></i> Print
                      </a>
                    </div>
                    <div
                      className="collapse gth-inner-collapse"
                      id={"intakeForm-detail" + index + historyIndex}
                    >
                      <div className="card-body">
                        {renderForm(formData, historyData)}
                        <AttachedSignature
                          isSignatureAttached={historyData?.isSignatureAttached}
                          attachedSignature={historyData?.attachedSignature}
                          isScribeTypeSignature={isScribeTypeSignature(
                            historyData
                          )}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="d-flex justify-content-center">
              <h3>{formData?.medicalForm?.title} has not been submitted yet</h3>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FormView;
