import React from "react";

const ReferralComplete = () => {
  return (
    <>
      <div className="gth-invite-provider col-12" style={{ display: "none" }}>
        <div className="row">
          <div className="col-12">
            <div className="mb-3">
              <label htmlFor="emailAddress" className="form-label">
                Email address
              </label>
              <input
                type="email"
                className="form-control"
                id="emailAddress"
                placeholder="Enter Email"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="FirstName" className="form-label">
                First Name
              </label>
              <input
                type="email"
                className="form-control"
                id="FirstName"
                placeholder="Enter First Name"
              />
            </div>
            <div claclassNamess="mb-3">
              <label htmlFor="LastNameFirstName" className="form-label">
                Last Name
              </label>
              <input
                type="email"
                className="form-control"
                id="LastName"
                placeholder="Enter Last Name"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReferralComplete;
