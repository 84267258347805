import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import BookedAppointmentPage from "../../components/BookedAppointment/BookedAppointmentPage";
import { useLocation } from "react-router-dom";
const BookedAppointment = () => {
  const { state } = useLocation();

  return (
    <>
      <body className="mobile-body">
        <div className="body-content">
          <section className="py-4 py-md-5">
            <BookedAppointmentPage
              consultation={state.consultation}
              scheduledDateTime={state.scheduledDateTime}
              timeZone={state.timeZone}
            />
          </section>
        </div>
      </body>
    </>
  );
};

export default BookedAppointment;
