import React from "react";
import SmartScanPosePage from "../../components/SmartScanPose/SmartScanPosePage";
const SmartScanPose = () => {
  return (
    <>
      <body className="mobile-body">
        <div className="body-content">
          <section className="review-photo-section py-4 py-md-5">
            <SmartScanPosePage />
          </section>
        </div>
      </body>
    </>
  );
};

export default SmartScanPose;
