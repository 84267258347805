import React from "react";
import SmartScanLowerLipPage from "../../components/SmartScanLowerLip/SmartScanLowerLipPage";

const SmartScanLowerLip = () => {
  return (
    <>
      <body className="mobile-body">
        <div className="body-content">
          <section className="review-photo-section py-4 py-md-5">
            <SmartScanLowerLipPage />
          </section>
        </div>
      </body>
    </>
  );
};

export default SmartScanLowerLip;
