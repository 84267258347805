import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// import UI components
import Navbar from "../../components/Navbar/Navbar";
import AppointmentScheduleTab from "../../components/AppointmentSchedule/AppointmentScheduleTab";
import AppointmentSchedulePages from "../../components/AppointmentSchedule/AppointmentSchedulePages";

// import http requests
import { getCampaign_Ajax } from "../../helpers/requests";

// import actions
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";

// import helpers
import {
  handleApiErrors,
  queryStringToJSON,
  getCampaignStatesOfType,
  isCampaignShareStateAvailable
} from "../../helpers/utils";
import {
  setIsPharmacySelectionEnabled,
  setIsScheduledAppointmentCaptureCreditOrDebitCardEnabled
} from "../../redux/actions/userData";
import {
  CampaignStateSettingTypes,
  DentalDotComPortalType
} from "../../constants";

const AppointmentSchedule = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const schedulerCampaignId = useSelector(
    (state) => state?.appSettings?.settingsData?.schedulerCampaignId
  );

  // campaign data
  const [schedulerCampaign, setSchedulerCampaign] = useState(null);
  const [campaignForms, setCampaignForms] = useState([]);
  const [sharedStates, setSharedStates] = useState([]);
  const [declinedStates, setDeclinedStates] = useState([]);

  useEffect(() => {
    getCampaign(schedulerCampaignId);
  }, [schedulerCampaignId]);

  function prepareAndSetCampaignForms(campaignForms) {
    if (
      campaignForms &&
      Array.isArray(campaignForms) &&
      campaignForms.length > 0
    ) {
      var arr = [];

      campaignForms.forEach((form) => {
        if (form) {
          arr.push({
            title: form.medicalForm?.title,
            formComponent: form.medicalForm?.components,
            medicalFormId: form.formId,
            isSubmitted: false
          });
        }
      });

      setCampaignForms(arr);
    }
  }

  function getCampaign(campaignId) {
    let params = queryStringToJSON();
    let includeZState = false;
    if (params.includezstate) {
      if (params.includezstate == "true") {
        includeZState = true;
      }
    }
    if (campaignId) {
      let params = {
        campaignId: campaignId,
        includeIntakeForm: false,
        includeZState: includeZState,
        portalType: DentalDotComPortalType
      };

      dispatch(showLoadingSpinner());
      getCampaign_Ajax(
        params,
        function (response) {
          dispatch(hideLoadingSpinner());

          if (response && response.success && response.data) {
            if (response.data.campaignForms) {
              prepareAndSetCampaignForms(response.data.campaignForms);
            }

            setSharedStates(isCampaignShareStateAvailable(response?.data));

            let declineState = getCampaignStatesOfType(
              CampaignStateSettingTypes.Declined,
              response.data.campaignStates
            );

            setDeclinedStates(declineState);

            if (response.data.campaignStates) {
              response.data.campaignStates = getCampaignStatesOfType(
                CampaignStateSettingTypes.Assigned,
                response.data.campaignStates
              );
            }

            if (
              response.data?.isStateAvailabilityandInsuranceCheckEnabled &&
              response.data.campaignStates &&
              declineState
            ) {
              response.data.campaignStates =
                response.data.campaignStates.concat(declineState);
            }

            setSchedulerCampaign(response.data);

            if (response.data.isCaptureCreditOrDebitCardEnabled) {
              dispatch(
                setIsScheduledAppointmentCaptureCreditOrDebitCardEnabled(true)
              );
            } else {
              dispatch(
                setIsScheduledAppointmentCaptureCreditOrDebitCardEnabled(false)
              );
            }

            if (response.data.isPharmacySelectionEnabled) {
              dispatch(setIsPharmacySelectionEnabled(true));
            } else {
              dispatch(setIsPharmacySelectionEnabled(false));
            }
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  return (
    <body>
      <section className="step-section py-4 py-md-5">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
                <AppointmentScheduleTab />
                <AppointmentSchedulePages
                  schedulerCampaign={schedulerCampaign}
                  campaignForms={campaignForms}
                  setCampaignForms={setCampaignForms}
                  setSchedulerCampaign={setSchedulerCampaign}
                  sharedStates={sharedStates}
                  declinedStates={declinedStates}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- scripts --> */}
      <script
        src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js"
        integrity="sha512-894YE6QWD5I59HgZOGReFYm4dnWc1Qt5NtvYSaNcOP+u1T9qYdvdihz0PPSiiqn/+/3e7Jo4EaG7TubfWGUrMQ=="
        crossOrigin="anonymous"
        referrerPolicy="no-referrer"
      ></script>
      <script src="https://cdnjs.cloudflare.com/ajax/libs/signature_pad/1.3.2/signature_pad.min.js"></script>
      <script
        src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"
        crossOrigin="anonymous"
      ></script>
    </body>
  );
};

export default AppointmentSchedule;
