import { useEffect, useState } from "react";
import { getSecondOpinionRecordRequestProgress } from "../../indexedDbHelper/secondOpinionRecordRequestProgressIndexedDbHelper";
import { useLocation, useNavigate } from "react-router-dom";
import { showAlertDialouge } from "../../helpers/utils";

const useGetSecondOpinionRecordRequestProgress = () => {
  const { state } = useLocation();
  const [secondOpinionState, setSecondOpinionState] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (state) {
      getSecondOpinionRecordRequestProgress(state.patientId)
        .then((secondOpinionProgress) => {
          if (secondOpinionProgress) {
            setSecondOpinionState({ ...secondOpinionProgress });
          } else {
            navigate("/questionnaire-intake-form");
          }
        })
        .catch((err) => {
          showAlertDialouge("Error", err);
        });
    } else {
      navigate("/questionnaire-intake-form");
    }
  }, [state]);

  return secondOpinionState;
};

export default useGetSecondOpinionRecordRequestProgress;
