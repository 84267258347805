import { useEffect, useState } from "react";
import {
  getEligibility_ajax,
  getSecondOpinionSetting_Ajax
} from "../../helpers/requests";
import { saveSecondOpinionRecordRequestProgress } from "../../indexedDbHelper/secondOpinionRecordRequestProgressIndexedDbHelper";
import InsuranceFormUpdate from "../InsuranceInformation/InsuranceFormUpdate";
import QuestionnaireTabs from "../QuestionnaireTabs/QuestionnaireTabs";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSecondOpinionCharge } from "../../redux/actions/userData";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import { showConfirmDialouge } from "../../helpers/utils";
import InsuranceCheckModal from "./InsuranceCheckModal";
import { EligibilityStatus } from "../../constants";
import { getPatientInfo } from "../../helpers/authManager";

const QuestionnaireInsuranceInfoPage = (props) => {
  const { savedProgress } = props;
  const [insuranceEligibilityStatus, setInsuranceEligibilityStatus] =
    useState("");
  const [isInsuranceDataSaved, setIsInsuranceDataSaved] = useState(false);
  const clientName = useSelector(
    (state) => state?.appSettings?.settingsData?.clientName
  );
  const secondOpinionCharge = useSelector(
    (state) => state?.userData.secondOpinionCharge
  );
  const [shouldShowInsuranceForm, setShouldShowInsuranceForm] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showApplyToDependentsOption, setShowApplyToDependentsOption] =
    useState(false);

  useEffect(() => {
    if (savedProgress?.patientId) {
      setShowApplyToDependentsOption(
        savedProgress.patientId == getPatientInfo().patid
      );
    }
  }, [savedProgress]);

  useEffect(() => {
    getSecondOpinionCharge(clientName);
  }, [clientName]);

  function getSecondOpinionCharge(clientName) {
    if (clientName) {
      dispatch(showLoadingSpinner());
      getSecondOpinionSetting_Ajax(
        clientName,
        function (response) {
          dispatch(hideLoadingSpinner());

          if (
            response &&
            response.success &&
            response?.data?.secondOpinionSetting
          ) {
            dispatch(
              setSecondOpinionCharge(
                response?.data?.secondOpinionSetting?.charge
              )
            );
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function getNextPageState() {
    let newState = {
      secondOpinionForm: savedProgress?.secondOpinionForm,
      patientId: savedProgress?.patientId,
      patientState: savedProgress?.patientState,
      insuranceEligibilityStatus: insuranceEligibilityStatus
    };

    return newState;
  }

  function navigateToNextPage() {
    let newState = getNextPageState();
    navigate("/record-request", {
      state: { ...newState }
    });
  }

  useEffect(() => {
    if (insuranceEligibilityStatus) {
      let newState = getNextPageState();

      saveSecondOpinionRecordRequestProgress(
        { ...newState, insuranceEligibilityStatus: insuranceEligibilityStatus },
        savedProgress?.patientId
      );

      if (
        isInsuranceDataSaved &&
        insuranceEligibilityStatus == EligibilityStatus.Yes
      ) {
        hideInsuranceCheckModal().then((resolve) => {
          navigateToNextPage();
        });
      } else if (
        isInsuranceDataSaved &&
        insuranceEligibilityStatus == EligibilityStatus.No
      ) {
        hideInsuranceCheckModal().then((resolve) => {
          setShouldShowInsuranceForm(false);
        });
      }
    }
  }, [insuranceEligibilityStatus]);

  function checkEligibility() {
    getEligibility_ajax(savedProgress.patientId, function (response) {
      setInsuranceEligibilityStatus(response?.data?.eligibilityStatus);
    });
  }

  function handleNextBtn() {
    if (isInsuranceDataSaved) {
      showInsuranceCheckModal().then((resolve) => {
        setTimeout(checkEligibility, 500);
      });
    }
  }

  function hideInsuranceCheckModal() {
    return new Promise((resolve) => {
      window.$("#check-insurance-modal").modal("hide");
      resolve(true);
    });
  }

  function showInsuranceCheckModal() {
    return new Promise((resolve) => {
      window.$("#check-insurance-modal").modal("show");
      resolve(true);
    });
  }

  function handleBackButton(event) {
    event.preventDefault();
    showConfirmDialouge(
      "Are you sure?",
      "You have unsaved changes, are you sure that you want to cancel? All of your changes will be lost.",
      function () {
        navigate("/questionnaire-insurance-info");
      }
    );
  }

  function reviewBtnClicked() {
    setIsInsuranceDataSaved(false);
    setShouldShowInsuranceForm(true);
    setInsuranceEligibilityStatus("");
  }

  function continueBtnClicked() {
    navigateToNextPage();
  }

  useEffect(() => {
    if (isInsuranceDataSaved) {
      handleNextBtn();
    }
  }, [isInsuranceDataSaved]);

  function onInsuranceInformationSubmittedInSecondOpinionFlow() {
    setIsInsuranceDataSaved(true);
  }

  function showInsuranceNotCoveredMessage() {
    if (
      !shouldShowInsuranceForm &&
      insuranceEligibilityStatus == EligibilityStatus.No
    ) {
      return (
        <div className="row accordion notcovered py-5" style={{}}>
          <div className="col-md-12 mb-5">
            <div className="text-center mx-auto" style={{ maxWidth: 410 }}>
              <h2 className="mb-3">You May Not Be Covered</h2>
              <h4>
                After checking with your insurance, it looks like you are not
                covered for this service.
              </h4>
            </div>
          </div>
          <div className="col-lg-6 mb-5 mb-lg-0">
            <h4 className="lh-base mb-4">
              If you think there may have been a mistake in your insurance
              information, you can go back to review it now.
            </h4>
            <button
              onClick={reviewBtnClicked}
              className="btn btn-primary btn-lg px-5"
            >
              Review Your Insurance
            </button>
          </div>
          <div className="col-lg-6">
            <h4 className="lh-base mb-4">
              If you'd still like to proceed with a Second Opinion, the price is
              {" $"}
              {secondOpinionCharge}. Your credit card will not be charged until
              the Second Opinion report is delivered to you.
            </h4>
            <button
              onClick={continueBtnClicked}
              className="btn btn-secondary continue btn-lg px-5"
            >
              Continue
            </button>
          </div>
        </div>
      );
    }
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
              <QuestionnaireTabs />
              {showInsuranceNotCoveredMessage()}
              {shouldShowInsuranceForm ? (
                <>
                  <div className="col-md-12 mb-2 mt-2 pb-2">
                    <div
                      style={{
                        maxWidth: 780,
                        margin: "0 auto",
                        textAlign: "center"
                      }}
                    >
                      <h2 className="mb-3">
                        You may be covered for this service!
                      </h2>
                      <p>
                        Let's quickly check your insurance coverage. Please
                        review your insurance information below and make any
                        changes, if needed. If it all looks good, click next and
                        we'll run an insurance check.
                      </p>
                    </div>
                  </div>
                  <div className=" text-start">
                    <InsuranceFormUpdate
                      patientId={savedProgress?.patientId}
                      showApplyToDependentsOption={showApplyToDependentsOption}
                      isSecondOpinionFlow={true}
                      onInsuranceInformationSubmittedInSecondOpinionFlow={
                        onInsuranceInformationSubmittedInSecondOpinionFlow
                      }
                      saveButtonVerbiage={"Submit and Next"}
                    />
                  </div>
                  <div className="form-group text-center mt-3 col-sm-12 button-wrapper">
                    <button
                      onClick={handleBackButton}
                      className="btn btn-outline btn-rounded btn-lg px-5 mx-md-2 mt-3 mt-md-0 w-100 w-md-auto"
                    >
                      Back
                    </button>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      <InsuranceCheckModal />
    </>
  );
};

export default QuestionnaireInsuranceInfoPage;
