import React, { useState, useEffect } from "react";
import thanksMsg from "../../assets/images/thanks-msg.svg";
import { RegistrationTypes } from "../../constants";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { getLogoUrl } from "../../ApplicationSettings";

const RegistrationSuccess = () => {
  const { state } = useLocation();
  const [patientName, setPatientName] = useState(null);
  useEffect(() => {
    if (state?.patientName) {
      setPatientName(state.patientName);
    }
  }, []);
  const navigate = useNavigate();
  const logo = require("./../../assets/images/" + getLogoUrl());

  function navigateToSignupPage() {
    let url =
      "/sign-up" + "?registrationType=" + RegistrationTypes.SkipPassword;
    navigate(url);
  }

  return (
    <>
      <header className="py-2">
        <nav className="navbar navbar-expand-lg p-0">
          <div className="container-fluid">
            <Link to="/" className="navbar-brand p-0">
              <img src={logo} alt="Logo" width="120" height="50" />
            </Link>
          </div>
        </nav>
      </header>
      <section className="step-section py-4 py-md-5 v-middle">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="progress mb-3">
                <div
                  className="progress-bar"
                  role="progress"
                  style={{ width: "100%" }}
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
                <div className="success-msg">
                  <img src={thanksMsg} alt="dental-com"></img>
                  <h2 className="display-4 fw-bold mb-2">Success</h2>
                  <p className="mb-4">{patientName} has been Registered.</p>
                  <button
                    className="btn btn-secondary btn-rounded btn-lg px-4 px-md-5 mx-2"
                    onClick={navigateToSignupPage}
                  >
                    Register A New Patient
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RegistrationSuccess;
