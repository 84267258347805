import React from "react";
import QuestionnairInsuranceInfoPage from "../../components/QuestionnairInsuranceFormPage/QuestionnairInsuranceInfoPage";
import useGetSecondOpinionRecordRequestProgress from "../../components/CustomHooks/useGetSecondOpinionRecordRequestProgress";

const QuestionnaireInsuranceInfo = () => {
  const state = useGetSecondOpinionRecordRequestProgress();
  return (
    <>
      <div className="body-content">
        <section className="questionnaire-section py-4 py-md-5">
          <QuestionnairInsuranceInfoPage savedProgress={state} />
        </section>
      </div>
    </>
  );
};

export default QuestionnaireInsuranceInfo;
