import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { confirmAlert } from "react-confirm-alert"; // Import

import "../../assets/css/style.css";
import Navbar from "../../components/Navbar/Navbar";

import UploadsTable from "../../components/Uploads/UploadsTable";
import UploadsViewModals from "../../components/Uploads/UploadsViewModals";
import UploadsDeleteModal from "../../components/Uploads/UploadsDeleteModal";
import UploadsModalForm from "../../components/Uploads/UploadsModalForm";

import {
  getMedicalRecords_Ajax,
  saveMedicalRecord_ajax,
  deleteMedicalRecord_Ajax,
  getProviderData_Ajax
} from "../../helpers/requests";
import {
  showLoadingSpinner,
  hideLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import { showAlertDialouge, showConfirmDialouge } from "../../helpers/utils";
import { setFileUrlToOpen } from "../../redux/actions/fileViewer";
import { IS_SEEN } from "../../constants";
import { getPgidFromUrl } from "../../ApplicationSettings";

const Uploads = () => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const currentPatient = useSelector(
    (state) => state?.userData?.currentPatient
  );
  const familyMembersList = useSelector(
    (state) => state?.userData?.familyMembersList
  );

  const [totalCount, setTotalCount] = useState(0);
  const [medicalRecords, setMedicalRecords] = useState([]);
  const [patientUserId, setPatientUserId] = useState(0);
  const [patientId, setPatientId] = useState(0);
  const [patientName, setPatientName] = useState(null);
  const [reloadMedicalRecords, setReloadMedicalRecords] = useState(false);
  const [providerData, setProviderData] = useState(null);
  const [familyMembersUserIds, setFamilyMembersUserIds] = useState([]);

  useEffect(() => {
    storePatientDetails();
    storeFamilyMembersUserIds();
  }, [currentPatient]);

  useEffect(() => {
    if (currentPatient) {
      setReloadMedicalRecords(true);
    }
  }, [currentPatient]);

  function storePatientDetails() {
    var patientInfo = currentPatient;

    if (patientInfo) {
      if (patientInfo.userId) {
        setPatientUserId(patientInfo.userId);
      }

      if (patientInfo.patid) {
        setPatientId(patientInfo.patid);
      }

      if (patientInfo.fullName) {
        setPatientName(patientInfo.fullName);
      }
    }
  }

  function storeFamilyMembersUserIds() {
    if (
      familyMembersList &&
      Array.isArray(familyMembersList) &&
      familyMembersList.length > 0
    ) {
      var arr = [];

      familyMembersList.map((member) => {
        if (member && member.userId) {
          arr.push(member.userId);
        }
      });

      setFamilyMembersUserIds(arr);
    }
  }

  function getMedicalRecords(currentPageNum, itemPerPage) {
    var patientId = currentPatient?.patid;

    if (currentPageNum && itemPerPage && patientId) {
      dispatch(showLoadingSpinner());
      getMedicalRecords_Ajax(
        patientId,
        itemPerPage,
        currentPageNum,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (response && response.data && response.data.records) {
            if (response.data.records && Array.isArray(response.data.records)) {
              setMedicalRecords(response.data.records);
            }
            if (response.data.totalCount) {
              setTotalCount(response.data.totalCount);
            }
          } else if (response && !response.success) {
            setMedicalRecords([]);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
        }
      );
    }
  }

  function deleteMedicalRecord(record) {
    showConfirmDialouge(
      "Are you sure?",
      "You are about to delete this medical record along with the attachments and notes.",
      function () {
        if (record && record.recordId) {
          dispatch(showLoadingSpinner());
          deleteMedicalRecord_Ajax(
            record.recordId,
            function (response) {
              dispatch(hideLoadingSpinner());
              if (response && response.success && response.message) {
                showAlertDialouge("Success", response.message, function () {
                  setReloadMedicalRecords(true);
                });
              } else if (response && !response.success && response.message) {
                showAlertDialouge("Error", response.message);
              }
            },
            function (err) {
              dispatch(hideLoadingSpinner());
            }
          );
        }
      }
    );
  }

  function saveMedicalRecord(medicalRecord, uploadedFiles, reset) {
    var pgId = getPgidFromUrl();

    if (
      pgId &&
      patientId &&
      medicalRecord &&
      uploadedFiles &&
      Array.isArray(uploadedFiles) &&
      uploadedFiles.length > 0
    ) {
      medicalRecord.pgId = pgId;
      medicalRecord.patientId = patientId;
      medicalRecord.seenByPatinet = IS_SEEN;

      var formData = new FormData();
      formData.append("recordVM", JSON.stringify(medicalRecord));

      uploadedFiles.map((upload) => {
        if (upload && upload.file) {
          formData.append("photos", upload.file);
        }
      });

      dispatch(showLoadingSpinner());
      saveMedicalRecord_ajax(
        formData,
        function (response) {
          dispatch(hideLoadingSpinner());

          if (reset) {
            reset();
          }

          if (response && response.success && response.message) {
            showAlertDialouge("Message", response.message, function () {
              setReloadMedicalRecords(true);
            });
          } else if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
        }
      );
    }
  }

  function openFile(url) {
    if (url) {
      dispatch(setFileUrlToOpen(url));
    }
  }

  function getProviderData(record) {
    if (record && record.creatorProvider && record.creatorProvider.providerid) {
      dispatch(showLoadingSpinner());
      setProviderData(null);
      getProviderData_Ajax(
        record.creatorProvider.providerid,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (response && response.success && response.data) {
            setProviderData(response.data);
          } else if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
        }
      );
    }
  }

  return (
    <>
      <body>
        <UploadsTable
          patientUserId={patientUserId}
          totalCount={totalCount}
          medicalRecords={medicalRecords}
          deleteMedicalRecord={deleteMedicalRecord}
          getMedicalRecords={getMedicalRecords}
          openFile={openFile}
          reloadMedicalRecords={reloadMedicalRecords}
          setReloadMedicalRecords={setReloadMedicalRecords}
          getProviderData={getProviderData}
          familyMembersUserIds={familyMembersUserIds}
        />
        <UploadsViewModals providerData={providerData} />
        <UploadsDeleteModal />
        <UploadsModalForm
          patientName={patientName}
          saveMedicalRecord={saveMedicalRecord}
        />
        {/* <!-- scripts --> */}
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js"
          integrity="sha512-894YE6QWD5I59HgZOGReFYm4dnWc1Qt5NtvYSaNcOP+u1T9qYdvdihz0PPSiiqn/+/3e7Jo4EaG7TubfWGUrMQ=="
          crossOrigin="anonymous"
          referrerPolicy="no-referrer"
        ></script>
        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"
          integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"
          crossOrigin="anonymous"
        ></script>
      </body>
    </>
  );
};

export default Uploads;
