import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import AppointmentScheduleTab from "../../components/AppointmentSchedule/AppointmentScheduleTab";
import PharmacySelection from "../../components/PharmacySelection/PharmacySelection";

const AppointmentSchedulePharmacySelection = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [selectedPharmacy, setSelectedPharmacy] = useState(null);

  useEffect(() => {
    if (!state) {
      navigate("/appointment-schedule");
    }
  }, [state]);

  function goToNextPage() {
    const {
      schedulerCampaign,
      patientState,
      campaignForms,
      patientData,
      userData,
      insuranceInfo = {},
      primaryDentistInfo = null,
      primaryConsultantInfoCollectionDecision
    } = state;

    if (selectedPharmacy) {
      navigate("/appointment-schedule-date-time", {
        state: {
          schedulerCampaign,
          patientState,
          campaignForms,
          selectedPharmacy,
          patientData,
          userData,
          insuranceInfo,
          primaryDentistInfo,
          primaryConsultantInfoCollectionDecision
        }
      });
    } else {
      navigate("/appointment-schedule-date-time", {
        state: {
          schedulerCampaign,
          patientState,
          campaignForms,
          patientData,
          userData,
          insuranceInfo,
          primaryDentistInfo,
          primaryConsultantInfoCollectionDecision
        }
      });
    }
  }

  return (
    <>
      <body>
        <section className="step-section py-4 py-md-5">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
                  <AppointmentScheduleTab />
                  <PharmacySelection
                    patientState={state?.patientState}
                    goToNextPage={goToNextPage}
                    selectedPharmacy={selectedPharmacy}
                    setSelectedPharmacy={setSelectedPharmacy}
                    patientData={state.patientData}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js"
          integrity="sha512-894YE6QWD5I59HgZOGReFYm4dnWc1Qt5NtvYSaNcOP+u1T9qYdvdihz0PPSiiqn/+/3e7Jo4EaG7TubfWGUrMQ=="
          crossOrigin="anonymous"
          referrerPolicy="no-referrer"
        ></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/signature_pad/1.3.2/signature_pad.min.js"></script>
        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"
          integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"
          crossOrigin="anonymous"
        ></script>
      </body>
    </>
  );
};

export default AppointmentSchedulePharmacySelection;
