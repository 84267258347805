import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";

import NavBar from "./../../components/Navbar/Navbar";
import EducationTable from "../../components/Education/EducationTable";
import EducationProviderModal from "../../components/Education/EducationProviderModal";

import { handleApiErrors, showAlertDialouge } from "../../helpers/utils";
import {
  getPatientEducations_Ajax,
  getProviderData_Ajax
} from "../../helpers/requests";
import {
  showLoadingSpinner,
  hideLoadingSpinner
} from "../../redux/actions/loadingSpinner";

const Education = () => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const currentPatient = useSelector(
    (state) => state?.userData?.currentPatient
  );

  const [patientEducations, setPatientEducations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [utcOffset, setUtcOffset] = useState(0);
  const [isDST, setIsDST] = useState(false);
  const [providerData, setProviderData] = useState(null);

  function getPatientEducations() {
    if (currentPatient) {
      setIsLoading(true);
      dispatch(showLoadingSpinner());

      getPatientEducations_Ajax(
        currentPatient.patid,
        function (response) {
          setIsLoading(false);
          dispatch(hideLoadingSpinner());

          if (response && response.success && response.data) {
            setPatientEducations(response.data.doctorPatientEducationsVMs);
            setIsDST(response.data.isDST);
            setUtcOffset(response.data.utcOffset);
          } else if (response && !response.success) {
            setPatientEducations([]);
            setIsDST(false);
            setUtcOffset(0);
          }
        },
        function (err) {
          handleApiErrors(err);
          setIsLoading(false);
          dispatch(hideLoadingSpinner());
        }
      );
    }
  }

  function getProviderData(education) {
    if (education && education.provider && education.provider.providerid) {
      dispatch(showLoadingSpinner());
      setProviderData(null);
      getProviderData_Ajax(
        education.provider.providerid,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (response && response.success && response.data) {
            setProviderData(response.data);
          } else if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
        }
      );
    }
  }

  return (
    <>
      <body>
        <EducationTable
          getPatientEducations={getPatientEducations}
          patientEducations={patientEducations}
          isLoading={isLoading}
          utcOffset={utcOffset}
          isDST={isDST}
          getProviderData={getProviderData}
          currentPatient={currentPatient}
        />
        <EducationProviderModal providerData={providerData} />
      </body>
    </>
  );
};

export default Education;
