import {
  SET_EXTERNAL_PATIENT_ID,
  SET_EXTERNAL_EMPLOYEE_ID,
  SET_EXTERNAL_PATIENT_EMAIL
} from "./typeConstants";

export const saveExternalPatId = (patId) => ({
  type: SET_EXTERNAL_PATIENT_ID,
  payload: {
    patId
  }
});

export const saveExternalEmployeeId = (employerId) => ({
  type: SET_EXTERNAL_EMPLOYEE_ID,
  payload: {
    employerId
  }
});

export const saveExternalPatientEmail = (email) => ({
  type: SET_EXTERNAL_PATIENT_EMAIL,
  payload: {
    email
  }
});
