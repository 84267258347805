import React, { useState, useEffect } from "react";
import {
  formatDateTime,
  isSmartScanScorePerfect,
  onImageError,
  getDiscountString
} from "../../helpers/utils";
import DentalConditionDetails from "./DentalConditionDetails";
import WellnessScoreSummaryInPrintPage from "./WellnessScoreSummaryInPrintPage";
import SmartScanImagesInPrintPage from "./SmartScanImagesInPrintPage";
import { useSelector } from "react-redux";
import { Shades, SmartScanPerfectScore } from "../../constants";
import ShareWellnessReport from "./ShareWellnessReport";
import AetnaDarkLogo from "../../assets/images/aetna-dark-logo.png";
import PriceTagIcon from "../../assets/images/price-tagIcon.png";
import useDisplaySmartScanRecommendedProduct from "../CustomHooks/useDisplaySmartScanRecommendedProduct";
import WhitenessScore from "../../components/SmartScanGraph/WhitenessScore";

const WellnessScorePrintPage = (props) => {
  const {
    smartScanCaseReport,
    wellnessScoreSummary,
    smartScanId,
    isMobile,
    currentScore,
    patientId
  } = props;
  const logoUrl = useSelector(
    (state) => state?.appSettings?.settingsData?.logoUrl
  );
  const [currentShadeColor, setCurrentShadeColor] = useState();

  useEffect(() => {
    if (currentScore > 0) {
      let curShade = Shades.find((x) => x.score == currentScore);
      setCurrentShadeColor(curShade?.color);
    }
  }, [currentScore]);

  const {
    shouldShowRecommendedProductsSection,
    renderProductsInPrintPage,
    renderIssues,
    copyPromoCode
  } = useDisplaySmartScanRecommendedProduct(smartScanCaseReport);

  const logo = require("./../../assets/images/" + logoUrl);

  function printWellnessReport() {
    const style = document.createElement("style");

    style.innerHTML = `
        @media print {
            .whiteness_num {
                background-color: ${currentShadeColor} !important;
                -webkit-print-color-adjust: exact; /* Ensures color is printed */
            }
        }
    `;

    document.head.appendChild(style);
    window.print();
  }

  const [isLoadingCompleted, setIsLoadingCompleted] = useState({
    Cavities: false,
    "Defective Restoration": false,
    "Broken Tooth": false,
    "Plaque BuildUp": false,
    "Gum Inflammation": false
  });

  const [smartScanScore, setSmartScanScore] = useState(false);
  const [productPromoCodes, setProductPromoCodes] = useState([]);
  const updateIsLoadingCompletedStatus = (componentName, value) => {
    setIsLoadingCompleted((prevStatus) => ({
      ...prevStatus,
      [componentName]: value
    }));
  };

  // Determine if all children have sent 'true'
  const allComponentsLoaded = Object.values(isLoadingCompleted).every(
    (status) => status
  );

  useEffect(() => {
    if (
      wellnessScoreSummary?.currentDentalCondition?.score ==
      SmartScanPerfectScore
    ) {
      setSmartScanScore(wellnessScoreSummary?.currentDentalCondition?.score);
    }
  }, [wellnessScoreSummary]);

  useEffect(() => {
    setProductPromoCodes(smartScanCaseReport?.promoCodes);
  }, [smartScanCaseReport]);

  return smartScanCaseReport && wellnessScoreSummary ? (
    <>
      <div
        style={{
          maxWidth: "797px",
          color: "#000",
          margin: "20px auto",
          padding: "20px 20px",
          fontFamily: "Arial, Helvetica, sans-serif",
          background: "#fff",
          minHeight: "600px"
        }}
      >
        <div className="webview11">
          <div class="d-flex flex-wrap align-items-center justify-content-between gap-3 mb-4">
            <a className="p-0 d-inline-block" href="/dashboard">
              <img src={logo} alt="" style={{ width: "100%" }} />
            </a>
            {!isMobile && (
              <div>
                <button
                  class="btn btn-outline-primary btn-xs noprint me-2"
                  data-bs-toggle="modal"
                  data-bs-target="#smartscan-print-share"
                >
                  Share
                </button>
                {!allComponentsLoaded && (
                  <button className="btn btn-secondary btn-sm text-nowrap mt-2 mt-md-0">
                    <i class="fa fa-circle-o-notch fa-spin"></i>
                    <span> Loading..</span>.
                  </button>
                )}
                {allComponentsLoaded && (
                  <button
                    onClick={printWellnessReport}
                    style={{
                      backgroundColor: "#3fbbeb",
                      fontSize: 12,
                      color: "#fff",
                      padding: "3px 15px",
                      border: "1px solid #3fbbeb",
                      cursor: "pointer",
                      borderRadius: "50rem"
                    }}
                    className="noprint"
                  >
                    <i className="fa fa-print" /> Print
                  </button>
                )}
              </div>
            )}
          </div>
          <div class="d-flex flex-wrap align-items-center justify-content-between gap-2 text-black mb-3">
            <div class="fw-bold py-0 py-sm-2">
              Scan Date:
              <span style={{ color: "#3fbbeb", paddingLeft: 10 }}>
                {formatDateTime(
                  wellnessScoreSummary?.currentSmartScanDate,
                  "MM.DD.YYYY"
                )}
              </span>
            </div>
            <div class="fw-bold py-0 py-sm-2">
              Patient Name:
              <span style={{ color: "#3fbbeb", paddingLeft: 10 }}>
                {smartScanCaseReport?.patient?.fullName}
              </span>
            </div>
            <div class="fw-bold py-0 py-sm-2">
              Patient DOB:
              <span style={{ color: "#3fbbeb", paddingLeft: 10 }}>
                {formatDateTime(
                  smartScanCaseReport?.patient?.dateOfBirth,
                  "MM.DD.YYYY"
                )}
              </span>
            </div>
          </div>
          <WellnessScoreSummaryInPrintPage
            wellnessScoreSummary={wellnessScoreSummary}
          />
          {isSmartScanScorePerfect(smartScanScore) && (
            <SmartScanImagesInPrintPage smartScanId={smartScanId} />
          )}
          <DentalConditionDetails
            dentalConditionData={smartScanCaseReport?.cavity}
            title={"Cavities"}
            updateIsLoadingCompletedStatus={updateIsLoadingCompletedStatus}
          />

          <DentalConditionDetails
            dentalConditionData={smartScanCaseReport?.defectiveRestoration}
            title={"Defective Restoration"}
            updateIsLoadingCompletedStatus={updateIsLoadingCompletedStatus}
          />

          <DentalConditionDetails
            dentalConditionData={smartScanCaseReport?.brokenTooth}
            title={"Broken Tooth"}
            updateIsLoadingCompletedStatus={updateIsLoadingCompletedStatus}
          />

          <DentalConditionDetails
            dentalConditionData={smartScanCaseReport?.plaqueBuildUp}
            title={"Plaque BuildUp"}
            updateIsLoadingCompletedStatus={updateIsLoadingCompletedStatus}
          />

          <DentalConditionDetails
            dentalConditionData={smartScanCaseReport?.gumInflammation}
            title={"Gum Inflammation"}
            updateIsLoadingCompletedStatus={updateIsLoadingCompletedStatus}
          />
        </div>
      </div>
      {shouldShowRecommendedProductsSection() && (
        <div className="wellness-score-product-print">
          <div className="row">
            <div className="col-md-12">
              <div className="poor_categories">
                <h2 className="mb-3">
                  Let us help get you on the road to better oral health!
                </h2>
                <p className="mb-4">
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        smartScanCaseReport?.recommendedCategoryGenericDescription
                    }}
                  ></div>
                </p>
                {productPromoCodes?.length > 0 &&
                  productPromoCodes.map((promoCode, index) => (
                    <div className="member-card member-card-print accordion-item-print row mb-4">
                      <div className="col-sm-4 d-flex flex-nowrap align-items-center gap-2 mb-3 mb-sm-0">
                        <img
                          className="price-tag"
                          src={PriceTagIcon}
                          alt=""
                          onError={onImageError}
                        ></img>
                        <p className="fs-4">
                          Aetna® members get {getDiscountString(promoCode)} off
                          CVS Health® and Listerine® oral health products
                        </p>
                      </div>
                      <div className="col-lg-5 position-relative text-center mb-3 mb-lg-0">
                        <div
                          id={"tooltip_title" + index}
                          className="tooltip-title"
                          style={{ display: "none" }}
                        >
                          Copied!
                        </div>
                        <button
                          className="btn btn-dark btn-sm text-white"
                          onClick={() => copyPromoCode(index, promoCode?.code)}
                        >
                          Use code{" "}
                          <span className="fw-bold">{promoCode?.code}</span> at
                          checkout!
                        </button>
                      </div>
                      <div className="col-lg-3">
                        <div className="d-flex align-items-center justify-content-center justify-content-lg-end gap-3 ms-lg-auto">
                          <img
                            className="logo-height"
                            src={AetnaDarkLogo}
                            alt=""
                            onError={onImageError}
                          ></img>
                        </div>
                      </div>
                    </div>
                  ))}
                {renderIssues(false)}
                <div className="tabs_column">
                  <div className="accordion" id="accordionExample">
                    {renderProductsInPrintPage()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <ShareWellnessReport smartScanId={smartScanCaseReport?.smartScanId} />
      {currentScore > 0 && (
        <div
          className="smart-scan-card mobile-center patient-member-card mb-3"
          style={{
            maxWidth: "797px",
            margin: "20px auto",
            padding: "20px",
            fontFamily: "Arial, Helvetica, sans-serif",
            background: "#fff"
          }}
        >
          <WhitenessScore
            patientId={patientId}
            smartScanId={smartScanId}
            currentScore={currentScore}
            itemPerPage={3}
            hideAllArrow={true}
            chartHeight={11}
          />
        </div>
      )}
    </>
  ) : (
    <></>
  );
};

export default WellnessScorePrintPage;
