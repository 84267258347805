import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Pagination from "../../redux/customHooks/pagination";
import { Pagination as PaginationConstants } from "../../constants";
import NoPreviewAvailble from "./../../assets/images/No_preview_available.jpg";
import {
  getDefaultPicPath,
  isImageFile,
  isPDFfile,
  isValidDocFileForPatientUpload,
  onImageError
} from "../../helpers/utils";
import PrescriptionImageModal from "./PrescriptionImageModal";
import { useSelector } from "react-redux";
import IconPDF from "./../../assets/images/icon-pdf.svg";
import GenericFileIcon from "../../assets/images/word_processor_file_icon.png";

const PrescriptionTable = (props) => {
  const [currentPage, setCurrentPage] = useState(
    PaginationConstants.FirstPageNo
  );
  const {
    totalCount,
    getPatientPrescriptions,
    reloadPrescriptions,
    setReloadPrescriptions,
    prescriptions,
    uploadedPrescriptions,
    openFile
  } = props;

  const currentPatient = useSelector(
    (state) => state?.userData?.currentPatient
  );

  useEffect(() => {
    getPatientPrescriptions(currentPage, PaginationConstants.ItemPerPage);
  }, [currentPage]);

  useEffect(() => {
    if (reloadPrescriptions) {
      getPatientPrescriptions(currentPage, PaginationConstants.ItemPerPage);
      setReloadPrescriptions(false);
    }
  }, [reloadPrescriptions]);

  function prescriptionTable(patientPrescriptions) {
    if (
      patientPrescriptions &&
      Array.isArray(patientPrescriptions) &&
      patientPrescriptions.length > 0
    ) {
      return patientPrescriptions.map((p) => {
        return (
          p.photos.length <= 0 && (
            <tr
              style={{ textDecoration: p.isCanceled && "line-through" }}
              className="fs-5"
              key={p.prescriptionId.toString()}
            >
              <td data-label="Prescription Name"> {p.name ? p.name : ""}</td>
              <td data-label="Created Date">
                {new Date(p.createdOn).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "numeric"
                })}
              </td>
              <td data-label="Medicine Name">
                <ul className="list-unstyled pl-0">
                  {p.prescriptionMedications?.map((medication) => {
                    return (
                      <li key={medication.medicineId.toString()}>
                        {medication.medicine?.name}
                      </li>
                    );
                  })}
                </ul>
              </td>
              <td data-label="Dosage">
                <ul className="list-unstyled pl-0">
                  {p.prescriptionMedications?.map((medication) => {
                    return (
                      <li key={medication.medicineId.toString()}>
                        {medication.unit}
                      </li>
                    );
                  })}
                </ul>
              </td>
              <td data-label="Quantity">
                <ul className="list-unstyled pl-0">
                  {p.prescriptionMedications?.map((medication) => {
                    return (
                      <li key={medication.medicineId.toString()}>
                        {medication.quantity}
                      </li>
                    );
                  })}
                </ul>
              </td>
              <td className="text-break" data-label="Description">
                <ul className="list-unstyled pl-0">
                  {p.prescriptionMedications?.map((medication) => {
                    return (
                      <li key={medication.medicineId.toString()}>
                        {medication.description ? medication.description : ""}
                      </li>
                    );
                  })}
                </ul>
              </td>
              <td className="text-break" data-label="Notes">
                {" "}
                {p.notes ? p.notes : ""}
              </td>
              <td data-label="Refill">
                <ul className="list-unstyled pl-0">
                  {p.prescriptionMedications?.map((medication) => {
                    return (
                      <li key={medication.medicineId.toString()}>
                        {medication.refill ? medication.refill : "0"}
                      </li>
                    );
                  })}
                </ul>
              </td>
              <td data-label="Pharmacy">
                <b>{p.pharmacy?.name}</b>
                <p className="mb-0">{p.pharmacy?.address}</p>
                <p className="mb-0">{p.pharmacy?.abstractAddress}</p>
                <p className="mb-0">
                  {p.pharmacy?.phone ? (
                    <span>Phone: {p.pharmacy.phone} </span>
                  ) : (
                    " "
                  )}
                </p>
                <p className="mb-0">
                  {p.pharmacy?.fax ? <span>Fax: {p.pharmacy.fax} </span> : " "}
                </p>
              </td>
              <td data-label="Action" className="text-center">
                {!p.isCanceled ? (
                  <a
                    href={`/prescription-print/${currentPatient.patid}/${p.prescriptionId}`}
                    target="_blank"
                    className="btn btn-primary btn-xs"
                  >
                    <i className="bi bi-printer-fill me-1"></i>Print{" "}
                  </a>
                ) : (
                  ""
                )}
              </td>
            </tr>
          )
        );
      });
    }
  }

  function uploadedPrescriptionTable(patientPrescriptions) {
    if (
      patientPrescriptions &&
      Array.isArray(patientPrescriptions) &&
      patientPrescriptions.length > 0
    ) {
      return patientPrescriptions.map((p) => {
        return (
          p.photos.length > 0 && (
            <tr
              style={{ textDecoration: p.isCanceled && "line-through" }}
              className="fs-5"
              key={p.prescriptionId.toString()}
            >
              <td data-label="Created Date">
                {new Date(p.createdOn).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "numeric"
                })}
              </td>
              <td>
                <div className="rpe-reports">
                  <div
                    id={"prescription-files-" + p.prescriptionId.toString()}
                    className="d-flex align-items-center"
                  >
                    {p.photos.map((photo) => {
                      if (isImageFile(photo.photoId)) {
                        return (
                          <a
                            href="#"
                            className="me-2"
                            data-bs-toggle="modal"
                            data-bs-target="#fileViewerImage"
                            onClick={() => openFile(photo.photoId)}
                          >
                            <img
                              className="gth-uploads-icon"
                              src={photo.photoId}
                              onError={onImageError}
                              alt="File img"
                            />
                          </a>
                        );
                      } else if (
                        isValidDocFileForPatientUpload(photo.photoId)
                      ) {
                        return (
                          <a
                            href="#"
                            className="text-body gth-pdf-modal"
                            data-bs-toggle="modal"
                            data-bs-target="#fileViewerDoc"
                            onClick={() => openFile(photo.photoId)}
                          >
                            <img
                              src={
                                isPDFfile(photo.photoId)
                                  ? IconPDF
                                  : GenericFileIcon
                              }
                              onError={onImageError}
                              className="gth-uploads-icon"
                              alt=""
                            />
                          </a>
                        );
                      } else {
                        return (
                          <a
                            href={photo.photoId}
                            target="_blank"
                            className="me-2"
                          >
                            <img
                              className="gth-uploads-icon"
                              src={photo.photoId}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src =
                                  getDefaultPicPath(currentTarget);
                              }}
                              alt="File img"
                            />
                          </a>
                        );
                      }
                    })}
                  </div>
                </div>
              </td>
            </tr>
          )
        );
      });
    }
  }

  return (
    <>
      <div className="container">
        <div className="row mb-3">
          <div className="col-sm-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Prescription
                </li>
              </ol>
            </nav>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
              {prescriptions.length <= 0 &&
                uploadedPrescriptions.length <= 0 && (
                  <div className="d-flex justify-content-center mt-5 mb-5">
                    <h2>No prescription available.</h2>
                  </div>
                )}

              {prescriptions &&
                Array.isArray(prescriptions) &&
                prescriptions.length > 0 && (
                  <>
                    <div className="gth-main-header mb-3">
                      <h1>Prescription</h1>
                    </div>
                    <div
                      className="fade show active Prescription"
                      id="Prescription"
                    >
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Prescription Name</th>
                            <th scope="col">Date</th>
                            <th scope="col">Medicine</th>
                            <th scope="col">Dosage</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Description</th>
                            <th scope="col">Notes</th>
                            <th scope="col">Refill</th>
                            <th scope="col">Pharmacy</th>
                            <th className="text-center" scope="col">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>{prescriptionTable(prescriptions)}</tbody>
                      </table>
                    </div>
                  </>
                )}

              {uploadedPrescriptions &&
                Array.isArray(uploadedPrescriptions) &&
                uploadedPrescriptions.length > 0 && (
                  <>
                    <div className="gth-main-header mb-3">
                      <h1>Uploaded Prescription</h1>
                    </div>
                    <div
                      className="fade show active Prescription"
                      id="Prescription"
                    >
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">Created Date</th>
                            <th>Files</th>
                          </tr>
                        </thead>
                        <tbody>
                          {uploadedPrescriptionTable(uploadedPrescriptions)}
                        </tbody>
                      </table>
                    </div>
                  </>
                )}

              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={totalCount ? totalCount : 0}
                pageSize={PaginationConstants.ItemPerPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrescriptionTable;
