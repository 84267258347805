import React from "react";
import SmartScanSelfieConfirm from "../../components/SmartScanSelfieConfirmPage/SmartScanSelfieConfirm";
import { smartScanCategory } from "../../constants";
import useGetSmartScanSelfieCaptureProgress from "../../components/CustomHooks/useGetSmartScanSelfieCaptureProgress";

const SmartScanUpperArchConfirm = () => {
  const smartScanState = useGetSmartScanSelfieCaptureProgress();

  return (
    <>
      <body className="mobile-body">
        <div className="body-content">
          <section className="review-photo-section py-4 py-md-5">
            {smartScanState ? (
              <SmartScanSelfieConfirm
                nextPagePath={"/smart-scan-lower-arch"}
                retakePagePath={"/smart-scan-upper-arch"}
                state={smartScanState}
                category={smartScanCategory.UpperArch}
              />
            ) : (
              <></>
            )}
          </section>
        </div>
      </body>
    </>
  );
};

export default SmartScanUpperArchConfirm;
