import React from "react";
import { Link } from "react-router-dom";
import "./../../assets/images/payment-card-icons.png";
import InputMask from "react-input-mask";
import QuestionnaireTabs from "../QuestionnaireTabs/QuestionnaireTabs";

const QuestionnaireBillingInfoPage = () => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  const countryList = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas (the)",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia (Plurinational State of)",
    "Bonaire, Sint Eustatius and Saba",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory (the)",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cayman Islands (the)",
    "Central African Republic (the)",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands (the)",
    "Colombia",
    "Comoros (the)",
    "Congo (the Democratic Republic of the)",
    "Congo (the)",
    "Cook Islands (the)",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Curaçao",
    "Cyprus",
    "Czechia",
    "Côte d'Ivoire",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic (the)",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Falkland Islands (the) [Malvinas]",
    "Faroe Islands (the)",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories (the)",
    "Gabon",
    "Gambia (the)",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard Island and McDonald Islands",
    "Holy See (the)",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran (Islamic Republic of)",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea (the Democratic People's Republic of)",
    "Korea (the Republic of)",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People's Democratic Republic (the)",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands (the)",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia (Federated States of)",
    "Moldova (the Republic of)",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands (the)",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger (the)",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands (the)",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine, State of",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines (the)",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Republic of North Macedonia",
    "Romania",
    "Russian Federation (the)",
    "Rwanda",
    "Réunion",
    "Saint Barthélemy",
    "Saint Helena, Ascension and Tristan da Cunha",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin (French part)",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Sint Maarten (Dutch part)",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia and the South Sandwich Islands",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan (the)",
    "Suriname",
    "Svalbard and Jan Mayen",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan",
    "Tajikistan",
    "Tanzania, United Republic of",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands (the)",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates (the)",
    "United Kingdom of Great Britain and Northern Ireland (the)",
    "United States Minor Outlying Islands (the)",
    "United States of America (the)",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela (Bolivarian Republic of)",
    "Viet Nam",
    "Virgin Islands (British)",
    "Virgin Islands (U.S.)",
    "Wallis and Futuna",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe",
    "Åland Islands"
  ];

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
              <QuestionnaireTabs />
              <fieldset className="payment-detail-section d-flex flex-wrap justify-content-between flex-column btn-min-vh111 min-vh">
                <form action="">
                  <legend className="fs-1 mb-3 mb-md-4">Card Details</legend>
                  <div className="form-group email-group mb-3 col-sm-12 position-relative">
                    <label
                      className="form-label pb-md-1 mx-3 my-0 w-auto position-absolute top-50 start-0 translate-middle-y"
                      htmlFor="payment_email"
                    >
                      Email
                    </label>
                    <input
                      id="payment_email"
                      type="email"
                      className="form-control"
                      placeholder="john@gmail.com"
                    />
                  </div>
                  <p className="text-muted fs-3 mb-3">Save Card Information</p>
                  <div className="form-group mb-3 col-sm-12">
                    <label className="form-label">Card Information</label>
                    <div className="payment-card-fields d-flex flex-wrap">
                      <InputMask
                        mask={"9999 9999 9999 9999"}
                        type="text"
                        className="form-control payment-card-number"
                        placeholder="1234 xxxx xxxx xxxx"
                      />
                      <InputMask
                        mask={"99/99"}
                        type="text"
                        className="form-control payment-card-date border-top-000 w-50"
                        placeholder="MM / YY"
                      />
                      <input
                        type="password"
                        className="form-control payment-card-cvc border-top-000 w-50"
                        placeholder="CVC"
                      />
                    </div>
                  </div>
                  <div className="form-group mb-3 col-sm-12">
                    <label className="form-label">Name on Card</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="John Doe"
                    />
                  </div>
                  <div className="form-group mb-3 col-sm-12">
                    <label className="form-label">Country or Region</label>
                    <div className="region-fields">
                      <select
                        className="form-select form-control"
                        id="country"
                        name="country"
                      >
                        {countryList.map((country) => (
                          <option value={country} key={country}>
                            {country}
                          </option>
                        ))}
                      </select>
                      <InputMask
                        mask={"99999"}
                        type="text"
                        className="form-control"
                        placeholder="ZIP"
                      />
                    </div>
                  </div>
                  <div className="form-group text-center mt-3 mt-md-5 col-sm-12 button-wrapper">
                    <Link
                      to="/questionnaire-review-submit"
                      className="btn btn-outline btn-rounded btn-lg px-5 mx-md-2 mt-3 mt-md-0 w-100 w-md-auto"
                    >
                      Cancel
                    </Link>
                    <Link
                      to="/dashboard"
                      className="btn btn-secondary btn-rounded btn-lg px-3 px-md-5 mt-3 mt-md-0 w-100 w-md-auto"
                    >
                      Save Card
                    </Link>
                  </div>
                  <div className="form-group mt-3 col-md-12">
                    <div className="form-text icon fs-3 d-table mx-auto mt-0">
                      <i
                        className="bi bi-exclamation-circle-fill text-gold lh-1"
                        style={{ top: "-4px" }}
                      ></i>
                      By saving card information you allow Dental.com to charge
                      your card for future payments in accordance with their
                      terms.
                    </div>
                  </div>
                </form>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default QuestionnaireBillingInfoPage;
