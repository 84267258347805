import React from "react";
import { useLocation, Link } from "react-router-dom";

import FormSubmissionComponent from "../../components/FormSubmission/FormSubmission";

const FormSubmission = () => {
  const { state } = useLocation();

  return (
    <>
      <body>
        <section className="step-section py-4 py-md-5">
          <div className="container">
            <div className="row mb-3">
              <div className="col-sm-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Form Submission
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-10 offset-sm-1 col-md-12 offset-md-0 col-xl-10 offset-xl-1">
                <div className="gth-main-header mb-3">
                  <h1>Form Submission</h1>
                </div>
                <FormSubmissionComponent formData={state?.formData} />
              </div>
            </div>
          </div>
        </section>
        {/* <!-- scripts --> */}
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js"
          integrity="sha512-894YE6QWD5I59HgZOGReFYm4dnWc1Qt5NtvYSaNcOP+u1T9qYdvdihz0PPSiiqn/+/3e7Jo4EaG7TubfWGUrMQ=="
          crossOrigin="anonymous"
          referrerPolicy="no-referrer"
        ></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/signature_pad/1.3.2/signature_pad.min.js"></script>
        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"
          integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"
          crossOrigin="anonymous"
        ></script>
      </body>
    </>
  );
};

export default FormSubmission;
