import { SET_FILE_URL_TO_OPEN, SET_FILE_NAME } from "./typeConstants";

export const setFileUrlToOpen = (fileUrlToOpen, modalToReopen) => ({
  type: SET_FILE_URL_TO_OPEN,
  payload: {
    fileUrlToOpen,
    modalToReopen
  }
});

export const setFileName = (fileName) => ({
  type: SET_FILE_NAME,
  payload: { fileName }
});
