import React from "react";
import QuestionnaireRecordRequestPage from "../../components/QuestionnaireRecordRequest/QuestionnaireRecordRequestPage";

const QuestionnaireRecordRequest = () => {
  return (
    <body>
      <div className="body-content">
        <section className="questionnaire-section py-4 py-md-5">
          <QuestionnaireRecordRequestPage />
        </section>
      </div>
    </body>
  );
};

export default QuestionnaireRecordRequest;
