import React from "react";
import "../../assets/css/style.css";

import Navbar from "../../components/Navbar/Navbar";
import ForgotPasswordForm from "../../components/ForgotPassword/ForgotPasswordForm";
import ForgotPasswordAlert from "../../components/ForgotPassword/ForgotPasswordAlert";

export default function ForgotPassword() {
  return (
    <>
      <body>
        <section className="account-section reset-password-section py-4 py-md-5 v-middle min-vh-100">
          <ForgotPasswordForm />
        </section>
        <ForgotPasswordAlert />

        {/* <!-- scripts --> */}
        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"
          integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"
          crossOrigin="anonymous"
        >
          {" "}
        </script>
      </body>
    </>
  );
}
