import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import moment from "moment";

import {
  seeDentistNow_Ajax,
  postBookingProcedures_Ajax,
  getBasicCampaignsInfo_Ajax
} from "../../helpers/requests";
import { getTokenData } from "../../helpers/authManager";
import {
  handleApiErrors,
  redirectToCheckout,
  getLocalTimezoneOffset,
  formatDateTimeWithZone,
  showAlertDialouge,
  handleBeforeUnload
} from "../../helpers/utils";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import SeeDentistPromo from "../SeeDentist/SeeDentistPromo";
import useIsBillingInformationAvailable from "../CustomHooks/useIsBillingInformationAvailable";
import {
  DentalDotComPortalType,
  PaymentCancelUrl,
  ConsentOptions
} from "../../constants";
import useIsParTypeCarrieSelected from "../CustomHooks/useIsParTypeCarrieSelected";
import { getBaseUrl } from "../../ApplicationSettings";
import RequestPhoneCallModal from "../RequestPhoneCallModal/RequestPhoneCallModal";

const AppointmentScheduleReviewPage = (props) => {
  const {
    schedulerCampaign,
    patientState,
    scheduledDateTime,
    campaignForms,
    selectedPharmacy,
    patientData,
    primaryDentistInfo = null,
    primaryConsultantInfoCollectionDecision
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const schedulerCampaignId = useSelector(
    (state) => state?.appSettings?.settingsData?.schedulerCampaignId
  );
  const officeId = useSelector(
    (state) => state?.appSettings?.settingsData?.officeId
  );
  const schedulerCampaignCharge = useSelector((state) => {
    if (state?.userData?.schedulerCampaignCharge > 0) {
      return state?.userData?.schedulerCampaignCharge;
    } else if (location.state?.userData?.schedulerCampaignCharge > 0) {
      return location.state?.userData?.schedulerCampaignCharge;
    }
    return 0;
  });
  const hasPromoCode = useSelector((state) => {
    if (state.userData?.hasSchedulerPromoCode) {
      return state.userData?.hasSchedulerPromoCode;
    } else if (location.state?.userData?.hasSchedulerPromoCode) {
      return location.state?.userData?.hasSchedulerPromoCode;
    }
    return false;
  });
  const [validPromoCode, setValidPromoCode] = useState(null);
  const isBillingInformationAvailable = useIsBillingInformationAvailable(
    location.state?.userData
      ?.isScheduledAppointmentCaptureCreditOrDebitCardEnabled
  );
  const [isParTypeCarrierSelected, isCarrierInfoAvailable] =
    useIsParTypeCarrieSelected(patientData?.patid);

  const phoneNoRef = useRef();
  const isVoipFeatureEnabled = useSelector(
    (state) => state?.appSettings?.settingsData?.isVoipFeatureEnabled
  );
  const shouldRequestCallBackFromScheduleAppoitment = useSelector(
    (state) =>
      state?.appSettings?.settingsData
        ?.shouldRequestCallBackFromScheduleAppoitment
  );

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);

  function startOnDemandConsultationFlow() {
    if (patientState && patientData && schedulerCampaign) {
      var NewUser = {
        pgId: schedulerCampaign.pgId,
        firstName: patientData.firstName,
        lastName: patientData.lastName,
        email: patientData.user?.email,
        phone: patientData.user?.phone,
        dateOfBirth: "1990-10-11",
        gender: patientData.gender
      };

      var dateTimeString = moment(
        scheduledDateTime,
        "YYYY-MM-DD HH:mm a"
      ).format("YYYY-MM-DD HH:mm a");

      var submittedCampaignForms = [];

      if (
        campaignForms &&
        Array.isArray(campaignForms) &&
        campaignForms.length > 0
      ) {
        campaignForms.map((form) => {
          submittedCampaignForms.push({
            medicalFormId: form.medicalFormId,
            formData: JSON.stringify(form.formData)
          });
        });
      }

      var paymentSuccessUrl =
        getBaseUrl() + "/dashboard?apptBookingSuccess=true";
      let paymentCancelUrl = getBaseUrl() + PaymentCancelUrl;

      var localTimeZoneOffset = getLocalTimezoneOffset();

      var patientDataJson = {
        Pgid: schedulerCampaign.pgId,
        NewUser: NewUser,
        PreferredPharmacyId: 0,
        State: patientState,
        Country: patientData.country,
        IsVDCAppDeepLinkNeeded: false,
        PatientId: patientData.patid,
        ScheduledDateTime: dateTimeString,
        LocalTimeZoneOffset: localTimeZoneOffset,
        IsDentalDotComCall: true,
        IsScheduledCall: true,
        SubmittedCampaignForms: submittedCampaignForms,
        DisablePatientEmail: true,
        OfficeId: officeId,
        PaymentSuccessUrl: paymentSuccessUrl,
        PaymentCancelUrl: paymentCancelUrl,
        AddHomeOfficeFromParam: true,
        preferredPharmacyId: selectedPharmacy?.id,
        CreatedFrom: DentalDotComPortalType,
        SharedWithPrimaryConsultant: primaryConsultantInfoCollectionDecision
      };

      if (phoneNoRef.current) {
        patientDataJson.recipientPhoneNumber = phoneNoRef.current;
      }

      if (isParTypeCarrierSelected() || !isBillingInformationAvailable) {
        patientDataJson.IsPaymentDisabled = true;
      }
      if (
        primaryDentistInfo != null &&
        primaryConsultantInfoCollectionDecision == ConsentOptions.Agree
      ) {
        patientDataJson.ProviderInvitationByPatient = primaryDentistInfo;
      }

      var params = {
        promoCode: validPromoCode
      };

      dispatch(showLoadingSpinner());
      seeDentistNow_Ajax(
        schedulerCampaign.campaignId,
        params,
        JSON.stringify(patientDataJson),
        function (response) {
          dispatch(hideLoadingSpinner());
          if (response && response.success) {
            var videoConfURL = undefined;
            var isRedirectToStripe = false;
            var paymentSessionId = undefined;
            var stripeAccount = undefined;

            if (response.data && response.data.videoConfURL) {
              videoConfURL = response.data.videoConfURL;

              if (response.data.portalAutoLoginApiKey) {
                videoConfURL +=
                  "&apikey=" + response.data.portalAutoLoginApiKey;
                videoConfURL += "&token=" + getTokenData().token;
              }
            }

            if (response.data && response.data.postBookingProcedures) {
              postBookingProcedures_Ajax(
                JSON.stringify(response.data.postBookingProcedures)
              );
            }

            if (response.data && response.data.isRedirectToStripe) {
              isRedirectToStripe = response.data.isRedirectToStripe;
            }
            if (response.data && response.data.paymentSessionId) {
              paymentSessionId = response.data.paymentSessionId;
            }
            if (response.data && response.data.stripeAccount) {
              stripeAccount = response.data.stripeAccount;
            }

            window.removeEventListener("beforeunload", handleBeforeUnload);

            if (isRedirectToStripe) {
              showAlertDialouge(
                "Message",
                "Please select '" +
                  patientData.fullName +
                  "' from dependents list at the top-right corner to see upcoming virtual appointments in the dashboard after completing payment." +
                  "Please Note: If payment is unsuccessful, all progress will be lost.",
                function () {
                  if (isRedirectToStripe) {
                    redirectToCheckout(paymentSessionId, stripeAccount);
                  }
                }
              );
            } else {
              showAlertDialouge(
                "Message",
                "Appointment Scheduled Successfully. Please select '" +
                  patientData.fullName +
                  "' from dependents list at the top-right corner to see upcoming virtual appointments in the dashboard.",
                function () {
                  navigate("/dashboard?apptBookingSuccess=true");
                }
              );
            }
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function getDateTimeString() {
    if (scheduledDateTime) {
      var dateTime = moment(scheduledDateTime, "YYYY-MM-DD hh:mm A");

      return (
        dateTime.format("dddd") +
        " " +
        dateTime.format("MMMM Do YYYY") +
        " at " +
        formatDateTimeWithZone(dateTime, "h:mm:ss A")
      );
    }
  }

  function shouldShowPromoCodeSection() {
    if (!isCarrierInfoAvailable()) {
      return false;
    }
    if (!isBillingInformationAvailable || isParTypeCarrierSelected()) {
      return false;
    }

    return hasPromoCode;
  }

  function onCallMeBackBtnClicked(phoneNo) {
    if (phoneNo) {
      phoneNoRef.current = phoneNo;
      startOnDemandConsultationFlow();
    }
  }

  function getCancelButton() {
    let className =
      "btn btn-outline btn-rounded btn-lg px-3 px-md-5 mx-md-2 mt-3 mt-md-0";
    let id = "billing-info-tab";

    if (isVoipFeatureEnabled && shouldRequestCallBackFromScheduleAppoitment) {
      return (
        <button
          className={className}
          id={id}
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#dentist-form-modal"
        >
          Cancel
        </button>
      );
    } else {
      return (
        <Link to="/dashboard">
          <button className={className} id={id} type="button">
            Cancel
          </button>
          ;
        </Link>
      );
    }
  }

  return (
    <>
      <div
        className="tab-pane fade show"
        id="review-submit"
        role="tabpanel"
        aria-labelledby="review-submit-tab"
      >
        <fieldset>
          <legend className="fs-1 mb-4 mb-md-5">Review and Submit</legend>
          <div className="row mb-5">
            <div className="col-md-12 col-lg-6">
              <p className="mb-4 mb-md-5">
                Visit is for <br />{" "}
                <span className="fw-bold">{patientData?.fullName}</span>
              </p>
              <p className="mb-4 mb-md-5">
                Date and Time <br />
                <span className="fw-bold">{getDateTimeString()}</span>
              </p>
              <p className="fs-3 text-violet">
                <i className="bi bi-play-btn-fill me-1"></i> Make sure to test
                your video and audio capabilities prior to starting your visit.
              </p>
            </div>
            <SeeDentistPromo
              initialCharge={schedulerCampaignCharge}
              type={"Schedule virtual appointment"}
              validPromoCode={validPromoCode}
              setValidPromoCode={setValidPromoCode}
              campaignId={schedulerCampaignId}
              hasPromoCode={hasPromoCode}
              shouldShowPromoCodeSection={shouldShowPromoCodeSection}
            ></SeeDentistPromo>
          </div>

          {isBillingInformationAvailable && (
            <div className="fs-3 text-muted fst-italic text-center mt-3 mt-md-5">
              Your payment method will not be charged until your visit is
              complete.
            </div>
          )}

          <div
            className="nav form-group text-center d-flex flex-wrap justify-content-center mt-2 mt-md-4 col-sm-12 button-wrapper"
            id="step-tab"
            role="tablist"
          >
            {getCancelButton()}
            <button
              className="btn btn-secondary btn-rounded btn-lg px-3 px-md-5 mx-md-2 mt-3 mt-md-0"
              type="submit"
              onClick={startOnDemandConsultationFlow}
            >
              {" "}
              Confirm Appointment
            </button>
          </div>
        </fieldset>
        <RequestPhoneCallModal
          onCallMeBackBtnClicked={onCallMeBackBtnClicked}
        />
      </div>
    </>
  );
};

export default AppointmentScheduleReviewPage;
