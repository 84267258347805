import React, { useState, useRef, useEffect } from "react";
import Highcharts from "highcharts";
import { HighchartsReact } from "highcharts-react-official";
import leftArrowIcon from "./../../assets/images/icon-arrow-left.png";
import rightArrowIcon from "./../../assets/images/icon-arrow-right.png";
import IconWhitnessScore from "./../../assets/images/whiteness-score-icon.png";
import {
  Pagination,
  SortOrders,
  SmartScanGraphDataPropertyIndices,
  Shades
} from "../../constants";
import { getSmartScanWhitenessScores_Ajax } from "../../helpers/requests";
import { useDispatch, useSelector } from "react-redux";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import { handleApiErrors, isEmptyArray } from "../../helpers/utils";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { getPatientInfo } from "../../helpers/authManager";

const WhitenessScore = (props) => {
  const {
    patientId,
    onWhitnessGraphPointClick,
    currentScore,
    itemPerPage,
    hideAllArrow,
    chartHeight
  } = props;
  const [smartScanWhitenessScore, setSmartScanWhitenessScoreData] = useState();
  const [smartScanGraphData, setSmartScanGraphData] = useState([]);
  const smartScanWhitenessGraphDataRef = useRef([]);
  const [whitenessScoreTotalCount, setWhitenessScoreTotalCount] = useState(0);
  const [currentShadeColor, setCurrentShadeColor] = useState();
  const chartRef = useRef();
  const [currentPageNum, setCurrentPageNum] = useState(Pagination.FirstPageNo);
  const currentPageNumRef = useRef(Pagination.FirstPageNo);
  const dispatch = useDispatch();
  const totalPageCount = useRef();
  const location = useLocation();
  const chartMaxHeight = 80;
  const deviceMinWidth = 400;
  const chartStandardHeight = (chartHeight / 16) * 100;
  const isDemoShadeSelectorEnabled = useSelector(
    (state) => state?.appSettings?.settingsData?.isDemoShadeSelectorEnabled
  );

  const patientInfo = getPatientInfo();

  const isDemoPatient = useSelector((state) =>
    state?.appSettings?.settingsData?.demoPatientIds?.includes(
      patientInfo?.patid
    )
  );

  const demoData = [
    {
      whitenessScore: 9,
      createdOn: "2024-09-11T07:23:22",
      smartScanId: 1050,
      patientId: 1073
    },
    {
      whitenessScore: 7,
      createdOn: "2024-09-11T05:26:03",
      smartScanId: 1049,
      patientId: 1073
    }
  ];

  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "area",
      spacingLeft: 25,
      spacingRight: 30,
      height: getChartHeightInPercentageBasedOnDeviceWidth() + "%",
      panning: false
    },
    title: {
      useHTML: true,
      enabled: true,
      text: null
    },
    xAxis: {
      categories: [],
      max: itemPerPage - 1
    },
    yAxis: {
      title: {
        text: ""
      },
      tickInterval: 2,
      tickPositions: [0, 2, 4, 6, 8, 10],
      tickmarkPlacement: "on",
      min: 0,
      max: 10,
      reversed: true,
      startOnTick: false,
      endOnTick: false,
      lineColor: {
        linearGradient: [0, 0, 0, 300],
        stops: [
          [0, "rgb(237, 237, 237)"],
          [1, "rgb(211, 148, 0)"]
        ]
      },
      lineWidth: 5
    },
    plotOptions: {
      series: {
        point: {
          events: {
            click: function () {
              if (!isDemoPatient) {
                handleGraphPointClick(this.index);
              }
            }
          }
        }
      },
      area: {
        lineColor: "#666666",
        lineWidth: 0,
        marker: {
          lineWidth: 1,
          lineColor: "#666666"
        },
        dataLabels: {
          enabled: true
        }
      }
    },
    tooltip: {
      enabled: false
    },
    series: [
      {
        type: "area",
        name: "Score",
        data: []
      }
    ]
  });

  function getChartHeightInPercentageBasedOnDeviceWidth() {
    const width = window.innerWidth;
    let height = 0;
    if (width <= deviceMinWidth) {
      height = chartMaxHeight;
    } else {
      height = chartStandardHeight;
    }

    return height;
  }

  useEffect(() => {
    if (currentPageNum > 0 && patientId > 0 && !isDemoPatient) {
      getSmartScanWhitenessScores();
    } else {
      setSmartScanWhitenessScoreData(demoData.reverse());
    }
  }, [currentPageNum, patientId]);

  function getSmartScanWhitenessScores() {
    if (patientId > 0 && currentPageNum > 0) {
      let params = {
        currentPageNum: currentPageNum,
        sortingOrder: SortOrders.Descending,
        itemPerPage: itemPerPage
      };

      dispatch(showLoadingSpinner());
      getSmartScanWhitenessScores_Ajax(
        patientId,
        params,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (response && response.success && response.data) {
            setSmartScanWhitenessScoreData(
              response.data.whitenessScoresVM.reverse()
            );
            setWhitenessScoreTotalCount(response.data.totalCount);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  useEffect(() => {
    if (whitenessScoreTotalCount > 0) {
      totalPageCount.current = Math.ceil(
        whitenessScoreTotalCount / itemPerPage
      );
    }
  }, [whitenessScoreTotalCount]);

  function hasSmartScans() {
    return !isEmptyArray(smartScanWhitenessScore);
  }

  useEffect(() => {
    if (smartScanWhitenessScore && !isEmptyArray(smartScanGraphData)) {
      setChartOptions((prevOptions) => {
        return {
          ...prevOptions,
          xAxis: {
            categories: !isEmptyArray(smartScanGraphData)
              ? smartScanGraphData?.map(
                  (sc) => sc[SmartScanGraphDataPropertyIndices.CreatedOn]
                )
              : [],
            max: Math.min(smartScanGraphData.length, itemPerPage) - 1
          },
          series: [
            {
              data: !isEmptyArray(smartScanGraphData) ? smartScanGraphData : []
            }
          ]
        };
      });
    }
  }, [smartScanGraphData]);

  useEffect(() => {
    if (chartOptions) {
      ShowHideNavigtionArrow();
    }
  }, [chartOptions]);

  useEffect(() => {
    if (!isEmptyArray(smartScanWhitenessScore)) {
      let graphData = prepareGraphData(smartScanWhitenessScore);

      if (graphData) {
        smartScanWhitenessGraphDataRef.current = [...graphData];
        setSmartScanGraphData(smartScanWhitenessGraphDataRef.current);
      }
    }
  }, [smartScanWhitenessScore, whitenessScoreTotalCount]);

  useEffect(() => {
    if (currentScore > 0) {
      let curShade = Shades.find((x) => x.score == currentScore);
      setCurrentShadeColor(curShade?.color);
    }
  }, [currentScore]);

  function prepareGraphData(whitenessScoreData) {
    if (!isEmptyArray(whitenessScoreData)) {
      let whitenessScoreGraphData = whitenessScoreData.map((score) => {
        return [
          moment(score.createdOn).format("MMM DD<br>YYYY"),
          score.whitenessScore,
          score.smartScanId,
          score.patientId
        ];
      });

      return whitenessScoreGraphData;
    }
  }

  function ShowHideNavigtionArrow() {
    if (chartRef?.current?.chart) {
      let chart = chartRef.current.chart;
      let leftArrow = chart?.leftArrow;
      let rightArrow = chart?.rightArrow;

      if (leftArrow) {
        if (currentPageNumRef.current < totalPageCount.current) {
          leftArrow.show();
        } else {
          leftArrow.hide();
        }
      }

      if (rightArrow) {
        if (currentPageNumRef.current > Pagination.FirstPageNo) {
          rightArrow.show();
        } else {
          rightArrow.hide();
        }
      }

      if (hideAllArrow) {
        leftArrow?.hide();
        rightArrow?.hide();
      }
    }
  }

  function OnLeftArrowClicked() {
    if (currentPageNumRef.current <= totalPageCount.current) {
      setCurrentPageNum((prev) => {
        currentPageNumRef.current = prev + 1;
        return prev + 1;
      });
    }
  }

  function onRightArrowClicked() {
    if (currentPageNumRef.current > Pagination.FirstPageNo) {
      setCurrentPageNum((prev) => {
        currentPageNumRef.current = prev - 1;
        return prev - 1;
      });
    }
  }

  useEffect(() => {
    if (chartRef?.current?.chart) {
      let chart = chartRef?.current?.chart;

      if (whitenessScoreTotalCount > itemPerPage) {
        chart.leftArrow = chart.renderer
          .image(
            leftArrowIcon,
            0,
            getChartHeightInPercentageBasedOnDeviceWidth(),
            30,
            30
          )
          .attr({ zIndex: 10 });
        chart.rightArrow = chart.renderer
          .image(
            rightArrowIcon,
            chart.chartWidth - 30,
            getChartHeightInPercentageBasedOnDeviceWidth(),
            30,
            30
          )
          .attr({ zIndex: 10 });
        chart.leftArrow.on("click", OnLeftArrowClicked).add();
        chart.rightArrow.on("click", onRightArrowClicked).add();
      } else if (whitenessScoreTotalCount <= itemPerPage) {
        if (chart?.leftArrow) {
          chart.leftArrow.destroy();
          chart.leftArrow = null;
        }

        if (chart?.rightArrow) {
          chart.rightArrow.destroy();
          chart.rightArrow = null;
        }
      }
    }
  }, [whitenessScoreTotalCount]);

  function handleGraphPointClick(index) {
    if (index >= 0) {
      let whitenessScoreInfo = smartScanWhitenessGraphDataRef.current[index];
      if (whitenessScoreInfo && onWhitnessGraphPointClick) {
        onWhitnessGraphPointClick(whitenessScoreInfo);
      }
    }
  }

  return (
    <div className="custom-border-radius-xlg bg-white">
      <div className="card text-body custom-border-radius-xlg text-decoration-none d-flex flex-row align-items-lg-center border-0 pt-4 pt-md-3 mb-3">
        <div className="card-header flex-shrink-0 border-0">
          <img src={IconWhitnessScore} alt="" />
        </div>
        <div
          className="card-body d-flex flex-wrap justify-content-between align-items-center flex-grow-1 px-3 px-md-4 pe-0 pe-md-0 py-0 border-0 whiteness-score-div"
          id="wellness-current-value"
        >
          {currentScore > 0 ? (
            <h3 className="col-12 col-sm-3 card-title pe-lg-4 mb-4 mb-lg-0 whiteness-score-header">
              Your Whiteness Score
            </h3>
          ) : currentScore == 0 ? (
            <h3 className="col-12 col-lg-7 card-title pe-lg-4 mb-4 mb-lg-0">
              Whiteness score is not available for this smart scan.
            </h3>
          ) : (
            <div className="col-12 col-lg-7 card-title pe-lg-4 mb-4 mb-lg-0"></div>
          )}
          {currentScore > 0 && (
            <div className="col-12 col-sm-4 content-center text-center d-flex flex-wrap pe-0 pe-lg-4 mb-3 mb-lg-0 wellness-current-score wellness-score1">
              <div className="d-flex flex-wrap justify-content-center justify-content-lg-center align-items-center mb-3 w-100">
                <div className="ps-0 pe-0 px-md-2">
                  <span
                    className="whiteness_num d-block mb-2"
                    style={{ backgroundColor: currentShadeColor }}
                  >
                    {currentScore}
                  </span>
                </div>
              </div>
              <div className="fw-bold w-100">
                Your Whiteness Shade Score is {currentScore}.
              </div>
            </div>
          )}
          <div className="col-12 col-sm-5 content-center">
            <div
              className={
                "highcharts-figure margin-left-0 d-lg-block " +
                (hasSmartScans() ? "" : "opacity-50 filter-grayscale")
              }
            >
              <div id="score-chart">
                <HighchartsReact
                  ref={chartRef}
                  highcharts={Highcharts}
                  options={chartOptions}
                />
              </div>
              {location && location.pathname == "/smart-scan-wellness-score" ? (
                <div className="chart-bottom-text d-none">
                  You're improving your oral health - keep up the good work!
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhitenessScore;
