import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import SeeDentistTabs from "../../components/SeeDentist/SeeDentistTabs";
import SeeDentistReviewPage from "../../components/SeeDentist/SeeDentistReviewPage";

const SeeDentistReview = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!state) {
      navigate("/see-dentist");
    }
  }, [state]);

  return (
    <>
      <body>
        <section className="step-section py-4 py-md-5">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
                  <div className="tab-content" id="step-tabContent">
                    <SeeDentistTabs />
                    <SeeDentistReviewPage
                      onDemandCampaign={state?.onDemandCampaign}
                      patientState={state?.patientState}
                      campaignForms={state?.campaignForms}
                      selectedPharmacy={state?.selectedPharmacy}
                      patientData={state?.patientData}
                      smartScanId={state?.smartScanId}
                      insuranceInfo={state?.insuranceInfo}
                      primaryDentistInfo={state?.primaryDentistInfo}
                      primaryConsultantInfoCollectionDecision={
                        state?.primaryConsultantInfoCollectionDecision
                      }
                      returnUrl={state?.returnUrl}
                      shouldUseBDPhoneNumber={state?.shouldUseBDPhoneNumber}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- scripts --> */}
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js"
          integrity="sha512-894YE6QWD5I59HgZOGReFYm4dnWc1Qt5NtvYSaNcOP+u1T9qYdvdihz0PPSiiqn/+/3e7Jo4EaG7TubfWGUrMQ=="
          crossOrigin="anonymous"
          referrerPolicy="no-referrer"
        ></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/signature_pad/1.3.2/signature_pad.min.js"></script>
        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"
          integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"
          crossOrigin="anonymous"
        ></script>
      </body>
    </>
  );
};

export default SeeDentistReview;
