import { SAVE_APP_SETTINGS_DATA } from "../actions/typeConstants";

var initialState = {
  settingsData: null
};

export const appSettings = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_APP_SETTINGS_DATA:
      return Object.assign({}, state, {
        settingsData: action?.payload?.appSettings
      });
    default:
      return state;
  }
};
