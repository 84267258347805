import {
  SAVE_TOKEN_DATA,
  REMOVE_TOKEN_DATA,
  SAVE_PGID,
  SAVE_CURRENT_PATIENT,
  REMOVE_CURRENT_PATIENT,
  SAVE_FAMILY_MEMBERS_LIST,
  REMOVE_FAMILY_MEMBERS_LIST,
  SET_IS_FAMILY_MEMBERS_LIST_CHANGED,
  SET_IS_ONLINE_CSR_AVAILABLE,
  SET_LOGGED_IN_PATIENT_PHOTO_ID,
  SET_IS_ON_DEMAND_CAPTURE_CREDIT_OR_DEBIT_CARD_ENABLED,
  SET_IS_SCHEDULED_APPOINTMENT_CAPTURE_CREDIT_OR_DEBIT_CARD_ENABLED,
  SET_IS_PHARMACY_SELECTION_ENABLED,
  SET_IS_RELOAD_PATIENT_STATUS_SUMMARY,
  SAVE_PATIENT_AND_FAMILY_MEMBERS_LIST,
  SET_HAS_ON_DEMAND_PROMO_CODE,
  SET_HAS_SCHEDULER_PROMO_CODE,
  SET_HAS_SECOND_OPINION_PROMO_CODE,
  SET_ON_DEMAND_CAMPAIGN_CHARGE,
  SET_SCHEDULER_CAMPAIGN_CHARGE,
  SET_SECOND_OPINION_CHARGE,
  SET_IS_SUBMITTED_FORM_REVIEW_ENABLED,
  SET_SMART_SCAN_CHARGE,
  SET_SELECTED_PATIENT_INSURANCE,
  SET_SELECTED_PATIENT_FOR_APPOINTMENT
} from "../actions/typeConstants";

let initialState = {
  tokenData: null,
  isLoggedIn: false,
  pgId: 0,
  currentPatient: null,
  familyMembersList: [],
  isCurrentPatientAvailable: false,
  isFamilyMembersListChanged: false,
  isOnlineCsrAvailable: false,
  loggedInPatientPhotoId: null,
  isOnDemandCaptureCreditOrDebitCardEnabled: false,
  isScheduledAppointmentCaptureCreditOrDebitCardEnabled: false,
  isPharmacySelectionEnabled: false,
  isReloadPatientStatusSummary: false,
  patientAndFamilyMembersList: [],
  hasOnDemandPromoCode: false,
  hasSecondOpinionPromoCode: false,
  hasSchedulerPromoCode: false,
  selectedPatientInsurance: null,
  selectedPatientForAppointment: null
};

export const userData = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_TOKEN_DATA:
      return Object.assign({}, state, {
        tokenData: action?.payload?.tokenData,
        isLoggedIn: true
      });
    case REMOVE_TOKEN_DATA:
      return Object.assign({}, state, {
        tokenData: null,
        isLoggedIn: false,
        pgId: 0
      });
    case SAVE_PGID:
      return Object.assign({}, state, {
        pgId: action?.payload?.pgId
      });
    case SAVE_CURRENT_PATIENT:
      return Object.assign({}, state, {
        currentPatient: action?.payload?.patientData,
        isCurrentPatientAvailable: true
      });
    case REMOVE_CURRENT_PATIENT:
      return Object.assign({}, state, {
        currentPatient: null,
        isCurrentPatientAvailable: false
      });
    case SAVE_FAMILY_MEMBERS_LIST:
      return Object.assign({}, state, {
        familyMembersList: action?.payload?.familyMemers
      });
    case SAVE_PATIENT_AND_FAMILY_MEMBERS_LIST:
      return Object.assign({}, state, {
        patientAndFamilyMembersList:
          action?.payload?.patientAndFamilyMembersList
      });
    case REMOVE_FAMILY_MEMBERS_LIST:
      return Object.assign({}, state, {
        familyMembersList: null
      });
    case SET_IS_FAMILY_MEMBERS_LIST_CHANGED:
      return Object.assign({}, state, {
        isFamilyMembersListChanged: action?.payload?.isChanged
      });
    case SET_IS_ONLINE_CSR_AVAILABLE:
      return Object.assign({}, state, {
        isOnlineCsrAvailable: action?.payload?.isAvailable
      });
    case SET_LOGGED_IN_PATIENT_PHOTO_ID:
      return Object.assign({}, state, {
        loggedInPatientPhotoId: action?.payload?.photoId
      });
    case SET_IS_ON_DEMAND_CAPTURE_CREDIT_OR_DEBIT_CARD_ENABLED:
      return Object.assign({}, state, {
        isOnDemandCaptureCreditOrDebitCardEnabled:
          action?.payload?.isOnDemandCaptureCreditOrDebitCardEnabled
      });
    case SET_IS_SCHEDULED_APPOINTMENT_CAPTURE_CREDIT_OR_DEBIT_CARD_ENABLED:
      return Object.assign({}, state, {
        isScheduledAppointmentCaptureCreditOrDebitCardEnabled:
          action?.payload?.isScheduledAppointmentCaptureCreditOrDebitCardEnabled
      });
    case SET_IS_PHARMACY_SELECTION_ENABLED:
      return Object.assign({}, state, {
        isPharmacySelectionEnabled: action?.payload?.isEnabled
      });
    case SET_IS_RELOAD_PATIENT_STATUS_SUMMARY:
      return Object.assign({}, state, {
        isReloadPatientStatusSummary:
          action?.payload?.isReloadPatientStatusSummary
      });
    case SET_HAS_ON_DEMAND_PROMO_CODE:
      return Object.assign({}, state, {
        hasOnDemandPromoCode: action?.payload?.hasOnDemandPromoCode
      });
    case SET_HAS_SCHEDULER_PROMO_CODE:
      return Object.assign({}, state, {
        hasSchedulerPromoCode: action?.payload?.hasSchedulerPromoCode
      });
    case SET_HAS_SECOND_OPINION_PROMO_CODE:
      return Object.assign({}, state, {
        hasSecondOpinionPromoCode: action?.payload?.hasSecondOpinionPromoCode
      });
    case SET_ON_DEMAND_CAMPAIGN_CHARGE:
      return Object.assign({}, state, {
        onDemandCampaignCharge: action?.payload?.onDemandCampaignCharge
      });
    case SET_SCHEDULER_CAMPAIGN_CHARGE:
      return Object.assign({}, state, {
        schedulerCampaignCharge: action?.payload?.schedulerCampaignCharge
      });
    case SET_SECOND_OPINION_CHARGE:
      return Object.assign({}, state, {
        secondOpinionCharge: action?.payload?.secondOpinionCharge
      });
    case SET_IS_SUBMITTED_FORM_REVIEW_ENABLED:
      return Object.assign({}, state, {
        isSubmittedFormReviewEnabled:
          action?.payload?.isSubmittedFormReviewEnabled
      });
    case SET_SMART_SCAN_CHARGE:
      return Object.assign({}, state, {
        smartScanCharge: action?.payload?.smartScanCharge
      });
    case SET_SELECTED_PATIENT_INSURANCE:
      return Object.assign({}, state, {
        selectedPatientInsurance: action?.payload?.selectedPatientInsurance
      });
    case SET_SELECTED_PATIENT_FOR_APPOINTMENT:
      return Object.assign({}, state, {
        selectedPatientForAppointment:
          action?.payload?.selectedPatientForAppointment
      });
    default:
      return state;
  }
};
