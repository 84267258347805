import React from "react";
import { formatDateTime } from "../../helpers/utils";

const ParentReport = (props) => {
  const { parentReport } = props;

  return (
    parentReport && (
      <div
        style={{
          maxWidth: "797px",
          margin: "20px auto",
          padding: "30px",
          fontFamily: "Arial, Helvetica, sans-serif",
          background: "#fff"
        }}
      >
        <div className="text-center text-primary mb-4">
          <h3 className="mb-2">
            <b>Kindergarten Oral Health Assessment (KOHA)</b>
          </h3>
          <h3 className="mb-2">
            <b>Parent Report</b>
          </h3>
        </div>
        <div>
          <p>Dear Parent or Guardian,</p>
          <p>
            Your child <b>{parentReport?.childFullName}</b> completed the state
            required Kindergarten Oral Health Assessment on{" "}
            <b>
              {formatDateTime(parentReport?.dateOfAssessment, "MM.DD.YYYY")}
            </b>
            . Enclosed is some information about the results of that assessment.
          </p>
          <p>
            The dentist or dental hygienist who completed the assessment found
            the following:
          </p>
          <div className="d-flex flex-wrap gap-3 mb-3">
            <p className="lh-base mb-0">
              <b>Untreated Decay</b> (Visible Decay Present)
            </p>
            <div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="DecayReport"
                  id="Decaylabel"
                  checked={parentReport?.untreatedDecay == 1}
                />
                <label className="form-check-label" htmlFor="Decaylabel">
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="DecayReport"
                  id="DecayNolabel"
                  checked={parentReport?.untreatedDecay == 2}
                />
                <label className="form-check-label" htmlFor="DecayNolabel">
                  No
                </label>
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap gap-3 mb-3">
            <p className="lh-base mb-0">
              <b>Caries Experience</b> (Visible decay and/or fillings present)
            </p>
            <div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="typeOfReport"
                  id="chartlabel"
                  checked={parentReport?.cariesExperience == 1}
                />
                <label className="form-check-label" htmlFor="chartlabel">
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="typeOfReport"
                  id="otherlabel"
                  checked={parentReport?.cariesExperience == 2}
                />
                <label className="form-check-label" htmlFor="otherlabel">
                  No
                </label>
              </div>
            </div>
          </div>
          <p>
            <b>Treatment Urgency:</b>
          </p>
          <div>
            <ul className="treatment-list">
              <li>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="treatmentReport"
                    id="treatment1"
                    checked={parentReport?.treatmentUrgency == 1}
                  />
                  <label className="form-check-label" htmlFor="treatment1">
                    No obvious problem found.
                  </label>
                  <span>
                    This means that you should continue with regular dental
                    check-ups.
                  </span>
                </div>
              </li>
              <li>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="treatmentReport"
                    id="treatment2"
                    checked={parentReport?.treatmentUrgency == 2}
                  />
                  <label className="form-check-label" htmlFor="treatment2">
                    Early dental care is recommended.
                  </label>
                  <span>
                    This means that you should have a dental professional
                    evaluate your child’s condition at your earliest convenience
                    to determine what their condition is and what needs to be
                    done.{" "}
                  </span>
                </div>
              </li>
              <li>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="treatmentReport"
                    id="treatment3"
                    checked={parentReport?.treatmentUrgency == 3}
                  />
                  <label className="form-check-label" htmlFor="treatment3">
                    Urgent care is needed because of signs of pain, infection,
                    swelling, or mouth sores.
                  </label>
                  <span>
                    This means that you should have a dental professional
                    evaluate your child’s condition as soon as you can to learn
                    more about their condition and what needs to be done.
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  );
};

export default ParentReport;
