import React from "react";

const UploadsDeleteModal = () => {
  return (
    <>
      {/* <!-- Delete alert Modal --> */}
      <div className="modal fade" id="deleteAlert-modal" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn-close text-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <h5 className="mb-4 text-center">
                Are you sure you want to Delete?
              </h5>
              <div className="gth-confirmation-btn mx-auto d-flex justify-content-center">
                <a
                  href="#"
                  data-bs-dismiss="modal"
                  className="btn btn-primary me-3"
                >
                  Yes
                </a>
                <a
                  href="#"
                  data-bs-dismiss="modal"
                  className="btn btn-outline-secondary"
                >
                  No
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- //Delete alert Modal --> */}
    </>
  );
};

export default UploadsDeleteModal;
