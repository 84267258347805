import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getSmartScan_Ajax } from "../../helpers/requests";
import {
  getSmartScanImageCategoryName,
  handleApiErrors,
  isEmptyArray,
  onImageError,
  showAlertDialouge
} from "../../helpers/utils";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import { smartScanCategory } from "../../constants";

const SmartScanImages = (props) => {
  const { smartScanId } = props;
  const [smartScan, setSmartScan] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (smartScanId > 0) {
      getSmartScan();
    }
  }, [smartScanId]);

  function getSmartScan() {
    if (smartScanId > 0) {
      dispatch(showLoadingSpinner());

      getSmartScan_Ajax(
        smartScanId,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (
            response &&
            response.success &&
            response.data &&
            response.data.smartScanVM
          ) {
            setSmartScan(response.data.smartScanVM);
          } else if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function getSmartScanSubmittedImage(category) {
    if (!isEmptyArray(smartScan?.smartScanUploads)) {
      let image = smartScan?.smartScanUploads?.find(
        (u) => u.category == category
      );

      return (
        <div className="col-lg-2 col-sm-4">
          <div className="position-relative">
            <figure className="mb-0">
              <img
                className="w-100 object-cover border-radius-lg"
                alt=""
                src={image.photoId}
                onError={onImageError}
              />
            </figure>
            <span className="badge bg-primary btn-xs fs-5 fw-normal m-3 position-absolute top-0 start-0">
              {getSmartScanImageCategoryName(image.category).toUpperCase()}
            </span>
          </div>
        </div>
      );
    }
  }

  return (
    <>
      {smartScan &&
      smartScan.smartScanUploads &&
      Array.isArray(smartScan.smartScanUploads) &&
      smartScan.smartScanUploads.length > 0 ? (
        <div className="row align-items-center smart-scan-request-wrapper p-3 pt-0 gy-3">
          {getSmartScanSubmittedImage(smartScanCategory.UpperArch)}
          {getSmartScanSubmittedImage(smartScanCategory.LowerArch)}
          {getSmartScanSubmittedImage(smartScanCategory.UpperLip)}
          {getSmartScanSubmittedImage(smartScanCategory.LowerLip)}
          {getSmartScanSubmittedImage(smartScanCategory.Bite)}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default SmartScanImages;
