import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const CancelPayment = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(-3);
  }, []);

  return <></>;
};

export default CancelPayment;
