import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getSmartScan_Ajax } from "../../helpers/requests";
import {
  getSmartScanImageCategoryName,
  handleApiErrors,
  isEmptyArray,
  onImageError,
  showAlertDialouge
} from "../../helpers/utils";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import { smartScanCategory } from "../../constants";

const SmartScanImagesInPrintPage = (props) => {
  const { smartScanId } = props;
  const [smartScan, setSmartScan] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (smartScanId > 0) {
      getSmartScan();
    }
  }, [smartScanId]);

  function getSmartScan() {
    if (smartScanId > 0) {
      dispatch(showLoadingSpinner());

      getSmartScan_Ajax(
        smartScanId,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (
            response &&
            response.success &&
            response.data &&
            response.data.smartScanVM
          ) {
            setSmartScan(response.data.smartScanVM);
          } else if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function getSmartScanSubmittedImage(category) {
    if (!isEmptyArray(smartScan?.smartScanUploads)) {
      let image = smartScan?.smartScanUploads?.find(
        (u) => u.category == category
      );

      return (
        <div
          className="col-6 col-sm-3 col-md-2"
          style={{ padding: "5px", position: "relative" }}
        >
          <img
            alt=""
            src={image.photoId}
            style={{ width: "100%", height: "120px", objectFit: "cover" }}
            onError={onImageError}
          />
          <span
            className="badge bg-primary btn-xs d-table ms-0 text-uppercase"
            style={{
              fontSize: "10px",
              margin: "5px",
              backgroundColor: "#3fbbeb !important",
              bottom: "-23px",
              left: "0px"
            }}
          >
            {getSmartScanImageCategoryName(image.category).toUpperCase()}
          </span>
        </div>
      );
    }
  }

  return (
    <>
      {smartScan &&
      smartScan.smartScanUploads &&
      Array.isArray(smartScan.smartScanUploads) &&
      smartScan.smartScanUploads.length > 0 ? (
        <>
          <div className="mb-3 mb-md-4">
            <div
              className="row align-items-center text-black mb-3 pb-3"
              style={{
                borderBottom: "1px solid #eee",
                fontSize: "15px"
              }}
            >
              <div className="col-8">
                <b>Smart Scan Submitted Images</b>
              </div>
            </div>

            <div className="row align-items-center text-black mb-3">
              {getSmartScanSubmittedImage(smartScanCategory.UpperArch)}
              {getSmartScanSubmittedImage(smartScanCategory.LowerArch)}
              {getSmartScanSubmittedImage(smartScanCategory.UpperLip)}
              {getSmartScanSubmittedImage(smartScanCategory.LowerLip)}
              {getSmartScanSubmittedImage(smartScanCategory.Bite)}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default SmartScanImagesInPrintPage;
