import React, { useState } from "react";
import SharedEducationModal from "./SharedEducationModal";
import { isEmptyArray } from "../../helpers/utils";

export default function CaseReportEducations({ caseReportEducations }) {
  const [sharedEducation, setSharedEducation] = useState(null);

  function showSharedEducation(sharedEducation) {
    if (sharedEducation) {
      setSharedEducation(sharedEducation);
    }
  }

  return (
    <>
      {!isEmptyArray(caseReportEducations) ? (
        <div className="form-group mt-4 mb-3 col-md-12">
          <label className="form-label">Shared Education</label>
          <table cellPadding="0" cellSpacing="0" border="0" width="100%">
            <tbody>
              {caseReportEducations.map((education) => {
                return (
                  <tr key={education.caseReportEducationId?.toString()}>
                    <td className="py-1 w-50 align-top">
                      {education.patientEducation.education.title}
                    </td>
                    <td className="py-1 w-50 align-top text-end text-md-start">
                      <a
                        href="#"
                        onClick={() => {
                          showSharedEducation(
                            education.patientEducation.education
                          );
                        }}
                        data-bs-toggle="modal"
                        data-bs-target="#educationModal"
                        className="text-decoration-none"
                      >
                        View <i className="bi bi-box-arrow-up-right"></i>
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <></>
      )}
      <SharedEducationModal sharedEducation={sharedEducation} />
    </>
  );
}
