import React from "react";
import InputMask from "react-input-mask";
import {
  validateDateOfBirth,
  validateDate,
  validateScheduleAppointmentDate
} from "../../helpers/utils";
import { ValidationTypes } from "../../constants";

const DateInputMask = (props) => {
  const {
    register,
    fieldName,
    errors,
    validationType,
    handleChange,
    excludeDependentPatientAgeLimitCheck = true,
    value,
    disabled
  } = props;

  function validateDateBasedOnType(value) {
    if (validationType == ValidationTypes.DateOfBirth) {
      return validateDateOfBirth(value, excludeDependentPatientAgeLimitCheck);
    } else if (validationType == ValidationTypes.ScheduleAppointment) {
      handleChange(fieldName, value);
      return validateScheduleAppointmentDate(value);
    } else {
      return validateDate(value);
    }
  }

  return (
    <div>
      <InputMask
        mask="99/99/9999"
        max={"9999-12-31"}
        className="form-control"
        placeholder="mm/dd/yyyy"
        maskChar={""}
        name={fieldName}
        value={value}
        {...register(fieldName, {
          required: "This field is required.",
          validate: (value) => validateDateBasedOnType(value)
        })}
        disabled={disabled}
      />
      {errors[fieldName]?.message && (
        <p className="help-block">{errors[fieldName].message}</p>
      )}
    </div>
  );
};

export default DateInputMask;
