import React from "react";
import InputMask from "react-input-mask";

const QuestionnairePage = () => {
  const showDivHandler = () => {
    console.log("hello");
  };
  const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "District Of Columbia",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakot",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming"
  ];
  return (
    <section className="questionnaire-section py-4 py-md-5">
      <div className="container max-960">
        <div className="row">
          <div className="col-sm-12">
            <div className="gth-main-header mb-3">
              <div className="heading-wrapper back-to-wrapper text-center px-5 position-relative">
                <a
                  className="back-to text-body fs-1 lh-1 d-inline-block align-top position-absolute"
                  href="options.html"
                >
                  <i className="bi bi-arrow-left lh-1 d-inline-block align-top"></i>
                </a>
                <h1 className="title">Second Opinion Questionnaire</h1>
              </div>
            </div>
            <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
              <form>
                <div className="form-group mb-3 col-md-12">
                  <div className="dropdown upload-file-dropdown">
                    <a
                      className="btn btn-outline-secondary btn-rounded btn-lg w-100"
                      href="javascript:;"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="bi bi-cloud-upload"></i> &nbsp; Upload File
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <input type="file" name="" />
                        <a
                          className="dropdown-item"
                          href="photo-review-questionnaire.html"
                        >
                          Photo Library <i className="bi bi-images"></i>
                        </a>
                      </li>
                      <li>
                        <input type="file" name="" />
                        <a className="dropdown-item" href="javascript:;">
                          Take Photo <i className="bi bi-camera"></i>
                        </a>
                      </li>
                      <li>
                        <input type="file" name="" />
                        <a className="dropdown-item" href="javascript:;">
                          Choose Files <i className="bi bi-folder2"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="form-group mt-4 mb-3 col-md-12">
                  <div className="form-group-title fs-3 mb-2 mb-md-3 lh-1">
                    Add Notes
                  </div>
                  <textarea
                    className="form-control"
                    name=""
                    id=""
                    placeholder="Add here..."
                  ></textarea>
                </div>

                <h3 className="mt-4">Second Opinion Review Questionnaire</h3>
                <div className="fs-4 text-primary mt-1 mb-3 mb-md-4">
                  Please fill out the questions below and select “Save” to
                  continue
                </div>

                <div className="form-group mt-4 mb-3 col-sm-12">
                  <div className="form-group-title fs-3 text-violet mb-2 mb-md-3 lh-1">
                    Who is this visit for?
                  </div>
                  <div className="form-check mb-2 ps-0">
                    <select
                      className="form-select form-control"
                      name="dependentsList"
                      id=""
                    >
                      <option value="" defaultValue="">
                        Sonya Berger
                      </option>
                      <option>Candy Doe</option>
                      <option>Tony Doe</option>
                      <option>Maria</option>
                    </select>
                  </div>
                  <div className="mt-2">
                    <a
                      className="text-muted"
                      href="javascript:;"
                      onClick={showDivHandler}
                    >
                      <i className="bi bi-plus"></i> Add Dependent{" "}
                      <i
                        className="bi bi-info-circle-fill text-primary ms-1"
                        aria-hidden="true"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title=""
                        data-bs-original-title="Note from VDC"
                        aria-label="Note from VDC"
                      ></i>
                    </a>
                  </div>
                  <div id="show-hide-div" className="row pt-4">
                    <div className="form-group mb-3 col-md-6">
                      <label className="form-label">First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="First name"
                      />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label className="form-label">Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last name"
                      />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label className="form-label">Select Relation</label>
                      <select
                        className="form-select form-control"
                        name="state"
                        id="state"
                      >
                        <option value="" defaultValue="">
                          Select Relation
                        </option>
                        <option value="1">Child</option>
                        <option value="2">Spouse</option>
                        <option value="3">Other</option>
                      </select>
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label className="form-label">Date of Birth</label>
                      <input
                        type="text"
                        id="number-input-date"
                        className="form-control"
                        placeholder="mm/dd/yyyy"
                        maxLength="10"
                      />
                    </div>
                    <div className="form-group mb-3 col-md-12 d-flex flex-wrap align-items-center">
                      <label className="d-inline-block mb-2">Gender</label>
                      <div className="form-check mb-2 me-2 me-md-4 d-inline-block">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="anyAllergy"
                          id="genderMale"
                        />
                        <label
                          className="form-check-label fs-4"
                          htmlFor="genderMale"
                        >
                          Male
                        </label>
                      </div>
                      <div className="form-check mb-2 me-2 me-md-4 d-inline-block">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="anyAllergy"
                          id="genderFemale"
                        />
                        <label
                          className="form-check-label fs-4"
                          htmlFor="genderFemale"
                        >
                          Female
                        </label>
                      </div>
                      <div className="form-check mb-2 d-inline-block">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="anyAllergy"
                          id="genderUnknown"
                        />
                        <label
                          className="form-check-label fs-4"
                          htmlFor="genderUnknown"
                        >
                          Unknown
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group mt-4 mb-3 col-sm-12">
                  <div className="form-group-title fs-3 text-violet mb-2 mb-md-3 lh-1">
                    Where will you be located during your visit?
                  </div>
                  <select
                    className="form-select form-control"
                    name="state"
                    id="state"
                  >
                    <option value="" defaultValue="">
                      State
                    </option>
                    {states.map((state) => (
                      <option value={state} key={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group mt-4 mb-3 col-md-12">
                  <div className="form-group-title fs-3 text-violet mb-2 mb-md-3 lh-1">
                    What is the reason for your visit?
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Tooth infection that has not been healing"
                  />
                  <div className="form-text icon">
                    <i className="bi bi-exclamation-circle-fill text-gold"></i>
                    Please seek emergency medical services if you are
                    experiencing a medical emergency (ie. chest pain, head or
                    eye injury, broken bones, difficultly breathing, etc).
                  </div>
                </div>
                <div className="form-group mt-4 mb-3">
                  <div className="form-group-title fs-3 text-violet mb-2 mb-md-3 lh-1">
                    Please select your pain level below:
                  </div>
                  <div className="form-check ps-0 mb-2">
                    <label className="form-check-label fs-4 d-inline">
                      Mild
                    </label>
                    <input
                      className="form-check-input mx-1 position-static float-none"
                      type="radio"
                      name="painLevel"
                      id="painLevel1"
                    />
                    <input
                      className="form-check-input mx-1 position-static float-none"
                      type="radio"
                      name="painLevel"
                      id="painLevel2"
                    />
                    <input
                      className="form-check-input mx-1 position-static float-none"
                      type="radio"
                      name="painLevel"
                      id="painLevel3"
                    />
                    <input
                      className="form-check-input mx-1 position-static float-none"
                      type="radio"
                      name="painLevel"
                      id="painLevel4"
                    />
                    <input
                      className="form-check-input mx-1 position-static float-none"
                      type="radio"
                      name="painLevel"
                      id="painLevel5"
                    />
                    <label className="form-check-label fs-4 d-inline">
                      Worst pain possible
                    </label>
                  </div>
                </div>
                <div className="form-group mt-4 mb-3 d-flex flex-wrap checkbox-wrapper">
                  <div className="form-group-title fs-3 text-violet mb-2 mb-md-3 lh-1 w-100">
                    Are you experiencing any of the following? (check all that
                    apply)
                  </div>
                  <div className="form-check mb-2 me-4">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="anyExp"
                      id="anyExp1"
                    />
                    <label className="form-check-label fs-4" htmlFor="anyExp1">
                      Fever
                    </label>
                  </div>
                  <div className="form-check mb-2 me-4">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="anyExp"
                      id="anyExp2"
                    />
                    <label className="form-check-label fs-4" htmlFor="anyExp2">
                      Swelling
                    </label>
                  </div>
                  <div className="form-check mb-2 me-4">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="anyExp"
                      id="anyExp3"
                    />
                    <label className="form-check-label fs-4" htmlFor="anyExp3">
                      Sores/Lesions
                    </label>
                  </div>
                  <div className="form-check mb-2 me-4">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="anyExp"
                      id="anyExp4"
                    />
                    <label className="form-check-label fs-4" htmlFor="anyExp4">
                      Dry Mouth
                    </label>
                  </div>
                  <div className="form-check mb-2 me-4">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="anyExp"
                      id="anyExp5"
                    />
                    <label className="form-check-label fs-4" htmlFor="anyExp5">
                      Sore Throat
                    </label>
                  </div>
                  <div className="form-check mb-2 me-4">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="anyExp"
                      id="anyExp6"
                    />
                    <label className="form-check-label fs-4" htmlFor="anyExp6">
                      Sensitivity
                    </label>
                  </div>
                  <div className="form-check mb-2 me-4">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="anyExp"
                      id="anyExp7"
                    />
                    <label className="form-check-label fs-4" htmlFor="anyExp7">
                      None of the above
                    </label>
                  </div>
                  <div className="form-check mb-2 me-4">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="anyExp"
                      id="anyExp"
                    />
                    <label className="form-check-label fs-4" htmlFor="anyExp">
                      Other
                    </label>
                  </div>
                  <div
                    className="box1 otherExpBox w-100"
                    style={{ display: "none" }}
                  >
                    <label className="mb-2" htmlFor="otherExpInput">
                      If yes, please list below:
                    </label>
                    <input
                      type="text"
                      id="otherExpInput"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="form-group mt-4 mb-3 d-md-flex flex-md-wrap">
                  <div className="form-group-title fs-3 text-violet mb-2 mb-md-3 lh-1">
                    Do you have any allergies?
                  </div>
                  <div className="form-check mb-2 ms-auto me-4 d-inline-block">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="anyAllergy"
                      id="allergyYes"
                      value="allergyBox"
                    />
                    <label
                      className="form-check-label fs-4"
                      htmlFor="allergyYes"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check mb-2 me-4 d-inline-block">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="anyAllergy"
                      id="allergyNo"
                    />
                    <label
                      className="form-check-label fs-4"
                      htmlFor="allergyNo"
                    >
                      No
                    </label>
                  </div>
                  <div
                    className="box1 allergyBox w-100"
                    style={{ display: "none" }}
                  >
                    <label className="mb-2" htmlFor="allergyInput">
                      If yes, please list below:
                    </label>
                    <input
                      type="text"
                      id="allergyInput"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="form-group mt-3 mb-3 d-md-flex flex-md-wrap">
                  <div className="form-group-title fs-3 text-violet mb-2 mb-md-3 lh-1">
                    Do you have any medical conditions?
                  </div>
                  <div className="form-check mb-2 ms-auto me-4 d-inline-block">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="anyMedical"
                      id="medicalYes"
                      value="medicalBox"
                    />
                    <label
                      className="form-check-label fs-4"
                      htmlFor="medicalYes"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check mb-2 me-4 d-inline-block">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="anyMedical"
                      id="medicalNo"
                    />
                    <label
                      className="form-check-label fs-4"
                      htmlFor="medicalNo"
                    >
                      No
                    </label>
                  </div>
                  <div
                    className="box2 medicalBox w-100"
                    style={{ display: "none" }}
                  >
                    <label className="mb-2" htmlFor="allergyInput">
                      If yes, please list below:
                    </label>
                    <input
                      type="text"
                      id="allergyInput"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="form-group mt-3 mb-3 d-md-flex flex-md-wrap">
                  <div className="form-group-title fs-3 text-violet mb-2 mb-md-3 lh-1">
                    Are you currently taking medications?
                  </div>
                  <div className="form-check mb-2 ms-auto me-4 d-inline-block">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="anyMedication"
                      id="mediactionYes"
                      value="mediactionBox"
                    />
                    <label
                      className="form-check-label fs-4"
                      htmlFor="mediactionYes"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check mb-2 me-4 d-inline-block">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="anyMedication"
                      id="mediactionNo"
                    />
                    <label
                      className="form-check-label fs-4"
                      htmlFor="mediactionNo"
                    >
                      No
                    </label>
                  </div>
                  <div
                    className="box3 mediactionBox w-100"
                    style={{ display: "none" }}
                  >
                    <label className="mb-2" htmlFor="allergyInput">
                      If yes, please list below:
                    </label>
                    <input
                      type="text"
                      id="allergyInput"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="form-group mt-4 mb-3 col-md-8 col-xl-6">
                  <div className="form-group-title fs-3 text-violet mb-2 mb-md-3 lh-1">
                    When was your last dental exam?
                  </div>
                  <InputMask
                    id="number-input-date2"
                    className="form-control"
                    placeholder="mm/dd/yyyy"
                    mask="99/99/9999"
                    maxLength="10"
                  />
                </div>
                <div className="form-group mt-4 mb-3 col-md-12">
                  <div className="form-check d-flex flex-wrap mb-3 w-100">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name=""
                      id="terms_condition"
                    />
                    <label
                      className="form-check-label fs-4"
                      htmlFor="terms_condition"
                    >
                      I agree that I have read and consent to the Terms and
                      Conditions of Use
                    </label>
                    <div className="mt-1">
                      <a href="javascript:;">
                        Dental.com Terms and Conditions of Use
                      </a>
                    </div>
                  </div>
                  <div className="form-check d-flex flex-wrap mb-3 w-100">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name=""
                      id="privacy_policy"
                    />
                    <label
                      className="form-check-label fs-4"
                      htmlFor="privacy_policy"
                    >
                      I agree that I have read and consent to the Privacy
                      Practices
                    </label>
                    <div className="mt-1">
                      <a href="javascript:;">
                        Dental.com Terms Notice of Privacy Practices
                      </a>
                    </div>
                  </div>
                </div>
                <div className="form-group text-center mt-2 mt-md-4 col-sm-12 button-wrapper">
                  <button
                    formAction="photo-review-questionnaire.html"
                    type="submit"
                    className="btn btn-secondary btn-rounded btn-lg mt-3 mt-md-0 w-100"
                  >
                    Save & Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default QuestionnairePage;
