import moment from "moment";
import React from "react";
import { isEmptyArray } from "../../helpers/utils";
import CaseReportAnnotations from "./CaseReportAnnotations";
import GeneralProgressNotesTable from "./GeneralProgressNotesTable";
import CaseReportVideoEducations from "./CaseReportVideoEducations";
import CaseReportEducations from "./CaseReportEducations";
import ScheduleAppointment from "./ScheduleAppointment";
import CaseReportObservations from "./CaseReportObservations";
import CaseReportAlternativeTreatments from "./CaseReportAlternativeTreatments";
import CaseReportPeriodontalAnalyses from "./CaseReportPeriodontalAnalyses";
import ObservationTypeCaseReportPatientDetails from "./ObservationTypeCaseReportPatientDetails";

export default function ObservationTypeCaseReport({
  caseReport,
  caseReportAnnotations,
  schedulerCampaign,
  includeZState,
  patientNotes
}) {
  return (
    <>
      <div className="form-group col-md-12">
        <label className="form-label mb-3">
          <strong>Patient Information</strong>
        </label>
        <div className="form-section">
          <ObservationTypeCaseReportPatientDetails caseReport={caseReport} />
        </div>
      </div>
      <div className="form-group mt-4 mb-3 col-md-12">
        {caseReport && caseReport.secondOpinion.patient ? (
          <label className="form-label">
            Second Opinion Report for
            {caseReport.secondOpinion.patient.fullName ? (
              <b> {caseReport.secondOpinion.patient.fullName}</b>
            ) : (
              <></>
            )}
          </label>
        ) : (
          <></>
        )}
      </div>

      <div>
        <p className="mb-4">
          Thank you for choosing dental.com for a second opinion review of
          {caseReport && caseReport.secondOpinion.recordRequestHistory ? (
            <b>
              {" "}
              {caseReport.secondOpinion.recordRequestHistory.dentistName +
                "'s"}{" "}
            </b>
          ) : (
            " your "
          )}
          dental records and recommended treatment plan.
        </p>
      </div>

      {!isEmptyArray(caseReportAnnotations) ? (
        <div className="form-group mt-4 mb-3 col-md-12">
          <p>
            <strong>
              The following existing situation was noted from your x-rays and
              other records:
            </strong>
          </p>
          <CaseReportAnnotations
            caseReportAnnotations={caseReportAnnotations}
          />
        </div>
      ) : (
        <></>
      )}

      {caseReport ? (
        <>
          <CaseReportAlternativeTreatments
            caseReportAlternativeTreatments={
              caseReport?.caseReportAlternativeTreatments
            }
          />
          <p>
            <strong>{caseReport.provider.fullName}</strong> has also noted
          </p>
          {!isEmptyArray(caseReport?.caseReportProgressNotes) ? (
            <>
              <div className="form-group mt-4 mb-3 col-md-12">
                <p className="mb-3">
                  <strong>General Progress Notes</strong>
                </p>
                <GeneralProgressNotesTable patientNotes={patientNotes} />
              </div>
            </>
          ) : (
            <></>
          )}

          <CaseReportObservations
            caseReportObservations={caseReport?.caseReportObservations}
          />

          <CaseReportPeriodontalAnalyses
            caseReportPeriodontalAnalyses={
              caseReport?.caseReportPeriodontalAnalyses
            }
          />

          {caseReport.generalObservation ? (
            <>
              <div className="form-group mb-4">
                <p className="mb-2">
                  <strong>General Observations</strong>
                </p>
                <p>{caseReport.generalObservation}</p>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}

      <div className="form-group mb-4">
        <p className="mb-2">
          <strong>What to do next? </strong>
        </p>
        <p>
          Your next steps depend on your feelings regarding the original
          treatment plan and your overall comfort level with the dentist who
          provided it. If you trust your dentist and feel confident in their
          recommendations, we suggest scheduling another appointment to discuss
          the alternatives outlined in our second opinion report. If cost is a
          concern, explore less expensive treatment options together.
        </p>
        <p>
          On the other hand, if you're uncertain or uncomfortable with the
          initial treatment plan, it may be beneficial to find a different
          dentist. In this case, bring along our second opinion report, discuss
          your options, and communicate your goal clearly. If your goal is to
          receive the most cost-effective yet professionally acceptable
          treatment possible, be upfront in your discussion.
        </p>
      </div>

      {caseReport ? (
        <div className="form-group mt-4 mb-3 col-md-12">
          <label className="form-label">
            <b>Photo Review Report</b>
          </label>
          <div className="d-flex flex-wrap align-items-center mt-1">
            <div>
              <img
                className="img-70 rounded-circle"
                src={caseReport.provider.photoId}
                alt=""
              />
            </div>
            <div className="flex-1 ps-3">
              <div>{caseReport.provider.fullName}</div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {caseReport ? (
        <CaseReportVideoEducations
          caseReportVideoEducations={caseReport?.caseReportVideoEducations}
        />
      ) : (
        <></>
      )}
      {caseReport ? (
        <CaseReportEducations
          caseReportEducations={caseReport?.caseReportEducations}
        />
      ) : (
        <></>
      )}
      <ScheduleAppointment
        schedulerCampaign={schedulerCampaign}
        caseReport={caseReport}
        includeZState={includeZState}
      />
    </>
  );
}
