import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

// import UI components
import Navbar from "../../components/Navbar/Navbar";
import AppointmentScheduleTab from "../../components/AppointmentSchedule/AppointmentScheduleTab";
import AppointmentScheduleDateTime from "./../../components/AppointmentSchedule/AppointmentScheduleDateTime";
import AppointmentReschedulePage from "../../components/AppointmentReschedule/AppointmentReschedulePage";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

// import http requests
import { updateOnDemandConsultation_Ajax } from "../../helpers/requests";

// import actions
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";

// import helpers
import {
  handleApiErrors,
  getLocalTimezoneOffset,
  showConfirmDialouge,
  isValidScheduledDate,
  showAlertDialouge,
  formatDateTime
} from "../../helpers/utils";
import { useAlert } from "react-alert";
import { DateTimeFormats } from "../../constants";

const AppointmentReschedule = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();

  const [scheduledDateTime, setScheduledDateTime] = useState(null);
  const [inputs, setInputs] = useState({
    date: "",
    hour: "",
    minute: "",
    meridiem: "AM"
  });

  useEffect(() => {
    const { date, hour, minute, meridiem } = inputs;

    if (date && hour && minute && meridiem) {
      var dateTimeString = date + " " + hour + ":" + minute + " " + meridiem;
      setScheduledDateTime(
        formatDateTime(dateTimeString, DateTimeFormats.YYYY_MM_DD_hh_mm_A)
      );
    }
  }, [inputs]);

  useEffect(() => {
    if (state.existingScheduledDateTime) {
      var dateObj = moment.utc(state.existingScheduledDateTime).local();

      var date = dateObj.format(DateTimeFormats.MM_DD_YYYY);
      var hour = dateObj.format("h");
      var minute = dateObj.format("m");
      var meridiem = dateObj.format("A");

      var existingData = {
        date,
        hour,
        minute,
        meridiem
      };

      setInputs(existingData);
    }
  }, [state.existingScheduledDateTime]);

  function isInputDateValid() {
    if (scheduledDateTime) {
      if (!isValidScheduledDate(scheduledDateTime)) {
        showAlertDialouge(
          "Error",
          "Please select a date and time at least one hour from now."
        );
      } else {
        return true;
      }
    }
  }

  function rescheduleAppointment() {
    if (!isInputDateValid()) {
      return;
    }

    if (state && state.consultationId && scheduledDateTime) {
      showConfirmDialouge(
        "Are you sure?",
        "You are about to reschedule this appointment",
        function () {
          const { consultationId } = state;

          var dateTimeString = moment(
            scheduledDateTime,
            "YYYY-MM-DD HH:mm a"
          ).format("YYYY-MM-DD HH:mm a");
          var localTimeZoneOffset = getLocalTimezoneOffset();

          var data = {
            id: consultationId,
            scheduledDateTime: dateTimeString,
            localTimeZoneOffset: localTimeZoneOffset
          };

          dispatch(showLoadingSpinner());
          updateOnDemandConsultation_Ajax(
            JSON.stringify(data),
            function (response) {
              dispatch(hideLoadingSpinner());

              if (response && response.success) {
                showAlertDialouge(
                  "Message",
                  "Your appointment has been rescheduled successfully",
                  function () {
                    navigate("/dashboard");
                  }
                );
              } else if (response && !response.success && response.message) {
                showAlertDialouge("Error", response.message);
              }
            },
            function (err) {
              dispatch(hideLoadingSpinner());
              handleApiErrors(err);
            }
          );
        }
      );
    } else {
      showAlertDialouge("Error", "Appointment not found");
    }
  }

  return (
    <>
      <body>
        <section className="step-section py-4 py-md-5">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
                  <AppointmentReschedulePage
                    inputs={inputs}
                    setInputs={setInputs}
                    rescheduleAppointment={rescheduleAppointment}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- scripts --> */}
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js"
          integrity="sha512-894YE6QWD5I59HgZOGReFYm4dnWc1Qt5NtvYSaNcOP+u1T9qYdvdihz0PPSiiqn/+/3e7Jo4EaG7TubfWGUrMQ=="
          crossOrigin="anonymous"
          referrerPolicy="no-referrer"
        ></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/signature_pad/1.3.2/signature_pad.min.js"></script>
        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"
          integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"
          crossOrigin="anonymous"
        ></script>
      </body>
    </>
  );
};

export default AppointmentReschedule;
