import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import Navbar from "../../components/Navbar/Navbar";
import VisitHistoryTable from "../../components/VisitHistory/VisitHistoryTable";
import { getPatientInfo } from "../../helpers/authManager";
import {
  getAppointments_Ajax,
  getOnDemandConsultations_Ajax,
  getAssociatedOnDemandCampaigns_Ajax,
  getCampaign_Ajax
} from "../../helpers/requests";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import moment from "moment";
import { handleApiErrors } from "../../helpers/utils";
import { ConsultationStatus, SortOrders } from "../../constants";

const VisitHistory = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const [utcOffset, setUtcOffset] = useState(0);
  const [isDST, setIsDST] = useState(false);

  const [patientOnDemandConsultations, setPatientOnDemandConsultations] =
    useState(null);
  const [totalOnDemandConsultationsCount, setTotalOnDemandConsultationsCount] =
    useState(0);

  const [followUpConsultations, setFollowUpConsultations] = useState([]);
  const [totalFollowUpConsultationsCount, setTotalFollowUpConsultationsCount] =
    useState(0);

  const currentPatient = useSelector(
    (state) => state?.userData?.currentPatient
  );

  const [reloadVisitHistory, setReloadVisitHistory] = useState(false);

  useEffect(() => {
    if (currentPatient) {
      setReloadVisitHistory(true);
    }
  }, [currentPatient]);

  function getOnDemandConsultations(currentPageNum, itemPerPage) {
    var patientInfo = currentPatient;

    if (patientInfo && patientInfo.patid && currentPageNum && itemPerPage) {
      let params = {
        patientId: patientInfo.patid,
        itemPerPage: itemPerPage,
        currentPageNum: currentPageNum,
        statuses: "5",
        sendAllRows: false,
        orderByProviderJoined: 2,
        sortingOrder: 2,
        includePostCallReferralSuggestionCount: true
      };

      dispatch(showLoadingSpinner());
      getOnDemandConsultations_Ajax(
        params,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (
            response &&
            response.success &&
            response.data.consultations &&
            Array.isArray(response.data.consultations) &&
            response.data.consultations.length > 0
          ) {
            if (response.data.totalCount) {
              setTotalOnDemandConsultationsCount(response.data.totalCount);
            }
            setPatientOnDemandConsultations(response.data);
          } else {
            setTotalOnDemandConsultationsCount(0);
            setPatientOnDemandConsultations([]);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function getFollowUpConsultations(currentPageNum, itemPerPage) {
    var patientInfo = currentPatient;

    if (patientInfo && patientInfo.patid && currentPageNum && itemPerPage) {
      var params = {
        patientId: patientInfo.patid,
        itemPerPage: itemPerPage,
        currentPageNum: currentPageNum,
        sendAllRows: false,
        statuses: ConsultationStatus.FollowUp,
        sortingOrder: SortOrders.Ascending
      };

      dispatch(showLoadingSpinner());
      getOnDemandConsultations_Ajax(
        params,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (
            response &&
            response.success &&
            response.data.consultations &&
            Array.isArray(response.data.consultations) &&
            response.data.consultations.length > 0
          ) {
            setFollowUpConsultations(response.data.consultations);

            if (response.data.totalCount) {
              setTotalFollowUpConsultationsCount(response.data.totalCount);
            }
          } else {
            setFollowUpConsultations([]);
            setTotalFollowUpConsultationsCount(0);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  return (
    <>
      <body>
        <VisitHistoryTable
          patientOnDemandConsultations={patientOnDemandConsultations}
          totalOnDemandConsultationsCount={totalOnDemandConsultationsCount}
          getOnDemandConsultations={getOnDemandConsultations}
          followUpConsultations={followUpConsultations}
          totalFollowUpConsultationsCount={totalFollowUpConsultationsCount}
          getFollowUpConsultations={getFollowUpConsultations}
          utcOffset={utcOffset}
          isDST={isDST}
          currentPatient={currentPatient}
          reloadVisitHistory={reloadVisitHistory}
          setReloadVisitHistory={setReloadVisitHistory}
        />
        {/* <!-- scripts --> */}
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js"
          integrity="sha512-894YE6QWD5I59HgZOGReFYm4dnWc1Qt5NtvYSaNcOP+u1T9qYdvdihz0PPSiiqn/+/3e7Jo4EaG7TubfWGUrMQ=="
          crossOrigin="anonymous"
          referrerPolicy="no-referrer"
        ></script>
        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"
          integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"
          crossOrigin="anonymous"
        ></script>
      </body>
    </>
  );
};

export default VisitHistory;
