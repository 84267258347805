import { useSelector } from "react-redux";
import useGetPatientInsuranceInfo from "./useGetPatientInsuranceInfo";
import { getCarrierIdFromInsurance, isEmptyArray } from "../../helpers/utils";
import useGetInsuranceCarrierOptions from "./useGetInsuranceCarrierOptions";
import { useEffect, useState } from "react";
import { ContractedTypes } from "../../constants";

const useIsParTypeCarrieSelected = (patientId, selectedCarrierId) => {
  const insuranceInfo = useGetPatientInsuranceInfo(patientId);
  const insuranceCarrier = useGetInsuranceCarrierOptions();
  const parCarrierCheckEnabled = useSelector((state) =>
    state?.appSettings?.settingsData?.ParCarrierCheckedEnabled
  );
  const [parTypeCarrierIds, setParTypeCarrierIds] = useState([]);

  const populateParTypeCarrier = () => {
    if (!isEmptyArray(insuranceCarrier)) {
      setParTypeCarrierIds(
        insuranceCarrier
          .filter((a) => a.contractedType == ContractedTypes.Par)
          .map((a) => a.id)
      );
    }
  };

  useEffect(() => {
    populateParTypeCarrier();
  }, [insuranceCarrier]);

  let carrierId = selectedCarrierId > 0 ? selectedCarrierId : 0;

  const isParTypeCarrierSelected = () => {
    if (parCarrierCheckEnabled) {
      if (carrierId <= 0 && insuranceInfo?.carrierName) {
        carrierId = getCarrierIdFromInsurance(insuranceInfo, insuranceCarrier);
      }
      return parTypeCarrierIds.includes(carrierId);
    }
    return false;
  };

  const isCarrierInfoAvailable = () => {
    return insuranceCarrier?.length > 0;
  };

  return [isParTypeCarrierSelected, isCarrierInfoAvailable];
};

export default useIsParTypeCarrieSelected;
