import React, { useEffect, useState } from "react";
import { Form } from "@formio/react";
import "formiojs/dist/formio.builder.min.css";

import { formatDateTimeWithZone, toLocal } from "../../helpers/utils";
import { DateTimeFormats, SignatureType } from "../../constants";
import AttachedSignature from "../Print/AttachedSignature";

const FormPrintPage = (props) => {
  const { formData, historyData } = props;
  const [isScribeTypeSignature, setIsScribeTypeSignature] = useState(false);

  useEffect(() => {
    setIsScribeTypeSignature(
      historyData?.signatureType == SignatureType.Scribe
    );
  }, [historyData]);

  function getDateTime() {
    var time = "";

    if (historyData?.modifiedOn) {
      time = toLocal(historyData.modifiedOn);
    } else if (historyData?.createdOn) {
      time = toLocal(historyData.createdOn);
    }

    if (time) {
      return formatDateTimeWithZone(
        toLocal(time),
        DateTimeFormats.MMM_DD_YYYY + " [at] " + DateTimeFormats.HH_MM_A
      );
    }
  }

  useEffect(() => {
    if (document.getElementById("signaturePreview") && historyData) {
      let submissionData = JSON.parse(historyData?.formData);
      document.getElementById("signaturePreview").value =
        submissionData?.signature;
    }
  });

  function renderForm() {
    if (formData && historyData) {
      var formComponents = JSON.parse(formData?.components);
      var submissionData = JSON.parse(historyData?.formData);

      // form components
      var formObj = {
        display: "form",
        components: formComponents
      };

      // submitted data
      var submissionData = {
        data: JSON.parse(historyData?.formData)
      };

      // form display options
      var formOptions = {
        readOnly: true,
        viewAsHtml: true
      };

      return (
        <div id="form-body">
          <div
            style={{
              background: "#eee",
              fontSize: "14px",
              padding: "12px 15px",
              fontWeight: "600",
              marginTop: "10px",
              marginBottom: "20px",
              borderRadius: "5px"
            }}
          >
            <p style={{ marginBottom: "2px" }}>Form title: {formData?.title}</p>
            <p style={{ marginBottom: "2px" }}>
              Submitted by: {historyData?.createdUser?.fullName}
            </p>
            <p style={{ marginBottom: "2px" }}>{getDateTime()}</p>
          </div>
          <Form
            form={formObj}
            submission={submissionData}
            options={formOptions}
          />
          <AttachedSignature
            isSignatureAttached={historyData?.isSignatureAttached}
            attachedSignature={historyData?.attachedSignature}
            isScribeTypeSignature={isScribeTypeSignature}
          />
        </div>
      );
    }
  }

  return (
    <>
      <div
        id="form-print-section"
        style={{
          maxWidth: "700px",
          color: "#5D6267",
          margin: "20px auto",
          padding: "20px 30px",
          fontFamily: "Arial, Helvetica, sans-serif",
          background: "#fff",
          minHeight: "600px"
        }}
      >
        {renderForm()}
      </div>
    </>
  );
};

export default FormPrintPage;
