import React, { useState, useEffect } from "react";
import { copyToClipboard, showAlertDialouge } from "../../helpers/utils";

const UnsupportedBrowserModal = (props) => {
  const { message, Url, okBtnCallback } = props;
  const [hasCopiedText, setHasCopiedText] = useState(false);

  useEffect(() => {
    if (hasCopiedText) {
      setTimeout(function () {
        setHasCopiedText(false);
      }, 2000);
    }
  }, [hasCopiedText]);

  useEffect(() => {
    let unsupporedBrowserModal = document.getElementById(
      "modal-unsupported-browser"
    );
    if (unsupporedBrowserModal) {
      unsupporedBrowserModal.addEventListener("hidden.bs.modal", function () {
        resetModal();
        if (okBtnCallback) {
          okBtnCallback();
        }
      });
    }
  }, []);

  const OnCopyBtnClicked = () => {
    copyToClipboard(
      Url,
      function () {
        setHasCopiedText(true);
      },
      function () {
        showAlertDialouge("Error", "Failed to copy", function () {
          window.location.reload();
        });
      }
    );
  };

  function resetModal() {
    setHasCopiedText(false);
  }

  function hideModal() {
    window.$("#modal-unsupported-browser").modal("hide");
  }

  return (
    <>
      <div
        className="modal fade position-fixed"
        id="modal-unsupported-browser"
        data-backdrop="static"
        data-keyboard="false"
        role="dialog"
      >
        <div className="modal-dialog modal-md modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <h2>Message</h2>
              <button
                type="button"
                className="btn-close text-white"
                aria-label="Close"
                onClick={hideModal}
              ></button>
            </div>
            <div className="modal-body pt-0 pb-4">
              <p>{message}</p>
              {Url ? (
                <div className="form-group d-flex my-4">
                  <span
                    role="button"
                    tabIndex="0"
                    className="form-control111 d-flex flex-wrap align-content-center px-2 py-0 border-end-0 w-auto rounded-0 rounded-start"
                    style={{ border: "1px solid var(--colorBorder)" }}
                  >
                    <i className="bi bi-link-45deg fs-3"></i>
                  </span>

                  <input
                    className="form-control flex-grow-1 ps-0 border-start-0 border-end-0 rounded-0 pe-none"
                    type="text"
                    defaultValue={Url}
                  />
                  <button
                    className="form-control btn-secondary text-white px-3 border-0 w-auto rounded-0 rounded-end"
                    type="button"
                    onClick={OnCopyBtnClicked}
                  >
                    {hasCopiedText ? "Copied!" : "Copy"}
                  </button>
                </div>
              ) : (
                <></>
              )}

              <div className="gth-confirmation-btn mx-auto d-flex justify-content-center">
                <button
                  href="#"
                  data-bs-dismiss="modal"
                  className="btn btn-primary px-4 px-md-5"
                  onClick={hideModal}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
        <p
          className={
            "copy-confirm-footer bg-secondary text-white " +
            (hasCopiedText ? "active" : "")
          }
        >
          URL has been copied to the clipboard
        </p>
      </div>
    </>
  );
};

export default UnsupportedBrowserModal;
