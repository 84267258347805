import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import Navbar from "./../../components/Navbar/Navbar";
import SeeDentistTabs from "../../components/SeeDentist/SeeDentistTabs";
import SeeDentistForm from "../../components/SeeDentist/SeeDentistForm";

// import http requests
import { getCampaign_Ajax } from "../../helpers/requests";

// import actions
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";

// import helpers
import {
  handleApiErrors,
  queryStringToJSON,
  getCampaignStatesOfType,
  isCampaignShareStateAvailable
} from "../../helpers/utils";
import {
  setIsOnDemandCaptureCreditOrDebitCardEnabled,
  setIsPharmacySelectionEnabled,
  setIsSubmittedFormReviewEnabled
} from "../../redux/actions/userData";
import {
  CampaignStateSettingTypes,
  DentalDotComPortalType
} from "../../constants";

const SeeDentist = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const onDemandCampaignId = useSelector(
    (state) => state?.appSettings?.settingsData?.onDemandCampaignId
  );

  // campaign data
  const [onDemandCampaign, setOnDemandCampaign] = useState(null);
  const [campaignForms, setCampaignForms] = useState([]);
  const [sharedStates, setSharedStates] = useState([]);
  const [declinedStates, setDeclinedStates] = useState([]);
  const [shouldUseBDPhoneNumber, setShouldUseBDPhoneNumber] = useState(false);

  useEffect(() => {
    getCampaign(onDemandCampaignId);
  }, [onDemandCampaignId]);

  function prepareAndSetCampaignForms(campaignForms) {
    if (
      campaignForms &&
      Array.isArray(campaignForms) &&
      campaignForms.length > 0
    ) {
      var arr = [];

      campaignForms.forEach((form) => {
        if (form) {
          arr.push({
            title: form.medicalForm?.title,
            formComponent: form.medicalForm?.components,
            medicalFormId: form.formId,
            isSubmitted: false
          });
        }
      });

      setCampaignForms(arr);
    }
  }

  function getSmartScanId() {
    if (state?.smartScanId) {
      return state.smartScanId;
    }

    let params = queryStringToJSON();
    if (params?.smartscanid) {
      return parseInt(params.smartscanid);
    }
  }

  function getSelectedPatientId() {
    if (state?.patientId) {
      return state.patientId;
    }

    let params = queryStringToJSON();
    if (params?.patientid) {
      return parseInt(params.patientid);
    }
  }

  function getCampaign(campaignId) {
    var params = queryStringToJSON();
    var includeZState = false;
    if (params.includezstate) {
      if (params.includezstate == "true") {
        includeZState = true;
      }
    }

    if (params?.shouldusebdphonenumber) {
      setShouldUseBDPhoneNumber(params.shouldusebdphonenumber);
    }
    if (campaignId) {
      var params = {
        campaignId: campaignId,
        includeIntakeForm: false,
        includeZState: includeZState,
        portalType: DentalDotComPortalType
      };

      dispatch(showLoadingSpinner());
      getCampaign_Ajax(
        params,
        function (response) {
          dispatch(hideLoadingSpinner());

          if (response && response.success && response.data) {
            if (response.data.campaignForms) {
              prepareAndSetCampaignForms(response.data.campaignForms);
            }

            if (response.data.campaignStates) {
              setSharedStates(isCampaignShareStateAvailable(response?.data));

              let declineState = getCampaignStatesOfType(
                CampaignStateSettingTypes.Declined,
                response.data.campaignStates
              );

              setDeclinedStates(declineState);

              response.data.campaignStates = getCampaignStatesOfType(
                CampaignStateSettingTypes.Assigned,
                response.data.campaignStates
              );

              if (
                response.data?.isStateAvailabilityandInsuranceCheckEnabled &&
                response.data.campaignStates &&
                declineState
              ) {
                response.data.campaignStates =
                  response.data.campaignStates.concat(declineState);
              }
            }

            setOnDemandCampaign(response.data);

            if (response.data.isCaptureCreditOrDebitCardEnabled) {
              dispatch(setIsOnDemandCaptureCreditOrDebitCardEnabled(true));
            } else {
              dispatch(setIsOnDemandCaptureCreditOrDebitCardEnabled(false));
            }

            if (response.data.isPharmacySelectionEnabled) {
              dispatch(setIsPharmacySelectionEnabled(true));
            } else {
              dispatch(setIsPharmacySelectionEnabled(false));
            }

            dispatch(
              setIsSubmittedFormReviewEnabled(
                response.data.isSubmittedFormReviewEnabled
              )
            );
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  return (
    <>
      <body>
        <section className="step-section py-4 py-md-5">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
                  <SeeDentistTabs />
                  <SeeDentistForm
                    onDemandCampaign={onDemandCampaign}
                    campaignForms={campaignForms}
                    setCampaignForms={setCampaignForms}
                    setOnDemandCampaign={setOnDemandCampaign}
                    sharedStates={sharedStates}
                    selectedPatientId={getSelectedPatientId()}
                    smartScanId={getSmartScanId()}
                    declinedStates={declinedStates}
                    shouldUseBDPhoneNumber={shouldUseBDPhoneNumber}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js"
          integrity="sha512-894YE6QWD5I59HgZOGReFYm4dnWc1Qt5NtvYSaNcOP+u1T9qYdvdihz0PPSiiqn/+/3e7Jo4EaG7TubfWGUrMQ=="
          crossOrigin="anonymous"
          referrerPolicy="no-referrer"
        ></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/signature_pad/1.3.2/signature_pad.min.js"></script>
        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"
          integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"
          crossOrigin="anonymous"
        ></script>
      </body>
    </>
  );
};

export default SeeDentist;
