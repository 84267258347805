import React from "react";
import { useLocation } from "react-router-dom";

import QuestionnaireUploadRecordPage from "../../components/QuestionnaireUpdateRecord/QuestionnaireUploadRecordPage";

const QuestionnaireUploadRecord = () => {
  const { state } = useLocation();

  return (
    <>
      <body className="mobile-body">
        <QuestionnaireUploadRecordPage
          secondOpinionId={state?.secondOpinionId}
        />
      </body>
    </>
  );
};
export default QuestionnaireUploadRecord;
