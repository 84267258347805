import React from "react";
import "./Login.module.css";
import "../../assets/css/style.css";

import Navbar from "../../components/Navbar/Navbar";
import LoginForm from "../../components/Login/Login";

export default function Login() {
  return (
    <>
      <body>
        <section className="account-section sign-in-section py-4 py-md-5 v-middle min-vh-100">
          <LoginForm />
        </section>

        {/* <!-- scripts --> */}
        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"
          integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"
          crossOrigin="anonymous"
        >
          {" "}
        </script>
      </body>
    </>
  );
}
