import { createStore, applyMiddleware } from "redux";
import { rootReducer } from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension";

export default function configureStore() {
  function logger({ getState }) {
    return (next) => (action) => {
      //console.log('will dispatch', action);
      const returnValue = next(action);

      //console.log('state after dispatch', getState());
      return returnValue;
    };
  }

  // Combine middleware and DevTools extension
  const composedEnhancers = composeWithDevTools(applyMiddleware(logger));

  let store = createStore(rootReducer, composedEnhancers);
  return store;
}
