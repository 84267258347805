import React, { useEffect, useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Link, useNavigate } from "react-router-dom";
import InputMask from "react-input-mask";
import { useForm, Controller } from "react-hook-form";
import { useState } from "react";
import {
  isFutureDate,
  isPastDate,
  isPatientDateOfBirthValid,
  isValidDate,
  showAlertDialouge
} from "../../helpers/utils";
import moment from "moment";
import {
  DateTimeFormats,
  RecordForOptions,
  RequestorPatientRelations,
  SecondOpinionConsentFormFields,
  ValidationTypes
} from "../../constants";
import { getPatientInfo } from "../../helpers/authManager";
import DateInputMask from "../DateInput/DateInputMask";
import { saveSecondOpinionRecordRequestProgress } from "../../indexedDbHelper/secondOpinionRecordRequestProgressIndexedDbHelper";
import useGetSecondOpinionRecordRequestProgress from "../CustomHooks/useGetSecondOpinionRecordRequestProgress";

const ConsentFormPage = (props) => {
  const { recordRequestProviderData, patientId } = props;
  const savedProgress = useGetSecondOpinionRecordRequestProgress();

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    control,
    formState: { errors, isValid },
    reset
  } = useForm({
    mode: "onChange",
    defaultValues: {
      recordFor: RecordForOptions.Myself,
      signatureDate: moment().format(DateTimeFormats.MM_DD_YYYY)
    }
  });

  let sigCanvas = useRef({});
  const [recordFor, setRecordFor] = useState("");

  useEffect(() => {
    populateConsentForm(savedProgress);
  }, [savedProgress]);

  function populateConsentForm(savedProgress) {
    if (savedProgress?.consentForm) {
      setValue(
        SecondOpinionConsentFormFields.DateOfBirth,
        savedProgress?.consentForm?.formData?.dateOfBirth,
        { shouldValidate: true }
      );

      setValue(
        SecondOpinionConsentFormFields.DentistOrPracticeName,
        savedProgress?.consentForm?.formData?.dentistOrPracticeName,
        { shouldValidate: true }
      );

      setValue(
        SecondOpinionConsentFormFields.PatientName,
        savedProgress?.consentForm?.formData?.patientName,
        { shouldValidate: true }
      );

      setValue(
        SecondOpinionConsentFormFields.RecordFor,
        savedProgress?.consentForm?.formData?.recordFor,
        { shouldValidate: true }
      );

      setValue(
        SecondOpinionConsentFormFields.Relation,
        savedProgress?.consentForm?.formData?.relation,
        { shouldValidate: true }
      );

      setValue(
        SecondOpinionConsentFormFields.Requestor,
        savedProgress?.consentForm?.formData?.requestor,
        { shouldValidate: true }
      );

      setValue(
        SecondOpinionConsentFormFields.SignatureDate,
        savedProgress?.consentForm?.formData?.signatureDate,
        { shouldValidate: true }
      );

      if (sigCanvas?.current) {
        let canvas = sigCanvas.current.getCanvas();
        let { height, width } = canvas;
        sigCanvas.current.fromDataURL(
          savedProgress?.consentForm?.formData?.signatureDataURL,
          { height: height, width: width }
        );
      }
    }
  }

  const clear = () => {
    sigCanvas.current.clear();
    setValue(SecondOpinionConsentFormFields.SignatureDataURL, "");
  };

  const formatIntoPng = () => {
    if (sigCanvas.current) {
      const dataURL = sigCanvas.current.toDataURL();
      return dataURL;
    }
  };

  function onSubmit(formData) {
    if (formData) {
      var dobValidityCheck = isPatientDateOfBirthValid(formData.dateOfBirth);
      if (dobValidityCheck.valid) {
        var consentFormData = { formData: formData };

        saveSecondOpinionRecordRequestProgress(
          {
            ...savedProgress,
            consentForm: consentFormData
          },
          patientId
        );

        navigate("/questionnaire-review-submit", {
          state: {
            consentForm: consentFormData,
            recordRequestProviderData,
            patientId
          }
        });
      } else {
        if (!dobValidityCheck.valid) {
          var errorMessage = dobValidityCheck.errorMessage
            ? dobValidityCheck.errorMessage
            : "Please enter a valid date of birth.";
          showAlertDialouge("Error", errorMessage);
        }
      }
    }
  }

  function handleRecordForRadioBtnClick(event) {
    if (event && event.target.value) {
      setRecordFor(event.target.value);

      if (event.target.value == RecordForOptions.Myself) {
        setValue(SecondOpinionConsentFormFields.PatientName, "");
      }
    }
  }

  return (
    <>
      <div className="container">
        <div className="row" style={{ textAlign: "left" }}>
          <div className="col-sm-12">
            <div className="gth-main-header mb-3">
              <div className="heading-wrapper back-to-wrapper position-relative">
                <h1 className="title">Consent Form</h1>
              </div>
            </div>
            <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
              <form className="consent-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="fs-2 text-center mb-4 mb-md-5">
                  Request and Authorization For Release of Dental Records
                </div>
                <div className="row">
                  <div className="form-group mb-3 col-sm-12">
                    <label className="form-label fw-bold">
                      Section 1 - Request and Authorization
                    </label>
                    <p>
                      I,{" "}
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Requestor"
                        {...register(SecondOpinionConsentFormFields.Requestor, {
                          required: true
                        })}
                      />
                      <span className="text-danger">*</span> (“Requestor”),
                      hereby requests a copy of:
                    </p>
                    <ul className="check-list list-unstyled my-2">
                      <li className="mb-2 me-3 me-md-3 me-lg-5 d-inline-block">
                        <div className="form-check d-flex flex-wrap w-100">
                          <label
                            className="form-check-label fs-body"
                            htmlFor="dental_records1"
                          >
                            <input
                              className="form-check-input"
                              type="radio"
                              name="dental_records"
                              id="dental_records1"
                              value={RecordForOptions.Myself}
                              {...register(
                                SecondOpinionConsentFormFields.RecordFor,
                                { required: true }
                              )}
                              onClick={handleRecordForRadioBtnClick}
                            />
                            My dental records,
                          </label>
                        </div>
                      </li>
                      <li className="mb-2 me-3 me-md-3 me-lg-5 d-inline-block">
                        Or
                      </li>
                      <li className="mb-2 d-inline-block">
                        <div className="form-check d-flex flex-wrap w-100">
                          <label
                            className="form-check-label fs-body w-auto"
                            htmlFor="dental_records2"
                          >
                            <input
                              className="form-check-input"
                              type="radio"
                              name="dental_records"
                              id="dental_records2"
                              value={RecordForOptions.Other}
                              {...register(
                                SecondOpinionConsentFormFields.RecordFor,
                                { required: true }
                              )}
                              onClick={handleRecordForRadioBtnClick}
                            />
                            the dental records of &nbsp;
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Patient's Name"
                            disabled={recordFor != RecordForOptions.Other}
                            {...register(
                              SecondOpinionConsentFormFields.PatientName,
                              { required: recordFor == RecordForOptions.Other }
                            )}
                          />
                          <span className="text-danger">*</span>
                        </div>
                      </li>
                    </ul>
                    <p>
                      (Patient’s Date of Birth:
                      <span className="text-danger">*</span>) &nbsp;{" "}
                      <DateInputMask
                        register={register}
                        fieldName={SecondOpinionConsentFormFields.DateOfBirth}
                        errors={errors}
                        validationType={ValidationTypes.DateOfBirth}
                      />
                    </p>
                  </div>
                  <div className="form-group mb-3 col-sm-12">
                    <label className="form-label fw-bold">
                      Section 2 - Scope of Request
                    </label>
                    <p>
                      This request includes but is not limited to all diagnosis,
                      treatment plans, x-rays, lab test results, treatment,
                      treatment plans, cost estimates and billing records, for
                      all findings and conditions included in or related to my
                      most recent treatment plan(s).
                    </p>
                  </div>
                  <div className="form-group mb-3 col-sm-12">
                    <label className="form-label fw-bold">
                      Section 3 - Form and Format of Records and Delivery{" "}
                    </label>
                    <p>
                      In accordance with HIPAA regulations (45 CFR Sec. 164.524
                      (c)(2)(i) & (c)(3)(ii)), I authorize and direct &nbsp;
                      <input
                        style={{ minWidth: "220px" }}
                        type="text"
                        className="form-control"
                        placeholder="Dentist's Name or Practice"
                        {...register(
                          SecondOpinionConsentFormFields.DentistOrPracticeName,
                          { required: true }
                        )}
                      />
                      <span className="text-danger">*</span>,
                    </p>
                    <p>
                      to provide the records in Electronic format and that they
                      be delivered Electronically to my HIPAA secure dental.com
                      patient portal utilizing the dental.com link referenced in
                      the e-mail accompanying this Request.
                    </p>
                  </div>
                  <div className="form-group mb-3 col-sm-12">
                    <label className="form-label fw-bold">
                      Section 4 - Purpose
                    </label>
                    <p>
                      The purpose of this request is to obtain a second opinion.
                    </p>
                  </div>
                  <div className="form-group mb-3 col-sm-12">
                    <label className="form-label fw-bold">
                      Section 5 – Acknowledgments
                    </label>
                    <p>
                      Requestor hereby understands and acknowledges that: (i) he
                      or she has the right to revoke this Request and
                      Authorization at any time by contacting customer service
                      at dental.com.
                    </p>
                  </div>
                  <div className="form-group mb-3 col-sm-12">
                    <label className="form-label fw-bold">
                      Section 6 – Duration of Authorization
                    </label>
                    <p>
                      This authorization is effective as of the date signed and
                      for a period of 60 days thereafter.
                    </p>
                  </div>
                  <div className="form-group mb-3 col-sm-12">
                    <label className="form-label fw-bold">
                      Section 7 – Signature of Requestor and Date.
                    </label>
                    <div className="d-flex align-items-end flex-wrap mt-3">
                      <div className="d-flex111 mb-3 me-md-3">
                        <span className="flex-shrink-1 me-2">Signature</span>

                        <div className="mt-2">
                          <div className="canvas-wrapper d-table position-relative">
                            <Controller
                              control={control}
                              name={
                                SecondOpinionConsentFormFields.SignatureDataURL
                              }
                              render={({ field }) => (
                                <>
                                  <SignatureCanvas
                                    ref={sigCanvas}
                                    onEnd={() => {
                                      field.onChange(formatIntoPng());
                                    }}
                                    penColor="black"
                                    backgroundColor="#e6e6e6"
                                    canvasProps={{
                                      height: 130,
                                      width: 350,
                                      className: "sigCanvas"
                                    }}
                                  />
                                  <a
                                    className="refresh-icon d-flex flex-wrap justify-content-center align-content-center position-absolute
                                                                        start-0 top-0"
                                    onClick={clear}
                                  >
                                    <i className="bi bi-arrow-clockwise"></i>
                                  </a>
                                </>
                              )}
                            />
                          </div>
                          <div className="form-group-title fs-5 text-muted mb-2 mb-md-3 lh-1">
                            Patient / Responsible Party Signature
                          </div>
                        </div>
                      </div>
                      <div className="d-flex mb-3 me-md-3">
                        <span className="flex-shrink-1 me-2">
                          Date<span className="text-danger">*</span>
                        </span>
                        <DateInputMask
                          register={register}
                          fieldName={
                            SecondOpinionConsentFormFields.SignatureDate
                          }
                          errors={errors}
                        />
                      </div>
                    </div>

                    <p className="fw-bold mt-3">
                      If not signed by the patient, the Requestor’s relationship
                      to the Patient is:
                    </p>
                    <ul className="check-list list-unstyled my-2">
                      <li className="mb-2">
                        <div className="form-check d-flex flex-wrap w-100">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="patient_relation1"
                            value={RequestorPatientRelations.Parent}
                            {...register(
                              SecondOpinionConsentFormFields.Relation
                            )}
                          />
                          <label
                            className="form-check-label fs-body"
                            htmlFor="patient_relation1"
                          >
                            Parent or guardian of minor patient
                          </label>
                        </div>
                      </li>
                      <li className="mb-2">
                        <div className="form-check d-flex flex-wrap w-100">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="patient_relation2"
                            value={RequestorPatientRelations.Conservator}
                            {...register(
                              SecondOpinionConsentFormFields.Relation
                            )}
                          />
                          <label
                            className="form-check-label fs-body w-auto"
                            htmlFor="patient_relation2"
                          >
                            Guardian or conservator of an incompetent patient
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="form-group text-center mt-3 mt-md-5 col-sm-12 button-wrapper">
                    <button
                      className="btn btn-secondary btn-rounded btn-lg px-3 px-md-5 mt-3 mx-md-2 w-100 w-md-auto"
                      disabled={!isValid}
                      style={{ minWidth: "220px" }}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConsentFormPage;
