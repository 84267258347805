import React from "react";
import { useSelector } from "react-redux";

const DentalInsuranceInfo = () => {
  const parCarrierCheckedEnabled = useSelector((state) =>
    state?.appSettings?.settingsData?.ParCarrierCheckedEnabled
  );
  return (
    <>
      {parCarrierCheckedEnabled && (
        <div style={{ marginBottom: "20px" }}>
          <p>
            <b>
              Good news! Most dental insurance plans now include coverage for
              dental.com services. Our exclusive arrangements encompass popular
              providers like:
            </b>
          </p>
          <ul>
            <li>Cigna Dental</li>
            <li>Humana Dental</li>
            <li>United Concordia Dental</li>
            <li>MetLife Dental</li>
          </ul>
          <p>
            For many of these company's plan members, these services are fully
            covered. If you're eligible, we'll handle the billing, and you won't
            incur any out-of-pocket costs.
          </p>
          <p>
            Don't worry if you have a different dental insurance plan-dental.com
            is here to assist. We'll gladly submit an insurance claim on your
            behalf, and any payment will be sent directly to you. The initial
            fee for treatment is $59, but if your dental plan covers it, you
            could be reimbursed for some or all the amount that you paid.
          </p>
        </div>
      )}
    </>
  );
};

export default DentalInsuranceInfo;
