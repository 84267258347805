import React from "react";
import { useLocation } from "react-router-dom";

import CaseReviewPage from "../../components/CaseReview/CaseReviewPage";
import FileViewer from "../../components/FileViewer/FileViewer";

const CaseReview = () => {
  const { state } = useLocation();

  return (
    <>
      <body className="mobile-body">
        <CaseReviewPage secondOpinionId={state?.secondOpinionId} />
        <FileViewer />
      </body>
    </>
  );
};

export default CaseReview;
