import React from "react";
import { useParams } from "react-router-dom";
import ResetPasswordForm from "../../components/ResetPasswordForm/ResetPasswordForm";

function ResetPassword() {
  const { token } = useParams();

  return (
    <body>
      <section className="account-section sign-in-section py-4 py-md-5 v-middle">
        <ResetPasswordForm token={token} />
      </section>
    </body>
  );
}

export default ResetPassword;
