import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";

import Navbar from "./../../components/Navbar/Navbar";
import FormSection from "../../components/Forms/FormSection";
import FormUpdateAndSubmitModal from "../../components/Forms/FormUpdateAndSubmitModal";

import { getAssignedForms_Ajax } from "../../helpers/requests";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import { handleApiErrors } from "../../helpers/utils";

const Forms = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();

  const currentPatient = useSelector(
    (state) => state?.userData?.currentPatient
  );

  const [assignedForms, setAssignedForms] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [reloadFormsData, setReloadFormsData] = useState(false);

  useEffect(() => {
    if (currentPatient) {
      setReloadFormsData(true);
    }
  }, [currentPatient]);

  function getAssignedForms(currentPage, itemPerPage) {
    var patientInfo = currentPatient;

    if (patientInfo && patientInfo.patid && currentPage && itemPerPage) {
      var params = {
        itemPerPage: itemPerPage,
        currentPageNum: currentPage,
        includeSubmittedFormOnly: false
      };

      dispatch(showLoadingSpinner());
      getAssignedForms_Ajax(
        patientInfo.patid,
        params,
        function (response) {
          dispatch(hideLoadingSpinner());

          if (
            response &&
            response.success &&
            response.data &&
            response.data.assignedForms &&
            Array.isArray(response.data.assignedForms) &&
            response.data.assignedForms.length > 0
          ) {
            setAssignedForms(response.data.assignedForms);
            setTotalCount(response.data.totalCount);
          } else if (response && !response.success) {
            setAssignedForms([]);
            setTotalCount(0);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  return (
    <>
      <body>
        <section className="step-section form-section py-4 py-md-5">
          <FormSection
            getAssignedForms={getAssignedForms}
            assignedForms={assignedForms}
            reloadFormsData={reloadFormsData}
            setReloadFormsData={setReloadFormsData}
            totalCount={totalCount}
          />
        </section>

        <FormUpdateAndSubmitModal />
        {/* <!-- scripts --> */}
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js"
          integrity="sha512-894YE6QWD5I59HgZOGReFYm4dnWc1Qt5NtvYSaNcOP+u1T9qYdvdihz0PPSiiqn/+/3e7Jo4EaG7TubfWGUrMQ=="
          crossOrigin="anonymous"
          referrerPolicy="no-referrer"
        ></script>
        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"
          integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"
          crossOrigin="anonymous"
        ></script>
      </body>
    </>
  );
};

export default Forms;
