import React from "react";
import SmartScanBitePage from "../../components/SmartScanBite/SmartScanBitePage";

const SmartScanBite = () => {
  return (
    <>
      <body className="mobile-body">
        <div className="body-content">
          <section className="review-photo-section py-4 py-md-5">
            <SmartScanBitePage />
          </section>
        </div>
      </body>
    </>
  );
};

export default SmartScanBite;
