import React from "react";
import { isEmptyArray } from "../../helpers/utils";

export default function CaseReportObservations({ caseReportObservations }) {
  return (
    <>
      {!isEmptyArray(caseReportObservations) ? (
        <>
          <div className="mb-4">
            <p className="mb-3">
              <strong>Observations</strong>
            </p>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Tooth #</th>
                  <th scope="col">Description</th>
                </tr>
              </thead>
              <tbody>
                {caseReportObservations.map((observation) => {
                  return (
                    <tr key={observation.caseReportObservationId.toString()}>
                      <td>{observation.tooth}</td>
                      <td>{observation.finding}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
