import React from "react";
import SmartScanInstructionPage from "../../components/SmartScanInstruction/SmartScanInstructionPage";

const SmartScanInstruction = () => {
  return (
    <>
      <body className="mobile-body">
        <div className="body-content">
          <section className="review-photo-section py-4 py-md-5">
            <SmartScanInstructionPage />
          </section>
        </div>
      </body>
    </>
  );
};

export default SmartScanInstruction;
