import React, { useEffect, useState } from "react";
import { Form } from "@formio/react";
import "formiojs/dist/formio.builder.min.css";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import {
  getMedicalFormPatient_Ajax,
  saveMedicalHistory_Ajax
} from "../../helpers/requests";
import { handleApiErrors, showAlertDialouge } from "../../helpers/utils";

const SurveyFormSubmissionComponent = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [formId, setFormId] = useState(0);
  const [patientId, setPatientId] = useState(0);
  const [appointmentId, setAppointmentId] = useState(0);
  const [providerId, setProviderId] = useState(0);
  const [consultationId, setConsultationId] = useState(0);
  const [isFormDataLoading, setIsFormDataLoading] = useState(true);
  const [smartScanId, setSmartScanId] = useState(0);

  const [formData, setFormData] = useState(null);

  useEffect(() => {
    getDataFromParams();
  });

  useEffect(() => {
    if (formId && patientId) {
      getFormData();
    }
  }, [formId, patientId]);

  function getFormData() {
    if (formId && patientId) {
      var params = {
        patId: patientId
      };

      if (consultationId) {
        params.consultationId = consultationId;
      }

      if (smartScanId) {
        params.smartScanId = smartScanId;
      }

      setIsFormDataLoading(true);
      dispatch(showLoadingSpinner());
      getMedicalFormPatient_Ajax(
        formId,
        params,
        function (response) {
          dispatch(hideLoadingSpinner());

          if (response && response.success && response.data) {
            setFormData(response.data);
            setIsFormDataLoading(false);
          } else if (response && !response.success) {
            if (response.message) {
              showAlertDialouge("Error", response.message, function () {
                if (response.data && response.data.isGenericFormSubmitted) {
                  navigate("/dashboard");
                }
              });
            }
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
          setIsFormDataLoading(false);
        }
      );
    }
  }

  function getDataFromParams() {
    try {
      var queryFormId = searchParams.get("formid");
      var queryPatId = searchParams.get("patid");
      var queryApptId = searchParams.get("apptid");
      var queryProviderId = searchParams.get("providerid");
      var queryConsultationId = searchParams.get("consultationid");
      let querySmartScanId = searchParams.get("smartscanid");

      if (queryFormId) {
        setFormId(queryFormId);
      }
      if (queryPatId) {
        setPatientId(queryPatId);
      }
      if (queryApptId) {
        setAppointmentId(queryApptId);
      }
      if (queryProviderId) {
        setProviderId(queryProviderId);
      }
      if (queryConsultationId) {
        setConsultationId(queryConsultationId);
      }
      if (querySmartScanId) {
        setSmartScanId(querySmartScanId);
      }
    } catch (ex) {}
  }

  function generateForm() {
    if (formData && formData.medicalForm && formData.medicalForm.components) {
      var formArray = JSON.parse(formData.medicalForm.components);

      var formObj = {
        display: "form",
        components: formArray
      };

      return (
        <div id="campaign-form">
          <div className="campaign-form-container">
            <Form key={formData.id} form={formObj} onSubmit={saveFormData} />
          </div>
        </div>
      );
    } else {
      return <Form form={[]} onSubmit={saveFormData} />;
    }
  }

  function saveFormData(submittedData) {
    if (submittedData && submittedData.data && formId && patientId) {
      var data = {
        formData: JSON.stringify(submittedData.data)
      };

      var params = {
        patId: patientId
      };

      if (appointmentId) {
        params.apptId = appointmentId;
      }

      if (providerId) {
        params.providerId = providerId;
      }

      if (consultationId) {
        params.consultationId = consultationId;
      }

      if (smartScanId) {
        params.smartScanId = smartScanId;
      }

      dispatch(showLoadingSpinner());
      saveMedicalHistory_Ajax(
        formId,
        params,
        JSON.stringify(data),
        function (response) {
          dispatch(hideLoadingSpinner());

          if (response && response.success) {
            showAlertDialouge(
              "Success",
              "Form has been submitted successfully",
              function () {
                navigate("/dashboard");
              }
            );
          } else if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function showSurveyFormData() {
    if (formData) {
      return (
        <>
          <div className="modal-header primary-border-color">
            <h2 className="modal-title text-primary text-center w-100">
              {formData.medicalForm?.title}
            </h2>
          </div>
          <div className="modal-body form-modal-body">
            <div className="card-body text-align-left">{generateForm()}</div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="modal-header mb-4">
            <h2 className="modal-title text-center w-100">
              Form data not found
            </h2>
            <button
              type="button"
              className="btn-close text-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
        </>
      );
    }
  }

  function showSurveyForm() {
    if (isFormDataLoading) {
      return <></>;
    } else {
      return (
        <>
          <div className="bg-white border-radius-xlg form-border-radius px-4 px-md-5 py-4">
            <div className="confirm-booking mt-md-3 float-none">
              {showSurveyFormData()}
              <Link to={"/dashboard"}>
                <button
                  className="btn btn-outline btn-rounded btn-lg px-3 px-md-5 mx-md-2 mt-3 mt-md-0 form-cancel-btn"
                  id="billing-info-tab"
                  type="button"
                >
                  cancel
                </button>
              </Link>
            </div>
          </div>
        </>
      );
    }
  }

  return <>{showSurveyForm()}</>;
};
export default SurveyFormSubmissionComponent;
