import { SET_FILE_URL_TO_OPEN, SET_FILE_NAME } from "../actions/typeConstants";

var initialState = {
  fileUrlToOpen: null,
  modalToReopen: null,
  fileName: null
};

export const fileViewer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILE_URL_TO_OPEN:
      return Object.assign({}, state, {
        fileUrlToOpen: action?.payload?.fileUrlToOpen,
        modalToReopen: action?.payload?.modalToReopen
      });
    case SET_FILE_NAME:
      return Object.assign({}, state, {
        fileName: action?.payload?.fileName
      });
    default:
      return state;
  }
};
