import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import QuestionnairePage from "../../components/Questionnaire/QuestionnairePage";

const Questionnaire = () => {
  return (
    <>
      <div className="body-content">
        <QuestionnairePage />
      </div>
    </>
  );
};

export default Questionnaire;
