import React, { useEffect } from "react";
import { Form } from "@formio/react";
import "formiojs/dist/formio.builder.min.css";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import { savePatientMedicalForm_Ajax } from "../../helpers/requests";
import {
  handleApiErrors,
  showAlertDialouge,
  addEventListenerInFormIo
} from "../../helpers/utils";

const FormSubmissionComponent = (props) => {
  const { formData } = props;

  const alert = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function generateForm() {
    if (formData && formData.medicalForm && formData.medicalForm.components) {
      var formArray = JSON.parse(formData.medicalForm.components);

      var formObj = {
        display: "form",
        components: formArray
      };

      return (
        <div id="campaign-form">
          <div className="campaign-form-container">
            <Form
              key={formData.id}
              form={formObj}
              onSubmit={saveFormData}
              onRender={addEventListenerInFormIo}
            />
          </div>
        </div>
      );
    } else {
      return (
        <Form
          form={[]}
          onSubmit={saveFormData}
          onRender={addEventListenerInFormIo}
        />
      );
    }
  }

  function saveFormData(submittedData) {
    if (submittedData && submittedData.data && formData && formData.id) {
      var data = {
        formData: JSON.stringify(submittedData.data)
      };

      dispatch(showLoadingSpinner());
      savePatientMedicalForm_Ajax(
        formData.id,
        JSON.stringify(data),
        function (response) {
          dispatch(hideLoadingSpinner());

          if (response && response.success) {
            showAlertDialouge(
              "Message",
              "Form has been submitted successfully.",
              function () {
                navigate("/forms");
              }
            );
          } else if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  return (
    <>
      <div className="bg-white border-radius-xlg form-border-radius px-4 px-md-5 py-4">
        <div className="confirm-booking mt-md-3 float-none">
          {formData ? (
            <>
              <div className="modal-header primary-border-color">
                <h2 className="modal-title text-primary text-center w-100">
                  {formData.medicalForm?.title}
                </h2>
              </div>
              <div className="modal-body form-modal-body">
                <div className="card-body">{generateForm()}</div>
              </div>
            </>
          ) : (
            <>
              <div className="modal-header">
                <h2 className="modal-title text-center w-100">
                  Form data not found
                </h2>
                <button
                  type="button"
                  className="btn-close text-white"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
            </>
          )}
          <Link to={"/forms"}>
            <button
              className="btn btn-outline btn-rounded btn-lg px-3 px-md-5 mx-md-2 mt-3 mt-md-0 form-cancel-btn"
              id="billing-info-tab"
              type="button"
            >
              cancel
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};
export default FormSubmissionComponent;
