import {
  SET_EXTERNAL_PATIENT_ID,
  SET_EXTERNAL_EMPLOYEE_ID,
  SET_EXTERNAL_PATIENT_EMAIL
} from "../actions/typeConstants";

let initialState = {
  patId: 0,
  employerId: 0,
  email: ""
};

export const externalPatient = (state = initialState, action) => {
  switch (action.type) {
    case SET_EXTERNAL_PATIENT_ID:
      return Object.assign({}, state, {
        patId: action?.payload?.patId
      });
    case SET_EXTERNAL_EMPLOYEE_ID:
      return Object.assign({}, state, {
        employerId: action?.payload?.employerId
      });
    case SET_EXTERNAL_PATIENT_EMAIL:
      return Object.assign({}, state, {
        email: action?.payload?.email
      });
    default:
      return state;
  }
};
