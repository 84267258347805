import React from "react";
import { useParams } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import RequestCompletedDetailPage from "../../components/RequestCompletedDetail/RequestCompletedDetailPage";

const RequestCompletedDetail = () => {
  const { secondOpinionId } = useParams();

  return (
    <>
      <body className="mobile-body">
        {secondOpinionId ? (
          <RequestCompletedDetailPage secondOpinionId={secondOpinionId} />
        ) : (
          <></>
        )}
      </body>
    </>
  );
};

export default RequestCompletedDetail;
