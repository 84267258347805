import React from "react";
import SmartScanPhotoOptionPage from "../../components/SmartScanPhotoOption/SmartScanPhotoOptionPage";

const SmartScanPhotoOption = () => {
  return (
    <>
      <div className="body-content">
        <section className="py-4 py-md-5">
          <SmartScanPhotoOptionPage />
        </section>
      </div>
    </>
  );
};

export default SmartScanPhotoOption;
