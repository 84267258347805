import React, { useEffect, useState } from "react";
import "../../assets/css/style.css";
import { getPatientInfo } from "../../helpers/authManager";
import InsuranceFormUpdate from "../../components/InsuranceInformation/InsuranceFormUpdate";
import { useSelector } from "react-redux";

const InsuranceInformationForm = () => {
  const currentPatient = useSelector(
    (state) => state?.userData?.currentPatient
  );

  const [showApplyToDependentsOption, setShowApplyToDependentsOption] =
    useState(false);

  useEffect(() => {
    if (currentPatient?.patid) {
      setShowApplyToDependentsOption(
        currentPatient.patid == getPatientInfo().patid
      );
    }
  }, [currentPatient]);

  return (
    <body>
      <section className="account-section sign-in-section pb-4 pt-2 v-middle">
        <div className="container">
          {currentPatient?.patid ? (
            <InsuranceFormUpdate
              patientId={currentPatient.patid}
              showApplyToDependentsOption={showApplyToDependentsOption}
            />
          ) : (
            <></>
          )}
        </div>
      </section>
    </body>
  );
};

export default InsuranceInformationForm;
