import React from "react";
import PatientRecordsRequestPage from "../../components/PatientRecordsRequest/PatientRecordsRequestPage";
import DeleteModal from "./DeleteModal";
import RecordSentModal from "./RecordSentModal";

const PatientRecordsRequest = () => {
  return (
    <>
      <body className="mobile-body">
        <div className="body-content">
          <section className="py-4 py-md-5">
            <PatientRecordsRequestPage />
          </section>
        </div>
        <DeleteModal />
      </body>
    </>
  );
};

export default PatientRecordsRequest;
