import React, { useState, useEffect } from "react";
import WellnessScorePrintPage from "../../components/WellnessScorePrint/WellnessScorePrintPage";
import ParentReport from "../../components/ParentReport/ParentReport";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getSmartScanCaseReport_Ajax,
  getSmartScanWellnessScoreSummery_Ajax
} from "../../helpers/requests";
import { handleApiErrors, showAlertDialouge } from "../../helpers/utils";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import { getWhitenessScoreReport_Ajax } from "../../helpers/requests";

const WellnessScorePrint = () => {
  const navigate = useNavigate();
  const { smartScanId } = useParams();
  const [smartScanCaseReport, setSmartScanCaseReport] = useState(null);
  const [wellnessScoreSummary, setWellnessScoreSummary] = useState(null);
  const [currentScore, setCurrentScore] = useState(0);
  const [parentReport, setParentReport] = useState(null);
  const [searchParams] = useSearchParams();
  const queryPatId = searchParams.get("patid");
  const showParentReport =
    searchParams.get("parentreport") == "true" ? true : false;
  const isMobile = searchParams.get("mobile") == "true" ? true : false;

  const dispatch = useDispatch();
  const shouldShowRecommendedProductsInSmartScan = useSelector(
    (state) =>
      state?.appSettings?.settingsData?.shouldShowRecommendedProductsInSmartScan
  );

  const shouldShowWhitnessReport = useSelector(
    (state) => state?.appSettings?.settingsData?.shouldShowWhitnessReport
  );

  useEffect(() => {
    if (smartScanId >= 0) {
      getSmartScanCaseReport(smartScanId);
    }
    if (shouldShowWhitnessReport) {
      getWhitenessScoreReport(smartScanId);
    }
  }, [smartScanId]);

  useEffect(() => {
    if (!smartScanCaseReport || smartScanCaseReport?.patId <= 0) {
      return;
    }
    if (queryPatId != smartScanCaseReport?.patId.toString()) {
      showAlertDialouge(
        "Message",
        "Smart scan report not found for the selected patient",
        function () {
          navigate("/");
        }
      );
    } else if (smartScanCaseReport?.patId > 0 && smartScanId >= 0) {
      getSmartScanWellnessScoreSummery(smartScanCaseReport?.patId);
    }
  }, [smartScanCaseReport]);

  function getSmartScanWellnessScoreSummery(patId) {
    if (patId > 0) {
      dispatch(showLoadingSpinner());
      let params = {
        smartScanId: smartScanId
      };

      getSmartScanWellnessScoreSummery_Ajax(
        patId,
        params,
        function (response) {
          dispatch(hideLoadingSpinner());

          if (response?.success && response?.data) {
            setWellnessScoreSummary(
              response.data.smartScanWellnessScoreSummery
            );
          } else if (!response?.success && response?.message) {
            showAlertDialouge("Message", response.message, function () {
              navigate("/");
            });
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function getSmartScanCaseReport(smartScanId) {
    if (smartScanId >= 0) {
      let params = {
        includeDetails: true,
        patId: queryPatId,
        shouldGetLatestSmartScanCaseReport: smartScanId > 0 ? false : true,
        includeRecommendedProducts: shouldShowRecommendedProductsInSmartScan,
        includeParentReport: showParentReport
      };

      dispatch(showLoadingSpinner());
      getSmartScanCaseReport_Ajax(
        smartScanId,
        params,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (response?.success && response?.data?.smartScanCaseReportVM) {
            setSmartScanCaseReport(response.data.smartScanCaseReportVM);
          }
          if (response?.success && response?.data?.parentReportVM) {
            setParentReport(response.data.parentReportVM);
          } else if (!response?.success && response?.message) {
            showAlertDialouge("Error", response.message, function () {
              navigate("/");
            });
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function getWhitenessScoreReport(smartScanId) {
    let params = {
      patId: queryPatId,
      shouldGetLatestSmartScanWhitnessReport: smartScanId > 0 ? false : true
    };

    dispatch(showLoadingSpinner());
    getWhitenessScoreReport_Ajax(
      smartScanId,
      params,
      function (resposne) {
        dispatch(hideLoadingSpinner());

        if (
          resposne &&
          resposne?.success &&
          resposne?.data?.whitenessReportVM
        ) {
          setCurrentScore(resposne.data.whitenessReportVM.shadeScore);
        } else {
          setCurrentScore(0);
        }
      },
      function (err) {
        dispatch(hideLoadingSpinner());
        handleApiErrors(err);
      }
    );
  }

  return (
    <body>
      <section className="step-section py-1">
        <div className="container">
          <WellnessScorePrintPage
            smartScanCaseReport={smartScanCaseReport}
            wellnessScoreSummary={wellnessScoreSummary}
            smartScanId={smartScanId}
            isMobile={isMobile}
            currentScore={currentScore}
            patientId={queryPatId}
          />
          {showParentReport && <ParentReport parentReport={parentReport} />}
        </div>
      </section>
    </body>
  );
};

export default WellnessScorePrint;
