import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { isEmptyArray, copyToClipboard } from "../../helpers/utils";
import ProductDetailsAccordion from "../WellnessScore/ProductDetailsAccordion";
import ProductDetails from "../WellnessScorePrint/ProductDetails";
import { SmartScanOralHealthStatus } from "../../constants";

const useDisplaySmartScanRecommendedProduct = (smartScanCaseReport) => {
  const shouldShowRecommendedProductsInSmartScan = useSelector(
    (state) =>
      state?.appSettings?.settingsData?.shouldShowRecommendedProductsInSmartScan
  );
  const [detectedIssues, setDetectedIssues] = useState([]);

  useEffect(() => {
    detectIssues();
  }, [smartScanCaseReport]);

  function shouldShowRecommendedProductsSection() {
    return (
      smartScanCaseReport?.smartScanCaseReportId > 0 &&
      shouldShowRecommendedProductsInSmartScan &&
      !isEmptyArray(smartScanCaseReport?.recommendedProducts)
    );
  }

  function renderProducts() {
    if (!isEmptyArray(smartScanCaseReport?.recommendedProducts)) {
      return (
        <>
          {smartScanCaseReport.recommendedProducts.map((product, index) => {
            return (
              <ProductDetailsAccordion
                key={product.productsId.toString()}
                product={product}
              ></ProductDetailsAccordion>
            );
          })}
        </>
      );
    }
  }

  function renderProductsInPrintPage() {
    if (!isEmptyArray(smartScanCaseReport?.recommendedProducts)) {
      return (
        <>
          {smartScanCaseReport.recommendedProducts.map((product, index) => {
            return (
              <ProductDetails
                key={product.productsId.toString()}
                product={product}
              ></ProductDetails>
            );
          })}
        </>
      );
    }
  }

  function detectIssues() {
    let issues = [];
    if (smartScanCaseReport != null) {
      if (
        smartScanCaseReport.cavity?.status != SmartScanOralHealthStatus.Good
      ) {
        issues.push("Cavities");
      }
      if (
        smartScanCaseReport.plaqueBuildUp?.status !=
        SmartScanOralHealthStatus.Good
      ) {
        issues.push("Plaque Buildup");
      }
      if (
        smartScanCaseReport.brokenTooth?.status !=
        SmartScanOralHealthStatus.Good
      ) {
        issues.push("Broken Tooth");
      }
      if (
        smartScanCaseReport.gumInflammation?.status !=
        SmartScanOralHealthStatus.Good
      ) {
        issues.push("Gum Inflammation");
      }
    }
    setDetectedIssues(issues);
  }

  function getCssClassBasedOnIssue(issue) {
    if (issue == "Cavities") return "bg-violet";
    if (issue == "Plaque Buildup") return "bg-primary";
    if (issue == "Gum Inflammation") return "bg-secondary";
    if (issue == "Broken Tooth") return "bg-danger text-white";
  }

  function renderIssues(changeTextColor) {
    let textCssClass = "";
    if (changeTextColor) {
      textCssClass = " text-white";
    }

    if (detectedIssues?.length > 0) {
      return (
        <div>
          <h3 className={"mb-3" + textCssClass}>Issues we detected:</h3>
          <ul className="issues_list">
            {detectedIssues.map((issue, index) => (
              <li className={getCssClassBasedOnIssue(issue)} key={index}>
                {issue}
              </li>
            ))}
          </ul>
        </div>
      );
    }
  }

  function showCopiedTooltip(index) {
    let tooltipTitle = document.getElementById("tooltip_title" + index);

    if (!tooltipTitle) {
      console.error("Required elements not found in the document.");
      return;
    }

    tooltipTitle.style.display = "block";

    setTimeout(function () {
      tooltipTitle.style.display = "none";
    }, 1500);
  }

  function copyPromoCode(index, promoCode) {
    copyToClipboard(
      promoCode,
      function () {
        showCopiedTooltip(index);
      },
      function () {
        showAlertDialouge("Error", "Failed to copy", function () {
          window.location.reload();
        });
      }
    );
  }

  return {
    shouldShowRecommendedProductsSection,
    renderProducts,
    shouldShowRecommendedProductsInSmartScan,
    renderProductsInPrintPage,
    renderIssues,
    copyPromoCode
  };
};

export default useDisplaySmartScanRecommendedProduct;
