import React from "react";
import No_preview_available from "../../assets/images/No_preview_available.jpg";
import { onImageError } from "../../helpers/utils";

const EducationProviderModal = (props) => {
  const { providerData } = props;

  return (
    <>
      {
        <div className="modal fade" id="providerProfile" tabIndex="-1">
          <div className="modal-dialog modal-dialog-centered">
            {providerData && (
              <>
                <div className="modal-content">
                  <div className="modal-header primary-border-color">
                    <h2 className="modal-title text-primary text-center w-100">
                      About Provider
                    </h2>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    {providerData.photoId ? (
                      <img
                        src={providerData.photoId}
                        onError={onImageError}
                        className="mb-3 provider-profile-img"
                      />
                    ) : (
                      <img
                        src={No_preview_available}
                        className="mb-3 provider-profile-img"
                      />
                    )}
                    <h2 className="mb-1">{providerData.user?.fullName}</h2>
                    <h5 className="mb-3">{providerData.title}</h5>
                    <p>{providerData.aboutMe}</p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      }
    </>
  );
};

export default EducationProviderModal;
