import React from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import SmartScanSubmittedRecordsPage from "../../components/SmartScanSubmittedRecordsPage/SmartScanSubmittedRecordsPage";
import { setFileUrlToOpen, setFileName } from "../../redux/actions/fileViewer";
import FileViewer from "../../components/FileViewer/FileViewer";
const SmartScanSubmittedRecords = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();

  function setCategoryImageInfo(url, fileName) {
    if (url) {
      dispatch(setFileUrlToOpen(url));
      dispatch(setFileName(fileName));
    }
  }

  return (
    <>
      <body className="mobile-body">
        <div className="body-content">
          <section className="review-photo-section py-4 py-md-5">
            <SmartScanSubmittedRecordsPage
              smartScanId={state?.smartScanId}
              showBackButton={state?.showBackButton}
              setCategoryImageInfo={setCategoryImageInfo}
            />
          </section>
        </div>
        <FileViewer />
      </body>
    </>
  );
};

export default SmartScanSubmittedRecords;
