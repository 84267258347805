import React from "react";
import WellnessScorePage from "../../components/WellnessScore/WellnessScorePage";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";

const WellnessScore = () => {
  const { state } = useLocation();
  const currentPatient = useSelector((state) => state.userData.currentPatient);

  const getSmartScanId = () => {
    if (state) {
      return state?.smartScanId;
    }
    return 0;
  };

  const getPatientId = () => {
    if (state?.patientId) {
      return state?.patientId;
    }
    return currentPatient?.patid;
  };

  return (
    <>
      <body>
        <section className="step-section py-4 py-md-5">
          <WellnessScorePage
            smartScanId={getSmartScanId()}
            patientId={getPatientId()}
          />
        </section>
      </body>
    </>
  );
};

export default WellnessScore;
