import React from "react";
import SmartScanLowerArchPage from "../../components/SmartScanLowerArch/SmartScanLowerArchPage";

const SmartScanLowerArch = () => {
  return (
    <>
      <body className="mobile-body">
        <div className="body-content">
          <section className="review-photo-section py-4 py-md-5">
            <SmartScanLowerArchPage />
          </section>
        </div>
      </body>
    </>
  );
};

export default SmartScanLowerArch;
