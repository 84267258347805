import { Outlet } from "react-router-dom";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";

const Layout = () => {
  return (
    <main className="App">
      <ScrollToTop />
      <Outlet />
    </main>
  );
};

export default Layout;
