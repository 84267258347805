import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import MyDependentsHeader from "../../components/MyDependents/MyDependentsHeader";
import MyDependentsAddMember from "../../components/MyDependents/MyDependentsAddMember";
import MyDependentsAccordion from "../../components/MyDependents/MyDependentsAccordion";
import MyDependentsDeleteModal from "../../components/MyDependents/MyDependentsDeleteModal";
import { useDispatch, useSelector } from "react-redux";
import {
  saveCurrentPatient,
  saveFamilyMembersList,
  setIsFamilyMembersListChanged
} from "../../redux/actions/userData";
import { handleApiErrors } from "../../helpers/utils";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import { getPatientInfo } from "../../helpers/authManager";
import { getFamilyMembers_Ajax } from "../../helpers/requests";
import { Link } from "react-router-dom";

const MyDependents = () => {
  const isFamilyMembersListChanged = useSelector(
    (state) => state.userData.isFamilyMembersListChanged
  );
  const [familyMembersList, setFamilyMembersList] = useState(null);
  const [isAddFamilyMemberFormVisible, setIsAddFamilyMemberFormVisible] =
    useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    getPatientsAndFamilyMembers();
  }, []);

  useEffect(() => {
    if (isFamilyMembersListChanged) {
      getPatientsAndFamilyMembers();
      dispatch(setIsFamilyMembersListChanged(false));
    }
  }, [isFamilyMembersListChanged]);

  function getPatientsAndFamilyMembers() {
    var patientInfo = getPatientInfo();

    if (patientInfo && patientInfo.patid) {
      var onlyFamilyMembersOptions = false;
      dispatch(showLoadingSpinner());
      getFamilyMembers_Ajax(
        onlyFamilyMembersOptions,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (
            response &&
            response.success &&
            response.data &&
            Array.isArray(response.data) &&
            response.data.length > 0
          ) {
            var currentPatient = null;
            var familyMembers = [];
            response.data.map((member) => {
              if (member.patid == patientInfo.patid) {
                currentPatient = member;
              } else {
                familyMembers.push(member);
              }
            });

            dispatch(saveFamilyMembersList(familyMembers));
            setFamilyMembersList(familyMembers);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  const toggleAddFamilyMemberFormVisibility = () => {
    setIsAddFamilyMemberFormVisible(!isAddFamilyMemberFormVisible);
  };

  return (
    <>
      <body>
        <section className="sec-my-dependence py-4 py-md-5">
          <div className="container">
            <div className="row mb-3">
              <div className="col-sm-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      My Dependents
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                {/* header */}
                <MyDependentsHeader
                  toggleAddFamilyMemberFormVisibility={
                    toggleAddFamilyMemberFormVisibility
                  }
                />

                {/* Add Member Form */}
                <MyDependentsAddMember
                  toggleAddFamilyMemberFormVisibility={
                    toggleAddFamilyMemberFormVisibility
                  }
                  isAddFamilyMemberFormVisible={isAddFamilyMemberFormVisible}
                />

                {familyMembersList &&
                  Array.isArray(familyMembersList) &&
                  familyMembersList.length > 0 && (
                    <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
                      {/* accordion and Member Edit Form */}

                      <MyDependentsAccordion
                        familyMembersList={familyMembersList}
                      />

                      {/* Delete Modal */}
                      {/* <MyDependentsDeleteModal /> */}
                    </div>
                  )}
              </div>
            </div>
          </div>
        </section>
      </body>
    </>
  );
};

export default MyDependents;
