import React from "react";
import multiplanLogo from "./../../assets/images/multiPlan.png";

function SubscriberCardModal() {
  return (
    <div
      className="modal fade"
      id="Subscriber-modal"
      tabIndex={-1}
      style={{ display: "none" }}
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        style={{ maxWidth: 650 }}
      >
        <div className="modal-content">
          <div className="modal-body py-2 py-sm-4">
            <div className="row">
              <div className="col-6 d-flex flex-column justify-content-between mb-0">
                <div>
                  <div className="mb-2">
                    <p>Administered By Cigna Health and Life Insurance Co.</p>
                    <p>Coverage Effective Date: 01/01/2024</p>
                  </div>
                  <div className="mb-2">
                    <h3
                      style={{
                        display: "table",
                        background: "#f6ff00",
                        padding: "2px 5px"
                      }}
                    >
                      Group: <strong>1234567</strong>
                    </h3>
                    <p className="d-flex align-items-center11 gap-1 fs-4 text-danger fw-bold my-1 my-sm-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width={21}
                        height={21}
                        x={0}
                        y={0}
                        viewBox="0 0 32 32"
                        style={{ enableBackground: "new 0 0 512 512" }}
                        xmlSpace="preserve"
                        className=""
                      >
                        <g>
                          <g
                            fill="#dc3545"
                            fillRule="evenodd"
                            clipRule="evenodd"
                          >
                            <path
                              d="M8.94 4.94a1.5 1.5 0 0 1 2.12 0l6 6a1.5 1.5 0 0 1-2.12 2.12L10 8.122l-4.94 4.94a1.5 1.5 0 0 1-2.12-2.122z"
                              fill="#dc3545"
                              opacity={1}
                              data-original="#000000"
                              className=""
                            />
                            <path
                              d="M10 4.5A1.5 1.5 0 0 1 11.5 6v16.5H28a1.5 1.5 0 0 1 0 3H10A1.5 1.5 0 0 1 8.5 24V6A1.5 1.5 0 0 1 10 4.5z"
                              fill="#dc3545"
                              opacity={1}
                              data-original="#000000"
                              className=""
                            />
                          </g>
                        </g>
                      </svg>
                      Here is your group number
                    </p>
                  </div>
                  <div className="mb-2">
                    <p>
                      <small>Issuer (80840)</small>
                    </p>
                    <h3
                      className="my-1"
                      style={{
                        display: "table",
                        background: "#f6ff00",
                        padding: "2px 5px"
                      }}
                    >
                      ID: <strong>U0777999901</strong>
                    </h3>
                    <p className="d-flex align-items-center111 gap-1 fs-4 text-danger fw-bold mt-1 mt-sm-2 mb-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width={21}
                        height={21}
                        x={0}
                        y={0}
                        viewBox="0 0 32 32"
                        style={{ enableBackground: "new 0 0 512 512" }}
                        xmlSpace="preserve"
                        className=""
                      >
                        <g>
                          <g
                            fill="#dc3545"
                            fillRule="evenodd"
                            clipRule="evenodd"
                          >
                            <path
                              d="M8.94 4.94a1.5 1.5 0 0 1 2.12 0l6 6a1.5 1.5 0 0 1-2.12 2.12L10 8.122l-4.94 4.94a1.5 1.5 0 0 1-2.12-2.122z"
                              fill="#dc3545"
                              opacity={1}
                              data-original="#000000"
                              className=""
                            />
                            <path
                              d="M10 4.5A1.5 1.5 0 0 1 11.5 6v16.5H28a1.5 1.5 0 0 1 0 3H10A1.5 1.5 0 0 1 8.5 24V6A1.5 1.5 0 0 1 10 4.5z"
                              fill="#dc3545"
                              opacity={1}
                              data-original="#000000"
                              className=""
                            />
                          </g>
                        </g>
                      </svg>
                      Here is your ID number
                    </p>
                    <h4>
                      Name: <strong>Jane Brunner</strong>
                    </h4>
                  </div>
                </div>
                <div>
                  <h4>RXBIN 12345 RXPCN 4445COMM</h4>
                  <h4>RxGroup 1234567</h4>
                </div>
              </div>
              <div className="col-6">
                <h4 className="fw-bold mb-1">Choice Fund LCP</h4>
                <h4 className="fw-bold mb-2">HRA 2500/5000</h4>
                <ul className="card-list">
                  <li className="fw-bold">No Referral Required</li>
                  <li>
                    <span className="fw-bold">PCP Visit</span>
                    <span>15%</span>
                  </li>
                  <li>
                    <span className="fw-bold">Specialist</span>
                    <span>15%</span>
                  </li>
                  <li>
                    <span className="fw-bold">Hospital ER</span>
                    <span>15%</span>
                  </li>
                  <li>
                    <span className="fw-bold">Urgent Care</span>
                    <span>15%</span>
                  </li>
                  <li>
                    <span className="fw-bold">Rx</span>
                    <span>$5/25/50/45</span>
                  </li>
                  <li className="fw-bold">Network Coinsurance:</li>
                  <li>
                    <span className="fw-bold">In</span>
                    <span>85%/15%</span>
                  </li>
                  <li>
                    <span className="fw-bold">Out</span>
                    <span>55%/45%</span>
                  </li>
                </ul>
                <ul className="card-list mb-0">
                  <li>
                    <span className="fw-bold">INN DED Ind/Fam</span>
                    <span>$5000/$5000</span>
                  </li>
                  <li>
                    <span className="fw-bold">OON DED Ind/Fam</span>
                    <span>$10000/$10000</span>
                  </li>
                  <li>
                    <span className="fw-bold">INN OOP Ind/Fam</span>
                    <span>$5000/$10000</span>
                  </li>
                  <li>
                    <span className="fw-bold">OON OOP Ind/Fam</span>
                    <span>$20000/$20000</span>
                  </li>
                </ul>
              </div>
              <div className="col-12 mt-2 text-center">
                <img
                  src={multiplanLogo}
                  alt="multiPlan"
                  loading="lazy"
                  style={{ maxWidth: 100 }}
                />
                <h4>Network Saving Program</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscriberCardModal;
