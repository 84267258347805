import { useSelector } from "react-redux";
import useSelectedPatientForAppointment from "./useSelectedPatientForAppointment";
import useSelectedPatientInsurance from "./useSelectedPatientInsurance";
import { useState, useEffect } from "react";

const useIsBillingInformationAvailable = (
  isCaptureCreditOrDebitCardEnabled
) => {
  const shouldHideBillingInformationBubbleIfInsuranceAvailable = useSelector(
    (state) =>
      state?.appSettings?.settingsData
        ?.hideBillingInformationBubbleIfInsuranceAvailable
  );
  const selectedPatientInsurance = useSelectedPatientInsurance();
  const selectedPatientForAppointment = useSelectedPatientForAppointment();
  const [isBillingInformationAvailable, setisBillingInformationAvailable] =
    useState();

  useEffect(() => {
    if (shouldHideBillingInformationBubbleIfInsuranceAvailable) {
      if (selectedPatientForAppointment?.patid) {
        if (selectedPatientInsurance?.insuredId) {
          setisBillingInformationAvailable(false);
        } else {
          setisBillingInformationAvailable(isCaptureCreditOrDebitCardEnabled);
        }
      } else {
        setisBillingInformationAvailable(false);
      }
    } else {
      setisBillingInformationAvailable(isCaptureCreditOrDebitCardEnabled);
    }
  }, [
    selectedPatientInsurance,
    selectedPatientForAppointment,
    isCaptureCreditOrDebitCardEnabled
  ]);

  return isBillingInformationAvailable;
};

export default useIsBillingInformationAvailable;
