import React, { useEffect, useState } from "react";
import { GoogleMapsApiKey, GooglePlaceAPIOptions } from "../../constants";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { Input, List } from "antd";
import { useSelector } from "react-redux";

const AutoComplete = (props) => {

  const {
    handleAddress,
    existingValue,
    inputElementId
  } = props;
  
  const [subscriberAddress, setSubscriberAddress] = useState();
  const [shouldShowAddressList, setShouldShowAddressList] = useState(false);

  const isAddressAutoPopulatingFeatureEnabled = useSelector(
    (state) => state?.appSettings?.settingsData?.isAddressAutoPopulatingEnabled
  );

  const {
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
    placesService,
  } = usePlacesService({
    apiKey: GoogleMapsApiKey,
    debounce: 500,
    options: GooglePlaceAPIOptions
  });

  useEffect(() => {
    setShouldShowAddressList(placePredictions.length > 0);
  }, [placePredictions]);

  useEffect(() => {
    setSubscriberAddress(existingValue);
  }, [existingValue]);

  const handleInputOnChange = (event) => {
    setSubscriberAddress(event.target.value);

    if (isAddressAutoPopulatingFeatureEnabled) {
      getPlacePredictions({ input: event.target.value });
      setShouldShowAddressList(true);
    }
    handleAddress({name: event.target.value});
  };

  return (
    <div className="search-location-input"
      onBlur={() => {
        setTimeout(() => {
          setShouldShowAddressList(false);
        }, 250);
      }}
    >
      <Input
        value={subscriberAddress}
        className="form-control"
        id={inputElementId}
        autocomplete="off"
        onChange={(evt) => {
          handleInputOnChange(evt);
        }}
        loading={isPlacePredictionsLoading}
      />
      {!isPlacePredictionsLoading && shouldShowAddressList && (<div
      className="user-address-dropdown"
      >
        <div
          className="dropdown-menu shadow "
        >
          <List
              dataSource={placePredictions}
              className="address-suggestion-list"
              renderItem={(item) => (
                <List.Item onClick={() => {
                  placesService?.getDetails(
                    {
                      placeId: item.place_id,
                    },
                    (placeDetails) => {
                      setShouldShowAddressList(false);
                      setSubscriberAddress(placeDetails.name);
                      handleAddress(placeDetails);
                    }
                  );
                }}>
                  <em className="fa fa-map-marker place-icon"></em> <span>{ item.description }</span>
                </List.Item>
              )}
            />
          </div>
        </div>
      ) }
    </div>
  );
};

export default AutoComplete;
