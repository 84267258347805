import React from "react";
import SmartScanSelfieCapture from "../SmartScanSelfieCapture/SmartScanSelfieCapture";
import { smartScanCategory } from "../../constants";
import useGetSmartScanSelfieCaptureProgress from "../CustomHooks/useGetSmartScanSelfieCaptureProgress";
import {
  getSmartScanImageBasedOnCaptureOption,
  getSmartScanInstructionsBasedOnCaptureOption
} from "../../helpers/smartScanUtils";

const SmartScanLowerLipPage = () => {
  const smartScanState = useGetSmartScanSelfieCaptureProgress();

  return (
    <>
      <SmartScanSelfieCapture
        state={smartScanState}
        previousPagePath={"/smart-scan-upper-lip-confirm"}
        nextPagePath={"/smart-scan-lower-lip-confirm"}
        category={smartScanCategory.LowerLip}
        poseImage={getSmartScanImageBasedOnCaptureOption(
          smartScanState?.captureOption,
          smartScanCategory.LowerLip
        )}
        instruction={getSmartScanInstructionsBasedOnCaptureOption(
          smartScanState?.captureOption,
          smartScanCategory.LowerLip
        )}
      />
    </>
  );
};

export default SmartScanLowerLipPage;
