import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { Form } from "@formio/react";
import "formiojs/dist/formio.full.min.css";

import SignatureCanvas from "react-signature-canvas";
import AddDependentForm from "./AddDependentForm";
import ReactTooltip from "react-tooltip";

import {
  saveMedicalHistory_Ajax,
  savePatientProfileSettings_Ajax
} from "../../helpers/requests";
import {
  initializeAdditionalChangesInFormIoFormDuringRender,
  handleApiErrors,
  isEmptyArray,
  isPatientDateOfBirthValid,
  showAlertDialouge
} from "../../helpers/utils";
import { useForm } from "react-hook-form";
import {
  ConsentOptions,
  GenderOptions,
  Roles,
  SignUpFormFields,
  ValidationTypes,
  AddDependentSuccessMessage
} from "../../constants";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import {
  saveCurrentPatient,
  setIsFamilyMembersListChanged,
  setSelectedPatientInsurance,
  setSeletedPatientForAppointment
} from "../../redux/actions/userData";
import {
  getPatientInfo,
  storeSelectedPatientProfile
} from "../../helpers/authManager";
import useGetPatientInsuranceInfo from "../CustomHooks/useGetPatientInsuranceInfo";
import ConsentFormForPrimaryConsultant from "../ConsentFormForPrimaryConsultant/ConsentFormForPrimaryConsultant";
import useShouldDisplayPrimaryConsultantInfoCaptureForm from "../CustomHooks/useShouldDisplayPrimaryConsultantInfoCaptureForm";
import DateInputMask from "../DateInput/DateInputMask";
import useDisplayInsuranceCaptureForm from "../CustomHooks/useDisplayInsuranceCaptureCheckbox";
import useShowVerbiageBasedOnNonParCarrierSelected from "../CustomHooks/useShowVerbiageBasedOnNonParCarrierSelected";
import useShowVerbiageBasedOnStateSelected from "../CustomHooks/useShowVerbiageBasedOnStateSelected";
import { getPatientOrigin } from "../../ApplicationSettings";

const AppointmentSchedulePages = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    schedulerCampaign,
    campaignForms,
    setCampaignForms,
    setSchedulerCampaign,
    sharedStates,
    declinedStates = null
  } = props;

  const patientInfo = getPatientInfo();
  const familyMembersList = useSelector(
    (state) => state.userData.familyMembersList
  );
  const patientAndFamilyMemberList = useSelector(
    (state) => state.userData.patientAndFamilyMembersList
  );
  const isPharmacySelectionEnabled = useSelector(
    (state) => state?.userData.isPharmacySelectionEnabled
  );
  const officeId = useSelector(
    (state) => state?.appSettings?.settingsData?.officeId
  );
  const userData = useSelector((state) => state.userData);

  const [showAddDependent, setShowAddDependent] = useState(false);
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const [totalForms, setTotalForms] = useState(0);
  const [patientState, setPatientState] = useState(null);
  const [patientId, setPatientId] = useState(0);
  const [displayFormData, setDisplayFormData] = useState(null);
  const [isAddFamilyMemberFormVisible, setIsAddFamilyMemberFormVisible] =
    useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [insurancePatientId, setInsurancePatientId] = useState(0);
  const insuranceInfo = useGetPatientInsuranceInfo(insurancePatientId);
  const shouldHideBillingInformationBubbleIfInsuranceAvailable = useSelector(
    (state) =>
      state?.appSettings?.settingsData
        ?.hideBillingInformationBubbleIfInsuranceAvailable
  );

  const shouldDisplayCampaingChargeInfo = useSelector(
    (state) => state?.appSettings?.settingsData?.displayCampaignCharge
  );
  const [shouldCaptureInsuranceInfo, setShouldCaptureInsuranceInfo] =
    useState(false);
  const [isInsuranceFormVisible, setIsInsuranceFormVisible] = useState(false);
  const [selectedCarrierId, setSelectedCarrierId] = useState(0);
  const displayInsuranceCaptureParameters = {
    patientId: patientId,
    shouldCaptureInsuranceInfo: shouldCaptureInsuranceInfo,
    setShouldCaptureInsuranceInfo: setShouldCaptureInsuranceInfo,
    isInsuranceFormVisible: isInsuranceFormVisible,
    setIsInsuranceFormVisible: setIsInsuranceFormVisible,
    shouldNavigateToNextPage: shouldNavigateToNextPage,
    setSelectedCarrierId: setSelectedCarrierId,
    selectedCarrierId: selectedCarrierId
  };
  const displayInsuranceCaptureInfo = useDisplayInsuranceCaptureForm(
    displayInsuranceCaptureParameters
  );
  const showVerbiageBasedOnNonParCarrierSelected =
    useShowVerbiageBasedOnNonParCarrierSelected(patientId, selectedCarrierId);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors, isValid },
    reset
  } = useForm({
    mode: "onChange"
  });

  const [primaryDentistInfo, setPrimaryDentistInfo] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
    Phone: ""
  });

  const [shouldAllowToContinue, setShouldAllowToContinue] = useState(true);
  const [
    primaryConsultantInfoCollectionDecision,
    setPrimaryConsultantInfoCollectionDecision
  ] = useState(ConsentOptions.None);
  const shouldDisplayPrimaryConsentForm =
    useShouldDisplayPrimaryConsultantInfoCaptureForm(
      sharedStates,
      schedulerCampaign,
      patientState
    );
  const [shouldShowDeclinedVerbiage, showVerbiageBasedOnStateSelected] =
    useShowVerbiageBasedOnStateSelected(
      declinedStates,
      schedulerCampaign,
      patientState
    );

  useEffect(() => {
    if (
      campaignForms &&
      Array.isArray(campaignForms) &&
      campaignForms.length > 0
    ) {
      setTotalForms(campaignForms.length);
    }
  }, [campaignForms]);

  useEffect(() => {
    dispatch(setSeletedPatientForAppointment(null));
    dispatch(setSelectedPatientInsurance(null));
  }, []);

  useEffect(() => {
    if (patientId && shouldHideBillingInformationBubbleIfInsuranceAvailable) {
      setInsurancePatientId(patientId);
    }
  }, [patientId]);

  function storeSelectedPatientDetailsAndInsuranceInformation() {
    if (
      patientId > 0 &&
      patientAndFamilyMemberList &&
      !isEmptyArray(patientAndFamilyMemberList)
    ) {
      let patientInfo = patientAndFamilyMemberList.find(
        (patient) => patient.patid == patientId
      );

      if (shouldHideBillingInformationBubbleIfInsuranceAvailable) {
        dispatch(setSelectedPatientInsurance(insuranceInfo));
        dispatch(setSeletedPatientForAppointment(patientInfo));
      }
    }
  }

  useEffect(() => {
    storeSelectedPatientDetailsAndInsuranceInformation();
  }, [insuranceInfo]);

  useEffect(() => {
    if (
      patientInfo &&
      patientAndFamilyMemberList &&
      Array.isArray(patientAndFamilyMemberList) &&
      patientAndFamilyMemberList.length == 1
    ) {
      setPatientId(patientInfo.patid);
    }
  }, [patientAndFamilyMemberList]);

  useEffect(() => {
    if (patientId > 0 && patientState) {
      setPrimaryConsultantInfoCollectionDecision(ConsentOptions.None);
      if (shouldDisplayPrimaryConsentForm()) {
        setShouldAllowToContinue(false);
      } else {
        setShouldAllowToContinue(true);
      }
      setIsFormVisible(true);
    } else {
      setIsFormVisible(false);
    }
  }, [patientId, patientState]);

  useEffect(() => {
    if (isOnDemandCampaignFormAvailable()) {
      var formData = {};
      var formArray = JSON.parse(campaignForms[currentFormIndex].formComponent);

      var formObj = {
        display: "form",
        components: formArray
      };

      var submissionData = {};
      var formOptions = {};

      if (campaignForms[currentFormIndex].formData) {
        submissionData = {
          data: campaignForms[currentFormIndex].formData
        };

        var formOptions = {
          readOnly: true,
          viewAsHtml: false
        };
      }

      formData.formObj = formObj;
      formData.submissionData = submissionData;
      formData.formOptions = formOptions;

      setDisplayFormData(formData);

      if (isAllFormSubmitted()) {
        navigateToAppointmentScheduleDateTime();
      }
    }
  }, [currentFormIndex, campaignForms]);

  function getOnDemandConsultationPrice() {
    if (schedulerCampaign && schedulerCampaign.campaignCharge) {
      return (
        <div className="fs-3 text-primary mb-3 mb-md-4">
          {
            shouldDisplayCampaingChargeInfo ?
              (<>Price of visit: <span>${schedulerCampaign.campaignCharge}</span></>)
              : (<></>)
          }
        </div>
      );
    }
  }

  function isOnDemandCampaignFormAvailable() {
    return (
      campaignForms && Array.isArray(campaignForms) && campaignForms.length > 0
    );
  }

  function onFormSubmit(submission) {
    setTimeout(function () {
      if (submission && submission.data) {
        onSuccessfulFormSubmission(submission.data);
      }
    }, 500);
  }

  function onSuccessfulFormSubmission(submissionData) {
    if (submissionData && currentFormIndex >= 0 && campaignForms) {
      var campaignFormsData = [...campaignForms];

      campaignFormsData?.forEach((form, index) => {
        if (index == currentFormIndex) {
          form.isSubmitted = true;
          form.formData = submissionData;
        }
      });

      setCampaignForms(campaignFormsData);
      nextBtnClicked();
    }
  }

  function nextBtnClicked() {
    if (currentFormIndex < totalForms - 1) {
      setCurrentFormIndex(currentFormIndex + 1);
    }
  }

  function isAllFormSubmitted() {
    var allSubmitted = true;

    if (isOnDemandCampaignFormAvailable()) {
      campaignForms.map((form) => {
        if (form?.isSubmitted == false) {
          allSubmitted = false;
        }
      });
    }

    return allSubmitted;
  }

  function addDependentBtnClicked(formData) {
    if (isAddFamilyMemberFormVisible && formData) {
      var patientInfo = getPatientInfo();
      var dobValidityCheck = isPatientDateOfBirthValid(
        getValues(SignUpFormFields.DateOfBirth)
      );

      if (dobValidityCheck.valid) {
        var dependentData = {
          firstName: formData.firstName,
          lastName: formData.lastName,
          gender: formData.gender,
          dateOfBirth: formData.dateOfBirth,
          respPartyUserId: patientInfo.user.id,
          isRespPartyIncluded: true,
          pgId: patientInfo.pgid,
          address1: patientInfo.address1,
          address2: patientInfo.address2,
          country: patientInfo.country,
          city: patientInfo.city,
          timezone: patientInfo.timezone,
          state: patientInfo.state,
          zip: patientInfo.zip,
          emergencyContactPhone: patientInfo.emergencyContactPhone,
          activeOfficeId: officeId,
          origin: getPatientOrigin()
        };

        dependentData.newUser = {
          pgId: patientInfo.pgid,
          firstName: formData.firstName,
          lastName: formData.lastName,
          dateOfBirth: formData.dateOfBirth,
          roleId: Roles.Patient,
          gender: formData.gender,
          country: patientInfo.country,
          city: patientInfo.city,
          timezone: patientInfo.timezone,
          state: patientInfo.state,
          zip: patientInfo.zip
        };

        dependentData.respParty = {
          pgId: patientInfo.pgid,
          email: patientInfo.user.userId,
          phone: patientInfo.user.phone,
          firstName: patientInfo.firstName,
          lastName: patientInfo.lastName,
          relationToPatient: formData.relation
        };

        var formData = new FormData();
        formData.append("patientVM", JSON.stringify(dependentData));

        dispatch(showLoadingSpinner());

        savePatientProfileSettings_Ajax(
          formData,
          function (response) {
            dispatch(hideLoadingSpinner());

            if (response && response.success && response.data) {
              reset();
              setIsAddFamilyMemberFormVisible(false);
              setPatientId(response.data.patid);
              showAlertDialouge("Message", AddDependentSuccessMessage);
              dispatch(setIsFamilyMembersListChanged(true));
            } else if (response && response.success && response.message) {
              showAlertDialouge("Error", response.message);
            }
          },
          function (err) {
            dispatch(hideLoadingSpinner());
            handleApiErrors(err);
          }
        );
      } else {
        var errorMessage = dobValidityCheck.errorMessage
          ? dobValidityCheck.errorMessage
          : "Please enter a valid date of birth.";
        showAlertDialouge("Error", errorMessage);
        return;
      }
    }
  }

  function navigateToAppointmentScheduleDateTime() {
    var nextRoute = "";

    if (isPharmacySelectionEnabled) {
      nextRoute = "/appointment-schedule-pharmacy-selection";
    } else {
      nextRoute = "/appointment-schedule-date-time";
    }

    var patientData = null;

    if (patientId > 0) {
      patientData = patientAndFamilyMemberList.find((member) => {
        return member.patid == patientId;
      });
    }

    navigate(nextRoute, {
      state: {
        schedulerCampaign,
        patientState,
        campaignForms,
        patientData,
        userData,
        insuranceInfo,
        primaryDentistInfo: getPrimaryConsultantInfo(),
        primaryConsultantInfoCollectionDecision
      }
    });
  }

  function getPrimaryConsultantInfo() {
    if (
      primaryDentistInfo != null &&
      primaryConsultantInfoCollectionDecision == ConsentOptions.Agree
    ) {
      return primaryDentistInfo;
    }
    return null;
  }

  function handlePatientStateChange(e) {
    const { value } = e.target;
    setPatientState(value);
  }

  function handlePatientChange(e) {
    const patientId = e.target.value;
    setPatientId(patientId);
    setExistingFamilyMemberAsCurrentPatient(patientId);
  }

  function setExistingFamilyMemberAsCurrentPatient(patientId) {
    if (patientId) {
      let patientInfo = patientAndFamilyMemberList.find(
        (member) => member.patid == patientId
      );
      setCurrentPatient(patientInfo);
      storeSelectedPatientProfile(JSON.stringify(patientInfo));
    }
  }

  function setCurrentPatient(patientInfo) {
    if (patientInfo) {
      dispatch(saveCurrentPatient(patientInfo));
    }
  }

  function toogleAddFamilyMemberFormVisibility() {
    setIsAddFamilyMemberFormVisible(!isAddFamilyMemberFormVisible);
  }

  function isNextBtnEnabled() {
    if (isAddFamilyMemberFormVisible) return isValid && patientState;
    else return patientState && patientId > 0;
  }

  function shouldDisplayCaptureInsurance() {
    if (isFormVisible && !shouldShowDeclinedVerbiage()) {
      return <>{displayInsuranceCaptureInfo()}</>;
    }
    return <></>;
  }

  function shouldShowVerbiageBasedOnNonParCarrier() {
    if (isCaptureInsuranceFormVisible()) {
      return <>{showVerbiageBasedOnNonParCarrierSelected()}</>;
    }
    return <></>;
  }

  function shouldNavigateToNextPage() {
    if (shouldCaptureInsuranceInfo && !displayFormData) {
      navigateToAppointmentScheduleDateTime();
    }
  }

  function shouldDisableNextButton() {
    return (
      !isNextBtnEnabled() ||
      !shouldAllowToContinue ||
      isCaptureInsuranceFormVisible() ||
      shouldShowDeclinedVerbiage()
    );
  }

  function isCaptureInsuranceFormVisible() {
    return isInsuranceFormVisible && shouldCaptureInsuranceInfo;
  }

  function shouldShowNextButton() {
    return (
      !displayFormData &&
      !isCaptureInsuranceFormVisible() &&
      !shouldShowDeclinedVerbiage()
    );
  }

  function shouldDisplayForm() {
    return (
      isFormVisible &&
      displayFormData &&
      shouldAllowToContinue &&
      !isCaptureInsuranceFormVisible() &&
      !shouldShowDeclinedVerbiage()
    );
  }

  return (
    <>
      <div
        className="tab-pane fade show active"
        id="see-dentist"
        role="tabpanel"
        aria-labelledby="see-dentist-tab"
      >
        <fieldset>
          <legend className="fs-1 mb-0">Schedule virtual appointment</legend>
          {getOnDemandConsultationPrice()}
          {shouldShowVerbiageBasedOnNonParCarrier()}

          <div className="form-group mt-4 mb-3 col-md-8 col-xl-6">
            <div className="form-group-title fs-3 text-violet mb-2 mb-md-3 lh-1">
              Who is this visit for? <span className="text-danger">*</span>
            </div>
            <div className="form-check mb-2 ps-0">
              <select
                className="form-select form-control"
                name="dependentsList"
                id=""
                value={patientId}
                disabled={isAddFamilyMemberFormVisible}
                onChange={handlePatientChange}
              >
                <option value="" selected="selected">
                  Select Patient
                </option>
                {!isEmptyArray(patientAndFamilyMemberList) &&
                  patientAndFamilyMemberList.map((patient, index) => (
                    <option key={index} value={patient.patid}>
                      {patient.fullName}
                    </option>
                  ))}
              </select>
            </div>
            <div
              className="mt-2"
              href="javascript:;"
              onClick={toogleAddFamilyMemberFormVisibility}
            >
              <a className="text-muted">
                <i className="bi bi-plus"> </i>
                Add Dependent
              </a>
            </div>

            <div
              id="show-hide-div"
              style={{
                display: isAddFamilyMemberFormVisible ? "flex" : "none"
              }}
            >
              <form
                className="row pt-4"
                onSubmit={handleSubmit(addDependentBtnClicked)}
              >
                <div className="form-group mb-3 col-md-6">
                  <label className="form-label">
                    First Name<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First name"
                    {...register(SignUpFormFields.FirstName, {
                      required: true,
                      maxLength: {
                        value: 50,
                        message: "Maximum 50 characters are allowed."
                      }
                    })}
                  />
                  {errors.firstName?.message && (
                    <p className="help-block">{errors.firstName.message}</p>
                  )}
                </div>
                <div className="form-group mb-3 col-md-6">
                  <label className="form-label">
                    Last Name<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First name"
                    {...register(SignUpFormFields.LastName, {
                      required: true,
                      maxLength: {
                        value: 50,
                        message: "Maximum 50 characters are allowed."
                      }
                    })}
                  />
                  {errors.lastName?.message && (
                    <p className="help-block">{errors.lastName.message}</p>
                  )}
                </div>
                <div className="form-group mb-3 col-md-6">
                  <label className="form-label">
                    Select Relation<span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-select form-control"
                    name="relation"
                    id="relation"
                    {...register(SignUpFormFields.Relation, { required: true })}
                  >
                    <option value="" defaultValue>
                      Select Relation
                    </option>
                    <option value="Son">Son</option>
                    <option value="Daughter">Daughter</option>
                    <option value="Spouse">Spouse</option>
                    <option value="Father">Father</option>
                    <option value="Mother">Mother</option>
                    <option value="Friend">Friend</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="form-group mb-3 col-md-6">
                  <label className="form-label">
                    Date of Birth<span className="text-danger">*</span>
                  </label>
                  <DateInputMask
                    register={register}
                    fieldName={SignUpFormFields.DateOfBirth}
                    errors={errors}
                    validationType={ValidationTypes.DateOfBirth}
                  />
                </div>
                <div className="form-group mb-3 col-md-12 d-flex flex-wrap align-items-center">
                  <label className="d-inline-block mb-2">
                    Gender<span className="text-danger">*</span>
                  </label>
                  <div className="form-check mb-2 me-2 me-md-4 d-inline-block">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="anyAllergy"
                      id="genderMale"
                      value={GenderOptions.Male}
                      {...register(SignUpFormFields.Gender, { required: true })}
                    />
                    <label
                      className="form-check-label fs-4"
                      htmlFor="genderMale"
                    >
                      Male
                    </label>
                  </div>
                  <div className="form-check mb-2 me-2 me-md-4 d-inline-block">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="anyAllergy"
                      id="genderFemale"
                      value={GenderOptions.Female}
                      {...register(SignUpFormFields.Gender, { required: true })}
                    />
                    <label
                      className="form-check-label fs-4"
                      htmlFor="genderFemale"
                    >
                      Female
                    </label>
                  </div>
                  <div className="form-check mb-2 me-2 me-md-4 d-inline-block">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="anyAllergy"
                      id="genderOther"
                      value={GenderOptions.Other}
                      {...register(SignUpFormFields.Gender, { required: true })}
                    />
                    <label
                      className="form-check-label fs-4"
                      htmlFor="genderOther"
                    >
                      Other
                    </label>
                  </div>
                </div>
                <div className="form-group text-center mt-2 mt-md-4 col-sm-12">
                  <button
                    disabled={!isValid}
                    type="submit"
                    className="btn btn-primary btn-rounded btn-sm px-5"
                  >
                    Add Dependent
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div className="form-group mt-4 mb-3 col-md-8 col-xl-6">
            <div className="form-group-title fs-3 text-violet mb-2 mb-md-3 lh-1">
              Where will you be located during your visit?{" "}
              <span className="text-danger">*</span>
            </div>
            <select
              className="form-select form-control"
              name="state"
              id="state"
              onChange={handlePatientStateChange}
            >
              <option value="" selected="selected">
                Select State
              </option>
              {schedulerCampaign &&
                schedulerCampaign.campaignStates &&
                schedulerCampaign.campaignStates.map((campaignState, index) => (
                  <option key={index} value={campaignState?.state}>
                    {campaignState?.stateName}
                  </option>
                ))}
            </select>
          </div>
          {showVerbiageBasedOnStateSelected()}
          {shouldDisplayPrimaryConsentForm() && isFormVisible && (
            <ConsentFormForPrimaryConsultant
              setPrimaryDentistInfo={setPrimaryDentistInfo}
              setShouldAllowToContinue={setShouldAllowToContinue}
              setPrimaryConsultantInfoCollectionDecision={
                setPrimaryConsultantInfoCollectionDecision
              }
              primaryConsultantInfoCollectionDecision={
                primaryConsultantInfoCollectionDecision
              }
            />
          )}
          {shouldDisplayCaptureInsurance()}

          {shouldDisplayForm() ? (
            <div id="campaign-form" key={currentFormIndex}>
              <h1>
                Form ({currentFormIndex + 1}/{totalForms})
              </h1>
              <div className="campaign-form-container">
                <h3>{campaignForms[currentFormIndex].title}</h3>
                <Form
                  onRender={initializeAdditionalChangesInFormIoFormDuringRender}
                  key={"displayed_" + currentFormIndex}
                  form={displayFormData.formObj}
                  onSubmit={onFormSubmit}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="d-table mx-auto">
            <div className="form-text icon fs-3">
              <i className="bi bi-exclamation-circle-fill text-gold"></i>If this
              is a medical emergency, please dial 911 or go to your nearest
              medical facility immediately.
            </div>
          </div>

          <div
            className="nav form-group text-center d-flex flex-wrap justify-content-center mt-2 mt-md-4 col-sm-12 button-wrapper"
            id="step-tab"
            role="tablist"
          >
            <Link className="d-block w-100 w-md-auto" to="/dashboard">
              <button
                type="submit"
                className="btn btn-outline btn-rounded btn-lg px-3 px-md-5 mx-md-2 mt-3 mt-md-0"
              >
                Cancel
              </button>
            </Link>
            {shouldShowNextButton() && (
              <button
                className="btn btn-secondary btn-rounded btn-lg px-3 px-md-5 mx-md-2 mt-3 mt-md-0"
                id="billing-info-tab"
                type="button"
                onClick={navigateToAppointmentScheduleDateTime}
                disabled={shouldDisableNextButton()}
              >
                Next
              </button>
            )}
          </div>
        </fieldset>
      </div>
    </>
  );
};

export default AppointmentSchedulePages;
