import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import "./../../assets/css/print-style.css";
import {
  getProviderData_Ajax,
  getNotePrintDetails_Ajax,
  getProgressNoteAttachments_Ajax
} from "../../helpers/requests";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import {
  getGenderText,
  handleApiErrors,
  isImageFile,
  isValidDocFileForPatientUpload,
  onImageError,
  showAlertDialouge
} from "../../helpers/utils";

import pdf_file_icon from "./../../assets/images/pdf_file_icon.png";
import $ from "jquery";
import { SignatureType } from "../../constants";
import AttachedSignature from "./AttachedSignature";

const NotesPrint = () => {
  const location = useLocation();
  const { noteId, noteType } = useParams();
  const alert = useAlert();
  const dispatch = useDispatch();
  const [providerData, setProviderData] = useState(null);
  const [notePrintDetails, setNotePrintDetails] = useState(null);
  const [noteAttachedForms, setNoteAttachedForms] = useState(null);
  const [isScribeTypeSignature, setIsScribeTypeSignature] = useState(false);

  useEffect(() => {
    getNotePrintDetails();
  }, []);

  useEffect(() => {
    getProviderData();
  }, [notePrintDetails]);

  useEffect(() => {
    getProgressNoteAttachments();
  }, []);

  useEffect(() => {
    setIsScribeTypeSignature(
      notePrintDetails?.signatureType == SignatureType.Scribe
    );
  }, [notePrintDetails]);

  function getProgressNoteAttachments() {
    if (noteType && noteId) {
      dispatch(showLoadingSpinner());
      getProgressNoteAttachments_Ajax(
        noteId,
        noteType,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (response && response.success && response.data) {
            if (
              response.data.attachedForms &&
              response.data.attachedForms.length > 0
            )
              setNoteAttachedForms(response.data.attachedForms);
          }
        },
        function (err) {
          handleApiErrors(err);
          dispatch(hideLoadingSpinner());
        }
      );
    }
  }

  function getNotePrintDetails() {
    if (noteType && noteId) {
      dispatch(showLoadingSpinner());
      getNotePrintDetails_Ajax(
        noteId,
        noteType,
        function (response) {
          dispatch(hideLoadingSpinner());
          setNotePrintDetails(response.data.note);
        },
        function (err) {
          dispatch(hideLoadingSpinner());
        }
      );
    }
  }

  function getProviderData() {
    if (
      notePrintDetails &&
      notePrintDetails.provider &&
      notePrintDetails.provider.providerid
    ) {
      dispatch(showLoadingSpinner());
      getProviderData_Ajax(
        notePrintDetails.provider.providerid,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (response && response.success && response.data) {
            setProviderData(response.data);
          } else if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function isValidDate(date) {
    var dateTime = new Date(date);
    if (!date || !dateTime.getTime()) {
      return false;
    } else {
      return true;
    }
  }

  function calculateAge(birthdayString) {
    // birthday is a string
    if (!isValidDate(birthdayString)) {
      return "";
    }

    var birthday = new Date(birthdayString);
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  function getAttachmentThumbnails(noteAttatchments) {
    return (
      <>
        {" "}
        <div style={{ marginTop: "20px" }}>
          {noteAttatchments &&
            Array.isArray(noteAttatchments) &&
            noteAttatchments.length > 0 &&
            noteAttatchments.map((attachment) => {
              return (
                attachment.fileId &&
                isImageFile(attachment.fileId) && (
                  <img
                    key={attachment.attatchmentId}
                    style={{ width: "80px", height: "80px" }}
                    src={attachment.fileId}
                    onError={onImageError}
                  />
                )
              );
            })}
        </div>
        <div style={{ marginTop: "20px" }}>
          {noteAttatchments &&
            Array.isArray(noteAttatchments) &&
            noteAttatchments.length > 0 &&
            noteAttatchments.map((attachment) => {
              return (
                isValidDocFileForPatientUpload(attachment.fileId) && (
                  <a
                    key={attachment.attatchmentId}
                    style={{ marginRight: "5px", display: "inline-block" }}
                  >
                    <img
                      key={attachment.attatchmentId}
                      style={{ width: "80px", height: "80px" }}
                      src={pdf_file_icon}
                      onError={onImageError}
                    />
                  </a>
                )
              );
            })}
        </div>
      </>
    );
  }

  function printNote() {
    document.title = "Note";
    window.print();
  }

  return (
    <>
      {notePrintDetails && providerData && (
        <body
          style={{ margin: "0", padding: "20px 15px", background: "#f2f2f2" }}
        >
          <div
            style={{
              textAlign: "right",
              maxWidth: "700px",
              margin: "0px auto 20px auto"
            }}
          >
            <button
              onClick={() => printNote()}
              style={{
                backgroundColor: "#3fbbeb",
                border: "1px solid #3fbbeb",
                borderRadius: "50rem",
                fontSize: "12px",
                padding: "3px 15px",
                color: "#fff",
                cursor: "pointer"
              }}
              className="noprint"
            >
              <i className="fa fa-print"></i> Print
            </button>
          </div>
          <div
            id="note-body"
            style={{
              maxWidth: "700px",
              color: "#5D6267",
              margin: "20px auto",
              padding: "20px 30px",
              fontFamily: "Arial, Helvetica, sans-serif",
              backgroundColor: "#fff",
              minHeight: "600px"
            }}
          >
            <table width={"100%"} style={{ borderBottom: "3px solid #3fbbeb" }}>
              <tbody>
                <tr>
                  <td style={{ width: "100%" }}>
                    <table style={{ marginBottom: "15px" }}>
                      <tr>
                        <td
                          style={{
                            fontSize: "16px",
                            padding: "7px 0",
                            fontWeight: "600"
                          }}
                        >
                          {notePrintDetails.provider?.fullName}{" "}
                          <span style={{ fontSize: "12px", fontWeight: "400" }}>
                            {providerData?.title}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: "12px", padding: "3px 0" }}>
                          {notePrintDetails.office?.officeNameWithAddress
                            ? notePrintDetails.office?.officeNameWithAddress
                            : " "}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: "12px", padding: "3px 0" }}>
                          {notePrintDetails.office?.arealAddress
                            ? notePrintDetails.office?.arealAddress
                            : " "}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: "12px", padding: "3px 0" }}>
                          Provider DEA:{" "}
                          {providerData.dea ? providerData.dea : " "}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: "12px", padding: "3px 0" }}>
                          Provider NPI:{" "}
                          {notePrintDetails.provider?.providerInsuranceInfo?.npi
                            ? notePrintDetails.provider?.providerInsuranceInfo
                                ?.npi
                            : " "}
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              className="patient-detail"
              width={"100%"}
              style={{ marginTop: "20px", borderSpacing: "0" }}
            >
              <thead>
                <tr>
                  <th
                    colSpan="3"
                    style={{
                      textAlign: "left",
                      fontSize: "12px",
                      padding: "10px 0"
                    }}
                  >
                    Patient Name:{" "}
                    <span style={{ color: "#3fbbeb", paddingLeft: "10px" }}>
                      {notePrintDetails.patient?.fullName}
                    </span>
                  </th>
                  <th
                    style={{
                      textAlign: "left",
                      fontSize: "12px",
                      padding: "10px 0"
                    }}
                  >
                    Age:{" "}
                    <span style={{ color: "#3fbbeb", paddingLeft: "10px" }}>
                      {calculateAge(notePrintDetails.patient?.dateOfBirth)}
                    </span>
                  </th>
                  <th
                    style={{
                      textAlign: "left",
                      fontSize: "12px",
                      padding: "10px 0"
                    }}
                  >
                    Gender:{" "}
                    <span style={{ color: "#3fbbeb", paddingLeft: "10px" }}>
                      {getGenderText(notePrintDetails.patient?.gender)}
                    </span>
                  </th>
                </tr>
                <tr>
                  <th
                    colSpan="2"
                    style={{
                      textAlign: "left",
                      fontSize: "12px",
                      padding: "10px 0"
                    }}
                  >
                    Date:{" "}
                    <span style={{ color: "#3fbbeb", paddingLeft: "10px" }}>
                      {new Date(notePrintDetails.createdOn).toLocaleDateString(
                        "en-US"
                      )}
                    </span>
                  </th>
                  <th
                    colSpan="3"
                    style={{
                      textAlign: "left",
                      fontSize: "12px",
                      padding: "10px 0"
                    }}
                  >
                    Address:{" "}
                    <span style={{ color: "#3fbbeb", paddingLeft: "10px" }}>
                      {notePrintDetails.patient?.fullAddress
                        ? notePrintDetails.patient?.fullAddress
                        : " "}
                    </span>
                  </th>
                </tr>
              </thead>
            </table>
            <table
              width={"100%"}
              style={{ marginTop: "20px", borderSpacing: "0" }}
            >
              <tbody>
                <tr>
                  {notePrintDetails.prescriptionNeeded && (
                    <p className="d-block text-decoration-none">
                      <strong>Prescription needed</strong>
                    </p>
                  )}
                </tr>
                <tr>
                  {notePrintDetails.referralSuggestionNeeded && (
                    <p className="d-block text-decoration-none">
                      <strong>Referral needed</strong>
                    </p>
                  )}
                </tr>
              </tbody>
            </table>
            <table width={"100%"}>
              <tbody>
                <tr>
                  <td
                    colSpan="2"
                    style={{
                      fontSize: "16px",
                      padding: "50px 0 10px",
                      fontWeight: "600"
                    }}
                  >
                    Notes
                  </td>
                </tr>
                {notePrintDetails.dateOfService == null && (
                  <>
                    <tr>
                      {
                        <td
                          className="text-break"
                          style={{
                            padding: "10px 0px",
                            lineHeight: "20px",
                            fontSize: "14px"
                          }}
                        >
                          {notePrintDetails.description ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: notePrintDetails.description
                              }}
                            />
                          ) : (
                            " "
                          )}
                        </td>
                      }
                    </tr>
                    <tr>
                      {notePrintDetails.noteAttatchments &&
                        Array.isArray(notePrintDetails.noteAttatchments) &&
                        notePrintDetails.noteAttatchments?.length > 0 && (
                          <td>
                            {getAttachmentThumbnails(
                              notePrintDetails.noteAttatchments
                            )}
                          </td>
                        )}

                      {notePrintDetails.attachedForms &&
                        Array.isArray(notePrintDetails.attachedForms) &&
                        notePrintDetails.attachedForms?.length > 0 && (
                          <td>
                            Attached Forms: <br />
                            <div
                              className="attached_tags small"
                              style={{ marginTop: "15px" }}
                            >
                              {notePrintDetails.attachedForms.map((forms) => {
                                return (
                                  <a
                                    style={{
                                      marginRight: "5px",
                                      display: "inline-block"
                                    }}
                                    href="javascript:void(0);"
                                  >
                                    <span>
                                      {forms.medicalForm?.title
                                        ? forms.medicalForm.title
                                        : " "}
                                    </span>
                                  </a>
                                );
                              })}
                            </div>
                          </td>
                        )}
                    </tr>
                  </>
                )}
              </tbody>
            </table>

            {notePrintDetails.dateOfService && (
              <table width={"100%"}>
                <tbody>
                  <tr>
                    <td
                      colSpan="2"
                      style={{
                        fontSize: "16px",
                        padding: "50px 0 10px",
                        fontWeight: "600"
                      }}
                    >
                      Date of Service:{" "}
                      {new Date(
                        notePrintDetails.dateOfService
                      ).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "numeric"
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="text-break"
                      style={{
                        padding: "10px 0px",
                        lineHeight: "20px",
                        fontSize: "14px"
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: notePrintDetails.description
                            ? notePrintDetails.description
                            : ""
                        }}
                      ></div>
                    </td>
                  </tr>
                  {noteAttachedForms &&
                    Array.isArray(noteAttachedForms) &&
                    noteAttachedForms.length > 0 && (
                      <tr>
                        <td>
                          Attached Forms: <br />
                          {
                            <div
                              className="attached_tags small"
                              style={{ marginTop: "15px" }}
                            >
                              {noteAttachedForms.map((attachedForm, index) => {
                                return (
                                  <a
                                    key={index.toString()}
                                    style={{
                                      marginRight: "5px",
                                      display: "inline-block"
                                    }}
                                    href="javascript:void(0);"
                                  >
                                    <span>
                                      {attachedForm.medicalForm?.title}
                                    </span>
                                  </a>
                                );
                              })}
                            </div>
                          }
                        </td>
                      </tr>
                    )}
                </tbody>
              </table>
            )}
            <AttachedSignature
              isSignatureAttached={notePrintDetails?.isSignatureAttached}
              attachedSignature={notePrintDetails?.attachedSignature}
              isScribeTypeSignature = {isScribeTypeSignature}
            />
          </div>
        </body>
      )}
    </>
  );
};

export default NotesPrint;
