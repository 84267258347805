import React from "react";
import { isEmptyArray } from "../../helpers/utils";

const useShowVerbiageBasedOnStateSelected = (
  declinedStates,
  onDemandCampaign,
  patientState
) => {
  function isStateAvailabilityandInsuranceCheckEnabledForCampaign() {
    return onDemandCampaign?.isStateAvailabilityandInsuranceCheckEnabled;
  }

  const shouldShowDeclinedVerbiage = () => {
    if (
      !isEmptyArray(declinedStates) &&
      isStateAvailabilityandInsuranceCheckEnabledForCampaign() &&
      patientState
    ) {
      return declinedStates.find((x) => x.state == patientState);
    }
    return false;
  };

  const showVerbiageBasedOnStateSelected = () => {
    if (shouldShowDeclinedVerbiage()) {
      return (
        <div className="d-table mx-auto">
          <div className="form-text icon fs-3">
            <i className="bi bi-exclamation-circle-fill text-gold"></i>Virtual
            dentists are not available in your State at this time. We apologize
            for the inconvenience.
          </div>
        </div>
      );
    }
    return <></>;
  };

  return [shouldShowDeclinedVerbiage, showVerbiageBasedOnStateSelected];
};

export default useShowVerbiageBasedOnStateSelected;
