import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useAlert } from "react-alert";

import { ForgotPasswordFormFields, Patterns } from "../../constants";
import { forgotPassword_Ajax } from "../../helpers/requests";
import {
  showLoadingSpinner,
  hideLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import { showAlertDialouge } from "../../helpers/utils";
import BrandLogo from "../BrandLogo/BrandLogo";
import { getPgidFromUrl } from "../../ApplicationSettings";

const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const alert = useAlert();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid }
  } = useForm({ mode: "onChange" });

  function submitPasswordResetRequest() {
    var mobileNumberOrEmailId = getValues(
      ForgotPasswordFormFields.MobileNumberOrEmailId
    );

    if (mobileNumberOrEmailId) {
      var pgId = getPgidFromUrl();
      var formData = {
        userId: mobileNumberOrEmailId,
        pgId: pgId,
        isDentalDotComPatient: true
      };

      dispatch(showLoadingSpinner());
      forgotPassword_Ajax(
        JSON.stringify(formData),
        function (response) {
          dispatch(hideLoadingSpinner());
          if (response && response.success) {
            showAlertDialouge(
              "Message",
              "An email has been sent with instruction to reset password. Please check your email.",
              function () {
                navigate("/login");
              }
            );
          } else if (response && !response.success && response.message) {
            dispatch(hideLoadingSpinner());
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {}
      );
    }
  }

  return (
    <div className="container">
      <BrandLogo/>
      <div className="row">
        <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xxl-4 offset-xxl-4">
          <form
            className="bg-white border-radius-xlg px-4 px-md-5 py-4"
            onSubmit={handleSubmit(submitPasswordResetRequest)}
          >
            <h2 className="text-center text-violet mb-4">Forgot Password</h2>
            <div className="form-group mb-3">
              <label className="mb-2" htmlFor="">
                Email ID
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Email ID"
                {...register(ForgotPasswordFormFields.MobileNumberOrEmailId, {
                  required: "Please enter an email address",
                  pattern: {
                      value: Patterns.Email,
                      message:
                        "Please enter a valid email address. (e.g. example@email.com)"
                    }
                })}
              />
              {errors.mobileNumberOrEmailId?.message && (
                 <p className="help-block"> {errors.mobileNumberOrEmailId?.message}</p>
              )}
            </div>
            <div className="form-group text-center mt-2 mt-md-4 col-sm-12">
              <button
                type="submit"
                className="btn btn-secondary btn-rounded btn-lg px-5"
                disabled={!isValid}
              >
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
