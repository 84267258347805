import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getLocalTimezoneOffset,
  showAlertDialouge,
  handleApiErrors,
  toTimezoneName,
  toTimezoneOffset,
  GetTimezoneFullName,
  redirectToCheckout,
  isEmptyArray,
  onImageError,
  queryStringToJSON,
  getCampaignStatesOfType
} from "../../helpers/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  getAppointmentSlots_Ajax,
  getCampaign_Ajax,
  getProviderSchedules_Ajax,
  postBookingProcedures_Ajax,
  secondOpinionConsultationRequest_Ajax
} from "../../helpers/requests";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import moment from "moment";
import {
  CampaignStateSettingTypes,
  DentalDotComPortalType,
  PaymentCancelUrl
} from "../../constants";
import { getBaseUrl } from "../../ApplicationSettings";

const SecondOpinionAppointmentPage = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { providerId, patientId, patientName } = props;

  const secondOpinionApptOid = useSelector(
    (state) => state?.appSettings?.settingsData?.secondOpinionApptOid
  );
  const campaignId = useSelector(
    (state) => state?.appSettings?.settingsData?.schedulerCampaignId
  );

  // campaign data
  const [schedulerCampaign, setSchedulerCampaign] = useState(null);

  const [inputs, setInputs] = useState({
    date: "",
    hour: "",
    minute: "",
    meridiem: "AM"
  });

  const { date, hour, minute, meridiem } = inputs;
  const [providerOffice, setProviderOffice] = useState(null);
  const [providerSchedules, setProviderSchedules] = useState([]);
  const [providerSchedulesGroupedByDay, setProviderSchedulesGroupedByDay] =
    useState([]);
  const [scheduledDateTime, setScheduledDateTime] = useState(null);
  const [providerInfo, setProviderInfo] = useState(null);
  const [availableDates, setAvailableDates] = useState([]);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selDate, setSelDate] = useState(null);
  const [selDateSlot, setSelDateSlot] = useState([]);
  const [selSlot, setSelSlot] = useState(null);
  const [isBookingInProgress, setIsBookingInProgress] = useState(false);
  const [patientState, setPatientState] = useState(null);

  useEffect(() => {
    getProviderSchedules();
    getCampaign(campaignId);
  }, []);

  useEffect(() => {
    if (providerOffice && providerSchedules) {
      groupScheduleTimesByDay();
    }
  }, [providerSchedules, providerOffice]);

  useEffect(() => {
    if (providerOffice) {
      getAppointmentSlot(moment().format("yyyy-MM-DD"));
    }
  }, [providerOffice]);

  useEffect(() => {
    const { date, hour, minute, meridiem } = inputs;

    if (date && hour && minute && meridiem) {
      let dateTimeString = date + " " + hour + ":" + minute + " " + meridiem;
      setScheduledDateTime(dateTimeString);
    }
  }, [inputs]);

  useEffect(() => {
    if (selDate) {
      if (
        availableSlots &&
        Array.isArray(availableSlots) &&
        availableSlots.length > 0
      ) {
        let selDateSlot = availableSlots.find((slot) => slot.field == selDate);

        if (selDateSlot) {
          setSelDateSlot(selDateSlot.groupList);
        } else {
          setSelDateSlot([]);
        }
      }
    }
  }, [selDate]);

  function handleDateChange(e) {
    const { value } = e.target;

    if (value) {
      getAppointmentSlot(moment(value).format("YYYY-MM-DD"));
      setInputs((inputs) => {
        return { ...inputs, date: value };
      });
    }
  }

  function handlePatientStateChange(e) {
    const { value } = e.target;
    setPatientState(value);
  }

  function confirmScheduleAppointmentBtnDisabled() {
    return (
      !date ||
      !hour ||
      !minute ||
      !meridiem ||
      !selSlot ||
      isBookingInProgress ||
      !patientState
    );
  }

  function groupScheduleTimesByDay() {
    if (!isEmptyArray(providerSchedules) && providerOffice) {
      let ScheduleTimings = providerSchedules.map((timing) => {
        let temp = { ...timing };

        temp.startTime12hr = moment(temp.startTime).format("hh:mm A ");
        temp.endTime12hr = moment(temp.endTime).format("hh:mm A ");

        return temp;
      });

      let schedulesGroupedByDay = ScheduleTimings.groupBy("day");
      setProviderSchedulesGroupedByDay(schedulesGroupedByDay);
    }
  }

  function getProviderSchedules() {
    if (secondOpinionApptOid > 0 && providerId > 0) {
      let params = {
        providerId: providerId
      };

      dispatch(showLoadingSpinner());
      getProviderSchedules_Ajax(
        secondOpinionApptOid,
        params,
        function (response) {
          if (response && response.success) {
            setProviderSchedules(
              response.data.providers[0].schedules[0].scheduleTimings
            );
            setProviderOffice(response.data.providers[0].schedules[0].office);

            let providerInfo = {
              fullName: response.data.providers[0].fullName,
              photoId: response.data.providers[0].photoId
            };

            setProviderInfo(providerInfo);
          } else if (response && !response.success) {
            showAlertDialouge("Error", response.message);
          }

          dispatch(hideLoadingSpinner());
        },
        function (err) {
          handleApiErrors(err);
          dispatch(hideLoadingSpinner());
        }
      );
    }
  }

  function GetSelectedDateIndexFromAvailableDates(dates, selectedDate) {
    try {
      if (dates && dates.length > 0 && selectedDate) {
        const availableDates = dates.map((date) => {
          return moment(date).format("YYYY-MM-DD");
        });

        return availableDates.indexOf(selectedDate);
      }
    } catch (ex) {}

    return -1;
  }

  function getAppointmentSlot(date) {
    if (secondOpinionApptOid > 0 && providerId > 0 && providerOffice && date) {
      let officeTimeZoneOffSet = toTimezoneOffset(providerOffice.timeZone);
      let localDatetime = moment()
        .utc()
        .add(officeTimeZoneOffSet, "hours")
        .format("YYYY-MM-DD HH:mm:ss");

      let params = {
        currentdate: moment(date).format("YYYY-MM-DD"),
        localDatetime: localDatetime,
        duration: 30,
        days: 4,
        includePastSlots: true,
        forceConvertToOfficeTimezone: true
      };

      if (officeTimeZoneOffSet) {
        params.localTimezoneOffset = officeTimeZoneOffSet;
      }

      dispatch(showLoadingSpinner());

      setAvailableDates([]);
      setAvailableSlots([]);
      setIsLoading(true);
      setSelDate(null);
      setSelDateSlot([]);
      setSelSlot(null);

      getAppointmentSlots_Ajax(
        secondOpinionApptOid,
        providerId,
        params,
        function (response) {
          if (
            response &&
            response.success &&
            response.data &&
            response.data.appSlots &&
            Array.isArray(response.data.appSlots)
          ) {
            let availableSlots = [...response.data.appSlots];
            let slotsGroupedByDate = availableSlots.groupBy("date");

            setAvailableSlots(slotsGroupedByDate);

            if (slotsGroupedByDate && slotsGroupedByDate.length > 0) {
              let availableDates = [];

              slotsGroupedByDate.forEach((slot) => {
                availableDates.push(slot.field);
              });

              if (
                availableDates &&
                Array.isArray(availableDates) &&
                availableDates.length > 0
              ) {
                setAvailableDates(availableDates);

                let selectedDateIndex = GetSelectedDateIndexFromAvailableDates(
                  availableDates,
                  moment(date).format("YYYY-MM-DD")
                );
                let index =
                  selectedDateIndex && selectedDateIndex > -1
                    ? selectedDateIndex
                    : 0;

                setSelDate(availableDates[index]);
                setSelDateSlot(slotsGroupedByDate[index].groupList);
                setInputs((inputs) => ({
                  ...inputs,
                  date: moment(availableDates[index]).format("YYYY-MM-DD")
                }));
              }
            }
          } else if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }

          setIsLoading(false);
          dispatch(hideLoadingSpinner());
        },
        function (err) {
          handleApiErrors(err);
          dispatch(hideLoadingSpinner());
          setIsLoading(false);
        }
      );
    }
  }

  function getCampaign(campaignId) {
    let params = queryStringToJSON();
    let includeZState = false;
    if (params.includezstate) {
      if (params.includezstate == "true") {
        includeZState = true;
      }
    }
    if (campaignId) {
      let params = {
        campaignId: campaignId,
        includeIntakeForm: false,
        includeZState: includeZState
      };

      dispatch(showLoadingSpinner());
      getCampaign_Ajax(
        params,
        function (response) {
          dispatch(hideLoadingSpinner());

          if (response && response.success && response.data) {
            if (response.data.campaignStates) {
              response.data.campaignStates = getCampaignStatesOfType(
                CampaignStateSettingTypes.Assigned,
                response.data.campaignStates
              );
            }

            setSchedulerCampaign(response.data);
          } else if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function isSelectedAppointmentDateTimeValid() {
    if (scheduledDateTime) {
      let dateTimeString = moment(
        scheduledDateTime,
        "YYYY-MM-DD HH:mm a"
      ).format("YYYY-MM-DD HH:mm:ss");
      let officeTimeZoneOffSet = toTimezoneOffset(providerOffice.timeZone);
      let officeLocalDatetime = moment()
        .utc()
        .add(officeTimeZoneOffSet, "hours")
        .format("YYYY-MM-DD HH:mm:ss");
      let timeDifference = moment(dateTimeString).diff(
        officeLocalDatetime,
        "minutes"
      );

      if (timeDifference <= 59) {
        showAlertDialouge(
          "Error",
          "Please select a date and time at least one hour from now."
        );
      } else {
        return true;
      }
    }
  }

  function addScheduledOnDemandCall() {
    if (
      patientId > 0 &&
      providerId > 0 &&
      schedulerCampaign &&
      providerOffice &&
      isSelectedAppointmentDateTimeValid()
    ) {
      let dateTimeString = moment(
        scheduledDateTime,
        "YYYY-MM-DD HH:mm a"
      ).format("YYYY-MM-DD HH:mm a");

      let paymentSuccessUrl =
        getBaseUrl() + "/dashboard?apptBookingSuccess=true";
      let paymentCancelUrl = getBaseUrl() + PaymentCancelUrl;
      let patientTimezoneOffset = getLocalTimezoneOffset();

      let patientDataJson = {
        Pgid: schedulerCampaign.pgId,
        PatientId: patientId,
        ProviderId: providerId,
        ScheduledDateTime: dateTimeString,
        OfficeTimezoneOffset: providerOffice.timeZone
          ? toTimezoneOffset(providerOffice.timeZone)
          : getLocalTimezoneOffset(),
        IsDentalDotComCall: true,
        IsScheduledCall: true,
        OfficeId: secondOpinionApptOid,
        PaymentSuccessUrl: paymentSuccessUrl,
        PaymentCancelUrl: paymentCancelUrl,
        LocalTimeZoneOffset: patientTimezoneOffset,
        Duration: 30,
        State: patientState,
        CreatedFrom: DentalDotComPortalType
      };

      dispatch(showLoadingSpinner());
      secondOpinionConsultationRequest_Ajax(
        schedulerCampaign.campaignId,
        JSON.stringify(patientDataJson),
        function (response) {
          dispatch(hideLoadingSpinner());
          if (response && response.success) {
            let isRedirectToStripe = false;
            let paymentSessionId = undefined;
            let stripeAccount = undefined;

            if (response.data && response.data.postBookingProcedures) {
              postBookingProcedures_Ajax(
                JSON.stringify(response.data.postBookingProcedures)
              );
            }

            if (response.data && response.data.isRedirectToStripe) {
              isRedirectToStripe = response.data.isRedirectToStripe;
            }
            if (response.data && response.data.paymentSessionId) {
              paymentSessionId = response.data.paymentSessionId;
            }
            if (response.data && response.data.stripeAccount) {
              stripeAccount = response.data.stripeAccount;
            }

            if (isRedirectToStripe) {
              showAlertDialouge(
                "Message",
                "Please select '" +
                  patientName +
                  "' from dependents list at the top-right corner to see upcoming virtual appointments in the dashboard after completing payment." +
                  "Please Note: If payment is unsuccessful, all progress will be lost.",
                function () {
                  if (isRedirectToStripe) {
                    redirectToCheckout(paymentSessionId, stripeAccount);
                  }
                }
              );
            } else {
              showAlertDialouge(
                "Message",
                "Appointment Scheduled Successfully. Please select '" +
                  patientName +
                  "' from dependents list at the top-right corner to see upcoming virtual appointments in the dashboard.",
                function () {
                  navigate("/dashboard");
                }
              );
            }
          } else if (response && !response.success) {
            showAlertDialouge(
              "Error",
              "Your appointment could not be scheduled. Please try again."
            );
          }

          setIsBookingInProgress(false);
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
          setIsBookingInProgress(false);
        }
      );
    }
  }

  function nextButtonClick(event) {
    event.preventDefault();
    let nextDate = moment();

    if (
      availableDates &&
      Array.isArray(availableDates) &&
      availableDates.length > 0
    ) {
      let date = availableDates[availableDates.length - 1];
      nextDate = moment(date).add(1, "days");
      nextDate = moment(nextDate).format("YYYY-MM-DD");
      getAppointmentSlot(nextDate);
    }
  }

  function prevButtonClick(event) {
    event.preventDefault();
    let prevDate = moment();

    if (
      availableDates &&
      Array.isArray(availableDates) &&
      availableDates.length > 0
    ) {
      let date = availableDates[0];
      prevDate = moment(date).subtract(1, "days");
      prevDate = moment(prevDate).format("YYYY-MM-DD");
      getAppointmentSlot(prevDate);
    }
  }

  function handleAvailableDateClick(date) {
    setSelDate(date);
    setSelSlot(null);
    setInputs((inputs) => {
      return { ...inputs, date: moment(date).format("yyyy-MM-DD") };
    });
  }

  function handleSlotClicked(slot) {
    if (slot) {
      let hour = moment(slot.startTime, "hh:mm:ss").format("hh");
      let minute = moment(slot.startTime, "hh:mm:ss").format("mm");
      let meridiem = moment(slot.startTime, "hh:mm:ss").format("a");

      setInputs((inputs) => {
        return {
          ...inputs,
          hour,
          minute,
          meridiem
        };
      });
      setSelSlot(slot);
    }
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className=" mb-3">
              <div className="heading-wrapper back-to-wrapper position-relative">
                <h1 className="title">Schedule Appointment</h1>
              </div>
            </div>
            <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
              <form action="">
                <div className="form-group mb-3 col-md-12">
                  <label className="form-label">Appointment For</label>
                  {patientName ? (
                    <div className="text-primary">{patientName}</div>
                  ) : (
                    <></>
                  )}
                </div>

                {providerOffice && providerInfo ? (
                  <>
                    <div className="form-group mb-3">
                      <label className="form-label">Provider</label>
                      <div className="d-flex flex-wrap align-items-center mt-1">
                        <div>
                          <img
                            className="img-70 rounded-circle"
                            src={providerInfo.photoId}
                            onError={onImageError}
                            alt=""
                          />
                        </div>
                        <div className="flex-1">
                          <div className="fs-5 ps-3">
                            {providerInfo.fullName
                              ? providerInfo.fullName
                              : " "}
                          </div>
                          {/* <div className="text-primary fs-5">Specialization - 1</div>
                                                <div className="text-primary fs-5">DDS, MDS</div> */}
                        </div>
                      </div>
                    </div>
                    <div className=" form-view appointment_for right-wrapper col-sm-12 col-lg-8">
                      <div className="form-group mb-3 select-date">
                        <div className="row">
                          <div className="col-sm-12 col-md-4">
                            <label className="form-label">
                              {providerOffice.timeZone ? (
                                <span>
                                  {"Availability times are shown in "}
                                  {
                                    <p className="fs-3 fw-bold">
                                      {GetTimezoneFullName(
                                        toTimezoneOffset(
                                          providerOffice.timeZone
                                        ),
                                        providerOffice.isDST
                                      ) +
                                        " (" +
                                        toTimezoneName(
                                          toTimezoneOffset(
                                            providerOffice.timeZone
                                          ),
                                          providerOffice.isDST
                                        ) +
                                        ")"}
                                    </p>
                                  }
                                </span>
                              ) : (
                                <></>
                              )}
                            </label>
                            {!isEmptyArray(providerSchedulesGroupedByDay) ? (
                              providerSchedulesGroupedByDay.map(
                                (day, index) => {
                                  return (
                                    <>
                                      <label className="form-label">
                                        {day.field} :<br />
                                        {day.groupList &&
                                        day.groupList.length > 0 ? (
                                          day.groupList.map((time, index) => {
                                            return (
                                              <>
                                                {time.startTime12hr +
                                                  " to " +
                                                  time.endTime12hr +
                                                  " "}
                                                <br />
                                              </>
                                            );
                                          })
                                        ) : (
                                          <></>
                                        )}
                                      </label>
                                    </>
                                  );
                                }
                              )
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="form-group mb-3 select-date">
                        <div className="row">
                          <div className="col-sm-12 col-md-6">
                            <label className="form-label">
                              Where will you be located during your visit?{" "}
                              <span className="text-danger">*</span>
                            </label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 col-md-4">
                            <select
                              className="form-select form-control"
                              name="state"
                              id="state"
                              onChange={handlePatientStateChange}
                            >
                              <option value="" selected="selected">
                                Select State
                              </option>
                              {schedulerCampaign &&
                                schedulerCampaign.campaignStates &&
                                schedulerCampaign.campaignStates.map(
                                  (campaignState, index) => (
                                    <option
                                      key={campaignState.state}
                                      value={campaignState.state}
                                    >
                                      {campaignState.stateName}
                                    </option>
                                  )
                                )}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="form-group mb-3 select-date">
                        <div className="row">
                          <div className="col-sm-12 col-md-4">
                            <label className="form-label">
                              Select Appointment Date
                            </label>
                            <input
                              className="form-control"
                              type="date"
                              disabled={isLoading || isBookingInProgress}
                              max={"9999-12-31"}
                              name="date"
                              value={date}
                              onChange={handleDateChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {availableDates &&
                Array.isArray(availableDates) &&
                availableDates.length > 0 &&
                selDate &&
                !isLoading ? (
                  <>
                    <div className="form-group select-slot float-none mb-3">
                      <div className="d-flex flex-column flex-sm-row flex-md-row flex-lg-row flex-nowrap justify-content-between align-items-stretch">
                        <button
                          className="nav-btn prev"
                          disabled={isLoading}
                          onClick={(e) => prevButtonClick(e)}
                        >
                          Prev
                        </button>

                        <div className="date-section flex-fill">
                          <div className="d-flex flex-row flex-wrap justify-content-between">
                            {availableDates.map((date, index) => {
                              return (
                                <>
                                  <div
                                    key={date.toString()}
                                    className={
                                      "flex-fill date-item" +
                                      (date === selDate ? " open" : "")
                                    }
                                    onClick={() =>
                                      handleAvailableDateClick(date)
                                    }
                                  >
                                    <h4>{moment(date).format("ddd")}</h4>
                                    <span>
                                      {moment(date).format("MMM DD, yyyy")}
                                    </span>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </div>

                        <button
                          className="nav-btn next"
                          disabled={isLoading}
                          onClick={(e) => nextButtonClick(e)}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {selDateSlot &&
                Array.isArray(selDateSlot) &&
                selDateSlot.length > 0 &&
                !isLoading ? (
                  <>
                    <div className="form-group select-slot float-none">
                      <label className="form-label">
                        Available Appointments
                      </label>
                      <div className="slot-row d-flex flex-wrap">
                        <div className="slot-box-outer w-100">
                          {selDateSlot.map((slot, index) => {
                            return slot.isAvailable ? (
                              <div
                                key={index.toString()}
                                onClick={() => {
                                  handleSlotClicked(slot);
                                }}
                                className={
                                  "slot-box" + (slot == selSlot ? " open" : "")
                                }
                              >
                                <p>
                                  {moment(slot.startTime, "hh:mm:ss").format(
                                    "hh:mm A"
                                  )}{" "}
                                </p>
                              </div>
                            ) : (
                              <></>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <div className="form-group text-center mt-4 col-md-12">
                  <button
                    className="btn btn-secondary btn-rounded btn-lg px-3 px-md-5 mx-md-2 mt-3 mt-md-0"
                    id="review-submit-tab"
                    type="button"
                    disabled={confirmScheduleAppointmentBtnDisabled()}
                    onClick={addScheduledOnDemandCall}
                  >
                    Schedule Appointment
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SecondOpinionAppointmentPage;
