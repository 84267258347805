import React, { useEffect, useState } from "react";
import {
  formatDateTime,
  toTimezoneName,
  formatDateTimeWithZone,
  toLocal,
  isEqualToCurrentDate,
  getTimeSince
} from "../../helpers/utils";
import { DateTimeFormats } from "../../constants";
import SharedEducationModal from "./SharedEducationModal";
import { Link } from "react-router-dom";
import $ from "jquery";

const EducationTable = (props) => {
  const {
    getPatientEducations,
    isLoading,
    patientEducations,
    utcOffset,
    isDST,
    getProviderData,
    currentPatient
  } = props;

  const [sharedEducation, setSharedEducation] = useState(null);

  useEffect(() => {
    if (currentPatient) {
      getPatientEducations();
    }
  }, [currentPatient]);

  function getEducationCreationTimeAndDate(education) {
    if (education && education.createdOn) {
      var creationTime = "";

      if (utcOffset && isDST) {
        creationTime =
          formatDateTime(education.createdOn, DateTimeFormats.HH_MM_A) +
          " (" +
          toTimezoneName(utcOffset, isDST) +
          ")";
      } else {
        creationTime = formatDateTimeWithZone(
          toLocal(education.createdOn),
          DateTimeFormats.HH_MM_A
        );
      }

      var creationDateOrTimeSince = "";

      if (utcOffset) {
        creationDateOrTimeSince = formatDateTime(
          education.createdOn,
          DateTimeFormats.MMM_DD_YYYY
        );
      } else {
        creationDateOrTimeSince = formatDateTime(
          toLocal(education.createdOn),
          DateTimeFormats.MMM_DD_YYYY
        );
      }

      if (creationTime && creationDateOrTimeSince) {
        return (
          <>
            <p className="fs-4 mb-0">{creationTime}</p>
            <span className="d-block">{creationDateOrTimeSince}</span>
          </>
        );
      }
    }
  }

  function showSharedEducation(sharedEducation) {
    if (sharedEducation) {
      setSharedEducation(sharedEducation);
    }
  }

  function getEducationTableRows(patientEducations) {
    if (isLoading) {
      return <></>;
    } else if (
      patientEducations &&
      Array.isArray(patientEducations) &&
      patientEducations.length > 0 &&
      !isLoading
    ) {
      return patientEducations.map((e, index) => {
        return (
          <tr key={index.toString()}>
            <td data-label="Title">
              <h5>{e.education.title}</h5>
              <p>{e.education.description}</p>
            </td>
            <td data-label="Date">{getEducationCreationTimeAndDate(e)}</td>
            <td data-label="Category">
              {e.education.educationCategory.description}
            </td>
            <td data-label="Shared By">
              <p
                data-bs-toggle="modal"
                data-bs-target="#providerProfile"
                onClick={() => getProviderData(e)}
              >
                {e.provider.fullName}
              </p>
            </td>
            <td data-label="">
              <a
                className="text-decoration-none"
                data-bs-toggle="modal"
                data-bs-target="#educationModal"
                onClick={() => {
                  showSharedEducation(e);
                }}
              >
                View <i className="bi bi-box-arrow-up-right"></i>
              </a>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="5">No educations found.</td>
        </tr>
      );
    }
  }

  return (
    <>
      <section className="step-section py-4 py-md-5">
        <div className="container">
          <div className="row mb-3">
            <div className="col-sm-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>

                  <li className="breadcrumb-item active" aria-current="page">
                    Education
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="gth-main-header d-flex justify-content-between align-items-center mb-3">
                <h1>Education</h1>
              </div>
              <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
                {patientEducations &&
                Array.isArray(patientEducations) &&
                patientEducations.length > 0 ? (
                  <>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>Date</th>
                          <th>Category</th>
                          <th>Shared By</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>{getEducationTableRows(patientEducations)}</tbody>
                    </table>
                  </>
                ) : (
                  <div className="d-flex justify-content-center mt-5 mb-5">
                    <h2>No patient education found!</h2>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <SharedEducationModal sharedEducation={sharedEducation} />
    </>
  );
};

export default EducationTable;
