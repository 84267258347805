import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const useSelectedPatientForAppointment = () => {
  const location = useLocation();

  const selectedPatientForAppointment = useSelector((state) => {
    if (state?.userData?.selectedPatientForAppointment) {
      return state.userData.selectedPatientForAppointment;
    } else if (location?.state?.patientData) {
      return location.state.patientData;
    }

    return null;
  });

  return selectedPatientForAppointment;
};

export default useSelectedPatientForAppointment;
