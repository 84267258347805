import { useEffect, useState } from "react";
import { getSmartScanSelfieCaptureProgress } from "../../indexedDbHelper/smartScanProgressIndexedDbHelper";
import { useLocation, useNavigate } from "react-router-dom";
import { showAlertDialouge } from "../../helpers/utils";
import { getIsSmartScanSelfieCaptureProgressCachingEnabled } from "../../ApplicationSettings";

const useGetSmartScanSelfieCaptureProgress = () => {
  const { state } = useLocation();
  const [smartScanState, setSmartScanState] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (state) {
      if (getIsSmartScanSelfieCaptureProgressCachingEnabled()) {
        getSmartScanSelfieCaptureProgress(state.patientId)
          .then((smartScanProgress) => {
            if (smartScanProgress) {
              setSmartScanState({ ...smartScanProgress });
            } else {
              navigate("/");
            }
          })
          .catch((err) => {
            showAlertDialouge("Error", err);
          });
      } else {
        setSmartScanState({ ...state });
      }
    } else {
      navigate("/");
    }
  }, [state]);

  return smartScanState;
};

export default useGetSmartScanSelfieCaptureProgress;
