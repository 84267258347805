import { getIsSmartScanSelfieCaptureProgressCachingEnabled } from "../ApplicationSettings";
import { setItem, getItem, removeItem } from "./indexedDbHelper";

export function getSmartScanDBKey(patid) {
  if (patid) {
    return "smartscanprogress_" + patid;
  }
}

export function saveSmartScanSelfieCaptureProgress(smartScanProgess, patid) {
  if (getIsSmartScanSelfieCaptureProgressCachingEnabled() && patid) {
    if (smartScanProgess) {
      return setItem(getSmartScanDBKey(patid), { ...smartScanProgess });
    }
  }
}

export function getSmartScanSelfieCaptureProgress(patid) {
  if (getIsSmartScanSelfieCaptureProgressCachingEnabled() && patid) {
    return getItem(getSmartScanDBKey(patid));
  }
}

export function clearSmartScanSelfieCaptureProgress(patid) {
  if (getIsSmartScanSelfieCaptureProgressCachingEnabled() && patid) {
    return removeItem(getSmartScanDBKey(patid));
  }
}
