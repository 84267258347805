import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import CancelModal from "./CancelModal";
import DoctorImage from "./../../assets/images/img-doctor.png";
import InputCalendarIcon from "./../../assets/images/icon-input-calendar.svg";
import IconCalendar from "./../../assets/images/icon-calendar.svg";
import IconOutlook from "./../../assets/images/icon-outlook.svg";
import IconCalendar2 from "./../../assets/images/icon-calendar2.svg";
import moment from "moment";
import {
  handleApiErrors,
  onImageError,
  showAlertDialouge,
  toTimezoneName,
  toTimezoneOffset
} from "../../helpers/utils";
import { getProviderData_Ajax } from "../../helpers/requests";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import { useDispatch } from "react-redux";

const BookedAppointmentPage = (props) => {
  const dispatch = useDispatch();

  const { consultation, scheduledDateTime, timeZone } = props;

  function isJoinButtonEnabled(appointment) {
    if (appointment && appointment.scheduledDateTime) {
      var dateTimeUtc = moment.utc(appointment.scheduledDateTime).local();
      var localDateTime = moment.now();

      var difference = dateTimeUtc.diff(localDateTime);

      if (difference < 0) {
        return true;
      }
    }
  }

  function getProviderFullName() {
    if (consultation && consultation.provider) {
      return (
        (consultation.provider.firstName
          ? consultation.provider.firstName
          : "") +
        (consultation.provider.middleName
          ? " " + consultation.provider.middleName
          : "") +
        (consultation.provider.lastName
          ? " " + consultation.provider.lastName
          : "")
      );
    }
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="gth-main-header mb-3">
              <div className="heading-wrapper back-to-wrapper position-relative">
                <h1 className="title">Schedule Appointment</h1>
              </div>
            </div>
            <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
              <form action="">
                <div className="form-group mt-4 mb-3">
                  <p>
                    <b>Provider</b>
                  </p>
                  <div className="d-flex align-items-center flex-wrap mt-1">
                    {consultation && consultation.provider ? (
                      <>
                        <div>
                          <img
                            className="img-70 rounded-circle"
                            src={consultation.provider.photoId}
                            alt="Provider Image"
                            onError={onImageError}
                          />
                        </div>
                        <div className="flex-1 ps-3">
                          <div className="fs-5">{getProviderFullName()}</div>
                          {/* <div className="text-primary fs-5">Specialization - 1</div>
                                                    <div className="text-primary fs-5">DDS, MDS</div> */}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="form-group mt-4 mb-3">
                  <div className="form-section">
                    <div className="gth-corpote-report">
                      <p>
                        <b>Date & Time</b>
                      </p>
                      <p>
                        {moment(scheduledDateTime).format(
                          "MMM DD, YYYY hh:mm A"
                        ) +
                          " (" +
                          toTimezoneName(toTimezoneOffset(timeZone)) +
                          ")"}
                      </p>
                    </div>
                    {/* <div className="gth-corpote-report">
                                            <p><b>Time</b></p>
                                            <p>10:30 AM</p>
                                        </div> */}
                  </div>
                </div>
                <div className="form-group mt-4 mb-3">
                  <p className="d-flex flex-wrap">
                    <img className="me-2" src={InputCalendarIcon} alt="" />{" "}
                    &nbsp; <b>Add to my calendar</b>
                  </p>
                  <div className="d-flex flex-wrap">
                    <a href="javascript:;" className="me-3">
                      <img src={IconCalendar} />
                    </a>
                    <a href="javascript:;" className="me-3">
                      <img src={IconOutlook} />
                    </a>
                    <a href="javascript:;" className="">
                      <img src={IconCalendar2} />
                    </a>
                  </div>
                </div>
                <div className="form-group mt-4 mb-3">
                  <div className="join-video-call-wrapper d-flex flex-wrap align-items-center">
                    <a
                      href="javascript:;"
                      className="btn btn-secondary btn-sm ms-0 disabled111"
                    >
                      Join video Call
                    </a>{" "}
                    <i
                      className="bi bi-info-circle-fill fs-3 text-primary ms-2"
                      aria-hidden="true"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-tip="​This button will automatically be activated 1 hour prior to your scheduled visit."
                      aria-label="​This button will automatically be activated 1 hour prior to your scheduled visit."
                    ></i>
                    <ReactTooltip
                      className="tooltip"
                      backgroundColor="white"
                      textColor="black"
                    />
                  </div>
                </div>

                <div className="form-group text-center mt-5 col-md-12">
                  <Link
                    to="/second-opinion-appointment"
                    className="btn btn-secondary btn-rounded btn-lg px-2 px-md-4 px-lg-5 mx-md-2 mt-3 w-100 w-md-auto"
                  >
                    Reschedule Appointment
                  </Link>
                  <a
                    href="#modal-delete"
                    data-bs-toggle="modal"
                    className="btn btn-outline btn-rounded btn-lg px-2 px-md-4 px-lg-5 mx-md-2 mt-3 w-100 w-md-auto"
                  >
                    Cancel Appointment
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <CancelModal />
    </>
  );
};
export default BookedAppointmentPage;
