import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import { verifyEmail_Ajax } from "../../helpers/requests";
import {
  showLoadingSpinner,
  hideLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import "../../assets/css/style.css";
import appLogo from "./../../assets/images/dental-com-logo.svg";
import { showAlertDialouge } from "../../helpers/utils";
import { getLogoUrl } from "../../ApplicationSettings";

export default function VerifyEmail() {
  const alert = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [verificationCode, setVerificationCode] = useState(null);
  const logo = require("./../../assets/images/" + getLogoUrl());
  let { code } = useParams();
  useEffect(() => {
    getVerificationCode();
  }, []);

  function getVerificationCode() {
    setVerificationCode(code);
  }

  function verifyEmailClicked() {
    if (verificationCode) {
      var jsonData = {
        ActivationCode: verificationCode
      };

      dispatch(showLoadingSpinner());
      verifyEmail_Ajax(
        JSON.stringify(jsonData),
        function (response) {
          dispatch(hideLoadingSpinner());
          if (response && response.success) {
            showAlertDialouge(
              "Message",
              "Email verification completed successfully. Please login to continue.",
              function () {
                navigate("/login");
              }
            );
          } else if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
        }
      );
    } else {
      showAlertDialouge("Error", "Verification code not found");
    }
  }

  return (
    <body>
      <header className="py-2">
        <nav className="navbar navbar-expand-lg p-0">
          <div className="container-fluid">
            <Link
              to="/"
              className="navbar-brand p-0"
              href="javascript:void(0);"
            >
              <img src={logo} alt="Logo" width="120" height="50" />
            </Link>
          </div>
        </nav>
      </header>

      <section className="step-section py-4 py-md-5">
        <div className="container">
          <div className="row">
            <div className="col-sm-10 offset-sm-1 col-md-12 offset-md-0 col-xl-10 offset-xl-1">
              <div className="gth-main-header mb-3">
                <h1>Email Verification</h1>
              </div>
              <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
                Please click on the following link to verify your email address.
                <div className="mt-md-5 col-12 d-flex align-items-center justify-content-center">
                  <button
                    className="btn btn-primary d-block"
                    onClick={verifyEmailClicked}
                  >
                    Verify
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <script
        src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js"
        integrity="sha512-894YE6QWD5I59HgZOGReFYm4dnWc1Qt5NtvYSaNcOP+u1T9qYdvdihz0PPSiiqn/+/3e7Jo4EaG7TubfWGUrMQ=="
        crossOrigin="anonymous"
        referrerPolicy="no-referrer"
      ></script>
      <script
        src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"
        crossOrigin="anonymous"
      ></script>
    </body>
  );
}
