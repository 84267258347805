import $ from "jquery";

export const postJsonDataToCICI = (
  url,
  method,
  jsonData,
  success_callback,
  error_callback
) => {
  $.ajax({
    url: url,
    type: method,
    data: jsonData,
    cache: false,
    success: function (data) {
      success_callback(data);
    },
    error: function (err) {
      error_callback(err);
    }
  });
};

export const postFormDataToWhitenessImageAPI = (
  url,
  method,
  formData,
  success_callback,
  error_callback
) => {
  $.ajax({
    url: url,
    type: method,
    data: formData,
    cache: false,
    contentType: false,
    processData: false,
    dataType: "json",
    success: function (data) {
      success_callback(data);
    },
    error: function (err) {
      error_callback(err);
    }
  });
};
