import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import useIsBillingInformationAvailable from "../CustomHooks/useIsBillingInformationAvailable";

const AppointmentScheduleTab = () => {
  const location = useLocation();
  const isScheduledAppointmentCaptureCreditOrDebitCardEnabled = useSelector(
    (state) => {
      if (
        state.userData.isScheduledAppointmentCaptureCreditOrDebitCardEnabled
      ) {
        return state.userData
          .isScheduledAppointmentCaptureCreditOrDebitCardEnabled;
      } else if (
        location.state?.userData
          ?.isScheduledAppointmentCaptureCreditOrDebitCardEnabled
      ) {
        return location.state?.userData
          ?.isScheduledAppointmentCaptureCreditOrDebitCardEnabled;
      }
      return false;
    }
  );
  const isPharmacySelectionEnabled = useSelector((state) => {
    if (state.userData.isPharmacySelectionEnabled) {
      return state.userData.isPharmacySelectionEnabled;
    } else if (location.state?.userData?.isPharmacySelectionEnabled) {
      return location.state?.userData?.isPharmacySelectionEnabled;
    }
    return false;
  });

  const isBillingInformationAvailable = useIsBillingInformationAvailable(
    isScheduledAppointmentCaptureCreditOrDebitCardEnabled
  );

  return (
    <>
      <ul
        className="nav d-flex flex-wrap justify-content-between mb-5 position-relative"
        id="step-tab"
        role="tablist"
      >
        <li className="nav-item" role="presentation">
          <Link to={""} className="text-decoration-none">
            <button
              className="complete fs-3 p-0 d-flex flex-wrap justify-content-center align-items-center align-content-center position-relative rounded-pill"
              id="see-dentist-tab"
              type="button"
            >
              <span className="num lh-1">1</span>
              <span className="value fs-6 text-muted lh-1 p-0 mt-2 position-absolute top-100 start-50 translate-middle-x">
                Visit <br />
                Details
              </span>
            </button>
          </Link>
        </li>
        {isPharmacySelectionEnabled ? (
          <li className="nav-item" role="presentation">
            <Link to={""} className="text-decoration-none">
              <button
                className={
                  "fs-3 p-0 d-flex flex-wrap justify-content-center align-items-center align-content-center position-relative rounded-pill " +
                  (location.pathname ===
                    "/appointment-schedule-pharmacy-selection" ||
                  location.pathname === "/appointment-schedule-date-time" ||
                  location.pathname === "/appointment-schedule-review"
                    ? "complete"
                    : " ")
                }
                id="appointment-schedule-pharmacy-selection-tab"
                type="button"
              >
                <span className="num lh-1">2</span>
                <span className="value fs-6 text-muted lh-1 p-0 mt-2 position-absolute top-100 start-50 translate-middle-x">
                  Select <br />
                  Pharmacy
                </span>
              </button>
            </Link>
          </li>
        ) : (
          <></>
        )}
        <li className="nav-item" role="presentation">
          <Link to={""} className="text-decoration-none">
            <button
              className={
                "fs-3 p-0 d-flex flex-wrap justify-content-center align-items-center align-content-center position-relative rounded-pill " +
                (location.pathname === "/appointment-schedule-date-time" ||
                location.pathname === "/appointment-schedule-review"
                  ? "complete"
                  : " ")
              }
              id="date-time-tab"
              type="button"
            >
              <span className="num lh-1">
                {isPharmacySelectionEnabled ? 3 : 2}
              </span>
              <span className="value fs-6 text-muted lh-1 p-0 mt-2 position-absolute top-100 start-50 translate-middle-x">
                Date <br />
                and Time
              </span>
            </button>
          </Link>
        </li>
        <li className="nav-item" role="presentation">
          <Link to={""} className="text-decoration-none">
            <button
              className={
                "fs-3 p-0 d-flex flex-wrap justify-content-center align-items-center align-content-center position-relative rounded-pill " +
                (location.pathname === "/appointment-schedule-review"
                  ? "complete"
                  : " ")
              }
              id="review-submit-tab"
              type="button"
            >
              <span className="num lh-1">
                {isPharmacySelectionEnabled ? 4 : 3}
              </span>
              <span className="value fs-6 text-muted lh-1 p-0 mt-2 position-absolute top-100 start-50 translate-middle-x">
                Review <br />
                and Submit
              </span>
            </button>
          </Link>
        </li>
        {isBillingInformationAvailable ? (
          <li className="nav-item" role="presentation">
            <Link to={""} className="text-decoration-none">
              <button
                className={
                  "fs-3 p-0 d-flex flex-wrap justify-content-center align-items-center align-content-center position-relative rounded-pill "
                }
                type="button"
              >
                <span className="num lh-1">
                  {isPharmacySelectionEnabled ? 5 : 4}
                </span>
                <span className="value fs-6 text-muted lh-1 p-0 mt-2 position-absolute top-100 start-50 translate-middle-x">
                  Billing <br />
                  Information
                </span>
              </button>
            </Link>
          </li>
        ) : (
          <></>
        )}
      </ul>
    </>
  );
};

export default AppointmentScheduleTab;
