import React from "react";
import { Link } from "react-router-dom";
import SecondOpinionIcon from "./../../assets/images/icon-second-opinion.svg";

const QuestionnaireAfterPaymentSuccessfulPage = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="gth-main-header mb-3">
              <div className="heading-wrapper back-to-wrapper position-relative text-align-left">
                <h1 className="title">Second Opinion</h1>
              </div>
            </div>
            <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
              <div className="fixed-button-bottom text-center">
                <div className="border border-transparent">
                  <figure className="mt-2 mt-md-4 mb-4 mb-md-5">
                    <img
                      style={{ maxWidth: "100px" }}
                      src={SecondOpinionIcon}
                      alt=""
                    />
                  </figure>
                  <h2
                    className="text-primary mb-4 mb-md-4 mx-auto"
                    style={{ maxWidth: "600px" }}
                  >
                    Second Opinion Submitted!
                  </h2>
                  <div className="col-sm-8 col-md-6 col-lg-4 mx-auto">
                    <p>
                      Track the status of your Second Opinion on your dashboard.
                    </p>
                    <p>
                      You'll be notified when your Second Opinion is complete.
                      To access it, simply click the Second Opinion panel on
                      your dashboard.
                    </p>
                  </div>
                  <Link
                    to="/dashboard"
                    className="btn btn-secondary btn-rounded btn-lg px-3 px-md-4 px-lg-5 mt-3 mt-md-0 w-100 w-md-auto"
                  >
                    Go To Your Dashboard
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionnaireAfterPaymentSuccessfulPage;
