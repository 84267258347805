export function getIsSmartScanSelfieCaptureProgressCachingEnabled() {
  return (
    window &&
    window.appSettings &&
    window.appSettings.isSmartScanSelfieCaptureProgressCachingEnabled
  );
}

export const getStripePublishableKey = () => {
  return (
    window && window.appSettings && window.appSettings.stripePublishableKey
  );
};

export const getChatHubUrl = () => {
  return window && window.appSettings && window.appSettings.chatHubUrl;
};

export const getLogoUrl = () => {
  if (window?.appSettings?.logoUrl) {
    return window.appSettings.logoUrl;
  }

  return "";
};

export const getApiUrl = () => {
  return window && window.appSettings && window.appSettings.apiUrl;
};

export const getBaseUrl = () => {
  return window && window.appSettings && window.appSettings.baseUrl;
};

export const getPgId = () => {
  return window && window.appSettings && window.appSettings.pgId;
};

export const getTdxBaseUrl = () => {
  // enterprise base url
  return window && window.appSettings && window.appSettings.tdxBaseUrl;
};

export const getPatientOrigin = () => {
  return window && window.appSettings && window.appSettings.patientOrigin;
};

export const getPgidFromUrl = () => {
  return window && window.appSettings && window.appSettings.pgId;
};

export const getCICIApiUrl = () => {
  return window?.appSettings?.CICIApiUrl;
};

export function isReferralFeatureEnabledForOrigin() {
  return window?.appSettings?.isReferralFeatureEnabled;
}

export const getWhitenessImageAPIUrl = () => {
  return window?.appSettings?.whitenessImageAPIUrl;
};
