import moment from "moment";
import React from "react";

export default function TreatmentRecomTypeCaseReportPatientDetails({
  caseReport
}) {
  return (
    <div className="form-section">
      {caseReport && caseReport.secondOpinion.patient ? (
        <>
          <div className="gth-corpote-report">
            <p>
              <b>Name</b>
            </p>
            {caseReport.secondOpinion.patient.fullName ? (
              <p>{caseReport.secondOpinion.patient.fullName}</p>
            ) : (
              <></>
            )}
          </div>
          <div className="gth-corpote-report">
            <p>
              <b>Date of Birth</b>
            </p>
            {caseReport.secondOpinion.patient.dateOfBirth ? (
              <p>
                {moment(caseReport.secondOpinion.patient.dateOfBirth).format(
                  "MM/DD/YYYY"
                )}
              </p>
            ) : (
              <></>
            )}
          </div>
        </>
      ) : (
        <></>
      )}
      {caseReport && caseReport.secondOpinion ? (
        <div className="gth-corpote-report">
          <p>
            <b>Request Submitted on</b>
          </p>
          <p>
            {moment(caseReport.secondOpinion.createdOn).format("MM/DD/YYYY")}
          </p>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
