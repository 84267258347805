import React, { useEffect, useState } from "react";
import {
  getPatientRegistrationSettings_Ajax,
  registerUser_Ajax
} from "../../helpers/requests";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { queryStringToJSON } from "../../helpers/utils";
import {
  RegistrationTypes,
  Roles,
  SELF_SUBSCRIBER_RELATION_CODE
} from "../../constants";
import FormShowAndSubmit from "../../components/FormSubmission/FormShowAndSubmit";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import {
  getLogoUrl,
  getPatientOrigin,
  getPgidFromUrl
} from "../../ApplicationSettings";

const RegistrationConsentForm = () => {
  const officeId = useSelector(
    (state) => state?.appSettings?.settingsData?.officeId
  );
  const [consentForms, setConsentForms] = useState([]);
  const [totalForms, setTotalForms] = useState(0);
  const [registrationType, setRegistrationType] = useState(null);
  const { state } = useLocation();
  const backFromNext = state?.backFromNext;
  const navigate = useNavigate();
  const logo = require("./../../assets/images/" + getLogoUrl());
  const dispatch = useDispatch();

  useEffect(() => {
    checkForQueryParams();
  }, []);

  useEffect(() => {
    getPatientRegistrationSettings_Ajax(officeId, function (response) {
      if (response?.success && response?.data) {
        let registrationForms = response.data?.registrationSettingsVM?.forms;
        let medicalForms = response.data?.medicalForms;

        let matchedForms = medicalForms.filter((medicalForm) => {
          return registrationForms.some((registrationForm) => {
            return medicalForm.formId === registrationForm.formId;
          });
        });

        let arr = [];

        matchedForms.forEach((form) => {
          if (form) {
            arr.push({
              title: form.title,
              formComponent: form.components,
              medicalFormId: form.formId,
              isSubmitted: false
            });
          }
        });
        setConsentForms(arr);
        setTotalForms(arr.length);
      }
    });
  }, [officeId]);

  useEffect(() => {
    if (backFromNext) {
      setConsentForms(state?.consentFormData);
      setSignUpFormData(state?.patientData);
    }
  }, [backFromNext]);

  function checkForQueryParams() {
    let params = queryStringToJSON();
    if (params) {
      if (params.registrationtype) {
        setRegistrationType(params.registrationtype);
      }
    }
  }

  function getPatientName(data) {
    let patientName = data?.firstName + " " + data?.lastName;
    return patientName;
  }

  function registerUser() {
    let pgId = getPgidFromUrl();
    let formData = state?.signUpFormData;

    if (pgId && formData && officeId >= 0) {
      let insuranceData = state?.insuranceData;
      formData.roleId = Roles.Patient;
      formData.pgId = pgId;
      formData.origin = getPatientOrigin();
      formData.patientInsurance = insuranceData;
      formData.SubmittedForms = convertFormDataForSubmission();

      const isSelfSubscriber =
        insuranceData?.relationToPatient == SELF_SUBSCRIBER_RELATION_CODE;
      const isSubscriberStateSameWithPatientState =
        formData?.state == insuranceData?.state;
      if (isSelfSubscriber && isSubscriberStateSameWithPatientState) {
        formData.address1 = insuranceData?.insuredAddress;
        formData.city = insuranceData?.insuredCity;
        formData.zip = insuranceData?.zip;
        formData.gender = insuranceData?.gender;
      }

      dispatch(showLoadingSpinner());
      registerUser_Ajax(
        JSON.stringify(formData),
        officeId,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (response?.success) {
            navigate("/registration-success", {
              state: {
                patientName: getPatientName(response.data?.authData?.user)
              }
            });
          } else if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
        }
      );
    }
  }

  function convertFormDataForSubmission() {
    let forms = [];
    consentForms.forEach((form) => {
      forms.push({
        medicalFormId: form.medicalFormId,
        formData: JSON.stringify(form.formData)
      });
    });

    return forms;
  }

  function goToNextPage() {
    if (registrationType == RegistrationTypes.SkipPassword) {
      registerUser();
    } else {
      navigate("/set-password", {
        state: {
          signUpFormData: state?.signUpFormData,
          isSignUpExternalPatient: state?.isSignUpExternalPatient,
          consentFormData: convertFormDataForSubmission(),
          from: "/registration-consent-form"
        }
      });
    }
  }

  return (
    <>
      <header className="py-2">
        <nav className="navbar navbar-expand-lg p-0">
          <div className="container-fluid">
            <Link to="/" className="navbar-brand p-0">
              <img src={logo} alt="Logo" width="120" height="50" />
            </Link>
          </div>
        </nav>
      </header>
      <body>
        <section className="step-section py-4 py-md-5">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="progress mb-3">
                  <div
                    className="progress-bar"
                    role="progress"
                    style={{ width: "75%" }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
                  <FormShowAndSubmit
                    forms={consentForms}
                    setForms={setConsentForms}
                    totalForms={totalForms}
                    handleAllFormSubmission={goToNextPage}
                  ></FormShowAndSubmit>
                </div>
              </div>
            </div>
          </div>
        </section>
      </body>
    </>
  );
};

export default RegistrationConsentForm;
