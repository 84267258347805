import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import InputMask from "react-input-mask";

import {
  formatDateTime,
  getMinuteOptions,
  getHourOptions
} from "../../helpers/utils";
import { DateTimeFormats, ValidationTypes } from "../../constants";
import moment from "moment";
import DateInputMask from "../DateInput/DateInputMask";
import { useForm } from "react-hook-form";

const AppointmentReschedulePage = (props) => {
  const { inputs, setInputs, rescheduleAppointment } = props;

  const { date, hour, minute, meridiem } = inputs;

  const {
    register,
    formState: { errors }
  } = useForm({ mode: "onChange" });

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  function handleDateChange(name, value) {
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  function rescheduleBtnDisabled() {
    return !date || !hour || !minute || !meridiem;
  }

  return (
    <>
      <div
        className="tab-pane fade show"
        id="date-time"
        role="tabpanel"
        aria-labelledby="date-time-tab"
      >
        <fieldset>
          <legend className="fs-1 mb-4">Reschedule Appointment</legend>

          <div className="offcanvas111 offcanvasform111 reschedule_appointment_sidebar appointment-wrapper d-flex flex-wrap">
            <div className="offcanvas-left left-wrapper col-sm-12 col-lg-4 bg-primary">
              <div className="offcanvas-packages-list111">
                <h2>You are rescheduling a Virtual Consultation</h2>
              </div>
            </div>
            <div className="offcanvas-right form-view appointment_for right-wrapper col-sm-12 col-lg-8">
              <div className="form-group mb-3 select-date">
                <div className="row">
                  <div className="col-sm-12 col-md-4">
                    <label className="form-label">
                      Select Appointment Date
                    </label>
                    <DateInputMask
                      register={register}
                      fieldName={"date"}
                      handleChange={handleDateChange}
                      errors={errors}
                      validationType={ValidationTypes.ScheduleAppointment}
                      value={date}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group col-sm-12">
                <label className="form-label">Select Appointment Time</label>
                <div className="row">
                  <div className="col-sm-4 mb-3">
                    <select
                      className="form-select form-control"
                      name="hour"
                      id="hour"
                      value={hour}
                      onChange={handleChange}
                    >
                      <option value="">Hour</option>
                      {getHourOptions()}
                    </select>
                  </div>
                  <div className="col-sm-4 mb-3">
                    <select
                      className="form-select form-control"
                      name="minute"
                      id="minute"
                      value={minute}
                      onChange={handleChange}
                    >
                      <option value="">Minute</option>
                      {getMinuteOptions()}
                    </select>
                  </div>
                  <div className="col-sm-4 mb-3">
                    <select
                      className="form-select form-control"
                      name="meridiem"
                      id="ampm"
                      value={meridiem}
                      onChange={handleChange}
                    >
                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="text-muted fs-5 mb-4">
                All appointment times are displayed according to your local time
                zone.
              </div>
              <div className="confirm-booking mt-md-3 float-none">
                <button
                  className="btn btn-secondary btn-rounded btn-lg px-3 px-md-5 mx-md-2 mt-3 mt-md-0"
                  id="review-submit-tab"
                  type="button"
                  disabled={rescheduleBtnDisabled()}
                  onClick={rescheduleAppointment}
                >
                  Reschedule Appointment
                </button>
                <Link to={"/dashboard"}>
                  <button
                    className="btn btn-outline btn-rounded btn-lg px-3 px-md-5 mx-md-2 mt-3 mt-md-0"
                    id="billing-info-tab"
                    type="button"
                  >
                    Back
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </>
  );
};
export default AppointmentReschedulePage;
