import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import FormView from "./FormView";

import Pagination from "../../redux/customHooks/pagination";
import {
  DateTimeFormats,
  MedicalFormTypes,
  Pagination as PaginationConstants
} from "../../constants";
import { formatDateTimeWithZone, toLocal } from "../../helpers/utils";

const FormSection = (props) => {
  const navigate = useNavigate();

  const {
    getAssignedForms,
    assignedForms,
    reloadFormsData,
    setReloadFormsData,
    totalCount
  } = props;

  const [currentPage, setCurrentPage] = useState(
    PaginationConstants.FirstPageNo
  );

  useEffect(() => {
    getAssignedForms(currentPage, PaginationConstants.ItemPerPage);
  }, [currentPage]);

  useEffect(() => {
    if (reloadFormsData) {
      getAssignedForms(currentPage, PaginationConstants.ItemPerPage);
      setReloadFormsData(false);
    }
  }, [reloadFormsData]);

  function getDateTime(form) {
    var time = "";

    if (form?.modifiedOn) {
      time = toLocal(form.modifiedOn);
    } else if (form?.createdOn) {
      time = toLocal(form.createdOn);
    }

    if (time) {
      return formatDateTimeWithZone(
        toLocal(time),
        DateTimeFormats.MMM_DD_YYYY + " [at] " + DateTimeFormats.HH_MM_A
      );
    }
  }

  function updateBtnClicked(formData) {
    if (formData) {
      navigate("/form-submission", {
        state: {
          formData
        }
      });
    }
  }

  function isUpdateAndSubmitButtonHidden(form) {
    return (
      form?.formType == MedicalFormTypes.SmartScan ||
      form?.isGenericPatientSurvey
    );
  }

  return (
    <>
      <div className="container">
        <div className="row mb-3">
          <div className="col-sm-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Forms
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="gth-main-header mb-3">
              <h1>Forms</h1>
            </div>
            <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
              {assignedForms &&
                Array.isArray(assignedForms) &&
                assignedForms.length > 0 &&
                assignedForms.map((formData, index) => {
                  return (
                    <div className="mb-3">
                      <div className="gth-panel-header bg-body py-3 rounded-3 d-flex justify-content-md-between align-items-start align-items-md-center flex-column flex-md-row">
                        <a
                          href={"#intakeForm-collapse1" + index}
                          className="text-body text-decoration-none px-3 mt-0 w-100 w-md-auto text-break"
                          data-bs-toggle="collapse"
                          role="button"
                          aria-expanded="false"
                          aria-controls={"#intakeForm-collapse1" + index}
                        >
                          {formData?.medicalForm?.title}
                        </a>
                        <a
                          href={"#intakeForm-collapse1" + index}
                          className="text-body text-decoration-none px-3 mt-1 mt-md-0 w-100 w-md-auto"
                          data-bs-toggle="collapse"
                          role="button"
                          aria-expanded="false"
                          aria-controls={"#intakeForm-collapse1" + index}
                        >
                          {getDateTime(formData)}
                        </a>
                        <div className="gth-btns-group px-3">
                          <a
                            href={"#intakeForm-collapse1" + index}
                            className="btn btn-outline-primary me-2 view-form"
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            aria-controls={"#intakeForm-collapse1" + index}
                          >
                            <i className="bi bi-eye"></i> View Forms
                          </a>
                          <a
                            href={"#intakeForm-collapse1" + index}
                            className="btn btn-outline-primary me-2 hide-form"
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="true"
                            aria-controls={"#intakeForm-collapse1" + index}
                          >
                            <i className="bi-eye-slash"></i> Hide Forms
                          </a>
                          {!(
                            formData.medicalForm &&
                            isUpdateAndSubmitButtonHidden(formData.medicalForm)
                          ) ? (
                            <button
                              onClick={() => updateBtnClicked(formData)}
                              className="btn btn-primary"
                              data-bs-toggle="modal"
                              data-bs-target="#intake-form-modal"
                            >
                              <i className="bi bi-plus"></i> Update &amp; Submit
                            </button>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <FormView index={index} formData={formData} />
                    </div>
                  );
                })}
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={totalCount ? totalCount : 0}
                pageSize={PaginationConstants.ItemPerPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormSection;
