import React from "react";
import { useNavigate } from "react-router-dom";
import { getCSSClassToStopScrollingInSmartScanPosePageInMobileDevice } from "../../helpers/utils";
import useGetSmartScanSelfieCaptureProgress from "../CustomHooks/useGetSmartScanSelfieCaptureProgress";
import { SmartScanCaptureOptions } from "../../constants";

const SmartScanInstructionPage = () => {
  const smartScanState = useGetSmartScanSelfieCaptureProgress();
  const navigate = useNavigate();

  function getSmartScanInstructionText() {
    if (smartScanState?.captureOption == SmartScanCaptureOptions.Myself) {
      return (
        <p>
          Start by putting your camera in selfie mode. We'll take five different
          photos to cover all angles. For best results, when taking the photos,
          keep the phone as close as possible to your mouth while still being
          able to see the screen, which is usually about 6 inches away. Take the
          photos in a well-lit place and use your flash, if possible.
        </p>
      );
    } else if (
      smartScanState?.captureOption == SmartScanCaptureOptions.Assisted
    ) {
      return (
        <p>
          We'll take five different photos to cover all angles. Have another
          person use the phone camera to capture photos and make sure to focus
          the camera on the teeth and gums. Take the photos in a well-lit place
          and use your flash, if possible.
        </p>
      );
    }

    return <></>;
  }

  function getSmartScanInstructionImage() {
    if (smartScanState?.captureOption == SmartScanCaptureOptions.Myself) {
      return (
        <figure className="mb-0">
          <img
            className="w-100 border-radius-xlg rounded-b-l-0 rounded-b-r-0"
            src={require("./../../assets/images/SelfieMode.png")}
            alt=""
          />
        </figure>
      );
    } else if (
      smartScanState?.captureOption == SmartScanCaptureOptions.Assisted
    ) {
      return (
        <figure className="mb-0">
          <img
            className="w-100 border-radius-xlg rounded-b-l-0 rounded-b-r-0"
            src={require("./../../assets/images/Assisted.jpg")}
            alt=""
          />
        </figure>
      );
    }
  }

  function goToPreviousPage() {
    navigate("/smart-scan-photo-options", {
      state: {
        ...smartScanState
      }
    });
  }

  function goToNextPage() {
    navigate("/smart-scan-upper-arch", {
      state: {
        ...smartScanState
      }
    });
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div
              className={
                "smart-scan-instruction-wrapper smart-scan-wrapper bg-white border-radius-xlg " +
                getCSSClassToStopScrollingInSmartScanPosePageInMobileDevice()
              }
            >
              {getSmartScanInstructionImage()}
              <div className="bg-primary px-4 px-md-5 py-4 border-radius-xlg rounded-t-l-0 rounded-t-r-0">
                {getSmartScanInstructionText()}
                <div className="text-center">
                  <button
                    className="btn btn-outline btn-rounded text-white btn-lg px-4 me-2 px-md-5 mx-auto mt-3 mt-md-2 w-100 w-md-auto"
                    onClick={goToPreviousPage}
                  >
                    Back
                  </button>
                  <button
                    className="btn btn-secondary btn-rounded btn-lg px-4 px-md-5 mx-auto mt-3 mt-md-2 w-100 w-md-auto"
                    onClick={goToNextPage}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SmartScanInstructionPage;
