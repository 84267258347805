import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";

import xRays from "./../../assets/images/x-ray1.jpg";
import {
  toLocalDate,
  isImageFile,
  isValidDocFileForPatientUpload,
  isPDFfile
} from "../../helpers/utils";
import Pagination from "../../redux/customHooks/pagination";
import { Pagination as PaginationConstants } from "../../constants";

import { onImageError } from "../../helpers/utils";
import IconPDF from "./../../assets/images/icon-pdf.svg";
import GenericFileIcon from "../../assets/images/word_processor_file_icon.png";

const UploadsTable = (props) => {
  const [currentPage, setCurrentPage] = useState(
    PaginationConstants.FirstPageNo
  );
  const {
    patientUserId,
    medicalRecords,
    totalCount,
    getMedicalRecords,
    deleteMedicalRecord,
    openFile,
    reloadMedicalRecords,
    setReloadMedicalRecords,
    getProviderData,
    familyMembersUserIds
  } = props;

  useEffect(() => {
    getMedicalRecords(currentPage, PaginationConstants.ItemPerPage);
  }, [currentPage]);

  useEffect(() => {
    if (reloadMedicalRecords) {
      getMedicalRecords(currentPage, PaginationConstants.ItemPerPage);
      setReloadMedicalRecords(false);
    }
  }, [reloadMedicalRecords]);

  function isDeleteButtonVisible(record) {
    return (
      record &&
      record.creatorUserId &&
      ((patientUserId && patientUserId == record.creatorUserId) ||
        familyMembersUserIds.includes(record.creatorUserId))
    );
  }

  function getCreatorName(record) {
    return (
      record?.creatorProvider?.fullName ||
      record?.creatorPatient?.fullName ||
      record?.creatorName
    );
  }

  function getRecordsThumbnails(record) {
    const { photos } = record;

    var arr = [];

    if (photos && Array.isArray(photos) && photos.length > 0) {
      photos.map((file, index) => {
        if (file.photoId) {
          if (isImageFile(file.photoId)) {
            arr.push(
              <p
                key={index}
                data-bs-toggle="modal"
                data-bs-target="#fileViewerImage"
                onClick={() => openFile(file.photoId)}
              >
                <img
                  className="gth-uploads-icon"
                  src={file.photoId}
                  alt="File img"
                  onError={onImageError}
                />
              </p>
            );
          } else if (isValidDocFileForPatientUpload(file.photoId)) {
            arr.push(
              <p
                key={index}
                className="text-body"
                data-bs-toggle="modal"
                data-bs-target="#fileViewerDoc"
                onClick={() => openFile(file.photoId)}
              >
                <img
                  src={isPDFfile(file.photoId) ? IconPDF : GenericFileIcon}
                  className="gth-uploads-icon"
                  alt=""
                  onError={onImageError}
                />
              </p>
            );
          } else {
            return <></>;
          }
        }
      });
    }

    return <>{arr}</>;
  }

  function displayRecordDate(date) {
    if (date) {
      return toLocalDate(date);
    }
  }

  return (
    <section className="step-section py-4 py-md-5">
      <div className="container">
        <div className="row mb-3">
          <div className="col-sm-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Uploads
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="gth-main-header d-flex justify-content-between align-items-center mb-3">
              <h1>Uploads</h1>
              <a
                href="#"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#addUploads-modal"
              >
                <i className="bi bi-plus"></i> Add Upload
              </a>
            </div>
            <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
              {medicalRecords &&
              Array.isArray(medicalRecords) &&
              medicalRecords.length > 0 ? (
                <>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Record Type</th>
                        <th>Date</th>
                        <th>Files</th>
                        <th>Note</th>
                        <th>Added By</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {medicalRecords != null &&
                        Array.isArray(medicalRecords) &&
                        medicalRecords.map((record, index) => {
                          return (
                            <tr key={record.recordId.toString()}>
                              <td data-label="Record Type">{record.name}</td>
                              <td data-label="Date">
                                {displayRecordDate(record.date)}
                              </td>
                              <td data-label="Files">
                                {getRecordsThumbnails(record)}
                              </td>
                              <td className="text-break" data-label="Note">
                                {record.note}
                              </td>
                              <td data-label="Added By">
                                {record?.creatorProvider ? (
                                  <p
                                    data-bs-toggle="modal"
                                    data-bs-target="#providerProfile"
                                    onClick={() => getProviderData(record)}
                                  >
                                    {getCreatorName(record)}
                                  </p>
                                ) : (
                                  <p>{getCreatorName(record)}</p>
                                )}
                              </td>
                              <td data-label="Action">
                                {isDeleteButtonVisible(record) ? (
                                  <button
                                    className="btn btn-danger btn-xs"
                                    onClick={() => deleteMedicalRecord(record)}
                                  >
                                    <i className="bi bi-trash-fill"></i> Delete
                                  </button>
                                ) : (
                                  <></>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={totalCount ? totalCount : 0}
                    pageSize={PaginationConstants.ItemPerPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </>
              ) : (
                <div className="d-flex justify-content-center mt-5 mb-5">
                  <h2>No medical record data found!</h2>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UploadsTable;
