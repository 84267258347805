import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { useForm } from "react-hook-form";

import {
  AddMedicalRecordFormFields,
  DateTimeFormats,
  MedicalRecordTypes,
  UploadRecordNames
} from "../../constants";
import {
  formatDateTime,
  isImageFile,
  isPDFfile,
  isValidDocFileForPatientUpload,
  onImageError,
  showAlertDialouge,
  toLocal
} from "../../helpers/utils";
import DateInputMask from "../DateInput/DateInputMask";

const UploadsModalForm = (props) => {
  const { patientName, saveMedicalRecord } = props;
  const alert = useAlert();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
    reset
  } = useForm({
    mode: "onChange",
    defaultValues: {
      type: MedicalRecordTypes.Chart,
      [AddMedicalRecordFormFields.Date]: formatDateTime(
        toLocal(),
        DateTimeFormats.MM_DD_YYYY
      )
    }
  });

  const typeWatch = watch(AddMedicalRecordFormFields.Type);
  const fileInputRef = useRef(null);

  function UpdateSelectedPhoto(event) {
    if (event && event.target && event.target.files) {
      var arr = [];

      if (
        uploadedFiles &&
        Array.isArray(uploadedFiles) &&
        uploadedFiles.length > 0
      ) {
        arr = [...uploadedFiles];
      }

      for (var i = 0; i < event.target.files.length; i++) {
        var file = event.target.files[i];
        var fileName = file.name.toLowerCase();

        if (isImageFile(fileName) || isValidDocFileForPatientUpload(fileName)) {
          var tempPath = URL.createObjectURL(file);
          arr.push({
            url: tempPath,
            type: fileName.split(".").pop(),
            file: file
          });
        } else {
          showAlertDialouge("Error", "File type not supported");
        }
      }

      if (arr && arr.length > 0) {
        setUploadedFiles(arr);
      }
    }
  }

  function DeleteUploadedPhotoClicked(fileIndex) {
    if (fileIndex < 0) return;

    var arr = [];

    if (
      uploadedFiles &&
      Array.isArray(uploadedFiles) &&
      uploadedFiles.length > 0
    ) {
      arr = uploadedFiles.filter((file, index) => {
        return index != fileIndex;
      });
    }

    if (arr) {
      setUploadedFiles(arr);
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  }

  function resetForm() {
    reset();
    setUploadedFiles([]);
  }

  function onSubmit(medicalRecord) {
    saveMedicalRecord(medicalRecord, uploadedFiles, resetForm);
  }

  function getUploadsThumbnail(uploadedFiles) {
    if (
      uploadedFiles &&
      Array.isArray(uploadedFiles) &&
      uploadedFiles.length > 0
    ) {
      var arr = [];

      uploadedFiles.map((upload, index) => {
        if (isImageFile(upload?.file?.name)) {
          arr.push(
            <div key={index}>
              <img
                className="uploads-record-img"
                src={upload.url}
                onError={onImageError}
              />
              <i
                className="uploads-record-remove-img bi bi-x-octagon-fill"
                onClick={() => DeleteUploadedPhotoClicked(index)}
              ></i>
            </div>
          );
        } else if (isValidDocFileForPatientUpload(upload?.file?.name)) {
          arr.push(
            <div key={index}>
              <div className="uploads-record-file d-flex justify-content-around">
                {isPDFfile(upload?.file?.name) ? (
                  <i className="bi bi-file-earmark-pdf-fill fs-1"></i>
                ) : (
                  <i className="bi bi-file-earmark-text-fill fs-1"></i>
                )}
                <i
                  className="bi bi-x-octagon-fill"
                  onClick={() => DeleteUploadedPhotoClicked(index)}
                ></i>
              </div>
            </div>
          );
        }
      });

      return arr;
    }
  }

  return (
    <div className="modal fade" id="addUploads-modal" tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header primary-border-color">
            <h2 className="modal-title text-primary text-center w-100">
              Uploads
            </h2>
            <button
              type="button"
              className="btn-close text-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-12 mb-3">
                  <div className="gth-custom-upload">
                    <div className="gth-browose-file d-flex align-items-center justify-content-center">
                      <span>
                        Drag & Drop
                        <br />
                        or <small className="text-primary">Browse</small>
                      </span>
                    </div>
                    <input
                      type="file"
                      multiple
                      className="gth-upload"
                      ref={fileInputRef}
                      onChange={UpdateSelectedPhoto}
                    />
                  </div>
                  {uploadedFiles != null && uploadedFiles.length > 0 && (
                    <div className="d-flex flex-wrap justify-content-left">
                      {getUploadsThumbnail(uploadedFiles)}
                    </div>
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="readyfor" className="form-label">
                    Record For
                  </label>
                  <input
                    disabled
                    type="text"
                    className="form-control"
                    id="readyfor"
                    value={patientName}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="recordname" className="form-label">
                    Record Type
                  </label>
                  <select
                    className="form-select form-control"
                    id="recordname"
                    {...register(AddMedicalRecordFormFields.Name, {
                      required: true
                    })}
                  >
                    <option key={0} value={UploadRecordNames.Images}>
                      {UploadRecordNames.Images}
                    </option>
                    <option key={0} value={UploadRecordNames.Documents}>
                      {UploadRecordNames.Documents}
                    </option>
                  </select>
                </div>
                <div className="col-12 mb-3">
                  <label className="d-block">Type of report</label>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="ReportLabel"
                      id="chartLabel"
                      value={MedicalRecordTypes.Chart}
                      checked={typeWatch == MedicalRecordTypes.Chart}
                      {...register(AddMedicalRecordFormFields.Type, {
                        required: true
                      })}
                    />
                    <label className="form-check-label" htmlFor="chartLabel">
                      Chart
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="ReportLabe1"
                      id="otherLabel"
                      value={MedicalRecordTypes.Other}
                      checked={typeWatch == MedicalRecordTypes.Other}
                      {...register(AddMedicalRecordFormFields.Type, {
                        required: true
                      })}
                    />
                    <label className="form-check-label" htmlFor="otherLabel">
                      Other
                    </label>
                  </div>
                </div>
                <div className="col-12 mb-3">
                  <label htmlFor="">Record created on (MM/DD/YYYY)</label>
                  <DateInputMask
                    register={register}
                    fieldName={AddMedicalRecordFormFields.Date}
                    errors={errors}
                    disabled={true}
                  />
                </div>
                <div className="col-12 mb-4">
                  <label htmlFor="">Record Note</label>
                  <textarea
                    className="form-control"
                    {...register(AddMedicalRecordFormFields.Note, {
                      required: true
                    })}
                  ></textarea>
                </div>
                <div className="col-12 d-flex align-items-center justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-primary d-block"
                    data-bs-dismiss="modal"
                    disabled={!isValid || uploadedFiles.length <= 0}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadsModalForm;
