import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import { getVisitSummary_Ajax } from "../../helpers/requests";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import {
  formatDateTime,
  handleApiErrors,
  isEmptyArray,
  isValidDate,
  onImageError,
  showAlertDialouge
} from "../../helpers/utils";
import { DateTimeFormats, ReferInfoStatusTypes } from "../../constants";

import "./../../assets/css/print-style.css";
import { Form } from "@formio/react";
import "formiojs/dist/formio.builder.min.css";

const VisitHistoryPrint = () => {
  const { patientId, consultationId } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [visitSummary, setVisitSummary] = useState([]);
  const [intakeForm, setIntakeForm] = useState([]);
  const [notes, setNotes] = useState([]);
  const [educations, setEducations] = useState([]);
  const [prescriptions, setPrescriptions] = useState([]);
  const [referrals, setReferrals] = useState([]);

  useEffect(() => {
    if (patientId && consultationId) {
      getVisitSummary(patientId, consultationId);
    }
  }, [patientId, consultationId]);

  useEffect(() => {
    if (document.getElementById("signaturePreview") && intakeForm) {
      let submissionData = JSON.parse(intakeForm.medicalHistories[0].formData);

      document.getElementById("signaturePreview").value =
        submissionData?.signature;
    }
  });

  function printVisitHistory() {
    document.title = "Visit history";
    window.print();
  }

  function getVisitSummary(patientId, consultationId) {
    if (patientId > 0 && consultationId > 0) {
      dispatch(showLoadingSpinner());
      getVisitSummary_Ajax(
        patientId,
        consultationId,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (response?.success && response?.data) {
            PopulateVisitSummaryInfo(response?.data);
          } else if (response && !response.success) {
            setVisitSummary([]);
            showAlertDialouge("Message", response.message, function () {
              navigate("/visit-history");
            });
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function PopulateVisitSummaryInfo(visitSummaryInfo) {
    if (visitSummaryInfo) {
      setVisitSummary(visitSummaryInfo);
      setIntakeForm(visitSummaryInfo?.intakeForms);
      setNotes(visitSummaryInfo?.notes);
      setEducations(visitSummaryInfo?.educations);
      setPrescriptions(visitSummaryInfo?.prescriptions);
      setReferrals(visitSummaryInfo?.referrals);
    }
  }

  function getFormattedDate(date) {
    if (date && isValidDate(date)) {
      return formatDateTime(date, DateTimeFormats.DD_MMM_YYYY);
    }
  }

  function showVistiSummary() {
    if (visitSummary) {
      return (
        <>
          {getIntakeForms()}
          {getNotes()}
          {getEducations()}
          {getPescriptions()}
          {getReferrals()}
        </>
      );
    } else {
      return <></>;
    }
  }

  function getIntakeForms() {
    return (
      <>
        <td className="visit-history-summary-td-title">
          <span className="visit-history-summary-table-title">Intake Form</span>
        </td>
        {intakeForm ? (
          <div className="card-body">{renderForm(intakeForm)}</div>
        ) : (
          <div className="d-flex justify-content-center mt-5 mb-5">
            <h2>No Intake Form Found</h2>
          </div>
        )}
      </>
    );
  }

  function getNotes() {
    return (
      <>
        <td className="visit-history-summary-td-title">
          <span className="visit-history-summary-table-title">Notes</span>
        </td>
        {!isEmptyArray(notes) ? (
          <div>
            <table
              cellSpacing="0"
              cellPadding="3"
              border="0"
              width="100%"
              style={{ fontSize: 12, marginTop: 15 }}
              className="table table-hover"
            >
              <thead>
                <tr>
                  <th scope="col">Date </th>
                  <th scope="col">Note</th>
                  <th scope="col">Added By</th>
                </tr>
              </thead>
              <tbody>
                {notes.map((note) => {
                  return (
                    <tr key={note.id}>
                      <td data-label="Created Date">
                        {getFormattedDate(note.createdOn)}
                      </td>
                      <td
                        data-label="Description"
                        dangerouslySetInnerHTML={{ __html: note.description }}
                      ></td>
                      <td data-label="Provider">{note?.provider?.fullName}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="d-flex justify-content-center mt-5 mb-5">
            <h2>No Notes Found</h2>
          </div>
        )}
      </>
    );
  }

  function getEducations() {
    return (
      <>
        <td className="visit-history-summary-td-title">
          <span className="visit-history-summary-table-title">Education</span>
        </td>
        {!isEmptyArray(educations) ? (
          <div>
            <table
              cellSpacing="0"
              cellPadding="3"
              border="0"
              width="100%"
              style={{ fontSize: 12, marginTop: 15 }}
              className="table table-hover"
            >
              <thead>
                <tr>
                  <th scope="col">Title</th>
                  <th scope="col">Date </th>
                  <th scope="col">Category</th>
                  <th scope="col">Shared By</th>
                </tr>
              </thead>
              <tbody>
                {educations.map((edu) => {
                  return (
                    <tr key={edu.id}>
                      <td data-label="Title">{edu?.education?.title}</td>
                      <td data-label="Created Date">
                        {getFormattedDate(edu.createdOn)}
                      </td>
                      <td data-label="Description">
                        {edu?.education?.educationCategory?.description}
                      </td>
                      <td data-label="Provider">{edu?.provider?.fullName}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="d-flex justify-content-center mt-5 mb-5">
            <h2>No Education Found</h2>
          </div>
        )}
      </>
    );
  }

  function getPescriptions() {
    return (
      <>
        <td className="visit-history-summary-td-title">
          <span className="visit-history-summary-table-title">
            Pescriptions
          </span>
        </td>
        {!isEmptyArray(prescriptions) ? (
          <div>
            <table
              cellSpacing="0"
              cellPadding="3"
              border="0"
              width="100%"
              style={{ fontSize: 12, marginTop: 15 }}
              className="table table-hover"
            >
              <thead>
                <tr>
                  <th scope="col">Pescription Name</th>
                  <th scope="col">Created Date </th>
                  <th scope="col">Medicine Name</th>
                  <th scope="col">Dosage</th>
                  <th scope="col">Pharmacy</th>
                </tr>
              </thead>
              <tbody>
                {prescriptions.map((prescription) => {
                  return (
                    <tr key={prescription.id}>
                      <td data-label="Pescription Name">{prescription.name}</td>
                      <td data-label="Created Date">
                        {getFormattedDate(prescription.createdOn)}
                      </td>
                      <td data-label="Medicine Name">
                        <ol style={{ paddingLeft: "1rem" }}>
                          {prescription?.prescriptionMedications?.map(
                            (medication) => {
                              return <li>{medication?.medicine?.name}</li>;
                            }
                          )}
                        </ol>
                      </td>
                      <td data-label="Dosage">
                        <ol style={{ paddingLeft: "1rem" }}>
                          {prescription?.prescriptionMedications?.map(
                            (medication) => {
                              return <li>{medication?.unit}</li>;
                            }
                          )}
                        </ol>
                      </td>
                      <td data-label="Pharmacy">
                        {prescription?.pharmacy?.name && (
                          <>
                            <b>
                              <span>{prescription?.pharmacy?.name}</span>
                            </b>
                            <br />
                            <p
                              style={{
                                fontSize: "12px;",
                                marginTop: "5px 0 0 0;"
                              }}
                            >
                              <span>{prescription?.pharmacy?.address}</span>
                              <br />
                              <span>
                                {prescription?.pharmacy?.abstractAddress}
                              </span>
                              <br />
                              <span>
                                Phone:{" "}
                                <span>{prescription?.pharmacy?.phone}</span>
                              </span>
                              <br />
                              <span>
                                Fax: <span>{prescription?.pharmacy?.fax}</span>
                              </span>
                            </p>
                          </>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="d-flex justify-content-center mt-5 mb-5">
            <h2>No Pescription Found</h2>
          </div>
        )}
      </>
    );
  }

  function getReferrals() {
    return (
      <>
        <td className="visit-history-summary-td-title">
          <span className="visit-history-summary-table-title">Referrals</span>
        </td>
        {!isEmptyArray(referrals) ? (
          <div>
            <table
              cellSpacing="0"
              cellPadding="3"
              border="0"
              width="100%"
              style={{ fontSize: 12, marginTop: 15 }}
              className="table table-hover"
            >
              <thead>
                <tr>
                  <th scope="col">Date </th>
                  <th scope="col">Selected Referral</th>
                  <th scope="col">Referral Reason </th>
                  <th scope="col">Referral Status</th>
                  <th scope="col">Referred By</th>
                </tr>
              </thead>
              <tbody>
                {referrals.map((referral) => {
                  return (
                    <tr key={referral.id}>
                      <td data-label="Created Date">
                        {getFormattedDate(referral.createdOn)}
                      </td>
                      <td data-label="Title">
                        <span>{getReferralDoctorName(referral)}</span>
                        <br />
                        <span>{getReferralDoctorEmail(referral)}</span>
                        <br />
                        <span>{getReferralDoctorPhone(referral)}</span>
                        <br />
                      </td>

                      <td data-label="Reason">{referral.reason}</td>
                      <td data-label="Status">
                        <span
                          className={getStatusClass(referral)}
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Your referral has been sent to the provider and is waiting on a response from the provider"
                        >
                          <strong>{getStatusText(referral)}</strong>
                        </span>
                      </td>
                      <td data-label="Referred By">
                        {referral?.referedFrom?.fullName}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="d-flex justify-content-center mt-5 mb-5">
            <h2>No Referral Found</h2>
          </div>
        )}
      </>
    );
  }

  function getReferralDoctorName(referral) {
    if (referral?.referedTo?.fullName) {
      return referral?.referedTo?.fullName;
    } else if (referral?.referedToImportedProvider?.fullName) {
      return referral?.referedToImportedProvider?.fullName;
    }
  }

  function getReferralDoctorEmail(referral) {
    if (referral?.email) {
      return referral?.email;
    } else if (referral?.referedToImportedProvider?.email) {
      return referral?.referedToImportedProvider?.email;
    }
  }

  function getReferralDoctorPhone(referral) {
    if (referral?.phone) {
      return referral?.phone;
    } else if (referral?.referedToImportedProvider?.phone) {
      return referral?.referedToImportedProvider?.phone;
    }
  }

  function getStatusClass(referral) {
    if (referral?.status == ReferInfoStatusTypes.Accepted) {
      return "text-success";
    } else if (referral?.status == ReferInfoStatusTypes.Declined) {
      return "text-danger";
    } else if (referral?.status == ReferInfoStatusTypes.None) {
      return "text-gold";
    }
  }

  function getStatusText(referral) {
    if (referral?.status == ReferInfoStatusTypes.Accepted) {
      return "Accepted";
    } else if (referral?.status == ReferInfoStatusTypes.Declined) {
      return "Declined";
    } else if (referral?.status == ReferInfoStatusTypes.None) {
      return "Pending";
    }
  }

  function renderForm(intakeForm) {
    if (intakeForm?.medicalHistories) {
      let formComponents = JSON.parse(intakeForm?.medicalForm?.components);
      let submissionData = JSON.parse(intakeForm.medicalHistories[0].formData);

      // form components
      let formObj = {
        display: "form",
        components: formComponents
      };

      // form display options
      let formOptions = {
        readOnly: true,
        viewAsHtml: true
      };

      return (
        <>
          <Form
            form={formObj}
            submission={{ data: submissionData }}
            options={formOptions}
          />
        </>
      );
    } else {
      return <p>No medical history available.</p>;
    }
  }

  return (
    <body
      style={{ margin: 0, padding: "0px 15px", background: "#f2f2f2" }}
      cz-shortcut-listen="true"
    >
      <div style={{ textAlign: "right", maxWidth: 700, margin: "20px auto" }}>
        <button
          disabled={!visitSummary}
          onClick={() => printVisitHistory()}
          style={{
            backgroundColor: "#3fbbeb",
            border: "1px solid #3fbbeb",
            borderRadius: "50rem",
            fontSize: 12,
            padding: "3px 15px",
            color: "#fff",
            cursor: "pointer"
          }}
          className="noprint btn"
        >
          <i className="fa fa-print"></i> Print
        </button>
      </div>
      <div
        id="visit-history-body"
        style={{
          maxWidth: 700,
          color: "#5D6267",
          margin: "20px auto",
          padding: "20px 30px",
          fontFamily: "Arial, Helvetica, sans-serif",
          background: "#fff",
          minHeight: 600
        }}
      >
        <table style={{ borderBottom: "3px solid #3fbbeb", width: "100%" }}>
          <tbody>
            <tr>
              <td style={{ width: "100%" }}>
                <table
                  style={{ marginTop: 20, borderSpacing: 0, width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th>
                        <a
                          href="javascript:;"
                          style={{
                            display: "inline-block",
                            verticalAlign: "top"
                          }}
                        >
                          <img
                            style={{ width: "250px", height: "auto" }}
                            src="http://member.dental.com/static/media/dental-com-logo.bab1de36375ca371a81d5e02f272ec7e.svg"
                            alt="Logo"
                            width="250"
                            height="50"
                            onError={onImageError}
                          />
                        </a>
                      </th>
                    </tr>
                  </thead>
                </table>
                <table>
                  <tbody>
                    <tr>
                      <td
                        style={{
                          fontSize: "1.5rem",
                          padding: "7px 0",
                          fontWeight: 600
                        }}
                      >
                        Patient Information
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table style={{ marginBottom: "10px" }}>
                  <tr>
                    <td>
                      <p style={{ marginBottom: "0px" }}>
                        <strong>Patient Name: </strong>{" "}
                        {visitSummary?.patientFullName}
                      </p>
                      <p style={{ marginBottom: "0px" }}>
                        <strong>DOB: </strong>{" "}
                        {getFormattedDate(visitSummary?.dateOfBirth)}
                      </p>
                      <p style={{ marginBottom: "0px" }}>
                        <strong>Provider: </strong>{" "}
                        {visitSummary?.providerFullName}
                      </p>
                      <p style={{ marginBottom: "0px" }}>
                        <strong>Completed Call Date: </strong>{" "}
                        {getFormattedDate(visitSummary?.completedCallDate)}
                      </p>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </tbody>
        </table>

        <div className="table-responsive">{showVistiSummary()}</div>
      </div>
    </body>
  );
};

export default VisitHistoryPrint;
