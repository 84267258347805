import React, { useEffect, useState } from "react";
import {
  GetPatientInsuranceInfo_Ajax,
  saveInsurance_Ajax
} from "../../helpers/requests";
import InsuranceForm from "./InsuranceForm";
import { InsuranceFormFields } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import { handleApiErrors, showAlertDialouge } from "../../helpers/utils";
import { getPatientInfo } from "../../helpers/authManager";

const InsuranceFormUpdate = (props) => {
  const {
    patientId,
    showApplyToDependentsOption,
    setIsInsuranceFormVisible,
    isInOnDemandFlow,
    shouldNavigateToNextPage,
    saveButtonVerbiage,
    setSelectedCarrierId,
    selectedCarrierId,
    isSecondOpinionFlow,
    onInsuranceInformationSubmittedInSecondOpinionFlow,
    isAddDependentFlow,
    handleSubmitWhenInAddDependentFlow = () => {},
    shouldPrePopulateFormWithResposiblePartysInfo,
    handleBackButton = () => {},
    focusFieldName = ""
  } = props;

  const [initialInsuranceData, setInitialInsuranceData] = useState({});
  const [defaultCarrierData, setDeafultCarrierData] = useState();
  const [hasCarrierLoaded, setHasCarrierLoaded] = useState(false);
  const shouldShowEmployerLocation = useSelector(
    (state) => state?.appSettings?.settingsData?.shouldShowEmployerLocation
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (patientId > 0 && hasCarrierLoaded) {
      let params = {
        includePatInsEligData: false
      };

      GetPatientInsuranceInfo_Ajax(patientId, params, function (response) {
        if (response && response.success && response.data) {
          setInitialInsuranceData(response.data);
        } else {
          let parentPatId = getPatientInfo().patid;
          if (patientId != parentPatId) {
            setInitialInsuranceDataFromParent(parentPatId, params);
          } else {
            initializeInsuranceFormData({});
          }
        }
      });
    } else if (
      shouldPrePopulateFormWithResposiblePartysInfo &&
      hasCarrierLoaded
    ) {
      let parentPatId = getPatientInfo().patid;
      setInitialInsuranceDataFromParent(parentPatId);
    }
  }, [
    patientId,
    hasCarrierLoaded,
    shouldPrePopulateFormWithResposiblePartysInfo
  ]);

  function setInitialInsuranceDataFromParent(parentPatId, params) {
    GetPatientInsuranceInfo_Ajax(parentPatId, params, function (response) {
      if (response && response.success && response.data) {
        if (
          response.data.isApplicableToDependent ||
          shouldPrePopulateFormWithResposiblePartysInfo
        ) {
          response.data.relationToPatient = "";
          response.data.id = 0;
          response.data.isApplicableToDependent = false;
          setInitialInsuranceData(response.data);
        } else {
          let insuranceFormFields = {
            subscriberIdType: response?.data?.subscriberIdType
          };

          if (defaultCarrierData) {
            insuranceFormFields.carrierCode = defaultCarrierData?.carrierCode;
            insuranceFormFields.carrierName = defaultCarrierData?.carrierName;
          }
          initializeInsuranceFormData(insuranceFormFields);
        }
      }
    });
  }

  function initializeInsuranceFormData(insuranceFormFields) {
    Object.keys(InsuranceFormFields).forEach((key) => {
      let value = insuranceFormFields[InsuranceFormFields[key]];
      insuranceFormFields[InsuranceFormFields[key]] = value ?? undefined;
    });
    setInitialInsuranceData(insuranceFormFields);
  }

  const updateExistingInsuranceWithFormData = (insuranceFormData) => {
    initialInsuranceData.insuredFirstName = insuranceFormData.insuredFirstName;
    initialInsuranceData.insuredLastName = insuranceFormData.insuredLastName;
    initialInsuranceData.insuredDob = insuranceFormData.insuredDob;
    initialInsuranceData.insuredAddress = insuranceFormData.insuredAddress;
    initialInsuranceData.insuredCity = insuranceFormData.insuredCity;
    initialInsuranceData.country = insuranceFormData.country;
    initialInsuranceData.gender = insuranceFormData.gender;
    initialInsuranceData.carrierCode = insuranceFormData.carrierCode;
    initialInsuranceData.relationToPatient =
      insuranceFormData.relationToPatient;
    initialInsuranceData.state = insuranceFormData.state;
    initialInsuranceData.zip = insuranceFormData.zip;
    initialInsuranceData.insuredId = insuranceFormData.insuredId;
    initialInsuranceData.isApplicableToDependent =
      insuranceFormData.isApplicableToDependent;
    initialInsuranceData.carrierName = insuranceFormData.carrierName;
    initialInsuranceData.groupNo = insuranceFormData.groupNo;
    initialInsuranceData.groupName = insuranceFormData.groupName;
    initialInsuranceData.ssn = insuranceFormData.ssn;
    initialInsuranceData.subscriberIdType = insuranceFormData.subscriberIdType;
    if (shouldShowEmployerLocation) {
      if (insuranceFormData.employerlocation === "Other") {
        initialInsuranceData.employerName =
          insuranceFormData.otherEmployerLocation;
      } else {
        initialInsuranceData.employerName = insuranceFormData.employerlocation;
      }
    } else {
      initialInsuranceData.employerName = insuranceFormData.employerName;
    }
  };

  const handleSubmit = (insuranceFormData) => {
    updateExistingInsuranceWithFormData(insuranceFormData);

    if (patientId) {
      dispatch(showLoadingSpinner());
      saveInsurance_Ajax(
        patientId,
        JSON.stringify(initialInsuranceData),
        function (response) {
          dispatch(hideLoadingSpinner());

          if (response && response.success) {
            showAlertDialouge("Success", response.message, function () {
              if (isSecondOpinionFlow) {
                onInsuranceInformationSubmittedInSecondOpinionFlow();
              }
            });
          } else if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    } else if (isAddDependentFlow && handleSubmitWhenInAddDependentFlow) {
      handleSubmitWhenInAddDependentFlow(insuranceFormData);
    }
  };

  return (
    <>
      <InsuranceForm
        insuranceFormData={initialInsuranceData}
        setDeafultCarrierData={setDeafultCarrierData}
        setHasCarrierLoaded={setHasCarrierLoaded}
        showApplyToDependentsOption={showApplyToDependentsOption}
        onSubmit={handleSubmit}
        setIsInsuranceFormVisible={setIsInsuranceFormVisible}
        isInOnDemandFlow={isInOnDemandFlow}
        shouldNavigateToNextPage={shouldNavigateToNextPage}
        saveButtonVerbiage={saveButtonVerbiage}
        setSelectedCarrierId={setSelectedCarrierId}
        selectedCarrierId={selectedCarrierId}
        isSecondOpinionFlow={isSecondOpinionFlow}
        isAddDependentFlow={isAddDependentFlow}
        handleBackButton={handleBackButton}
        focusFieldName={focusFieldName}
      />
    </>
  );
};

export default InsuranceFormUpdate;
