import React from "react";

const SuccessMessage = (props) => {
  const { patientFullName } = props;

  return (
    <>
      <fieldset className="d-none111">
        <div className="text-center d-flex flex-wrap align-content-center min-vh">
          <i
            className="bi bi-check-circle text-green lh-1 mb-4 w-100"
            style={{ fontSize: "60px" }}
          ></i>
          <h2 className="w-100">
            Thanks for submitting {patientFullName}'s Record!
          </h2>
        </div>
      </fieldset>
    </>
  );
};

export default SuccessMessage;
