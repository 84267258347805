import React from "react";
import { useState } from "react";
import { isEmptyArray } from "../../helpers/utils";
import { Form } from "@formio/react";
import "formiojs/dist/formio.full.min.css";

const RecordSentModal = (props) => {
  const { consentForm } = props;

  function isFormDataAvailable() {
    return (
      consentForm &&
      consentForm.medicalForm &&
      consentForm.medicalForm.components &&
      !isEmptyArray(consentForm.medicalHistories) &&
      consentForm.medicalHistories[0].formData
    );
  }

  function renderForm() {
    if (isFormDataAvailable()) {
      var formComponents = JSON.parse(consentForm.medicalForm.components);

      // form components
      var formObj = {
        display: "form",
        components: formComponents
      };

      // submitted data
      var submissionData = {
        data: JSON.parse(consentForm.medicalHistories[0].formData)
      };

      // form display options
      var formOptions = {
        readOnly: true,
        viewAsHtml: true
      };

      return (
        <div>
          <div
            style={{
              background: "#eee",
              fontSize: "14px",
              padding: "12px 15px",
              fontWeight: "600",
              marginTop: "10px",
              marginBottom: "20px",
              borderRadius: "5px"
            }}
          >
            <p style={{ marginBottom: "2px" }}>
              Form title: {consentForm.medicalForm.title}
            </p>
          </div>
          <Form
            form={formObj}
            submission={submissionData}
            options={formOptions}
          />
        </div>
      );
    }
  }

  return (
    <>
      <div
        className="modal fade"
        id="medical-records-release-modal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header primary-border-color">
              <h2 className="modal-title text-primary text-center w-100">
                Request and Authorization For Release of Dental Records
              </h2>
              <button
                type="button"
                className="btn-close text-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body px-4 py-4 position-static">
              <div className="consent-form">{renderForm()}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecordSentModal;
