import { setItem, getItem, removeItem } from "./indexedDbHelper";

export function getSecondOpinionDBKey(patid) {
  if (patid) {
    return "secondopinionprogress_" + patid;
  }
}

export function saveSecondOpinionRecordRequestProgress(
  secondOpinionRecordRequestProgess,
  patid
) {
  if (secondOpinionRecordRequestProgess && patid) {
    return setItem(getSecondOpinionDBKey(patid), {
      ...secondOpinionRecordRequestProgess
    });
  }
}

export function getSecondOpinionRecordRequestProgress(patid) {
  if (patid) {
    return getItem(getSecondOpinionDBKey(patid));
  }
}

export function clearSecondOpinionRecordRequestProgress(patid) {
  if (patid) {
    return removeItem(getSecondOpinionDBKey(patid));
  }
}
