import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";

import "../../assets/css/style.css";
import Navbar from "../../components/Navbar/Navbar";

import NotesTable from "../../components/Notes/NotesTable";
import NotesProviderInformation from "../../components/Notes/NotesProviderInformation";
import NotesImageModal from "../../components/Notes/NotesImageModal";
import NotesPdfModal from "../../components/Notes/NotesPdfModal";
import NotesDeleteAlertModal from "../../components/Notes/NotesDeleteAlertModal";

import {
  getPatientNotes_Ajax,
  getProgressNoteAttachments_Ajax,
  getProviderData_Ajax,
  getNotePrintDetails_Ajax,
  getCreatorInfo_Ajax
} from "../../helpers/requests";
import { getPatientInfo } from "../../helpers/authManager";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import { handleApiErrors, showAlertDialouge } from "../../helpers/utils";
import NoteAttachedFormsModal from "../../components/Notes/NoteAttachedFormsModal";
import { setFileUrlToOpen } from "../../redux/actions/fileViewer";

const Notes = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const pgId = useSelector((state) => state.userData.pgId);
  const currentPatient = useSelector(
    (state) => state?.userData?.currentPatient
  );
  const [totalCount, setTotalCount] = useState(0);
  const [patientNotes, setPatientNotes] = useState([]);
  const [utcOffset, setUtcOffset] = useState(0);
  const [isDST, setIsDST] = useState(false);
  const [providerData, setProviderData] = useState(null);
  const [reloadPatientNote, setReloadPatientNote] = useState(false);
  const [noteAttachedForms, setNoteAttachedForms] = useState(null);
  const [creatorInfo, setCreatorInfo] = useState(null);

  useEffect(() => {
    if (currentPatient) {
      setReloadPatientNote(true);
    }
  }, [currentPatient]);

  function getPatientNotes(currentPageNum, itemPerPage) {
    var patientInfo = currentPatient;

    if (patientInfo && patientInfo.patid && currentPageNum && itemPerPage) {
      var params = {
        itemperpage: itemPerPage,
        currentpagenum: currentPageNum,
        isOnlyProgressNote: false,
        addNotesprintDetails: true
      };

      dispatch(showLoadingSpinner());
      getPatientNotes_Ajax(
        patientInfo.patid,
        params,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (
            response &&
            response.success &&
            response.data &&
            response.data.progressNotes &&
            Array.isArray(response.data.progressNotes) &&
            response.data.progressNotes.length > 0
          ) {
            setPatientNotes(response.data.progressNotes);

            if (response.data.totalCount) {
              setTotalCount(response.data.totalCount);
            }

            if (response.data.utcOffset != undefined) {
              setUtcOffset(response.data.utcOffset);
            }

            if (response.data.isDST) {
              setIsDST(response.data.isDST);
            }
          } else if (response && !response.success) {
            setPatientNotes([]);
            setTotalCount(0);
            setUtcOffset(null);
            setIsDST(false);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function getProgressNoteAttachments(note) {
    if (note && note.noteType && note.patientNoteId) {
      dispatch(showLoadingSpinner());
      getProgressNoteAttachments_Ajax(
        note.patientNoteId,
        note.noteType,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (
            response &&
            response.success &&
            response.data &&
            response.data.attachedDocs
          ) {
            var arr = patientNotes;

            if (arr && Array.isArray(arr) && arr.length) {
              arr.forEach((patientNote) => {
                if (
                  patientNote &&
                  patientNote.patientNoteId &&
                  patientNote.patientNoteId == note.patientNoteId
                ) {
                  patientNote.noteAttatchments = response.data.attachedDocs;
                }
              });

              setPatientNotes([]);
              setPatientNotes(arr);
            }
          } else if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
        }
      );
    }
  }

  function getProgressNoteAttachedForms(note) {
    if (note && note.noteType && note.noteId) {
      var noteId = note.noteId;
      setNoteAttachedForms(null);
      dispatch(showLoadingSpinner());
      getProgressNoteAttachments_Ajax(
        noteId,
        note.noteType,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (response && response.success && response.data) {
            if (
              response.data.attachedForms &&
              response.data.attachedForms.length > 0
            ) {
              setNoteAttachedForms(response.data.attachedForms);
            }
          }
        },
        function (err) {
          handleApiErrors(err);
          dispatch(hideLoadingSpinner());
        }
      );
    }
  }

  function getProviderData(patientNote) {
    if (
      patientNote &&
      patientNote.provider &&
      patientNote.provider.providerid
    ) {
      dispatch(showLoadingSpinner());
      setProviderData(null);
      getProviderData_Ajax(
        patientNote.provider.providerid,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (response && response.success && response.data) {
            setProviderData(response.data);
          } else if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
        }
      );
    }
  }

  function getCreatorInfo(patientNote) {
    if (patientNote && patientNote.createdBy) {
      dispatch(showLoadingSpinner());
      setCreatorInfo(null);

      getCreatorInfo_Ajax(
        patientNote.createdBy,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (response?.success && response?.data) {
            setCreatorInfo(response.data);
          } else if (!response?.success && response?.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
        }
      );
    }
  }

  function openFile(url) {
    if (url) {
      dispatch(setFileUrlToOpen(url));
    }
  }

  function printBtnClicked(note) {
    if (note && note.noteType && (note.patientNoteId || note.noteId)) {
      var noteId = note.patientNoteId ? note.patientNoteId : note.noteId;

      dispatch(showLoadingSpinner());
      getNotePrintDetails_Ajax(
        noteId,
        note.noteType,
        function (response) {
          dispatch(hideLoadingSpinner());
        },
        function (err) {
          dispatch(hideLoadingSpinner());
        }
      );
    }
  }

  return (
    <body>
      <NotesTable
        patientNotes={patientNotes}
        totalCount={totalCount}
        getPatientNotes={getPatientNotes}
        reloadPatientNote={reloadPatientNote}
        setReloadPatientNote={setReloadPatientNote}
        getProgressNoteAttachments={getProgressNoteAttachments}
        openFile={openFile}
        getProviderData={getProviderData}
        utcOffset={utcOffset}
        isDST={isDST}
        getProgressNoteAttachedForms={getProgressNoteAttachedForms}
        getCreatorInfo={getCreatorInfo}
      />
      <NotesProviderInformation creatorInfo={creatorInfo} />
      <NotesDeleteAlertModal />
      <NoteAttachedFormsModal
        noteAttachedForms={noteAttachedForms}
        setNoteAttachedForms={setNoteAttachedForms}
      />

      {/* <!-- scripts --> */}
      <script
        src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js"
        integrity="sha512-894YE6QWD5I59HgZOGReFYm4dnWc1Qt5NtvYSaNcOP+u1T9qYdvdihz0PPSiiqn/+/3e7Jo4EaG7TubfWGUrMQ=="
        crossOrigin="anonymous"
        referrerPolicy="no-referrer"
      ></script>
      <script
        src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"
        crossOrigin="anonymous"
      ></script>
    </body>
  );
};

export default Notes;
