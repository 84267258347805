import localforage from "localforage";

export function setItem(key, value) {
  return localforage.setItem(key, value);
}

export function getItem(key) {
  return localforage.getItem(key);
}

export function removeItem(key) {
  return localforage.removeItem(key);
}
