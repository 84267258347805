import React from "react";
import { useSelector } from "react-redux";
import useIsParTypeCarrieSelected from "./useIsParTypeCarrieSelected";

const useShowVerbiageBasedOnNonParCarrierSelected = (
  patientId,
  selectedCarrierId
) => {
  const onDemandCallInsuranceCaptureEnabled = useSelector((state) =>
    state?.appSettings?.settingsData?.OnDemandCallInsuranceCaptureEnabled
  );
  const parCarrierCheckedEnabled = useSelector((state) =>
    state?.appSettings?.settingsData?.ParCarrierCheckedEnabled
  );
  const [isParTypeCarrierSelected] = useIsParTypeCarrieSelected(
    patientId,
    selectedCarrierId
  );
  const isInsuranceCarrierSelected = () => {
    return selectedCarrierId > 0;
  };
  const showVerbiageBasedOnNonParCarrierSelected = () => {
    if (
      parCarrierCheckedEnabled &&
      onDemandCallInsuranceCaptureEnabled &&
      isInsuranceCarrierSelected() &&
      !isParTypeCarrierSelected()
    ) {
      return (
        <p class="mb-3 mb-md-4">
          We do not have a direct agreement with your insurance at this time.
          <b> However, it still may be a covered benefit for you.</b> If you
          enter your insurance information, we will submit a claim on your
          behalf for possible direct reimbursement to you. If you are eligible
          for the benefit, there may be some reimbursement.
          <b>
            {" "}
            Please note you will still have to pay by credit card at the time of
            service.
          </b>
        </p>
      );
    }
    return <></>;
  };

  return showVerbiageBasedOnNonParCarrierSelected;
};

export default useShowVerbiageBasedOnNonParCarrierSelected;
