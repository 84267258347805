import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  showLoadingSpinner,
  hideLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";

const override = css`
  position: fixed;
  top: 40%;
  left: 48%;
  z-index: 1200;
`;

class LoadingSpinner extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    if (this.props.loadingSpinnerCount > 0) {
      return <ClipLoader loading={true} size={50} css={override} />;
    } else return null;
  }
}

const mapStateToProps = (state) => {
  return {
    loadingSpinnerCount: state.loadingSpinner.loadingSpinnerCount
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoadingSpinner,
      hideLoadingSpinner
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LoadingSpinner);
