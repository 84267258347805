import React from "react";
import SmartScanSelectPatientPage from "../../components/SmartScanSelectPatient/SmartScanSelectPatientPage";

const SmartScanSelectPatient = () => {
  return (
    <>
      <body className="mobile-body">
        <div className="body-content">
          <section className="review-photo-section py-4 py-md-5">
            <SmartScanSelectPatientPage />
          </section>
        </div>
      </body>
    </>
  );
};

export default SmartScanSelectPatient;
