import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import FormPrintPage from "../../components/FormPrint/FormPrintPage";

import { getMedicalFormPatientWithHistory_Ajax } from "../../helpers/requests";
import { handleApiErrors, showAlertDialouge } from "../../helpers/utils";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";

const FormPrint = () => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const { medicalFormPatientId, historyId } = useParams();

  const [formData, setFormData] = useState(null);
  const [historyData, setHistoryData] = useState(null);

  useEffect(
    function () {
      if (medicalFormPatientId && historyId) {
        getMedicalFormPatientWithHistory();
      }
    },
    [medicalFormPatientId, historyId]
  );

  function getMedicalFormPatientWithHistory() {
    if (medicalFormPatientId && historyId) {
      dispatch(showLoadingSpinner());
      getMedicalFormPatientWithHistory_Ajax(
        medicalFormPatientId,
        historyId,
        function (response) {
          dispatch(hideLoadingSpinner());

          if (
            response &&
            response.success &&
            response.data &&
            response.data.medicalForm &&
            response.data.medicalHistories &&
            Array.isArray(response.data.medicalHistories) &&
            response.data.medicalHistories.length > 0
          ) {
            setFormData(response.data.medicalForm);
            setHistoryData(response.data.medicalHistories[0]);
          } else if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors();
        }
      );
    }
  }

  function printForm() {
    document.title = "form";
    window.print();
  }

  return (
    <>
      <body style={{ margin: "0", padding: "0px 15px", background: "#f2f2f2" }}>
        <div
          style={{ textAlign: "right", maxWidth: "700px", margin: "20px auto" }}
        >
          <button
            onClick={() => printForm()}
            style={{
              backgroundColor: "#3fbbeb",
              border: "1px solid #3fbbeb",
              borderRadius: "50rem",
              fontSize: "12px",
              padding: "3px 15px",
              color: "#fff",
              cursor: "pointer"
            }}
            className="noprint"
          >
            <i className="fa fa-print"></i> Print
          </button>
        </div>
        <FormPrintPage formData={formData} historyData={historyData} />
      </body>
    </>
  );
};
export default FormPrint;
