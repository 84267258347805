import React, { useEffect, useState } from "react";
import { Form } from "@formio/react";
import { initializeAdditionalChangesInFormIoFormDuringRender } from "../../helpers/utils";

const FormShowAndSubmit = (props) => {
  const { forms, setForms, totalForms, handleAllFormSubmission } = props;

  const [displayFormData, setDisplayFormData] = useState(null);
  const [currentFormIndex, setCurrentFormIndex] = useState(0);

  useEffect(() => {
    if (totalForms > 0) {
      let formData = {};
      let formArray = JSON.parse(forms[currentFormIndex].formComponent);

      let formObj = {
        display: "form",
        components: formArray
      };

      let submissionData = {};
      let formOptions = {};

      if (forms[currentFormIndex].formData) {
        submissionData = {
          data: forms[currentFormIndex].formData
        };

        formOptions = {
          readOnly: true,
          viewAsHtml: true
        };
      }

      formData.formObj = formObj;
      formData.submissionData = submissionData;
      formData.formOptions = formOptions;

      setDisplayFormData(formData);

      if (isAllFormSubmitted()) {
        handleAllFormSubmission();
      }
    }
  }, [currentFormIndex, forms, totalForms]);

  function onSuccessfulFormSubmission(submissionData) {
    if (submissionData && currentFormIndex >= 0 && forms) {
      let formsData = [...forms];

      formsData?.forEach((form, index) => {
        if (index == currentFormIndex) {
          form.isSubmitted = true;
          form.formData = submissionData;
        }
      });

      setForms(formsData);
      nextBtnClicked();
    }
  }

  function nextBtnClicked() {
    if (currentFormIndex < totalForms - 1) {
      setCurrentFormIndex(currentFormIndex + 1);
    }
  }

  function isAllFormSubmitted() {
    let allSubmitted = true;

    if (totalForms > 0) {
      forms.map((form) => {
        if (!form?.isSubmitted) {
          allSubmitted = false;
          return allSubmitted;
        }
      });
    }

    return allSubmitted;
  }

  function shouldDisplayForm() {
    return totalForms > 0;
  }

  function onFormSubmit(submission) {
    setTimeout(function () {
      if (submission?.data) {
        onSuccessfulFormSubmission(submission.data);
      }
    }, 500);
  }

  return (
    <div
      className="tab-pane fade show active"
      id="see-dentist"
      role="tabpanel"
      aria-labelledby="see-dentist-tab"
    >
      {/* <form> */}
      <fieldset>
        {shouldDisplayForm() ? (
          <div id="campaign-form" key={currentFormIndex}>
            <h1>
              Form ({currentFormIndex + 1}/{totalForms})
            </h1>
            <div className="campaign-form-container">
              <h3>{forms[currentFormIndex].title}</h3>
              <Form
                onRender={initializeAdditionalChangesInFormIoFormDuringRender}
                key={"displayed_" + currentFormIndex}
                form={displayFormData?.formObj}
                onSubmit={onFormSubmit}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </fieldset>
    </div>
  );
};

export default FormShowAndSubmit;
