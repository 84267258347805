import React, { useEffect, useState } from "react";
import phone from "./../../assets/images/Phone.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelVoipCall_Ajax,
  getActiveVoipCalls_Ajax
} from "../../helpers/requests";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import {
  handleApiErrors,
  isEmptyArray,
  showAlertDialouge,
  showConfirmDialouge
} from "../../helpers/utils";
import Pagination from "../../redux/customHooks/pagination";
import {
  IntervalTimes,
  Pagination as PaginationConstants
} from "../../constants";

const RequestPhoneCall = () => {
  const currentPatient = useSelector(
    (state) => state?.userData?.currentPatient
  );
  const dispatch = useDispatch();
  const [voipcalls, setVoipCalls] = useState([]);
  const [voipCallsCurrentPage, setVoipCallsCurrentPage] = useState(0);
  const [voipCallsTotalCount, setVoipCallsTotalCount] = useState(0);
  const [voipCallsFilterOptions, setVoipCallsFilterOptions] = useState({
    currentPatient: currentPatient,
    currentPageNumber: voipCallsCurrentPage
  });

  useEffect(() => {
    if (voipCallsCurrentPage > 0) {
      let newOptions = {
        ...voipCallsFilterOptions,
        currentPageNumber: voipCallsCurrentPage
      };
      setVoipCallsFilterOptions(newOptions);
    }
  }, [voipCallsCurrentPage]);

  function InitVoipCallFetchingFilterOptions() {
    let newVoipCallsFilterOptions = {
      ...voipCallsFilterOptions,
      currentPatient: currentPatient,
      currentPageNumber: PaginationConstants.FirstPageNo
    };
    setVoipCallsCurrentPage(PaginationConstants.FirstPageNo);
    setVoipCallsFilterOptions(newVoipCallsFilterOptions);
  }

  useEffect(() => {
    if (currentPatient) {
      InitVoipCallFetchingFilterOptions();
    }
  }, [currentPatient]);

  useEffect(() => {
    if (
      voipCallsFilterOptions.currentPageNumber > 0 &&
      voipCallsFilterOptions.currentPatient
    ) {
      getVoipCalls();

      const interval = setInterval(() => {
        getVoipCalls();
      }, IntervalTimes.Minute_MS_30);

      return () => clearInterval(interval);
    }
  }, [voipCallsFilterOptions]);

  function getVoipCalls() {
    let params = {
      ItemPerPage: PaginationConstants.SmartScansItemPerPage,
      CurrentPageNumber: voipCallsFilterOptions.currentPageNumber,
      PatId: voipCallsFilterOptions.currentPatient.patid
    };

    dispatch(showLoadingSpinner());

    getActiveVoipCalls_Ajax(
      params,
      function (response) {
        dispatch(hideLoadingSpinner());
        if (response?.success && response?.data) {
          setVoipCalls(response?.data);
          setVoipCallsTotalCount(response?.total_count);
        } else if (response && !response.success) {
          setVoipCalls([]);
          setVoipCallsTotalCount(response?.total_count);
          if (response.message && response.total_count == -1) {
            showAlertDialouge("Error", response.message);
          }
        }
      },
      function (err) {
        dispatch(hideLoadingSpinner());
        handleApiErrors(err);
      }
    );
  }

  function onCancelBtnClicked(voipcall) {
    if (voipcall?.voipCallId) {
      showConfirmDialouge(
        "",
        "Are you sure you want to cancel call back request?",
        function () {
          cancelVoipCall(voipcall?.voipCallId);
        }
      );
    } else {
      showAlertDialouge("Error", "Appointment not found");
    }
  }

  function cancelVoipCall(voipCallId) {
    if (voipCallId) {
      dispatch(showLoadingSpinner());

      cancelVoipCall_Ajax(
        voipCallId,
        function (response) {
          dispatch(hideLoadingSpinner());

          if (response?.success) {
            showAlertDialouge(
              "Message",
              "Your Voip call has been cancelled successfully",
              function () {
                setVoipCallsCurrentPage(PaginationConstants.FirstPageNo);
                InitVoipCallFetchingFilterOptions();
                getVoipCalls();
              }
            );
          } else if (!response?.success && response?.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  return (
    !isEmptyArray(voipcalls) && (
      <div>
        {voipcalls.map((voipcall, index) => (
          <div
            key={index}
            className="patient-member-card request-phone-call mb-3"
          >
            <div className="card text-body text-decoration-none d-flex flex-row align-items-center border-0 border-radius-xlg p-3">
              <div className="card-header flex-shrink-000 p-0 border-0">
                <img src={phone} alt="phone" className="phone-call" />
              </div>
              <div className="card-body flex-grow-1 ps-3 pe-0 ps-md-4 py-0 border-0 d-flex flex-wrap">
                <div className="flex-grow-1 me-2">
                  <h3 className="card-title">Callback Requested</h3>
                  <p className="card-text text-muted">
                    A callback was requested. We will give you a call at{" "}
                    <strong className="text-black">
                      {voipcall?.recipientPhone}
                    </strong>
                  </p>
                </div>
                <div className="appointment-buttons my-2 my-md-0">
                  <button
                    onClick={() => onCancelBtnClicked(voipcall)}
                    className="btn btn-outline-primary btn-xs ms-2 ms-md-3"
                    style={{ minWidth: "0" }}
                    aria-label="Close"
                  >
                    <i className="bi bi-x-circle pe-1 d-none d-md-inline-block"></i>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
        <Pagination
          className="pagination-bar"
          currentPage={voipCallsCurrentPage}
          totalCount={voipCallsTotalCount ? voipCallsTotalCount : 0}
          pageSize={PaginationConstants.SmartScansItemPerPage}
          onPageChange={(pageNo) => setVoipCallsCurrentPage(pageNo)}
        />
        <hr className="my-4" />
      </div>
    )
  );
};

export default RequestPhoneCall;
