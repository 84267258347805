import React from "react";
import { Link } from "react-router-dom";

const ForgotPasswordAlert = () => {
  return (
    <>
      {/* <!-- Password sent modal --> */}
      <div className="modal fade" id="forgotPass-modal" tabIndex="-1">
        <div className="modal-dialog modal-sm">
          <div className="modal-content">
            <div className="modal-header primary-border-color">
              <h2 className="modal-title text-primary text-center w-100">
                Alert
              </h2>
            </div>
            <div className="modal-body text-center">
              <p>
                An email has been sent with instruction to reset password.
                Please check your email.
              </p>
              <a href="/" className="btn btn-primary btn-xs" aria-label="Close">
                OK
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordAlert;
