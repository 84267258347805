import React from "react";
const DummyWellnessSummeryScore = () => {
  return (
    <div className="col-12 col-lg-6 col-xl-5 text-center content-center d-flex flex-wrap flex-lg-nowrap align-items-center pe-0 pe-lg-4 mb-3 mb-lg-0 opacity-50 filter-grayscale position-relative">
      <div className="d-flex flex-wrap flex-lg-nowrap justify-content-between align-items-center">
        <div className="ps-0 pe-3 px-md-2">
          <span className="fs-3 fw-bold d-block">64</span>
          <small className="text-muted d-block">MM/DD/YY</small>
        </div>
        <div className="ps-0 pe-3 px-md-2">
          <span
            className="text-green lh-1 display-2 d-block mb-2"
            style={{ marginTop: "-20px" }}
          >
            {" "}
            95
          </span>
          <span className="bg-green-light fs-5 text-green fw-bold d-block px-2 rounded-pill">
            GOOD
          </span>
          <small className="text-muted d-block">MM/DD/YY</small>
        </div>
        <div className="ps-0 pe-0 px-md-2">
          <span className="fs-3 text-primary fw-bold d-block">
            <i className="bi bi-arrow-up fs-5"></i> 31
          </span>
          <small className="text-muted d-block">change</small>
        </div>
      </div>
      <div className="sample-text fs-1 fw-bold w-100 w-lg-auto opacity-50 filter-grayscale">
        Sample
      </div>
    </div>
  );
};

export default DummyWellnessSummeryScore;
