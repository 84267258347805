import { useSelector } from "react-redux";
import { isEmptyArray } from "../../helpers/utils";

const useShouldDisplayPrimaryConsultantInfoCaptureForm = (
  sharedStates,
  onDemandCampaign,
  patientState
) => {
  const isPrimaryDentistInformationCaptureEnabled = useSelector(
    (state) =>
      state?.appSettings?.settingsData?.primaryDentistInformationCaptureEnabled
  );

  function isRecordShareConsentEnabledForCampaign() {
    return onDemandCampaign?.isRecordShareConsentEnabled;
  }

  const shouldDisplayPrimaryConsentForm = () => {
    if (
      !isEmptyArray(sharedStates) &&
      isRecordShareConsentEnabledForCampaign() &&
      isPrimaryDentistInformationCaptureEnabled &&
      patientState
    ) {
      return sharedStates.find((x) => x.state == patientState);
    }
    return false;
  };
  return shouldDisplayPrimaryConsentForm;
};

export default useShouldDisplayPrimaryConsultantInfoCaptureForm;
