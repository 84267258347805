import React, { useEffect } from "react";
import QuestionnaireAfterPaymentSuccessfulPage from "../../components/QuestionnaireAfterPaymentSuccessfulPage/QuestionnaireAfterPaymentSuccessfulPage";
import { queryStringToJSON, showAlertDialouge } from "../../helpers/utils";

const QuestionnaireAfterPaymentSuccessful = () => {
  function showCardCaptureFailureAlert() {
    let params = queryStringToJSON();
    if (params.isauthorized == "false") {
      showAlertDialouge(
        "Error",
        "Failed to authorize the card. Please try again"
      );
    }
  }

  useEffect(() => {
    showCardCaptureFailureAlert();
  });

  return (
    <>
      <div className="body-content">
        <section className="questionnaire-section py-4 py-md-5">
          <QuestionnaireAfterPaymentSuccessfulPage />
        </section>
      </div>
    </>
  );
};

export default QuestionnaireAfterPaymentSuccessful;
