import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  removeCurrentPatient,
  removeTokenData,
  removeFamilyMembersList
} from "../actions/userData";
import { removeTokenData as removeTokenDataFromStorage } from "../../helpers/authManager";
import { getPgidFromUrl } from "../../ApplicationSettings";

const useClearSessionData = () => {
  const dispatch = useDispatch();

  const clearSessionData = useCallback(() => {
    let pgId = getPgidFromUrl();

    dispatch(removeCurrentPatient());
    dispatch(removeTokenData());
    dispatch(removeFamilyMembersList());
    removeTokenDataFromStorage(pgId);
  }, [dispatch]);

  return clearSessionData;
};
export default useClearSessionData;
