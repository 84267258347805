import React from "react";
import QuestionnaireIntakeFormPage from "../../components/QuestionnaireIntakeForm/QuestionnaireIntakeFormPage";

const QuestionnaireIntakeForm = () => {
  return (
    <>
      <div className="body-content">
        <section className="questionnaire-section py-4 py-md-5">
          <QuestionnaireIntakeFormPage />
        </section>
      </div>
    </>
  );
};

export default QuestionnaireIntakeForm;
