import React from "react";
import {
  getDefaultPicPath,
  isImageFile,
  isPDFfile,
  isValidDocFileForPatientUpload
} from "../../helpers/utils";
import IconPDF from "./../../assets/images/icon-pdf.svg";
import GenericFileIcon from "../../assets/images/word_processor_file_icon.png";
import { setFileUrlToOpen } from "../../redux/actions/fileViewer";
import { useDispatch } from "react-redux";

export default function CaseReportAnnotations({ caseReportAnnotations }) {
  const dispatch = useDispatch();

  function openFile(url) {
    if (url) {
      dispatch(setFileUrlToOpen(url));
    }
  }

  return (
    <div className="thumb-img-wrapper d-flex flex-nowrap overflow-auto tz-gallery">
      {caseReportAnnotations.map((annotation) => {
        if (isImageFile(annotation.photoId)) {
          return (
            <a
              key={annotation.caseReportAnnotationId.toString()}
              href="#"
              className="lightbox"
              data-bs-toggle="modal"
              data-toggle="modal"
              data-bs-target="#fileViewerImage"
              onClick={() => openFile(annotation.photoId)}
            >
              <img
                className=""
                src={annotation.photoId}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = getDefaultPicPath(currentTarget);
                }}
                alt="File img"
              />
            </a>
          );
        } else if (isValidDocFileForPatientUpload(annotation.photoId)) {
          return (
            <a
              key={annotation.caseReportAnnotationId}
              href="#"
              className="lightbox"
              data-bs-toggle="modal"
              data-toggle="modal"
              data-bs-target="#fileViewerDoc"
              onClick={() => openFile(annotation.photoId)}
            >
              <img
                className=""
                src={isPDFfile(annotation.photoId) ? IconPDF : GenericFileIcon}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = getDefaultPicPath(currentTarget);
                }}
                alt="File img"
              />
            </a>
          );
        } else {
          return (
            <a
              key={annotation.caseReportAnnotationId}
              href={annotation.photoId}
              target="_blank"
              className="lightbox"
            >
              <img
                src={annotation.photoId}
                onError={onImageError}
                alt="File img"
              />
            </a>
          );
        }
      })}
    </div>
  );
}
