import { onImageError } from "../../helpers/utils";

const AttachedSignature = (props) => {
  const isSignatureAttached = props?.isSignatureAttached;
  const attachedSignature = props?.attachedSignature;
  const isScribeTypeSignature = props?.isScribeTypeSignature;
  return (
    <>
      {isSignatureAttached && attachedSignature && (
        <div className="row  ">
          <div className="col-md-12">
            <div className="form-group">
              <h4 className="mb-2 note-signature-text">Signature</h4>
              <div className="page-break-before-auto">
                {isScribeTypeSignature ? (
                  <img
                    className="gth-signature"
                    src={attachedSignature}
                    onError={onImageError}
                  ></img>
                ) : (
                  <p type="text" className="gth-signature">
                    {" "}
                    {attachedSignature}{" "}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AttachedSignature;
