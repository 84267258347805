import React from "react";
import No_preview_available from "../../assets/images/No_preview_available.jpg";
import { onImageError } from "../../helpers/utils";

const UploadsViewModals = (props) => {
  const { fileUrlToOpen, providerData } = props;

  return (
    <>
      {/* <!-- Provider Information modal --> */}
      {
        <div className="modal fade" id="providerProfile" tabIndex="-1">
          <div className="modal-dialog modal-dialog-centered">
            {providerData && (
              <>
                <div className="modal-content">
                  <div className="modal-header primary-border-color">
                    <h2 className="modal-title text-primary text-center w-100">
                      About Provider
                    </h2>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    {providerData.photoId ? (
                      <img
                        src={providerData.photoId}
                        onError={onImageError}
                        className="mb-3 provider-profile-img"
                      />
                    ) : (
                      <img
                        src={No_preview_available}
                        className="mb-3 provider-profile-img"
                      />
                    )}
                    <h2 className="mb-1">{providerData.user?.fullName}</h2>
                    <h5 className="mb-3">{providerData.title}</h5>
                    <p>{providerData.aboutMe}</p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      }
      {/* <!-- //Provider Information modal -->
        <!-- Image File Modal --> */}
      <div className="modal fade" id="fileImage" tabIndex="-1">
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h2 className="modal-title">Images</h2>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <img
                src={fileUrlToOpen}
                onError={onImageError}
                alt="Provider Image"
              />
            </div>
          </div>
        </div>
      </div>
      {/* <!-- //Image File Modal -->
        <!-- PDF View Modal --> */}
      <div className="modal fade" id="filePDF-view" tabIndex="-1">
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h2 className="modal-title">PDF</h2>
              <button
                type="button"
                className="btn-close text-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <object
                data={fileUrlToOpen}
                type="application/pdf"
                width="100%"
                height="500"
              >
                <div className="m-3">
                  <p>
                    Your browser does not support viewing this file. You can{" "}
                    <a href={fileUrlToOpen} target="_blank">
                      click here to download the file
                    </a>
                    .
                  </p>
                </div>
              </object>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- //PDF View Modal -->
        <!-- Video call Modal --> */}
      <div className="modal fade" id="videoCall-modal" tabIndex="-1">
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h2 className="modal-title">Video Call Recording</h2>
              <button
                type="button"
                className="btn-close text-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <iframe
                className="w-100"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/2xU4RKUWDK0"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- //Video call Modal --> */}
    </>
  );
};

export default UploadsViewModals;
