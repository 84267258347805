import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  saveSmartScanPhotos_Ajax,
  smartScanPostBookingProcedures_Ajax
} from "../../helpers/requests";
import {
  arrayBufferToBlob,
  getSmartScanImageObjectNameForState,
  handleApiErrors,
  showAlertDialouge
} from "../../helpers/utils";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import { smartScanCategory } from "../../constants";
import useGetSmartScanSelfieCaptureProgress from "../CustomHooks/useGetSmartScanSelfieCaptureProgress";
import {
  clearSmartScanSelfieCaptureProgress,
  saveSmartScanSelfieCaptureProgress
} from "../../indexedDbHelper/smartScanProgressIndexedDbHelper";
import SmartScanSelfie from "./SmartScanSelfie";
import { getIsSmartScanSelfieCaptureProgressCachingEnabled } from "../../ApplicationSettings";

const SmartScanPosePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pgId = useSelector((state) => state?.appSettings?.settingsData?.pgId);
  const patientOrigin = useSelector(
    (state) => state?.appSettings?.settingsData?.patientOrigin
  );
  const [isSavingInProgress, setIsSavingInProgess] = useState(false);
  const smartScanState = useGetSmartScanSelfieCaptureProgress();

  function retakeBtnClicked(retakeType) {
    let newState = { ...smartScanState, isRetake: true };
    saveSmartScanSelfieCaptureProgress(newState, newState.patientId);
    let retakePageState;

    if (getIsSmartScanSelfieCaptureProgressCachingEnabled()) {
      retakePageState = {
        patientId: newState.patientId,
        isRetake: newState.isRetake
      };
    } else {
      retakePageState = { ...newState };
    }

    switch (retakeType) {
      case smartScanCategory.Bite:
        navigate("/smart-scan-bite-confirm", {
          state: retakePageState
        });
        break;
      case smartScanCategory.LowerArch:
        navigate("/smart-scan-lower-arch-confirm", {
          state: retakePageState
        });
        break;
      case smartScanCategory.UpperArch:
        navigate("/smart-scan-upper-arch-confirm", {
          state: retakePageState
        });
        break;
      case smartScanCategory.UpperLip:
        navigate("/smart-scan-upper-lip-confirm", {
          state: retakePageState
        });
        break;
      case smartScanCategory.LowerLip:
        navigate("/smart-scan-lower-lip-confirm", {
          state: retakePageState
        });
        break;
      default:
    }
  }

  function smartScanPostBookingProcedures(postBookingProcedures) {
    if (postBookingProcedures) {
      smartScanPostBookingProcedures_Ajax(
        JSON.stringify(postBookingProcedures)
      );
    }
  }

  function getSmartScanCategoryPhotoFileObj(category) {
    if (category && smartScanState) {
      let imageFile;
      if (getIsSmartScanSelfieCaptureProgressCachingEnabled()) {
        imageFile = arrayBufferToBlob(
          smartScanState[getSmartScanImageObjectNameForState(category)]?.file,
          smartScanState[getSmartScanImageObjectNameForState(category)]?.name,
          smartScanState[getSmartScanImageObjectNameForState(category)]
            ?.mimeType
        );
      } else {
        imageFile =
          smartScanState[getSmartScanImageObjectNameForState(category)]?.file;
      }

      return imageFile;
    }
  }

  function nextBtnClicked() {
    let bitePhoto = getSmartScanCategoryPhotoFileObj(smartScanCategory.Bite);
    let upperArchPhoto = getSmartScanCategoryPhotoFileObj(
      smartScanCategory.UpperArch
    );
    let lowerArchPhoto = getSmartScanCategoryPhotoFileObj(
      smartScanCategory.LowerArch
    );
    let upperLipPhoto = getSmartScanCategoryPhotoFileObj(
      smartScanCategory.UpperLip
    );
    let lowerLipPhoto = getSmartScanCategoryPhotoFileObj(
      smartScanCategory.LowerLip
    );

    if (
      smartScanState &&
      smartScanState.patientId > 0 &&
      bitePhoto &&
      lowerArchPhoto &&
      upperArchPhoto &&
      upperLipPhoto &&
      lowerLipPhoto
    ) {
      let formData = new FormData();
      let requestVM = {
        clientId: patientOrigin,
        pgId: pgId,
        patId: Number(smartScanState.patientId),
        createdBy: smartScanState.createdBy
      };

      formData.append("requestVM", JSON.stringify(requestVM));
      formData.append("bitePhoto", bitePhoto);
      formData.append("upperArchPhoto", upperArchPhoto);
      formData.append("lowerArchPhoto", lowerArchPhoto);
      formData.append("upperLipPhoto", upperLipPhoto);
      formData.append("lowerLipPhoto", lowerLipPhoto);

      dispatch(showLoadingSpinner());
      setIsSavingInProgess(true);

      saveSmartScanPhotos_Ajax(
        formData,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (
            response &&
            response.success &&
            response.data.smartScanId &&
            response.data.postBookingProcedures
          ) {
            smartScanPostBookingProcedures(response.data.postBookingProcedures);
            if (smartScanState.redirectUrl) {
              window.location.href = smartScanState.redirectUrl;
            } else {
              navigate("/smart-scan-submitted");
            }
          } else if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
          setIsSavingInProgess(false);
          clearSmartScanSelfieCaptureProgress(smartScanState.patientId);
        },
        function (error) {
          setIsSavingInProgess(false);
          dispatch(hideLoadingSpinner());
          handleApiErrors(error);
        }
      );
    }
  }

  async function goToPreviousPage() {
    let previousPageState;
    if (getIsSmartScanSelfieCaptureProgressCachingEnabled()) {
      previousPageState = {
        patientId: smartScanState?.patientId
      };
      let newState = { ...smartScanState, isRetake: false };
      await saveSmartScanSelfieCaptureProgress(
        newState,
        smartScanState.patientId
      );
    } else {
      previousPageState = { ...smartScanState, isRetake: false };
    }

    navigate("/smart-scan-bite-confirm", {
      state: previousPageState
    });
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="gth-main-header mb-3">
              <div className="heading-wrapper back-to-wrapper position-relative">
                <h1 className="title">Smart Scan</h1>
              </div>
            </div>
            <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
              <div className="text-center">
                {
                  <div className="row">
                    <SmartScanSelfie
                      retakeBtnClicked={retakeBtnClicked}
                      category={smartScanCategory.UpperArch}
                      imageFile={getSmartScanCategoryPhotoFileObj(
                        smartScanCategory.UpperArch
                      )}
                    />
                    <SmartScanSelfie
                      retakeBtnClicked={retakeBtnClicked}
                      category={smartScanCategory.LowerArch}
                      imageFile={getSmartScanCategoryPhotoFileObj(
                        smartScanCategory.LowerArch
                      )}
                    />
                    <SmartScanSelfie
                      retakeBtnClicked={retakeBtnClicked}
                      category={smartScanCategory.UpperLip}
                      imageFile={getSmartScanCategoryPhotoFileObj(
                        smartScanCategory.UpperLip
                      )}
                    />
                    <SmartScanSelfie
                      retakeBtnClicked={retakeBtnClicked}
                      category={smartScanCategory.LowerLip}
                      imageFile={getSmartScanCategoryPhotoFileObj(
                        smartScanCategory.LowerLip
                      )}
                    />
                    <SmartScanSelfie
                      retakeBtnClicked={retakeBtnClicked}
                      category={smartScanCategory.Bite}
                      imageFile={getSmartScanCategoryPhotoFileObj(
                        smartScanCategory.Bite
                      )}
                    />
                  </div>
                }
                <div className="btn-div">
                  <button
                    disabled={isSavingInProgress}
                    onClick={goToPreviousPage}
                    className="btn btn-outline btn-rounded btn-lg px-4 px-md-5 me-2 mt-3 mt-md-0 w-100 w-md-auto"
                  >
                    Back
                  </button>
                  <button
                    disabled={isSavingInProgress}
                    onClick={nextBtnClicked}
                    className="btn btn-secondary btn-rounded btn-lg px-4 px-md-5 mt-3 mt-md-0 w-100 w-md-auto"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SmartScanPosePage;
