import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function ScheduleAppointment({
  schedulerCampaign,
  caseReport,
  includeZState
}) {
  const shouldDisplayCampaingChargeInfo = useSelector(
    (state) => state?.appSettings?.settingsData?.displayCampaignCharge
  );

  return (
    <>
      <div className="text-primary text-center mt-5">
        If you would like to further discuss your treatment recommendation with
        this provider, please schedule an appointment
      </div>
      <div className="form-group text-center mt-4 col-md-12">
        {caseReport ? (
          <Link
            className="btn btn-secondary btn-rounded btn-lg px-3 px-md-5 w-100 w-md-auto"
            to={
              includeZState
                ? "/second-opinion-appointment?includeZState=true"
                : "/second-opinion-appointment"
            }
            state={{
              patientId: caseReport.patientId,
              providerId: caseReport.providerId,
              patientName: caseReport.secondOpinion.patient.fullName
            }}
          >
            Schedule an appointment
            {schedulerCampaign &&
            shouldDisplayCampaingChargeInfo &&
            schedulerCampaign.campaignCharge ? (
              <> ${schedulerCampaign.campaignCharge}</>
            ) : (
              <></>
            )}
          </Link>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
