import React, { useState, useEffect } from "react";
import { getPatientInfo } from "../../helpers/authManager";
import { getPrescriptions_Ajax } from "../../helpers/requests";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import {
  showLoadingSpinner,
  hideLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import Navbar from "./../../components/Navbar/Navbar";
import PrescriptionTable from "../../components/Prescription/PrescriptionTable";
import { handleApiErrors } from "../../helpers/utils";
import PrescriptionImageModal from "../../components/Prescription/PrescriptionImageModal";
import PrescriptionPdfModal from "../../components/Prescription/PrescriptionPdfModal";
import { setFileUrlToOpen } from "../../redux/actions/fileViewer";

const Prescription = () => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const currentPatient = useSelector(
    (state) => state?.userData?.currentPatient
  );

  const [totalCount, setTotalCount] = useState(0);
  const [prescriptions, setPrescriptions] = useState([]);
  const [uploadedPrescriptions, setUploadedPrescriptions] = useState([]);
  const [reloadPrescriptions, setReloadPrescriptions] = useState(false);
  const [isRequestPending, setIsRequesstPending] = useState(false);

  useEffect(() => {
    if (currentPatient) {
      setReloadPrescriptions(true);
    }
  }, [currentPatient]);

  function getPatientPrescriptions(currentPageNum, itemPerPage) {
    var patientInfo = currentPatient;
    if (
      patientInfo &&
      patientInfo.patid &&
      currentPageNum &&
      itemPerPage &&
      !isRequestPending
    ) {
      setIsRequesstPending(true);
      var params = {
        patient_id: patientInfo.patid,
        limit: itemPerPage,
        current_page: currentPageNum
      };

      var tablePrescriptions = [];
      var uploadedFilePrescriptions = [];

      dispatch(showLoadingSpinner());

      getPrescriptions_Ajax(
        params,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (
            response &&
            response.success &&
            response.data &&
            response.data.prescriptions &&
            Array.isArray(response.data.prescriptions) &&
            response.data.prescriptions.length > 0
          ) {
            if (response.data.totalCount) {
              setTotalCount(response.data.totalCount);

              response.data.prescriptions.forEach((p) => {
                if (p.photos && p.photos.length <= 0) {
                  tablePrescriptions.push(p);
                } else if (p.photos && p.photos.length > 0) {
                  uploadedFilePrescriptions.push(p);
                }
              });

              setPrescriptions(tablePrescriptions);
              setUploadedPrescriptions(uploadedFilePrescriptions);
            }
          } else if (response && !response.success) {
            setPrescriptions([]);
            setUploadedPrescriptions([]);
            setTotalCount(0);
          }

          setIsRequesstPending(false);
        },
        function (err) {
          handleApiErrors(err);
          dispatch(hideLoadingSpinner());
          setIsRequesstPending(false);
        }
      );
    }
  }

  function openFile(url) {
    if (url) {
      dispatch(setFileUrlToOpen(url));
    }
  }

  return (
    <>
      <body>
        <section className="sec-prescription  py-4 py-md-5">
          <PrescriptionTable
            totalCount={totalCount}
            getPatientPrescriptions={getPatientPrescriptions}
            reloadPrescriptions={reloadPrescriptions}
            setReloadPrescriptions={setReloadPrescriptions}
            prescriptions={prescriptions}
            uploadedPrescriptions={uploadedPrescriptions}
            openFile={openFile}
          />
        </section>

        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js"
          integrity="sha512-894YE6QWD5I59HgZOGReFYm4dnWc1Qt5NtvYSaNcOP+u1T9qYdvdihz0PPSiiqn/+/3e7Jo4EaG7TubfWGUrMQ=="
          crossOrigin="anonymous"
          referrerPolicy="no-referrer"
        ></script>
        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"
          integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"
          crossOrigin="anonymous"
        ></script>
      </body>
    </>
  );
};

export default Prescription;
