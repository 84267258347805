import React from "react";

const ConfirmModal = () => {
  return (
    <>
      {/* <!-- delete modal --> */}
      <div
        className="modal fade"
        id="modal-delete"
        tabIndex="-1"
        style={{ display: "none" }}
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content">
            <div className="modal-header border-0 pb-0">
              <button
                type="button"
                className="btn-close text-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body pt-0 pb-4">
              <h5 className="mb-4 text-center">
                Are you sure you want to Delete?
              </h5>
              <div className="gth-confirmation-btn mx-auto d-flex justify-content-center">
                <a
                  href="#"
                  data-bs-dismiss="modal"
                  className="btn btn-primary btn-xs me-3"
                >
                  Yes
                </a>
                <a
                  href="#"
                  data-bs-dismiss="modal"
                  className="btn btn-outline btn-xs"
                >
                  No
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmModal;
