import React from "react";
import UploadMoreRecordPage from "../../components/UploadMoreRecords/UploadMoreRecordPage";
import Navbar from "../../components/Navbar/Navbar";
const UploadMoreRecords = () => {
  return (
    <>
      <body className="mobile-body">
        <div className="body-content">
          <section className="questionnaire-section py-4 py-md-5">
            <UploadMoreRecordPage />
          </section>
        </div>
      </body>
    </>
  );
};

export default UploadMoreRecords;
