import React, { useState } from "react";
import InputMask from "react-input-mask";
import {
  ConsentOptions,
  PrimaryConsultantInfoFormField,
  Patterns
} from "../../constants";
import { useForm } from "react-hook-form";

const ConsentFormForPrimaryConsultant = (props) => {
  const {
    setPrimaryDentistInfo,
    setShouldAllowToContinue,
    setPrimaryConsultantInfoCollectionDecision,
    primaryConsultantInfoCollectionDecision
  } = props;

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitted }
  } = useForm({
    mode: "onChange"
  });
  const [isSubmitButtonAvailable, setIsSubmitButtonAvailable] = useState(true);
  const [isFormEditable, setIsFormEditable] = useState(true);

  const handleConsent = (event) => {
    setShouldAllowToContinue(false);
    setIsSubmitButtonAvailable(true);
    setPrimaryConsultantInfoCollectionDecision(event.target.value);
    setIsFormEditable(true);
  };

  const handleSubmitButtonClick = (formData) => {
    if (
      formData.FirstName !== "" &&
      formData.LastName !== "" &&
      formData.Email !== "" &&
      primaryConsultantInfoCollectionDecision == ConsentOptions.Agree
    ) {
      setPrimaryDentistInfo(formData);
      setIsFormEditable(false);
    } else {
      setPrimaryDentistInfo(null);
    }

    setShouldAllowToContinue(true);
    setIsSubmitButtonAvailable(false);
  };

  function hasSelectedConsentOption() {
    return (
      primaryConsultantInfoCollectionDecision == ConsentOptions.Agree ||
      primaryConsultantInfoCollectionDecision == ConsentOptions.Disagree
    );
  }

  function isSubmitBtnDisabled() {
    if (primaryConsultantInfoCollectionDecision == ConsentOptions.Agree) {
      return !isValid;
    } else if (!hasSelectedConsentOption()) {
      return true;
    }
  }

  function handleEdit() {
    setShouldAllowToContinue(false);
    setIsSubmitButtonAvailable(true);
    setIsFormEditable(true);
  }

  return (
    <>
      <form
        className="row pt-4"
        onSubmit={handleSubmit(handleSubmitButtonClick)}
      >
        <div className="my-3 my-md-4" id="assign-authority">
          <h2 className="mb-3 mb-md-4">
            Indicate below whether you consent to having a record of your
            telehealth visit shared with your Primary Care Dentist.
          </h2>
          <div className="form-check mb-2">
            <input
              type="radio"
              className="form-check-input"
              value={ConsentOptions.Agree}
              name="agree"
              id="agree"
              onChange={handleConsent}
            />
            <label className="form-check-label fs-4" htmlFor="agree">
              <b className="text-primary me-2">I Agree </b> to having a record
              of my telehealth visit shared with my Primary Care Dentist.
            </label>
          </div>
          <div className="form-check">
            <input
              type="radio"
              className="form-check-input"
              value={ConsentOptions.Disagree}
              name="agree"
              id="not-agree"
              onChange={handleConsent}
            />
            <label className="form-check-label fs-4" htmlFor="not-agree">
              <b className="text-primary me-2">I Do Not Agree </b> to having a
              record of my telehealth visit shared with my Primary Care Dentist.
            </label>
          </div>

          {primaryConsultantInfoCollectionDecision == ConsentOptions.Agree && (
            <div className="row mt-3 mt-md-4" id="dentist-form">
              {isSubmitted && !isFormEditable && (
                <div className="d-inline-block text-end">
                  <button className="btn btn-link ml-0" onClick={handleEdit}>
                    Edit Primary Care Dentist Information
                  </button>
                </div>
              )}
              <div className="form-group mb-3 col-md-6">
                <label
                  className="form-group-title fs-3 text-violet mb-2 mb-md-3 lh-1"
                  htmlFor="dentist-fname"
                  id="dentist-fname"
                >
                  Dentist's First Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="dentist-fname"
                  placeholder="First Name"
                  maxLength={50}
                  {...register(PrimaryConsultantInfoFormField.FirstName, {
                    required: true,
                    maxLength: {
                      value: 50,
                      message: "Maximum 50 characters are allowed."
                    }
                  })}
                  disabled={!isFormEditable}
                />
                {errors.FirstName?.message ? (
                  <p className="help-block">{errors.FirstName.message}</p>
                ) : (
                  <></>
                )}
              </div>
              <div className="form-group mb-3 col-md-6">
                <label
                  className="form-group-title fs-3 text-violet mb-2 mb-md-3 lh-1"
                  htmlFor="dentist-lname"
                  id="dentist-lname"
                >
                  Dentist's Last Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="dentist-lname"
                  placeholder="Last Name"
                  {...register(PrimaryConsultantInfoFormField.LastName, {
                    required: true,
                    maxLength: {
                      value: 50,
                      message: "Maximum 50 characters are allowed."
                    }
                  })}
                  disabled={!isFormEditable}
                />
                {errors.LastName?.message ? (
                  <p className="help-block">{errors.LastName.message}</p>
                ) : (
                  <></>
                )}
              </div>
              <div className="form-group mb-3 col-md-6">
                <label
                  className="form-group-title fs-3 text-violet mb-2 mb-md-3 lh-1"
                  htmlFor="dentist-email"
                  id="dentist-email"
                >
                  Dentist's Email <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="dentist-email"
                  placeholder="Email"
                  {...register(PrimaryConsultantInfoFormField.Email, {
                    required: "This field is required.",
                    pattern: {
                      value: Patterns.Email,
                      message:
                        "Please enter a valid email address. (e.g. example@email.com)"
                    }
                  })}
                  disabled={!isFormEditable}
                />
                {errors.Email?.message ? (
                  <p className="help-block">{errors.Email.message}</p>
                ) : (
                  <></>
                )}
              </div>
              <div className="form-group mb-3 col-md-6">
                <label
                  className="form-group-title fs-3 text-violet mb-2 mb-md-3 lh-1"
                  htmlFor="dentist-phoneno"
                  id="dentist-phoneno"
                >
                  Dentist's Phone Number
                </label>
                <InputMask
                  mask="(999)-999-9999"
                  className="form-control"
                  id="dentist-phoneno"
                  placeholder="(XXX)-XXX-XXXX"
                  {...register(PrimaryConsultantInfoFormField.Phone)}
                  disabled={!isFormEditable}
                />
              </div>
            </div>
          )}

          <div className="form-group text-center col-md-12">
            {isSubmitButtonAvailable && (
              <button
                className="btn btn-primary btn-rounded btn-md px-5 mt-4 w-100 w-md-auto"
                id="continue-btn"
                type="submit"
                disabled={isSubmitBtnDisabled()}
              >
                Continue
              </button>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default ConsentFormForPrimaryConsultant;
