import React, { useEffect, useState } from "react";
import {
  getSmartScanReportAccrodionColorClass,
  isEmptyArray,
  onImageError,
  getSeeADentistNowButtonText
} from "../../helpers/utils";
import { SmartScanOralHealthStatus } from "../../constants";
import { useSelector } from "react-redux";

const DentalConditionDetailsAccordion = (props) => {
  const {
    title,
    dentalConditionData,
    onDemandCampaignCharge,
    onSeeADentistNowButtonClicked
  } = props;
  const accordionId = title ? title.split(" ").join("_") : "";
  const isOnDemandCallFeatureEnabled = useSelector(
    (state) => state?.appSettings?.settingsData?.isOnDemandCallFeatureEnabled
  );
  const [isExpanded, setIsExpanded] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(0);
  const totalImages = dentalConditionData?.annotations?.length;
  const [seeADentistNowButtonVisibled, setSeeADentistNowButtonVisibled] =
    useState(false);

  function handleExpandDiv() {
    let isExpandedCurDiv = $("#heading" + accordionId).attr("aria-expanded");
    if (isExpandedCurDiv == "true") {
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  }

  function handleImageLoad() {
    setImagesLoaded(imagesLoaded + 1);
  }

  function checkAllImagesLoaded() {
    if (imagesLoaded == totalImages) {
      setSeeADentistNowButtonVisibled(true);
    }
  }

  useEffect(() => {
    checkAllImagesLoaded();
  }, [imagesLoaded]);

  return (
    <>
      {dentalConditionData ? (
        <div
          className={
            "accordion-item bg-white mb-3 " +
            getSmartScanReportAccrodionColorClass(dentalConditionData?.status)
          }
        >
          <div
            id={"heading" + accordionId}
            className="accordion-header fs-3 d-flex flex-wrap justify-content-between ps-5 pe-3 py-4 position-relative"
            data-bs-toggle="collapse"
            data-bs-target={"#collapse" + accordionId}
            aria-expanded="false"
            aria-controls={"collapse" + accordionId}
            onClick={handleExpandDiv}
          >
            <span className="name">{title}</span>
            <div className="score-condition">
              <span className="condition color-inherit fw-bold">
                {dentalConditionData?.status}
              </span>
            </div>
          </div>

          <div
            id={"collapse" + accordionId}
            className="accordion-collapse collapse"
            aria-labelledby={"heading" + accordionId}
          >
            <div className="accordion-body p-3 ps-5 pt-0">
              <div className="row">
                <div className="col-lg-7 mb-4 mb-lg-0">
                  <div className="fs-1 color-inherit fw-bold mb-3">
                    {dentalConditionData?.status}
                  </div>
                  <h4 className="mb-3">
                    <strong>{dentalConditionData?.description}</strong>
                  </h4>
                  <p>{dentalConditionData?.scoreInterpretation}</p>
                  <div className="d-flex align-items-center justify-content-between flex-wrap">
                    {dentalConditionData?.status ==
                    SmartScanOralHealthStatus.Poor ? (
                      <>
                        <h4>
                          <strong>
                            We recommend consulting with a dentist.
                          </strong>
                        </h4>
                        {seeADentistNowButtonVisibled &&
                          isOnDemandCallFeatureEnabled && (
                            <button
                              onClick={onSeeADentistNowButtonClicked}
                              className="btn btn-secondary btn-sm text-nowrap mt-2 mt-md-0"
                            >
                              {getSeeADentistNowButtonText()}
                            </button>
                          )}
                        {!seeADentistNowButtonVisibled && (
                          <button className="btn btn-secondary btn-sm text-nowrap mt-2 mt-md-0">
                            <i class="fa fa-circle-o-notch fa-spin"></i>
                            <span> Loading..</span>.
                          </button>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                {isExpanded && (
                  <div className="col-lg-5" style={{ marginBottom: "-1.5rem" }}>
                    <div className="row">
                      {!isEmptyArray(dentalConditionData?.annotations) ? (
                        dentalConditionData?.annotations.map(
                          (annotation, index) => {
                            return (
                              <div
                                key={annotation.photoId + index.toString()}
                                className="col-sm-6 mb-4"
                              >
                                <img
                                  className="w-100 h-100 object-cover"
                                  src={annotation.photoId}
                                  onLoad={handleImageLoad}
                                  onError={onImageError}
                                  loading="lazy"
                                  alt=""
                                />
                              </div>
                            );
                          }
                        )
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default DentalConditionDetailsAccordion;
