import React from "react";
import { onImageError } from "../../helpers/utils";
const ProductDetailsAccordion = (props) => {
  const { product } = props;
  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingThree">
        <div className="row cols-row-1 cols-row-sm-2 align-items-center border-bottom pb-3 m-0">
          <div className="col product-title-row">
            <h3 className="product-title fw-bold mb-2 mb-sm-0">
              {product?.name}
            </h3>
          </div>
          <div className="col text-end">
            <a
              href={product?.deepLink}
              className="btn btn-secondary btn-sm text-nowrap"
              target="_blank"
            >
              Shop on CVS.com <i className="bi bi-box-arrow-up-right ms-1"></i>
            </a>
          </div>
        </div>
        <button
          className="accordion-button text-secondary fw-bolder text-start collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={"#collapseThree" + product?.productsId}
          aria-expanded="false"
          aria-controls={"collapseThree" + product?.productsId}
        >
          Why is this important?{" "}
          <i className="bi bi-chevron-down bi-icon ms-1 px-011"></i>
        </button>
      </h2>
      <div
        id={"collapseThree" + product?.productsId}
        className="accordion-collapse collapse"
        aria-labelledby="headingThree"
        data-bs-parent="#accordionExample"
      >
        <div className="accordion-body pb-0">
          <div
            className="mb-3"
            dangerouslySetInnerHTML={{
              __html: product?.description
            }}
          ></div>
          <div className="d-flex flex-wrap align-items-center gap-3">
            <img src={product?.photoId} onError={onImageError} alt=""></img>
            <a
              href={product?.deepLink}
              target="_blank"
              className="btn btn-primary"
            >
              {product?.name} <i className="bi bi-box-arrow-up-right ms-1"></i>
            </a>
          </div>
          <button
            className="d-block accordion-button text-secondary fw-bolder text-start px-0"
            data-bs-toggle="collapse"
            data-bs-target={"#collapseThree" + product?.productsId}
          >
            View Less <i className="bi bi-chevron-up bi-icon ms-1"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailsAccordion;
