import React from "react";
import { useLocation } from "react-router-dom";

import QuestionnaireUpdateRecordPage from "../../components/QuestionnaireUpdateRecord/QuestionnaireUpdateRecordPage";

const QuestionnaireUpdateRecord = () => {
  const { state } = useLocation();
  return (
    <>
      <body className="mobile-body">
        <QuestionnaireUpdateRecordPage
          secondOpinionId={state?.secondOpinionId}
        />
      </body>
    </>
  );
};

export default QuestionnaireUpdateRecord;
