import React, { useEffect, useState } from "react";
import { getPatientInfo } from "../../helpers/authManager";
import profilePic from "./../../assets/images/img-profile-pic.png";
import patientPic from "./../../assets/images/patient-img.jpg";
import NoPreviewAvailble from "./../../assets/images/No_preview_available.jpg";
import {
  toLocalDateTime,
  isImageFile,
  isValidDocFileForPatientUpload,
  openUrlInNewTab,
  showAlertDialouge,
  isPDFfile,
  getNameInitials,
  onImageError
} from "../../helpers/utils";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import { setFileUrlToOpen } from "../../redux/actions/fileViewer";

const ChatModal = (props) => {
  const loggedInPatientPhotoId = useSelector(
    (state) => state?.userData?.loggedInPatientPhotoId
  );
  const patientAndFamilyMemberList = useSelector(
    (state) => state.userData.patientAndFamilyMembersList
  );

  const {
    chatRoom,
    chatMessages,
    currentUserId,
    uploadedFiles,
    setUploadedFiles,
    sendBtnClicked,
    messageInput,
    setMessageInput,
    chatBoxRef,
    chatMessagesFetchApiInProgress,
    handleScrollToTop,
    scrollToBottom,
    csrUserId,
    csrPhotoId,
    resetAllChatData,
    setCsrUserId,
    scrollBarPosition
  } = props;

  const [bigscreenChat, setBigScreenChat] = useState(false);
  const [nameToDisplayInHeader, setNameToDisplayInHeader] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (chatRoom) {
      var currentUserId = getPatientInfo().userId;
      if (
        chatRoom &&
        chatRoom.chatParticipants &&
        Array.isArray(chatRoom.chatParticipants) &&
        chatRoom.chatParticipants.length > 0
      ) {
        var users = chatRoom.chatParticipants.filter(function (participant) {
          return participant && participant.userId != currentUserId;
        });

        if (users && users.length > 0) {
          var areAllReciversCSR = users.every(function (user) {
            return user && user.isCsr;
          });

          if (areAllReciversCSR) {
            setNameToDisplayInHeader("Virtual Assistant");
          } else {
            var receiverNames = users.map(function (user) {
              return user.name;
            });

            if (Array.isArray(receiverNames)) {
              setNameToDisplayInHeader(receiverNames.join(", "));
            } else {
              setNameToDisplayInHeader("");
            }
          }
        }
      }
    }
  }, [chatRoom]);

  function UpdateSelectedPhoto(event) {
    if (event && event.target && event.target.files) {
      var arr = [...uploadedFiles];

      for (var i = 0; i < event.target.files.length; i++) {
        var file = event.target.files[i];
        var fileName = file.name.toLowerCase();

        if (isImageFile(fileName) || isValidDocFileForPatientUpload(fileName)) {
          var tempPath = URL.createObjectURL(file);
          arr.push({
            url: tempPath,
            type: fileName.split(".").pop(),
            file: file
          });
        } else {
          showAlertDialouge("Error", "File type not supported");
        }
      }

      if (arr && arr.length > 0) {
        setUploadedFiles(arr);
        setTimeout(() => {
          scrollToBottom();
        }, 50);
      }
    }
  }

  function DeleteUploadedPhotoClicked(event, fileIndex) {
    if (event) event.stopPropagation();

    if (fileIndex < 0) return;

    var arr = [];

    if (
      uploadedFiles &&
      Array.isArray(uploadedFiles) &&
      uploadedFiles.length > 0
    ) {
      arr = uploadedFiles.filter((file, index) => {
        return index != fileIndex;
      });
    }

    if (arr) {
      setUploadedFiles(arr);
    }
  }

  function renderUploadedFiles() {
    if (
      uploadedFiles &&
      Array.isArray(uploadedFiles) &&
      uploadedFiles.length > 0
    ) {
      var attachmentArr = [];
      uploadedFiles.map((attachment, index) => {
        if (isImageFile(attachment?.file?.name)) {
          attachmentArr.push(
            <p
              onClick={() => openFile(attachment.url)}
              className="chat-attachment-container"
              key={index}
            >
              <img
                className="chat-attachment-img"
                src={attachment?.url}
                onError={onImageError}
                alt="File img"
              />
              <i
                className="bi bi-x-octagon-fill"
                onClick={(event) => DeleteUploadedPhotoClicked(event, index)}
              ></i>
            </p>
          );
        } else if (isValidDocFileForPatientUpload(attachment?.file?.name)) {
          attachmentArr.push(
            <div
              onClick={() => openFile(attachment.url)}
              className="chat-attachment-file"
            >
              <p key={index} className="text-body mb-5 mr-5">
                {isPDFfile(attachment?.file?.name) ? (
                  <i className="bi bi-file-earmark-pdf-fill fs-1"></i>
                ) : (
                  <i className="bi bi-file-earmark-text-fill fs-1"></i>
                )}
                <i
                  className="chat-attachment-file-discard bi bi-x-octagon-fill"
                  onClick={(event) => DeleteUploadedPhotoClicked(event, index)}
                ></i>
              </p>
            </div>
          );
        }
      });

      return (
        <div className="uploads-container chat-attachment-uploads d-flex justify-content-flex-start flex-wrap">
          {attachmentArr}
        </div>
      );
    }
  }

  function openFile(url) {
    if (url) {
      scrollBarPosition.current = chatBoxRef.current.scrollTop;
      dispatch(setFileUrlToOpen(url, "#chatModal"));
    }
  }

  function getPatientFullName() {
    if (
      patientAndFamilyMemberList &&
      Array.isArray(patientAndFamilyMemberList) &&
      patientAndFamilyMemberList.length > 0 &&
      currentUserId
    ) {
      var patient = patientAndFamilyMemberList.find(
        (p) => p.userId == currentUserId
      );

      if (patient && patient.fullName) {
        return patient.fullName;
      }
    }
  }

  function getParticipantPhoto(message) {
    var photoId = "";
    var chatParticipantFullName = "";

    if (message && message.senderId) {
      if (message.senderId == currentUserId) {
        photoId = loggedInPatientPhotoId;

        if (!photoId) {
          chatParticipantFullName = getPatientFullName();
        }
      } else {
        chatParticipantFullName = nameToDisplayInHeader;
      }
    }

    return (
      <>
        {photoId ? (
          <img
            className="chat-msg-image"
            src={photoId}
            onError={onImageError}
            alt=""
          />
        ) : (
          <div className="chat-profile-initial me-2 rounded-circle d-flex justify-content-center align-items-center">
            {getNameInitials(chatParticipantFullName)}
          </div>
        )}
      </>
    );
  }

  function renderMessage(messages) {
    if (messages && Array.isArray(messages) && messages.length > 0) {
      var arr = [];
      messages.map((message) => {
        var replierClass = "";
        var attachmentsFlexClass = "";

        if (message?.senderId != currentUserId) {
          replierClass = "chat-sender chat-replyer";
          attachmentsFlexClass =
            "chat-attachment-uploads d-flex flex-wrap justify-content-start";
        } else {
          replierClass = "chat-sender";
          attachmentsFlexClass =
            "chat-attachment-uploads d-flex flex-wrap justify-content-end";
        }

        var attachmentArr = [];
        if (
          message.chatMessageAttachments &&
          Array.isArray(message.chatMessageAttachments) &&
          message.chatMessageAttachments.length > 0
        ) {
          message.chatMessageAttachments.map((file, index) => {
            if (file.photoId) {
              if (isImageFile(file.fileName)) {
                attachmentArr.push(
                  <p
                    data-bs-toggle="modal"
                    data-bs-target="#fileViewerImage"
                    onClick={() => openFile(file.photoId)}
                    className="chat-attachment-container"
                    key={index}
                  >
                    <img
                      className="chat-attachment-img"
                      src={file.photoId}
                      onError={onImageError}
                      alt="File img"
                    />
                  </p>
                );
              } else if (isValidDocFileForPatientUpload(file.fileName)) {
                attachmentArr.push(
                  <div
                    data-bs-toggle="modal"
                    data-bs-target="#fileViewerDoc"
                    onClick={() => openFile(file.photoId)}
                    className="message-bubble-attachment-file"
                  >
                    <p key={index} className="text-body mb-5 mr-5">
                      {isPDFfile(file.fileName) ? (
                        <i className="bi bi-file-earmark-pdf-fill fs-1"></i>
                      ) : (
                        <i className="bi bi-file-earmark-text-fill fs-1"></i>
                      )}
                    </p>
                  </div>
                );
              }
            }
          });
        }

        arr.push(
          <div className={replierClass}>
            <figure>{getParticipantPhoto(message)}</figure>
            <div className="chat-inner text-break">
              {message?.message ? (
                <div className="msg-text">{message?.message}</div>
              ) : (
                <></>
              )}
              <div className={attachmentsFlexClass}>{attachmentArr}</div>
            </div>
            <span className="msg-time">
              <small>{message?.time}</small>
            </span>
          </div>
        );
      });

      return arr;
    }
  }

  function handleChange(e) {
    const { value } = e.target;
    setMessageInput(value);
  }

  function shouldShowSendButton() {
    return (
      messageInput ||
      (uploadedFiles &&
        Array.isArray(uploadedFiles) &&
        uploadedFiles.length > 0)
    );
  }

  const canBeShow = () => {
    return bigscreenChat ? setBigScreenChat(false) : setBigScreenChat(true);
  };
  const bigChatBoxHandler = () => {
    setBigScreenChat(!bigscreenChat);
    return canBeShow();
  };

  function sendMsgOnEnterPressed(event) {
    if (event && event.key == "Enter") {
      event.preventDefault();
      sendBtnClicked();
    }
  }

  return (
    <>
      {/* <!-- Chat Modal --> */}
      <div
        className={
          "modal fade chat-modal shadow " +
          (bigscreenChat && csrUserId != 0
            ? "chat-fullscreen show"
            : csrUserId != 0
            ? "show"
            : " ")
        }
        id="chatModal"
        data-bs-backdrop="false"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className={"modal-dialog modal-fullscreen"}>
          <div className="modal-content border-0 rounded-0">
            <div className="modal-header bg-secondary justify-content-start align-items-stretch p-0 rounded-0 position-relative">
              <a
                className="fullscreen text-white d-flex flex-wrap align-content-center px-2"
                onClick={bigChatBoxHandler}
              >
                <i className="bi bi-box-arrow-up-left"></i>
              </a>
              <div className="flex-1 d-flex align-items-center px-0 py-2">
                <figure className="my-0 me-2">
                  <div className="chat-profile-initial me-2 rounded-circle d-flex justify-content-center align-items-center">
                    {getNameInitials(nameToDisplayInHeader)}
                  </div>
                </figure>
                <div className="right flex-1 d-flex flex-column align-items-start">
                  <h4 className="name text-truncate">
                    {nameToDisplayInHeader}
                  </h4>
                  <div className="fst-italic status online">
                    <span className="position-relative d-inline-block align-top w-100">
                      online
                    </span>
                  </div>
                </div>
              </div>
              <button
                className="chat-close-btn bg-transparent fs-3 d-flex flex-wrap align-content-center px-2 py-0 m-0 border-0 opacity-25"
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  resetAllChatData();
                }}
              >
                <i className="bi bi-x-circle-fill"></i>
              </button>
            </div>
            <div
              className="modal-body"
              id="dental-chat-box"
              ref={chatBoxRef}
              onScroll={handleScrollToTop}
            >
              {chatMessages &&
                Array.isArray(chatMessages) &&
                chatMessages.length > 0 &&
                chatMessages.map((message, index) => {
                  return (
                    <div className="chat-wrapper">
                      <div className="chat-date text-center text-muted fst-italic mb-3 opacity-75">
                        <span>{message?.field}</span>
                      </div>
                      {renderMessage(message?.groupList)}
                    </div>
                  );
                })}
              {renderUploadedFiles()}
              {chatMessagesFetchApiInProgress ? (
                <p>... loading messages</p>
              ) : (
                <></>
              )}
            </div>
            <div className="modal-footer d-flex flex-wrap align-items-center px-3 pb-3 border-0">
              <div className="form-control d-flex flex-1 p-0 pe-1 m-0">
                <textarea
                  className="chat-text-area border-0 rounded-pill text-truncate w-100"
                  type="text"
                  name=""
                  value={messageInput || ""}
                  onChange={handleChange}
                  onKeyPress={sendMsgOnEnterPressed}
                />
                <span
                  className="link-attachment fs-3 text-muted d-flex flex-wrap align-content-center px-1 opacity-75"
                  role="button"
                >
                  <i className="bi bi-paperclip"></i>
                </span>
                <span
                  className="link-image fs-3 text-muted d-flex flex-wrap align-content-center px-1 opacity-75"
                  role="button"
                >
                  <i className="bi bi-image"></i>
                </span>
                <div>
                  <input
                    type="file"
                    multiple
                    className="chat-upload-button"
                    onChange={UpdateSelectedPhoto}
                  ></input>
                </div>
              </div>
              <div className="send-btn-container">
                <button
                  className="msg-send-btn bg-violet fs-3 d-flex flex-wrap justify-content-center align-content-center p-0 m-0 ms-2 border-0 rounded-circle"
                  type="button"
                  onClick={sendBtnClicked}
                  disabled={!shouldShowSendButton()}
                >
                  <i className="bi bi-send"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatModal;
