import moment from "moment";
import React from "react";

export default function ObservationTypeCaseReportPatientDetails({
  caseReport
}) {
  return (
    <>
      {caseReport && caseReport.secondOpinion.patient ? (
        <>
          <div className="d-flex gap-3 gth-corpote-report mb-2">
            <p>
              <b>Name</b>
            </p>
            {caseReport.secondOpinion.patient.fullName ? (
              <p>{caseReport.secondOpinion.patient.fullName}</p>
            ) : (
              <></>
            )}
          </div>
          <div className="d-flex gap-3 gth-corpote-report mb-2">
            <p>
              <b>Date of Birth</b>
            </p>
            {caseReport.secondOpinion.patient.dateOfBirth ? (
              <p>
                {moment(caseReport.secondOpinion.patient.dateOfBirth).format(
                  "MM/DD/YYYY"
                )}
              </p>
            ) : (
              <></>
            )}
          </div>
          <div className="d-flex gap-3 gth-corpote-report mb-2">
            <p>
              <b>City/State</b>
            </p>
            {caseReport.secondOpinion.patient.state ? (
              <p>
                {caseReport.secondOpinion.patient.city}/
                {caseReport.secondOpinion.patient.state}
              </p>
            ) : (
              <></>
            )}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
