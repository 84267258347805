import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import QuestionnaireBillingInfoPage from "../../components/QuestionnaireBillingInfo/QuestionnaireBillingInfoPage";

const QuestionnaireBillingInfo = () => {
  return (
    <>
      <div className="body-content">
        <section className="questionnaire-section py-4 py-md-5">
          <QuestionnaireBillingInfoPage />
        </section>
      </div>
    </>
  );
};

export default QuestionnaireBillingInfo;
