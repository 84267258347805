import React, { useState, useEffect } from "react";

import Pagination from "../../redux/customHooks/pagination";
import {
  Pagination as PaginationConstants,
  ReferralSuggestingCompletionType,
  DistanceOptions,
  GenderOptions
} from "../../constants";
import { openUrlInNewTab } from "../../helpers/utils";
import { useRef } from "react";

const ReferralModel = (props) => {
  const {
    currentPatient,
    referralSuggestion,
    availableProviders,
    patientZip,
    distanceFilter,
    completeReferralType,
    mapUrl,
    email,
    firstName,
    lastName,
    totalAvailableProviderCount,
    generateMapUrl,
    seeMoreBtnClicked,
    seeLessBtnClicked,
    handleChange,
    searchRecommendedProviders,
    referToProvider,
    inviteProviderForRecommendedReferral
  } = props;

  const [currentPage, setCurrentPage] = useState(0);
  const isSearchButtonClicked = useRef(false);

  useEffect(() => {
    if (currentPage > 0 && !isSearchButtonClicked?.current) {
      searchRecommendedProviders(currentPage, PaginationConstants.ItemPerPage);
    }
  }, [currentPage]);

  function showNumberOfProvidersFound() {
    if (totalAvailableProviderCount >= 0) {
      return (
        <p>
          {totalAvailableProviderCount} provider
          {totalAvailableProviderCount > 1 ? "s" : ""} found
        </p>
      );
    }
  }

  function showMapButton(office) {
    return office && office?.lat && office?.lng;
  }

  function openMapInNewTab(office) {
    if (office && office.lat && office.lng) {
      var url =
        "https://maps.google.com/maps?q=" +
        office.lat +
        "," +
        office.lng +
        "&output=embed";
      openUrlInNewTab(url);
    }
  }

  function searchBtnClicked() {
    isSearchButtonClicked.current = true;
    setCurrentPage(PaginationConstants.FirstPageNo);
    searchRecommendedProviders(
      PaginationConstants.FirstPageNo,
      PaginationConstants.ItemPerPage
    );
  }

  function updatePageNumber(pageNumber) {
    isSearchButtonClicked.current = false;
    setCurrentPage(pageNumber);
  }

  return (
    <div
      className="modal fade"
      id="refferal-modal"
      tabIndex="-1"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-xl modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header primary-border-color">
            <h2 className="modal-title text-primary text-center w-100">
              Complete Referral
            </h2>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 mb-3">
                  <div className="form-check d-inline-flex me-4">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="completeReferralType"
                      value={ReferralSuggestingCompletionType.AvailableProvider}
                      checked={
                        completeReferralType ==
                        ReferralSuggestingCompletionType.AvailableProvider
                      }
                      id="availProvider"
                      onChange={handleChange}
                    />
                    <label className="form-check-label" htmlFor="availProvider">
                      Available Provider
                    </label>
                  </div>{" "}
                  <div className="form-check d-inline-flex">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="completeReferralType"
                      value={ReferralSuggestingCompletionType.InviteProvider}
                      checked={
                        completeReferralType ==
                        ReferralSuggestingCompletionType.InviteProvider
                      }
                      id="inviteProvider"
                      onChange={handleChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="inviteProvider"
                    >
                      Invite Your Provider
                    </label>
                  </div>
                </div>
                {completeReferralType ==
                ReferralSuggestingCompletionType.AvailableProvider ? (
                  <>
                    <div className="col-12 mb-3">
                      <div className="gth-insur-field d-flex flex-wrap">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control rounded-md-0"
                            name="patientZip"
                            value={patientZip}
                            placeholder="Zip Code"
                            onChange={handleChange}
                          />
                          <button
                            className="btn btn-primary"
                            type="button"
                            id="button-addon2"
                            onClick={searchBtnClicked}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="gth-avail-provider">
                      <div className="row">
                        <div className="col-md-4 col-lg-3 col-xl-2">
                          <h3 className="mb-3">Filters</h3>
                          <div className="gth-filter-col mb-4">
                            <h4 className="mb-2">Distance</h4>
                            <div className="form-check mb-2">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="distanceFilter"
                                id="distance1"
                                value={DistanceOptions.Five}
                                checked={distanceFilter == DistanceOptions.Five}
                                onChange={handleChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="distance1"
                              >
                                Within 5 miles
                              </label>
                            </div>
                            <div className="form-check mb-2">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="distanceFilter"
                                id="distance2"
                                value={DistanceOptions.Ten}
                                checked={distanceFilter == DistanceOptions.Ten}
                                onChange={handleChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="distance2"
                              >
                                Within 10 miles
                              </label>
                            </div>
                            <div className="form-check mb-2">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="distanceFilter"
                                id="distance3"
                                value={DistanceOptions.Fifty}
                                checked={
                                  distanceFilter == DistanceOptions.Fifty
                                }
                                onChange={handleChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="distance3"
                              >
                                Within 50 miles
                              </label>
                            </div>
                            <div className="form-check mb-2">
                              <input
                                className="form-check-input"
                                defaultChecked
                                type="radio"
                                name="distanceFilter"
                                id="distance4"
                                value={DistanceOptions.None}
                                checked={distanceFilter == DistanceOptions.None}
                                onChange={handleChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="distance4"
                              >
                                None
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-lg-6 col-8 mt-3 mt-lg-0">
                          <h2>Available Providers</h2>
                          {showNumberOfProvidersFound(availableProviders)}
                          {availableProviders &&
                            Array.isArray(availableProviders) &&
                            availableProviders.length > 0 &&
                            availableProviders.map((provider, index) => {
                              return (
                                <>
                                  <div className="card mb-3">
                                    <div className="card-body">
                                      <h3 className="text-primary">
                                        {provider?.fullName}
                                      </h3>
                                      <p className="mb-1">{provider?.title}</p>

                                      <a className="d-flex gth-address-box text-decoration-none mb-3">
                                        <i className="bi bi-geo-alt-fill"></i>
                                        <span className="text-body">
                                          {provider?.firstOffice?.name}
                                        </span>
                                      </a>
                                      <div className="w-100 mb-3">
                                        <a
                                          data-bs-dismiss="modal"
                                          href="#"
                                          className="btn btn-sm btn-primary providerSelected"
                                          onClick={() => {
                                            referToProvider(
                                              provider,
                                              provider?.firstOffice
                                            );
                                          }}
                                        >
                                          Select
                                        </a>
                                        {showMapButton(
                                          provider?.firstOffice
                                        ) ? (
                                          <>
                                            <button
                                              href="#"
                                              className="btn btn-sm btn-secondary d-none d-lg-inline mx-1"
                                              onClick={() =>
                                                generateMapUrl(
                                                  provider?.firstOffice
                                                )
                                              }
                                            >
                                              Map
                                            </button>
                                            <button
                                              href="#"
                                              className="btn btn-sm btn-secondary d-inline d-sm-inline d-md-inline d-lg-none d-xl-none m-1"
                                              onClick={() =>
                                                openMapInNewTab(
                                                  provider?.firstOffice
                                                )
                                              }
                                            >
                                              Map
                                            </button>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      {provider &&
                                        provider.additionalOffices &&
                                        Array.isArray(
                                          provider.additionalOffices
                                        ) &&
                                        provider.additionalOffices.length > 0 &&
                                        provider.additionalOffices.map(
                                          (office, index) => {
                                            return (
                                              <div className="gth-more-location mb-3">
                                                <div className="divider"></div>
                                                <p className="mb-1">
                                                  {provider?.title}
                                                </p>
                                                <a
                                                  href="#"
                                                  target="_blank"
                                                  className="d-flex gth-address-box text-decoration-none mb-3"
                                                >
                                                  <i className="bi bi-geo-alt-fill"></i>
                                                  <span className="text-body">
                                                    {office?.name}
                                                  </span>
                                                </a>
                                                <div className="w-100 mb-3">
                                                  <a
                                                    data-bs-dismiss="modal"
                                                    href="#"
                                                    className="btn btn-sm btn-primary providerSelected"
                                                    onClick={() => {
                                                      referToProvider(
                                                        provider,
                                                        office
                                                      );
                                                    }}
                                                  >
                                                    Select
                                                  </a>
                                                  {showMapButton(office) ? (
                                                    <>
                                                      <button
                                                        href="#"
                                                        className="btn btn-sm btn-secondary d-none d-lg-inline mx-1"
                                                        onClick={() =>
                                                          generateMapUrl(office)
                                                        }
                                                      >
                                                        Map
                                                      </button>
                                                      <button
                                                        href="#"
                                                        className="btn btn-sm btn-secondary d-inline d-sm-inline d-md-inline d-lg-none d-xl-none m-1"
                                                        onClick={() =>
                                                          openMapInNewTab(
                                                            office
                                                          )
                                                        }
                                                      >
                                                        Map
                                                      </button>
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                      {provider?.offices?.length > 1 &&
                                        provider?.additionalOffices?.length ==
                                          0 && (
                                          <a
                                            className="gth-more-add text-primary text-decoration-none mt-2"
                                            onClick={() =>
                                              seeMoreBtnClicked(provider)
                                            }
                                          >
                                            {provider?.offices?.length - 1} More
                                          </a>
                                        )}
                                      {provider?.additionalOffices?.length >
                                        0 && (
                                        <a
                                          className="gth-more-less text-primary text-decoration-none mt-2"
                                          onClick={() =>
                                            seeLessBtnClicked(provider)
                                          }
                                        >
                                          Less
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={
                              totalAvailableProviderCount
                                ? totalAvailableProviderCount
                                : 0
                            }
                            pageSize={PaginationConstants.ItemPerPage}
                            onPageChange={(page) => updatePageNumber(page)}
                          />
                        </div>
                        {mapUrl && (
                          <div className="col-lg-3 col-xl-4 d-none d-lg-block mt-3 mt-lg-0">
                            <iframe
                              className="w-100"
                              src={mapUrl}
                              width="600"
                              height="450"
                              style={{ border: "0" }}
                              allowFullScreen=""
                              loading="lazy"
                            ></iframe>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="gth-invite-provider">
                      <div className="row">
                        <div className="col-12">
                          <div className="mb-3">
                            <label
                              htmlFor="emailAddress"
                              className="form-label"
                            >
                              Email address
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              id="emailAddress"
                              placeholder="Enter Email"
                              name="email"
                              value={email}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="mb-3">
                            <label htmlFor="FirstName" className="form-label">
                              First Name
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              id="FirstName"
                              placeholder="Enter First Name"
                              name="firstName"
                              value={firstName}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="mb-3">
                            <label
                              htmlFor="LastNameFirstName"
                              className="form-label"
                            >
                              Last Name
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              id="LastName"
                              placeholder="Enter Last Name"
                              name="lastName"
                              value={lastName}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                      <button
                        href=""
                        data-bs-dismiss="modal"
                        className="btn btn-primary mb-3"
                        disabled={!email || !firstName || !lastName}
                        onClick={inviteProviderForRecommendedReferral}
                      >
                        Send
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ReferralModel;
