import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { useDispatch, useSelector } from "react-redux";

import Pagination from "../../redux/customHooks/pagination";
import { setFileUrlToOpen, setFileName } from "../../redux/actions/fileViewer";
import { isPDFfile, onImageError } from "../../helpers/utils";
import { useEffect } from "react";

const FileViewer = () => {
  const dispatch = useDispatch();

  const fileUrlToOpen = useSelector(
    (state) => state?.fileViewer?.fileUrlToOpen
  );
  const modalToReopen = useSelector(
    (state) => state?.fileViewer?.modalToReopen
  );
  const ImageName = useSelector((state) => state?.fileViewer?.fileName);

  const [totalPageCount, setTotalPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    if (fileUrlToOpen) {
      setTotalPageCount(0);
      setPageNumber(1);
    }
  }, [fileUrlToOpen]);

  function onDocumentLoadSuccess({ numPages }) {
    setTotalPageCount(numPages);
  }

  function clearFileViewer() {
    dispatch(setFileUrlToOpen(null, null));
    dispatch(setFileName(null));
    setTotalPageCount(0);
    setPageNumber(1);
  }

  function getImageName() {
    return ImageName ? ImageName : "Image";
  }

  return (
    <>
      <div className="modal fade" id="fileViewerImage" tabIndex="-1">
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h2 className="modal-title">{getImageName()}</h2>
              <button
                type="button"
                className="btn-close"
                data-bs-toggle="modal"
                data-bs-target={modalToReopen}
                aria-label="Close"
                onClick={clearFileViewer}
              ></button>
            </div>
            <div className="modal-body d-flex justify-content-center">
              <img src={fileUrlToOpen} onError={onImageError} />
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="fileViewerDoc" tabIndex="-1">
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h2 className="modal-title">File </h2>
              {fileUrlToOpen ? (
                <a
                  href={fileUrlToOpen}
                  target="_blank"
                  className="m-2 text-white"
                >
                  <i className="bi bi-download"></i>
                </a>
              ) : (
                <></>
              )}
              <button
                type="button"
                className="btn-close text-white"
                data-bs-toggle="modal"
                data-bs-target={modalToReopen}
                aria-label="Close"
                onClick={clearFileViewer}
              ></button>
            </div>
            <div className="modal-body">
              {fileUrlToOpen ? (
                <>
                  <div>
                    <Document
                      file={fileUrlToOpen}
                      onLoadSuccess={onDocumentLoadSuccess}
                      loading=""
                      error="File type not supported for preview. Please download."
                    >
                      <Page pageNumber={pageNumber} />
                    </Document>
                  </div>
                  <Pagination
                    className="pagination-bar"
                    currentPage={pageNumber}
                    totalCount={totalPageCount ? totalPageCount : 0}
                    pageSize={1}
                    onPageChange={(page) => setPageNumber(page)}
                  />
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FileViewer;
