import React from "react";
import { isEmptyArray } from "../../helpers/utils";

export default function CaseReportPeriodontalAnalyses({
  caseReportPeriodontalAnalyses
}) {
  return (
    <>
      {!isEmptyArray(caseReportPeriodontalAnalyses) ? (
        <>
          <div className="mb-4">
            <p>
              <strong>Periodontal Analysis</strong>
            </p>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Tooth # Or Area</th>
                  <th scope="col">Description</th>
                </tr>
              </thead>
              <tbody>
                {caseReportPeriodontalAnalyses.map((periodontalAnalysis) => {
                  return (
                    <tr
                      key={periodontalAnalysis.caseReportPeriodontalAnalysisId.toString()}
                    >
                      <td>{periodontalAnalysis.toothOrArea}</td>
                      <td>{periodontalAnalysis.finding}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
