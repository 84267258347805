import React, { useEffect } from "react";
import {
  DateTimeFormats,
  IS_SEEN,
  NotificationTypes,
  NotificationTypesToRedirectUrl,
  PATIENT_PORTAL_AUTO_LOGIN_API_KEY
} from "../../constants";
import {
  markAllNotificationsAsSeen_Ajax,
  processNotification_Ajax,
  updateSmartScanSeenStatus_ajax
} from "../../helpers/requests";
import {
  formatDateTime,
  toTimezoneName,
  formatDateTimeWithZone,
  toLocal,
  isEqualToCurrentDate,
  getTimeSince,
  handleApiErrors,
  openUrlInNewTab,
  showAlertDialouge,
  showConfirmDialouge,
  isFeatureNotificationDisabledForOrigin
} from "../../helpers/utils";
import { getTokenData } from "../../helpers/authManager";
import $ from "jquery";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import {
  showLoadingSpinner,
  hideLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import bellIcon from "./../../assets/images/icon-bell.svg";
import { useNavigate } from "react-router";
import { getTdxBaseUrl } from "../../ApplicationSettings";

const Notification = (props) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    patientNotifications,
    getPatientNotifications,
    setPatientNotifications,
    utcOffset,
    isDST,
    patientNotificationsCount,
    setPatientNotificationsCount,
    patientNotificationsRef
  } = props;

  useEffect(() => {
    getPatientNotifications();
  }, []);

  function getNotificationCreationTimeAndDate(notification) {
    if (notification && notification.createdOnUTC) {
      var creationDateOrTimeSince = "";
      if (isEqualToCurrentDate(toLocal(notification.createdOnUTC))) {
        creationDateOrTimeSince =
          getTimeSince(notification.createdOnUTC) + " ago";
      } else {
        creationDateOrTimeSince = formatDateTime(
          toLocal(notification.createdOnUTC),
          DateTimeFormats.MMM_DD_YYYY
        );
      }

      if (creationDateOrTimeSince) {
        return (
          <>
            <span className="d-block">{creationDateOrTimeSince}</span>
          </>
        );
      }
    }
  }

  function processNotification(notificationToRemove) {
    if (
      patientNotifications &&
      notificationToRemove &&
      patientNotificationsCount > 0
    ) {
      processNotification_Ajax(
        notificationToRemove.notificationId,
        function (response) {
          if (response.data.updateSeenStatus) {
            getPatientNotifications();
          }
        },
        function (error) {
          handleApiErrors(error);
        }
      );
    }
  }

  function markAllNotificationsAsSeen() {
    showConfirmDialouge(
      "Message",
      "Are you sure you want to clear all notifications?",
      function () {
        dispatch(showLoadingSpinner());
        markAllNotificationsAsSeen_Ajax(
          function (response) {
            if (response && response.success) {
              setPatientNotifications([]);
              setPatientNotificationsCount(0);
              patientNotificationsRef.current = null;
              showAlertDialouge(
                "Message",
                "All notifications have been cleared!"
              );
            } else if (response && !response.success && response.message) {
              showAlertDialouge("Error", response.message);
            }
            dispatch(hideLoadingSpinner());
          },
          function (err) {
            handleApiErrors(err);
            dispatch(hideLoadingSpinner());
          }
        );
      }
    );
  }

  function redirectToConferenceUrl(meetingJoinUrl) {
    var err = "";

    if (meetingJoinUrl) {
      openUrlInNewTab(meetingJoinUrl);
    } else {
      err = "Meeting join Url not found.";
    }

    if (err) {
      showAlertDialouge("Error", err);
    }
  }

  function updateSmartScanSeenStatus(smartScanIds) {
    if (smartScanIds) {
      let status = IS_SEEN;

      dispatch(showLoadingSpinner());
      updateSmartScanSeenStatus_ajax(
        JSON.stringify(smartScanIds),
        status,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (!response?.success && response?.message) {
            showAlertDialouge("Error", response?.message);
          }
        },
        function (err) {
          handleApiErrors(err);
          dispatch(hideLoadingSpinner());
        }
      );
    }
  }

  function navigateToWellnessScorePage(smartScanId, patientId) {
    if (smartScanId > 0 && patientId > 0) {
      navigate("/smart-scan-wellness-score", {
        state: {
          smartScanId: smartScanId,
          patientId: patientId
        }
      });
    }
  }

  function processResolvedSmartScanNotification(notification) {
    if (
      notification?.params?.smartScanId > 0 &&
      notification?.params?.patientId > 0
    ) {
      updateSmartScanSeenStatus([notification?.params?.smartScanId]);
      navigateToWellnessScorePage(
        notification?.params?.smartScanId,
        notification?.params?.patientId
      );
    }
  }

  function processSecondOpinionAdditionalInfoRequestNotification(notification) {
    if (
      notification?.params?.secondOpinionId > 0 &&
      notification?.params?.patientId > 0
    ) {
      navigate("/case-review", {
        state: {
          secondOpinionId: notification?.params?.secondOpinionId
        }
      });
    }
  }

  function openNotificationUrl(notification) {
    if (notification && notification.type === NotificationTypes.VIDEO_STARTED) {
      if (notification?.params?.conferenceAlias) {
        var meetingJoinUrl =
          getTdxBaseUrl() +
          "/Conference?alias=" +
          notification.params.conferenceAlias +
          "&isDentalDotComCall=true";

        let conferenceKey = notification.params.conferenceKey;
        let token = getTokenData().token;

        if (conferenceKey) {
          meetingJoinUrl += "&conferenceKey=" + conferenceKey;
        }

        if (token) {
          meetingJoinUrl +=
            "&apikey=" + PATIENT_PORTAL_AUTO_LOGIN_API_KEY + "&token=" + token;
        }

        redirectToConferenceUrl(meetingJoinUrl);
      }
    } else if (notification?.type == NotificationTypes.CASEREPORT_RESOLVED) {
      navigate(
        "/request-completed-details/" + notification.params.secondOpinionId
      );
    } else if (
      notification?.type == NotificationTypes.SMARTSCAN_CASEREPORT_RESOLVED
    ) {
      processResolvedSmartScanNotification(notification);
    } else if (
      notification?.type ==
      NotificationTypes.REQUESTING_SECONDOPINIONADDITIONALINFO_FOR_PATIENT
    ) {
      processSecondOpinionAdditionalInfoRequestNotification(notification);
    } else if (
      NotificationTypesToRedirectUrl.has(notification?.type) &&
      !isFeatureNotificationDisabledForOrigin(notification?.type)
    ) {
      navigate(NotificationTypesToRedirectUrl.get(notification?.type));
    }
    processNotification(notification);
  }

  function notifications(patientNotifications) {
    if (
      patientNotifications &&
      Array.isArray(patientNotifications.notifications) &&
      patientNotifications.notifications.length > 0
    ) {
      return patientNotifications.notifications.map((notification) => {
        return (
          <React.Fragment
            key={"notification-id-" + notification.notificationId.toString()}
          >
            <div
              className="gth-notification-list my-1"
              key={notification.notificationId.toString()}
              id={"notification-id-" + notification.notificationId.toString()}
            >
              <a
                href="javascript:;"
                className="gth-noti-card text-decoration-none p-3 d-inline-block align-top w-100"
                onClick={() => {
                  openNotificationUrl(notification);
                }}
              >
                <p className="text-muted">{notification.description}</p>
              </a>
              <a
                href="javascript:;"
                onClick={(event) => {
                  event.stopPropagation();
                  processNotification(notification);
                }}
                className="close fs-6 p-2"
              >
                <i className="bi bi-x-lg"></i>
              </a>
              <div className="gth-noti-card gth-notification-date p-3">
                <small className="">
                  {getNotificationCreationTimeAndDate(notification)}
                </small>
              </div>
            </div>
          </React.Fragment>
        );
      });
    } else {
      return (
        <div className="gth-notification-list">
          <p style={{ margin: "5px", textAlign: "center" }}>
            {" "}
            {"You have no notifications."}
          </p>
        </div>
      );
    }
  }

  return (
    <>
      <div className="dropdown-menu scrollable-menu dropdown-menu-end py-0 shadow ">
        <div className="gth-notification-drop">
          <h3 className="text-violet p-3">Notification</h3>
          {notifications(patientNotifications)}
        </div>
        {patientNotifications &&
        Array.isArray(patientNotifications.notifications) &&
        patientNotifications.notifications.length > 0 ? (
          <div className="clear-notification">
            <div className="text-center d-inline">
              <img src={bellIcon} alt="" />{" "}
              {patientNotifications.notifications.length +
                " unseen notifications."}
            </div>
            <span
              className="float-end"
              onClick={() => {
                markAllNotificationsAsSeen();
              }}
            >
              (Clear all)
            </span>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Notification;
