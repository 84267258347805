import React, { useState } from "react";
import { useEffect } from "react";
import {
  getAnnotatedRecords_Ajax,
  getCampaign_Ajax,
  getSecondOpinionCaseReport_Ajax
} from "../../helpers/requests";
import { useDispatch, useSelector } from "react-redux";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import {
  handleApiErrors,
  isEmptyArray,
  showAlertDialouge,
  queryStringToJSON,
  getCampaignStatesOfType
} from "../../helpers/utils";
import {
  CampaignStateSettingTypes,
  SecondOpinionCaseReportTypes
} from "../../constants";
import ObservationTypeCaseReport from "./ObservationTypeCaseReport";
import TreatmentRecommendationTypeCaseReport from "./TreatmentRecommendationTypeCaseReport";

const RequestCompletedDetailPage = (props) => {
  const dispatch = useDispatch();
  const { secondOpinionId } = props;

  const campaignId = useSelector(
    (state) => state?.appSettings?.settingsData?.schedulerCampaignId
  );

  const [caseReport, setCaseReport] = useState(null);
  const [caseReportAnnotations, setCaseReportAnnotations] = useState(null);
  const [patientNotes, setPatientNotes] = useState([]);
  const [schedulerCampaign, setSchedulerCampaign] = useState(null);
  const [includeZState, setIncludeZState] = useState(false);

  useEffect(() => {
    if (secondOpinionId > 0) {
      getSecondOpinionCaseReport();
    }
    getCampaign(campaignId);
  }, []);

  useEffect(() => {
    if (caseReport && caseReport.caseReportId > 0) {
      getAnnotatedRecords();
    }
  }, [caseReport]);

  function getAnnotatedRecords() {
    if (caseReport && caseReport.caseReportId > 0) {
      dispatch(showLoadingSpinner());
      getAnnotatedRecords_Ajax(
        caseReport.caseReportId,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (response && response.success && response.data) {
            setCaseReportAnnotations(response.data.caseReportAnnotations);
          } else if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function getSecondOpinionCaseReport() {
    if (secondOpinionId && secondOpinionId > 0) {
      dispatch(showLoadingSpinner());
      getSecondOpinionCaseReport_Ajax(
        secondOpinionId,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (response && response.success && response.data) {
            setCaseReport(response.data.caseReport);

            var arr = [];

            if (
              !isEmptyArray(response.data.caseReport.caseReportProgressNotes)
            ) {
              response.data.caseReport.caseReportProgressNotes.forEach(
                (note) => {
                  arr.push(note.progressNote);
                }
              );
            }

            setPatientNotes(arr);
          } else if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function getCampaign(campaignId) {
    let params = queryStringToJSON();
    let includeZState = false;
    if (params.includezstate) {
      if (params.includezstate == "true") {
        includeZState = true;
      }
    }
    setIncludeZState(includeZState);

    if (campaignId) {
      let params = {
        campaignId: campaignId,
        includeIntakeForm: false,
        includeZState: includeZState
      };

      dispatch(showLoadingSpinner());
      getCampaign_Ajax(
        params,
        function (response) {
          dispatch(hideLoadingSpinner());

          if (response && response.success && response.data) {
            if (response.data.campaignStates) {
              response.data.campaignStates = getCampaignStatesOfType(
                CampaignStateSettingTypes.Assigned,
                response.data.campaignStates
              );
            }
            setSchedulerCampaign(response.data);
          } else if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  return (
    <>
      <div className="body-content">
        <section className="review-photo-section py-4 py-md-5">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="gth-main-header mb-3">
                  <div className="heading-wrapper back-to-wrapper position-relative">
                    {/* <!-- <a className="back-to text-body fs-1 lh-1 d-inline-block align-top position-absolute" href="case-reviews.html"><i className="bi bi-arrow-left lh-1 d-inline-block align-top"></i></a> --> */}
                    <h1 className="title">Reports</h1>
                  </div>
                </div>
                <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
                  {caseReport &&
                  caseReport.type ==
                    SecondOpinionCaseReportTypes.TreatmentRecommendation ? (
                    <TreatmentRecommendationTypeCaseReport
                      caseReport={caseReport}
                      caseReportAnnotations={caseReportAnnotations}
                      patientNotes={patientNotes}
                      schedulerCampaign={schedulerCampaign}
                      includeZState={includeZState}
                    />
                  ) : (
                    <ObservationTypeCaseReport
                      caseReport={caseReport}
                      caseReportAnnotations={caseReportAnnotations}
                      schedulerCampaign={schedulerCampaign}
                      includeZState={includeZState}
                      patientNotes={patientNotes}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default RequestCompletedDetailPage;
