import { combineReducers } from "redux";
import { loadingSpinner } from "./loadingSpinner";
import { userData } from "./userData";
import { appSettings } from "./appSettings";
import { fileViewer } from "./fileViewer";
import { externalPatient } from "./externalPatient";

const appReducer = combineReducers({
  loadingSpinner,
  userData,
  appSettings,
  fileViewer,
  externalPatient
});

export const rootReducer = (state, action) => {
  return appReducer(state, action);
};
