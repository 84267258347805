import React from "react";
import { onImageError } from "../../helpers/utils";
const PrescriptionImageModal = (props) => {
  const { fileUrlToOpen } = props;

  return (
    <div className="modal fade" id="fileImage" tabIndex="-1">
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header bg-primary">
            <h2 className="modal-title">Images</h2>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <img
              src={fileUrlToOpen}
              onError={onImageError}
              alt="Provider Image"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrescriptionImageModal;
