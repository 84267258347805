import React from "react";
import { useSelector } from "react-redux";
import InsuranceFormUpdate from "../InsuranceInformation/InsuranceFormUpdate";

const useDisplayInsuranceCaptureForm = (displayInsuranceCaptureParameters) => {
  const {
    patientId,
    shouldCaptureInsuranceInfo,
    setShouldCaptureInsuranceInfo,
    isInsuranceFormVisible,
    setIsInsuranceFormVisible,
    shouldNavigateToNextPage,
    setSelectedCarrierId,
    selectedCarrierId
  } = displayInsuranceCaptureParameters;
  const onDemandCallInsuranceCaptureEnabled = useSelector((state) =>
    state?.appSettings?.settingsData?.OnDemandCallInsuranceCaptureEnabled
  );

  const shouldDisplayInsuranceCaptureForm = () => {
    if (onDemandCallInsuranceCaptureEnabled) {
      return (
        <div className="form-group my-4 col-md-12">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              checked={shouldCaptureInsuranceInfo}
              onChange={() => {
                setShouldCaptureInsuranceInfo(!shouldCaptureInsuranceInfo);
                setIsInsuranceFormVisible(true);
              }}
            />
            <label class="form-check-label" for="add-insurance">
              Click here to add or update your insurance.
            </label>
          </div>

          {isInsuranceFormVisible && shouldCaptureInsuranceInfo && (
            <div
              className="form-group my-4 col-md-12"
              style={{ marginLeft: "-50px" }}
            >
              <InsuranceFormUpdate
                patientId={patientId}
                showApplyToDependentsOption={true}
                setIsInsuranceFormVisible={setIsInsuranceFormVisible}
                isInOnDemandFlow={true}
                shouldNavigateToNextPage={shouldNavigateToNextPage}
                saveButtonVerbiage={"Save and Next"}
                setSelectedCarrierId={setSelectedCarrierId}
                selectedCarrierId={selectedCarrierId}
              />
            </div>
          )}
        </div>
      );
    }
    return <></>;
  };

  return shouldDisplayInsuranceCaptureForm;
};

export default useDisplayInsuranceCaptureForm;
