import React, { useState, useEffect } from "react";
import { onImageError } from "../../helpers/utils";
import { getQRCode_ajax } from "../../helpers/requests";
import { useDispatch } from "react-redux";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";

const ProductDetails = (props) => {
  const { product } = props;
  const [QRCode, setQRCode] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    getQRCode();
  }, [product]);

  function getQRCode() {
    let url = encodeURIComponent(product?.deepLink);

    dispatch(showLoadingSpinner());
    getQRCode_ajax(
      url,
      function (response) {
        dispatch(hideLoadingSpinner());
        if (response && response.success && response.data) {
          setQRCode(response.data);
        } else if (response && !response.success && response.message) {
          showAlertDialouge("Error", response.message);
        }
      },
      function (err) {
        dispatch(hideLoadingSpinner());
        handleApiErrors(err);
      }
    );
  }

  return (
    <div className="accordion-item accordion-item-print">
      <div className="accordion-header" id="headingOne">
        <div className="row cols-row-2 align-items-center border-bottom pb-3 m-0">
          <div className="col">
            <h3 className="fw-bold">{product?.name}</h3>
          </div>
        </div>
      </div>
      <div
        id="collapseOne"
        className="accordion-collapse collapse show"
        aria-labelledby="headingOne"
        data-bs-parent="#accordionExample"
      >
        <div className="accordion-body pb-0 ">
          <div
            className="mb-3"
            dangerouslySetInnerHTML={{
              __html: product?.description
            }}
          ></div>
          <div className="d-flex flex-wrap align-items-center gap-3 mt-4">
            <img
              className="dark-blend-mode"
              src={product?.photoId}
              onError={onImageError}
              alt=""
            ></img>
            <img
              className="qr_Code dark-blend-mode"
              onError={onImageError}
              src={QRCode}
              alt=""
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
