import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

// import UI components
import AppointmentScheduleTab from "../../components/AppointmentSchedule/AppointmentScheduleTab";
import AppointmentScheduleDateTime from "./../../components/AppointmentSchedule/AppointmentScheduleDateTime";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

// import helpers
import {
  formatDateTime,
  isValidScheduledDate,
  showAlertDialouge
} from "../../helpers/utils";
import { useAlert } from "react-alert";
import { DateTimeFormats } from "../../constants";

const AppointmentScheduleDateTimePage = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();

  const [scheduledDateTime, setScheduledDateTime] = useState(null);
  const [inputs, setInputs] = useState({
    date: "",
    hour: "",
    minute: "",
    meridiem: "AM"
  });

  useEffect(() => {
    if (!state) {
      navigate("/appointment-schedule");
    }
  }, [state]);

  useEffect(() => {
    const { date, hour, minute, meridiem } = inputs;

    if (date && hour && minute && meridiem) {
      var dateTimeString = date + " " + hour + ":" + minute + " " + meridiem;
      setScheduledDateTime(
        formatDateTime(dateTimeString, DateTimeFormats.YYYY_MM_DD_hh_mm_A)
      );
    }
  }, [inputs]);

  function isInputDateValid() {
    if (scheduledDateTime) {
      if (!isValidScheduledDate(scheduledDateTime)) {
        showAlertDialouge(
          "Error",
          "Please select a date and time at least one hour from now."
        );
      } else {
        return true;
      }
    }
  }

  function navigateToReview() {
    if (isInputDateValid() && state && scheduledDateTime) {
      const {
        schedulerCampaign,
        patientState,
        campaignForms,
        selectedPharmacy,
        patientData,
        userData,
        insuranceInfo = {},
        primaryDentistInfo,
        primaryConsultantInfoCollectionDecision
      } = state;

      navigate("/appointment-schedule-review", {
        state: {
          schedulerCampaign,
          patientState,
          scheduledDateTime,
          campaignForms,
          selectedPharmacy,
          patientData,
          userData,
          insuranceInfo,
          primaryDentistInfo,
          primaryConsultantInfoCollectionDecision
        }
      });
    }
  }

  return (
    <>
      <body>
        <section className="step-section py-4 py-md-5">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
                  <AppointmentScheduleTab />
                  <AppointmentScheduleDateTime
                    inputs={inputs}
                    setInputs={setInputs}
                    navigateToReview={navigateToReview}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- scripts --> */}
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js"
          integrity="sha512-894YE6QWD5I59HgZOGReFYm4dnWc1Qt5NtvYSaNcOP+u1T9qYdvdihz0PPSiiqn/+/3e7Jo4EaG7TubfWGUrMQ=="
          crossOrigin="anonymous"
          referrerPolicy="no-referrer"
        ></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/signature_pad/1.3.2/signature_pad.min.js"></script>
        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"
          integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"
          crossOrigin="anonymous"
        ></script>
      </body>
    </>
  );
};

export default AppointmentScheduleDateTimePage;
