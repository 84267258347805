import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getCaseReportEducationDetails_Ajax } from "../../helpers/requests";
import {
  getDefaultPicPath,
  handleApiErrors,
  isImageFile,
  isPDFfile,
  isValidDocFileForPatientUpload,
  onImageError,
  showAlertDialouge
} from "../../helpers/utils";
import { setFileUrlToOpen } from "../../redux/actions/fileViewer";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import IconPDF from "./../../assets/images/icon-pdf.svg";
import GenericFileIcon from "../../assets/images/word_processor_file_icon.png";

const SharedEducationModal = (props) => {
  const dispatch = useDispatch();

  const { sharedEducation } = props;
  const [sharedEducationDetails, setSharedEducationDetails] = useState(null);

  function openFile(url) {
    if (url) {
      dispatch(setFileUrlToOpen(url, "#educationModal"));
    }
  }

  useEffect(() => {
    if (sharedEducation) {
      setSharedEducationDetails(null);
      getShareEducationDetails();
    }
  }, [sharedEducation]);

  function getShareEducationDetails() {
    if (sharedEducation && sharedEducation.educationId > 0) {
      getCaseReportEducationDetails_Ajax(
        sharedEducation.educationId,
        function (response) {
          if (response && response.success && response.data) {
            setSharedEducationDetails(response.data.education);
          } else if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          handleApiErrors(err);
        }
      );
    }
  }

  function shareEducationModalBody() {
    return (
      sharedEducationDetails && (
        <>
          <div className="modal-header bg-primary">
            <h2 className="modal-title">Shared Education</h2>
            <button
              type="button"
              className="btn-close text-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <strong>Title</strong>
            <p>
              {sharedEducationDetails.title
                ? sharedEducationDetails.title
                : " "}
            </p>
            <strong>Description</strong>
            <p>
              {sharedEducationDetails.description
                ? sharedEducationDetails.description
                : " "}
            </p>
            {sharedEducationDetails.media &&
              Array.isArray(sharedEducationDetails.media) &&
              sharedEducationDetails.media.length > 0 && (
                <div className="form-group">
                  <div className="rpe-item">
                    <div className="rpe-reports">
                      <div className="n-education-content">
                        <label>Media</label>
                        <div id="media-gallery">
                          {sharedEducationDetails.media.map((media) => {
                            if (isImageFile(media.mediaId)) {
                              return (
                                <a
                                  href="#"
                                  className="rpe-img"
                                  data-bs-toggle="modal"
                                  data-toggle="modal"
                                  data-bs-target="#fileViewerImage"
                                  onClick={() => openFile(media.mediaId)}
                                >
                                  <img
                                    className=""
                                    src={media.mediaId}
                                    onError={onImageError}
                                    alt="File img"
                                  />
                                </a>
                              );
                            } else if (
                              isValidDocFileForPatientUpload(media.mediaId)
                            ) {
                              return (
                                <a
                                  href="#"
                                  className="text-body gth-pdf-modal rpe-img"
                                  data-toggle="modal"
                                  data-bs-toggle="modal"
                                  data-bs-target="#fileViewerDoc"
                                  onClick={() => openFile(media.mediaId)}
                                >
                                  <img
                                    className=""
                                    src={
                                      isPDFfile(media.mediaId)
                                        ? IconPDF
                                        : GenericFileIcon
                                    }
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null;
                                      currentTarget.src =
                                        getDefaultPicPath(currentTarget);
                                    }}
                                    alt="File img"
                                  />
                                </a>
                              );
                            } else {
                              return (
                                <a
                                  href={media.mediaId}
                                  target="_blank"
                                  className="text-body gth-pdf-modal rpe-img"
                                >
                                  <img
                                    src={media.mediaId}
                                    onError={onImageError}
                                    alt="File img"
                                  />
                                </a>
                              );
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </>
      )
    );
  }

  return (
    <>
      {/* <!-- Education Modal --> */}
      {
        <div className="modal fade" id="educationModal" tabIndex="-2">
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">{shareEducationModalBody()}</div>
          </div>
        </div>
      }
    </>
  );
};

export default SharedEducationModal;
