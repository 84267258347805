import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useLocation,
  Outlet,
  useNavigate,
  createSearchParams
} from "react-router-dom";
import Navbar from "../components/Navbar/Navbar";
import {
  getPatientLoginUrl,
  getTokenData,
  storeTokenData,
  storeNavigationBarHiddenValue,
  setChatBoxInvisibilityInLocalStorage,
  getChatBoxInvisibilityInLocalStorage
} from "./authManager";
import ChatBox from "../pages/ChatBox/ChatBox";
import {
  shouldExcludeNavbar,
  queryStringToJSONWithoutDecode,
  showAlertDialouge,
  handleApiErrors,
  formatVersion,
  checkForPaymentCompletionStatus,
  queryStringToJSON,
  extractCustomLinkQueryParams
} from "./utils";
import { saveTokenData, savePgId } from "../redux/actions/userData";
import {
  saveExternalEmployeeId,
  saveExternalPatId
} from "../redux/actions/externalPatient";

import {
  SupportedAPIVersionForAutoLogin,
  SmartScanCaptureOptions
} from "../constants";
import {
  showLoadingSpinner,
  hideLoadingSpinner
} from "../redux/actions/loadingSpinner";
import {
  getAutoLoginData_Ajax,
  getExternalPatientDetailsFromEncryptedData_Ajax
} from "./requests";
import useClearSessionData from "../redux/customHooks/useClearSessionData";

import { saveSmartScanSelfieCaptureProgress } from "../indexedDbHelper/smartScanProgressIndexedDbHelper";
import { getPatientOrigin, getPgId } from "../ApplicationSettings";

export default function RequireAuth() {
  const location = useLocation();
  const tokenData = getTokenData();
  const dispatch = useDispatch();
  let paramsWithoutDecode = extractCustomLinkQueryParams();
  let version = paramsWithoutDecode.version;
  let dataString = paramsWithoutDecode.dataString;
  const origin = getPatientOrigin();
  const navigate = useNavigate();
  const redirectTo = useRef();
  const isEncryptedLoginEnabled = useSelector(
    (state) => state?.appSettings?.settingsData?.encryptedLoginEnabled
  );

  const isDenticonPatient = useSelector(
    (state) => state?.appSettings?.settingsData?.isDenticonPatient
  );

  const getChatBoxInvisibilityInAppSettings = useSelector(
    (state) => state?.appSettings?.settingsData?.hideChatBox
  );
  const isReferralFeatureEnabled = useSelector(
    (state) => state?.appSettings?.settingsData?.isReferralFeatureEnabled
  );

  let clearSessionData = useClearSessionData();

  function shouldHideChatBox() {
    return (
      getChatBoxInvisibilityInAppSettings ||
      getChatBoxInvisibilityInLocalStorage()
    );
  }

  function redirectToTargetPage() {
    dispatch(showLoadingSpinner());
    return new Promise(function (resolve) {
      let params = {
        dataString: dataString,
        tdxPgId: getPgId()
      };
      let formattedVersion = formatVersion(version);

      getExternalPatientDetailsFromEncryptedData_Ajax(
        formattedVersion,
        origin,
        params,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (response && response.data && response.data.isExistingPatient) {
            showAlertDialouge(
              "Message",
              "User exists already with this email. Please login to continue"
            );
            navigate("/login", {
              state: {
                location
              }
            });
          } else if (
            response &&
            response.data &&
            response.data.externalPatientData
          ) {
            let isSignUpExternalPatient = true;
            let externalPatientData = response.data.externalPatientData;
            let externalPatId = externalPatientData?.denticonPatientId;
            let employerId = externalPatientData?.employerId;
            dispatch(saveExternalPatId(externalPatId));
            dispatch(saveExternalEmployeeId(employerId));

            navigate("/sign-up", {
              state: {
                externalPatId,
                employerId,
                isSignUpExternalPatient,
                externalPatientData
              }
            });
          } else if (response && response.data && response.data.isNewPatient) {
            setExternalPatientEmployer(response.data.employerId);
            navigate("/sign-up");
          } else {
            showAlertDialouge("Message", response.message);
            setExternalPatientEmployer(response.data.employerId);
            navigate("/sign-up");
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
          resolve(false);
        }
      );
    });
  }

  function setExternalPatientEmployer(employerId) {
    if (employerId) {
      dispatch(saveExternalEmployeeId(employerId));
    }
  }

  async function navigateBasedOnFeatureCode(data) {
    switch (data.featureCode) {
      case "0":
        navigate("/dashboard");
        break;
      case "1":
        storeNavigationBarHiddenValue(true);
        setChatBoxInvisibilityInLocalStorage(true);
        await saveSmartScanSelfieCaptureProgress(
          {
            patientId: data.info?.patid,
            captureOption: SmartScanCaptureOptions.Assisted,
            redirectUrl: data.oneTimeRedirectionURL,
            createdBy: data.info?.user?.id
          },
          data.info?.patid
        );

        navigate("/smart-scan-upper-arch", {
          state: {
            patientId: data.info?.patid,
            captureOption: SmartScanCaptureOptions.Assisted,
            redirectUrl: data.oneTimeRedirectionURL,
            createdBy: data.ProviderUserId
          }
        });
        break;
      case "2":
        setChatBoxInvisibilityInLocalStorage(true);
        let url =
          "/wellness-score-print/" +
          0 +
          "?patid=" +
          data.info?.patid +
          "&mobile=true";
        navigate(url);
        break;
      case "3":
        setChatBoxInvisibilityInLocalStorage(true);
        let targetUrl =
          "/wellness-score-print/" +
          0 +
          "?patid=" +
          data.info?.patid +
          "&parentreport=true&mobile=true";
        navigate(targetUrl);
        break;
      default:
        break;
    }
  }

  function autoLogin(origin, version, dataString) {
    if (!SupportedAPIVersionForAutoLogin.includes(version)) {
      showAlertDialouge(
        "Error",
        "This versrion is not supported now. Please try with correct version"
      );
    }

    let params = {
      dataString: dataString
    };
    let formattedVersion = formatVersion(version);
    dispatch(showLoadingSpinner());
    getAutoLoginData_Ajax(
      formattedVersion,
      origin,
      params,
      function (result) {
        dispatch(hideLoadingSpinner());

        if (result?.data?.token) {
          if (result.data.token && result.data.info?.pgid) {
            storeTokenData(result.data.info.pgid, JSON.stringify(result.data));
            dispatch(savePgId(result.data.info.pgid));
          } else if (result.data.token && result.data.user?.pgid) {
            storeTokenData(result.data.user.pgid, JSON.stringify(result.data));
            dispatch(savePgId(result.data.user.pgid));
          }

          dispatch(saveTokenData(result.data));

          let params = {};
          if (result.data.oneTimeRedirectionURL) {
            params.returnUrl = result.data.oneTimeRedirectionURL;
          }

          if (result.data.featureCode) {
            navigateBasedOnFeatureCode(result.data);
          } else if (redirectTo.current) {
            navigate(redirectTo.current);
          } else {
            navigate({
              pathname: "/see-dentist",
              search: createSearchParams(params).toString()
            });
          }
        } else if (result?.message) {
          showAlertDialouge("Error", result.message);
        }
      },
      function (err) {
        dispatch(hideLoadingSpinner());
      }
    );
  }

  function isLoggedInPatient() {
    return tokenData?.info;
  }

  function getCurrentWindowOrigin() {
    if (window?.location?.origin) {
      return window.location.origin + "/";
    }
    return null;
  }

  function logOut() {
    const loginUrl = getPatientLoginUrl();
    clearSessionData();
    window.location.replace(loginUrl);
  }

  function checkForPaymentQueryParams() {
    let params = queryStringToJSON();
    if (params?.paymentsuccess) {
      checkForPaymentCompletionStatus(params.paymentsuccess);
    }
  }

  function navigateTargetComponent() {
    if (isLoggedInPatient()) {
      let path = window.location.pathname;
      let params = queryStringToJSON();

      if (getPatientLoginUrl() != getCurrentWindowOrigin()) {
        logOut();
      } else if (path === "/") {
        navigate("/dashboard");
      } else if (params.secondopinionid > 0 && params.isadditionalinfo) {
        navigate("/case-review", {
          state: {
            secondOpinionId: params.secondopinionid
          }
        });
      } else {
        checkRedirectionPermission(path);
      }
    } else if (isEncryptedLoginEnabled && version && dataString) {
      autoLogin(origin, version, dataString);
    } else if (!isDenticonPatient || version <= 0 || dataString == null) {
      checkForPaymentQueryParams();
      const params = queryStringToJSON();
      navigate({
        pathname: "/login",
        search: createSearchParams(params).toString()
      });
    } else {
      redirectToTargetPage();
    }
  }

  function checkRedirectionPermission(path) {
    if (path === "/referrals" && !isReferralFeatureEnabled) {
      navigate("/dashboard");
    }
  }

  useEffect(() => {
    navigateTargetComponent();
  });

  return tokenData ? (
    <>
      {!shouldExcludeNavbar(location) ? (
        <header className="py-2">
          <Navbar />
        </header>
      ) : (
        <></>
      )}
      <Outlet />
      {!shouldHideChatBox() && <ChatBox />}
    </>
  ) : (
    <></>
  );
}
