import { useEffect, useState } from "react";
import { getInsuranceCarriers_Ajax } from "../../helpers/requests";
import { getPatientOrigin } from "../../ApplicationSettings";

const useGetInsuranceCarrierOptions = (patientId) => {
  const [carrierInfo, setCarrierInfo] = useState({});

  useEffect(() => {
    getInsuranceCarrierOptions();
  }, []);

  function getInsuranceCarrierOptions() {
    let params = {
      clientId: getPatientOrigin()
    };

    getInsuranceCarriers_Ajax(params, function (response) {
      if (response?.success && response.data) {
        let carriers = [];

        response.data.forEach((option) => {
          carriers.push({
            name: option.carrierName,
            id: option.id,
            code: option.carrierCode,
            contractedType: option.contracted
          });
        });
        setCarrierInfo(carriers);
      }
    });
  }

  return carrierInfo;
};

export default useGetInsuranceCarrierOptions;
