import React from "react";

const FormUpdateAndSubmitModal = () => {
  return (
    <>
      {/* <!-- Form Update and Submit Modal End --> */}
      <div className="modal fade" id="updateSubmit-modal" tabIndex="-1">
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0 pb-0">
              <button
                type="button"
                className="btn-close text-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="gth-update-modal-head card bg-body px-3 py-2 mb-3">
                <h3>Teledentist - NOTICE OF PRIVACY PRACTICES</h3>
              </div>
              <p className="text-center">
                Teledentist - NOTICE OF PRIVACY PRACTICES
              </p>
              <div className="card">
                <div className="card-header bg-body px-3 py-2 ">
                  <h3>NOTICE OF PRIVACY PRACTICES</h3>
                </div>
                <div className="card-body">
                  <p>This notice describes how Medical/Protected</p>
                  <p>Health Information about you may be used and</p>
                  <p>Disclosed and how you can get access to this</p>
                  <p>Information. Please review it carefully.</p>
                  <p>Summary:</p>
                  <p>
                    By law, we are required to provide you with our Notice of
                    Privacy Practices. (NPP)
                  </p>
                  <p>
                    This notice describes how you medical information may be
                    used and disclosed by us.
                  </p>
                  <p>
                    It also tells you how you can obtain access to this
                    information.
                  </p>
                  <p>As a patient, you have the following rights:</p>
                  <p>The right to inspect and copy your information;</p>
                  <p>The right to request corrections to your information;</p>
                  <p>
                    The right to request that your information be restricted;
                  </p>
                  <p>The right to request confidential communications;</p>
                  <p>
                    The right to report of disclosures of your information, and
                  </p>
                  <p>The right to paper copy of this notice.</p>
                  <p>
                    We want to assure you that your medical/protected health
                    information is secure with us. This Notice contains
                    information about how we will insure that your information
                    remains private.
                  </p>
                  <p>
                    If you have any questions about this notice, the name and
                    phone number of our contact person is listed on this page.
                  </p>
                  <div className="container-fluid">
                    <div className="row my-4">
                      <div className="col-6">
                        <strong>Effective date of this notice</strong>
                      </div>
                      <div className="col-6">
                        <strong>June 1, 2015</strong>
                      </div>
                      <div className="col-6">
                        <strong>Contact person </strong>
                      </div>
                      <div className="col-6">
                        <strong>Michael Sigler</strong>
                      </div>
                      <div className="col-6">
                        <strong>Phone number</strong>
                      </div>
                      <div className="col-6">
                        <strong>816-550-2615</strong>
                      </div>
                    </div>
                  </div>
                  <strong className="mb-3">
                    Acknowledgment of Notice of Privacy practices:
                  </strong>
                  <div className="form-check mt-3">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="privacyChecked"
                    />
                    <label
                      className="form-check-label text-muted"
                      htmlFor="privacyChecked"
                    >
                      By checking the box, I hereby acknowledge that I have
                      received a copy of the Notice of Privacy Practices. I
                      understand that if I have questions or complaints about my
                      privacy rights I can Contact the person listed above. I
                      further understand that The Dentist Is IN will offer the
                      updates to this Notice of Privacy Practices should it
                      beamended, modified or changed in any way.
                    </label>
                  </div>
                  <div className="divider"></div>
                  <h5 className="text-center mb-3">The TeleDentists</h5>
                  <h3 className="text-center">NOTICE OF PRIVACY PRACTICES</h3>
                  <div className="divider"></div>
                  <p className="text-center">
                    THIS NOTICE DESCRIBES HOW HEALTH INFORMATION ABOUT YOU MAY
                    BE USED AND DISCLOSED AND HOW YOU CAN GET ACCESS TO THIS
                    INFORMATION.
                  </p>
                  <p className="text-center">
                    PLEASE REVIEW IT CAREFULLY. THE PRIVACY OF YOUR HEALTH
                    INFORMATION IS IMPORTANT TO US.
                  </p>
                  <div className="divider"></div>
                  <h4 className="mb-3">
                    <strong>OUR LEGAL DUTY</strong>
                  </h4>
                  <p>
                    We are required by applicable federal and state law to
                    maintain the privacy of your health information. We are also
                    required to give you this Notice about our privacy
                    practices, our legal duties, and your rights concerning your
                    health information. We must follow the privacy practices
                    that are described in this Notice while it is in effect.
                    This Notice took effect April 14, 2003, and will remain in
                    effect until we replace it.
                  </p>
                  <p>
                    We reserve the right to change our privacy practices and the
                    terms of this Notice at any time, provided such changes are
                    permitted by applicable law. We reserve the right to make
                    the changes in our privacy practices and the new terms of
                    our Notice effective for all health information that we
                    maintain, including health information we created or
                    received before we made the changes. Before we make a
                    significant change in our privacy practices, we will change
                    this Notice and make the new Notice available upon request.
                  </p>
                  <p>
                    You may request a copy of our Notice at any time. For more
                    information about our privacy practices, or for additional
                    copies of this Notice, please contact us using the
                    information listed at the end of this Notice.
                  </p>
                  <div className="divider"></div>
                  <h4 className="mb-3">
                    <strong>USES AND DISCLOSURES OF HEALTH INFORMATION</strong>
                  </h4>
                  <p>
                    We use and disclose health information about you for
                    treatment, payment, and healthcare operations. For example:
                  </p>
                  <p>
                    <strong>Treatment:</strong> We may use or disclose your
                    health information to a physician or other healthcare
                    provider providing treatment to you.
                  </p>
                  <p>
                    <strong>Payment:</strong> We may use and disclose your
                    health information to obtain payment for services we provide
                    to you.
                  </p>
                  <p>
                    <strong>Healthcare Operations:</strong> We may use and
                    disclose your health information in connection with our
                    healthcare operations.Healthcare operations include quality
                    assessment and improvement activities, reviewing the
                    competence or qualifications of healthcare professionals,
                    evaluating practitioner and provider performance, conducting
                    training programs, accreditation, certification, licensing
                    or credentialing activities.
                  </p>
                  <p>
                    <strong>Your Authorization:</strong>In addition to our use
                    of your health information for treatment, payment or
                    healthcare operations, you may give us written authorization
                    to use your health information or to disclose it to anyone
                    for any purpose. If you give us an authorization, you may
                    revoke it in writing at any time. Your revocation will not
                    affect any use or disclosures permitted by your
                    authorization while it was in effect. Unless you give us a
                    written authorization, we cannot use or disclose your health
                    information for any reason except those described in this
                    Notice.
                  </p>
                  <p>
                    <strong>Your Authorization:</strong> In addition to our use
                    of your health information for treatment, payment or
                    healthcare operations, you may give us written authorization
                    to use your health information or to disclose it to anyone
                    for any purpose. If you give us an authorization, you may
                    revoke it in writing at any time. Your revocation will not
                    affect any use or disclosures permitted by your
                    authorization while it was in effect. Unless you give us a
                    written authorization, we cannot use or disclose your health
                    information for any reason except those described in this
                    Notice.
                  </p>
                  <p>
                    <strong>To Your Family and Friends:</strong> We must
                    disclose your health information to you, as described in the
                    Patient Rights section of this Notice. We may disclose your
                    health information to a family member, friend or other
                    person to the extent necessary to help with your healthcare
                    or with payment for your healthcare, but only if you agree
                    that we may do so.
                  </p>
                  <p>
                    <strong>Persons Involved In Care:</strong> We may use or
                    disclose health information to notify, or assist in the
                    notification of (including identifying or locating) a family
                    member, your personal representative or another person
                    responsible for your care, of your location, your general
                    condition, or death. If you are present, then prior to use
                    or disclosure of your health information, we will provide
                    you with an opportunity to object to such uses or
                    disclosures. In the event of your incapacity or emergency
                    circumstances, we will disclose health information based on
                    a determination using our professional judgment disclosing
                    only health information that is directly relevant to the
                    person’s involvement in your healthcare. We will also use
                    our professional judgment and our experience with common
                    practice to make reasonable inferences of your best interest
                    in allowing a person to pick up filled prescriptions,
                    medical supplies, x-rays, or other similar forms of health
                    information.
                  </p>
                  <p>
                    <strong>Marketing Health-Related Services:</strong> We will
                    not use your health information for marketing communications
                    without your written authorization.
                  </p>
                  <p>
                    <strong>Required by Law:</strong> We may use or disclose
                    your health information when we are required to do so by
                    law.
                  </p>
                  <p>
                    <strong>Abuse or Neglect:</strong> We may disclose your
                    health information to appropriate authorities if we
                    reasonably believe that you are a possible victim of abuse,
                    neglect, or domestic violence or the possible victim of
                    other crimes. We may disclose your health information to the
                    extent necessary to avert a serious threat to your health or
                    safety or the health or safety of others.
                  </p>
                  <p>
                    <strong>National Security:</strong> We may disclose to
                    military authorities the health information of Armed Forces
                    personnel under certain circumstances. We may disclose to
                    authorized federal officials health information required for
                    lawful intelligence, counterintelligence, and other national
                    security activities. We may disclose to correctional
                    institution or law enforcement official having lawful
                    custody of protected health information of inmate or patient
                    under certain circumstances.
                  </p>
                  <p>
                    <strong>Appointment Reminders:</strong> We may use or
                    disclose your health information to provide you with
                    appointment reminders (such as voicemail messages,
                    postcards, or letters).
                  </p>
                  <div className="divider"></div>
                  <h4 className="mb-3">
                    <strong>PATIENT RIGHTS</strong>
                  </h4>
                  <p>
                    <strong>Access:</strong> You have the right to look at or
                    get copies of your health information, with limited
                    exceptions. You may request that we provide copies in a
                    format other than photocopies. We will use the format you
                    request unless we cannot practicably do so. (You must make a
                    request in writing to obtain access to your health
                    information. You may obtain a form to request access by
                    using the contact information listed at the end of this
                    Notice. We will charge you a reasonable cost-based fee for
                    expenses such as copies and staff time. You may also request
                    access by sending us a letter to the address at the end of
                    this Notice. If you request copies, we will charge you $0.75
                    for each page, $ 35 per hour for staff time to locate and
                    copy your health information, and postage if you want the
                    copies mailed to you. If you request an alternative format,
                    we will charge a cost-based fee for providing your health
                    information in that format. If you prefer, we will prepare a
                    summary or an explanation of your health information for a
                    fee. Contact us using the information listed at the end of
                    this Notice for a full explanation of our fee structure.
                  </p>
                  <p>
                    <strong>Disclosure Accounting:</strong> You have the right
                    to receive a list of instances in which we or our business
                    associates disclosed your health information for purposes,
                    other than treatment, payment, healthcare operations and
                    certain other activities, for the last 6 years, but not
                    before April 14, 2003. If you request this accounting more
                    than once in a 12-month period, we may charge you a
                    reasonable, cost-based fee for responding to these
                    additional requests.
                  </p>
                  <p>
                    <strong>Restriction:</strong> You have the right to request
                    that we place additional restrictions on our use or
                    disclosure of your health information. We are not required
                    to agree to these additional restrictions, but if we do, we
                    will abide by our agreement (except in an emergency).
                  </p>
                  <p>
                    <strong>Alternative Communication:</strong> You have the
                    right to request that we communicate with you about your
                    health information by alternative means or to alternative
                    locations.{" "}
                    <strong>
                      {"{You must make your request in writing.}"}
                    </strong>{" "}
                    Your request must specify the alternative means or location,
                    and provide satisfactory explanation how payments will be
                    handled under the alternative means or location you request.
                  </p>
                  <p>
                    <strong>Amendment:</strong> You have the right to request
                    that we amend your health information. (Your request must be
                    in writing, and it must explain why the information should
                    be amended.) We may deny your request under certain
                    circumstances.
                  </p>
                  <p>
                    <strong>Electronic Notice:</strong> If you receive this
                    Notice on our Web site or by electronic mail (e-mail), you
                    are entitled to receive this Notice in written form.
                  </p>
                  <div className="divider"></div>
                  <h4 className="mb-3">
                    <strong>QUESTIONS AND COMPLAINTS</strong>
                  </h4>
                  <p>
                    If you want more information about our privacy practices or
                    have questions or concerns, please contact us.
                  </p>
                  <p>
                    If you are concerned that we may have violated your privacy
                    rights, or you disagree with a decision we made about access
                    to your health information or in response to a request you
                    made to amend or restrict the use or disclosure of your
                    health information or to have us communicate with you by
                    alternative means or at alternative locations, you may
                    complain to us using the contact information listed at the
                    end of this Notice. You also may submit a written complaint
                    to the U.S. Department of Health and Human Services. We will
                    provide you with the address to file your complaint with the
                    U.S. Department of Health and Human Services upon request.
                  </p>
                  <p>
                    We support your right to the privacy of your health
                    information. We will not retaliate in any way if you choose
                    to file a complaint with us or with the U.S. Department of
                    Health and Human Services.
                  </p>
                  <p>Contact Officer: Michael Sigler</p>
                  <p>
                    Telephone: 888-641-5505 Email:{" "}
                    <a
                      href="mailto:info@theteledentists.com"
                      className="text-decoration-none"
                    >
                      info@theteledentists.com
                    </a>
                  </p>
                  <p>Address: 5730 Ward Parkway, Kansas City, MO 64113</p>
                  <a href="#" className="btn btn-primary">
                    Submit
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- //Form Update and Submit Modal End--> */}
    </>
  );
};

export default FormUpdateAndSubmitModal;
