import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";

import "../../assets/css/style.css";

import Navbar from "../../components/Navbar/Navbar";
import Referral from "../../components/Referral/Referral";
import ReferralModel from "../../components/Referral/ReferralModel";
import ReferralProviderInformation from "../../components/Referral/ReferralProviderInformation";
import ReferralComplete from "../../components/Referral/ReferralComplete";

import {
  getPatientsIncompleteReferralSuggestion_Ajax,
  getPatientReferrals_Ajax,
  getRecommendedProviders_Ajax,
  referToDoctor_Ajax,
  getProviderData_Ajax
} from "../../helpers/requests";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import {
  handleApiErrors,
  isEmailAddressValid,
  showAlertDialouge
} from "../../helpers/utils";
import {
  Pagination as PaginationConstants,
  ReferralSuggestingCompletionType,
  DistanceOptions,
  GenderOptions,
  ReferToProviderTypes
} from "../../constants";
import { setIsReloadPatientStatusSummary } from "../../redux/actions/userData";

export default function Referrals() {
  const dispatch = useDispatch();
  const alert = useAlert();

  const currentPatient = useSelector(
    (state) => state?.userData?.currentPatient
  );
  const isOnlineCsrAvailable = useSelector(
    (state) => state?.userData?.isOnlineCsrAvailable
  );

  const isReferralSuggestionCompletionDisabled = useSelector(
    (state) =>
      state?.appSettings?.settingsData
        ?.OutsideReferralSuggestionCompletionEnabled
  );

  const [referralSuggestion, setReferralSuggestion] = useState(null);
  const [referrals, setReferrals] = useState([]);
  const [availableProviders, setAvailableProviders] = useState([]);
  const [mapUrl, setMapUrl] = useState(null);
  const [totalAvailableProviderCount, setTotalAvailableProviderCount] =
    useState(0);
  const [providerData, setProviderData] = useState(null);

  useEffect(() => {
    if (currentPatient && currentPatient.zip) {
      setInputs({ ...inputs, patientZip: currentPatient.zip });
    } else {
      setInputs({ ...inputs, patientZip: "" });
    }
  }, [currentPatient]);

  const [inputs, setInputs] = useState({
    patientZip: "",
    distanceFilter: DistanceOptions.Five,
    completeReferralType: ReferralSuggestingCompletionType.AvailableProvider,
    email: "",
    firstName: "",
    lastName: ""
  });

  const {
    patientZip,
    distanceFilter,
    completeReferralType,
    email,
    firstName,
    lastName
  } = inputs;

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  useEffect(() => {
    if (patientZip) {
      searchRecommendedProviders(
        PaginationConstants.FirstPageNo,
        PaginationConstants.ItemPerPage
      );
    }
  }, [distanceFilter]);

  function getPatientsIncompleteReferralSuggestion() {
    var patientInfo = currentPatient;

    if (patientInfo?.patid && !isReferralSuggestionCompletionDisabled) {
      dispatch(showLoadingSpinner());
      getPatientsIncompleteReferralSuggestion_Ajax(
        patientInfo.patid,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (
            response &&
            response.success &&
            response.data &&
            response.data.suggestion
          ) {
            setReferralSuggestion(response.data.suggestion);
          } else if (response && !response.success) {
            setReferralSuggestion(null);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function getPatientReferrals() {
    var patientInfo = currentPatient;

    if (patientInfo?.patid) {
      var params = {
        patientId: patientInfo.patid
      };

      dispatch(showLoadingSpinner());
      getPatientReferrals_Ajax(
        params,
        function (response) {
          dispatch(hideLoadingSpinner());
          var arr = [];
          if (
            response &&
            response.success &&
            response.data &&
            Array.isArray(response.data) &&
            response.data.length > 0
          ) {
            arr = response.data;
          } else if (response && response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }

          setReferrals(arr);
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function searchRecommendedProviders(currentPageNum, itemPerPage) {
    if (!patientZip) {
      showAlertDialouge("Error", "Please enter a zip code");
    }

    if (currentPageNum && itemPerPage) {
      setMapUrl(null);
      var params = {
        patientId: referralSuggestion.patientId,
        distance: distanceFilter,
        currentPage: currentPageNum,
        itemsPerPage: itemPerPage
      };

      dispatch(showLoadingSpinner());
      getRecommendedProviders_Ajax(
        patientZip,
        params,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (
            response &&
            response.data &&
            response.data.providers &&
            Array.isArray(response.data.providers) &&
            response.data.providers.length > 0
          ) {
            response.data.providers.forEach((provider, index) => {
              if (
                provider &&
                provider.offices &&
                Array.isArray(provider.offices)
              ) {
                if (provider.offices.length > 0) {
                  provider.firstOffice = provider.offices[0];
                }

                if (provider.offices.length > 1) {
                  provider.additionalOffices = [];
                }
              }
            });

            setTotalAvailableProviderCount(response.data.totalCount);
            setAvailableProviders(response.data.providers);
          } else if (response && !response.success && response.message) {
            setAvailableProviders([]);
            setTotalAvailableProviderCount(0);
            setMapUrl(null);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function seeMoreBtnClicked(officeProvider) {
    let providerId = officeProvider?.providerId
      ? officeProvider.providerId
      : officeProvider.importedProviderId;

    if (providerId) {
      var arr = [...availableProviders];

      arr.forEach((provider, index) => {
        if (
          provider &&
          (provider.providerid == providerId ||
            provider.importedProviderId == providerId)
        ) {
          provider.additionalOffices = [];
          if (
            provider.offices &&
            Array.isArray(provider.offices) &&
            provider.offices.length > 1
          ) {
            provider.additionalOffices = provider.offices.slice(1);
          }
        }
      });

      setAvailableProviders([]);
      setAvailableProviders(arr);
    }
  }

  function seeLessBtnClicked(officeProvider) {
    let providerId = officeProvider?.providerId
      ? officeProvider.providerId
      : officeProvider.importedProviderId;

    if (providerId) {
      var arr = [...availableProviders];

      arr.forEach((provider, index) => {
        if (
          provider &&
          (provider.providerid == providerId ||
            provider.importedProviderId == providerId)
        ) {
          provider.additionalOffices = [];
        }
      });

      setAvailableProviders([]);
      setAvailableProviders(arr);
    }
  }

  function referToProvider(provider, office) {
    if (provider && office && referralSuggestion) {
      var patientInfo = currentPatient;

      if (patientInfo) {
        var params = {
          PatientId: patientInfo.patid,
          ReferToImportedProviderId: provider.importedProviderId,
          ReferToId: provider.providerid,
          ReferToProviderType: ReferToProviderTypes.Recommendation,
          ReferFromId: referralSuggestion.providerId,
          ReferralSuggestionId: referralSuggestion.id,
          ReferToImportedOfficeId: office.importedOfficeId
        };

        dispatch(showLoadingSpinner());
        referToDoctor_Ajax(
          JSON.stringify(params),
          function (response) {
            dispatch(hideLoadingSpinner());
            if (response && response.success) {
              getPatientReferrals();
              getPatientsIncompleteReferralSuggestion();
              setAvailableProviders([]);
              dispatch(setIsReloadPatientStatusSummary(true));
            } else if (response && !response.success && response.message) {
              showAlertDialouge("Error", response.message);
            }
          },
          function (err) {
            dispatch(hideLoadingSpinner());
            handleApiErrors(err);
          }
        );
      }
    }
  }

  function inviteProviderForRecommendedReferral() {
    if (!isEmailAddressValid(email)) {
      showAlertDialouge("Error", "Please enter a valid email address");
    }

    var patientInfo = currentPatient;

    if (patientInfo && referralSuggestion) {
      var params = {
        PatientId: patientInfo.patid,
        Email: email,
        FirstName: firstName,
        LastName: lastName,
        ReferToProviderType: ReferToProviderTypes.Recommendation,
        ReferFromId: referralSuggestion.providerId,
        ReferralSuggestionId: referralSuggestion.id
      };

      dispatch(showLoadingSpinner());
      referToDoctor_Ajax(
        JSON.stringify(params),
        function (response) {
          dispatch(hideLoadingSpinner());
          if (response && response.success) {
            getPatientReferrals();
            getPatientsIncompleteReferralSuggestion();
            setAvailableProviders([]);
            dispatch(setIsReloadPatientStatusSummary(true));
          } else if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function generateMapUrl(office) {
    if (office && office?.lat && office?.lng) {
      var url =
        "https://maps.google.com/maps?q=" +
        office.lat +
        "," +
        office.lng +
        "&output=embed";
      setMapUrl(url);
    } else {
      showAlertDialouge("Error", "Invalid office location");
    }
  }

  function getProviderData(providerId) {
    if (providerId) {
      dispatch(showLoadingSpinner());
      setProviderData(null);
      getProviderData_Ajax(
        providerId,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (response && response.success && response.data) {
            setProviderData(response.data);
          } else if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          handleApiErrors(err);
          dispatch(hideLoadingSpinner());
        }
      );
    }
  }

  function getReferredFromData(referral) {
    if (referral && referral.referFromId) {
      getProviderData(referral.referFromId);
    }
  }

  function getReferredToData(referral) {
    if (referral && referral.referToId) {
      getProviderData(referral.referToId);
    }
  }

  function resetReferralInfo() {
    setTotalAvailableProviderCount(0);
    setAvailableProviders([]);

    setInputs((inputs) => ({
      ...inputs,
      email: '',
      firstName: '',
      lastName: '',
      completeReferralType: ReferralSuggestingCompletionType.AvailableProvider,
      distanceFilter: DistanceOptions.Five,
      patientZip: currentPatient && currentPatient.zip ? currentPatient.zip : ''
    }));
  }

  return (
    <body>
      <Referral
        currentPatient={currentPatient}
        getPatientReferrals={getPatientReferrals}
        getPatientsIncompleteReferralSuggestion={
          getPatientsIncompleteReferralSuggestion
        }
        referrals={referrals}
        referralSuggestion={referralSuggestion}
        getReferredFromData={getReferredFromData}
        getReferredToData={getReferredToData}
        isOnlineCsrAvailable={isOnlineCsrAvailable}
        resetReferralInfo = {resetReferralInfo}
      />
      <ReferralModel
        currentPatient={currentPatient}
        referralSuggestion={referralSuggestion}
        availableProviders={availableProviders}
        patientZip={patientZip}
        distanceFilter={distanceFilter}
        completeReferralType={completeReferralType}
        mapUrl={mapUrl}
        totalAvailableProviderCount={totalAvailableProviderCount}
        email={email}
        firstName={firstName}
        lastName={lastName}
        generateMapUrl={generateMapUrl}
        seeMoreBtnClicked={seeMoreBtnClicked}
        seeLessBtnClicked={seeLessBtnClicked}
        searchRecommendedProviders={searchRecommendedProviders}
        referToProvider={referToProvider}
        handleChange={handleChange}
        inviteProviderForRecommendedReferral={
          inviteProviderForRecommendedReferral
        }
      />
      <ReferralProviderInformation providerData={providerData} />
      <ReferralComplete />
      {/* <!-- scripts --> */}
      <script
        src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js"
        integrity="sha512-894YE6QWD5I59HgZOGReFYm4dnWc1Qt5NtvYSaNcOP+u1T9qYdvdihz0PPSiiqn/+/3e7Jo4EaG7TubfWGUrMQ=="
        crossOrigin="anonymous"
        referrerPolicy="no-referrer"
      ></script>
      <script
        src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"
        crossOrigin="anonymous"
      ></script>
    </body>
  );
}
