import React, { useEffect, useState } from "react";
import {
  getSmartScanImageCategoryName,
  onImageError
} from "../../helpers/utils";

export function SmartScanSelfie(props) {
  const { imageFile, retakeBtnClicked, category } = props;
  const [imgSrc, setImgSrc] = useState();

  useEffect(() => {
    if (imageFile) {
      let objURL = URL.createObjectURL(imageFile);
      setImgSrc(objURL);
    }
  }, [imageFile]);

  return (
    <div className="col-sm-6 mb-4">
      <div className="position-relative">
        <figure className="mb-0">
          <img
            className="w-100 h-100 object-cover border-radius-xlg"
            style={{ maxHeight: "310px" }}
            src={imgSrc}
            onError={onImageError}
            alt=""
          />
        </figure>
        <span className="badge bg-primary btn-xs fs-5 fw-normal m-3 position-absolute top-0 start-0">
          {getSmartScanImageCategoryName(category).toUpperCase()}
        </span>
        <button
          onClick={() => retakeBtnClicked(category)}
          style={{ background: "none" }}
          className="btn-xs fs-5 text-white fw-normal text-decoration-none lh-1 px-2 m-3 border position-absolute bottom-0 end-0"
        >
          RETAKE
        </button>
      </div>
    </div>
  );
}

export default SmartScanSelfie;
